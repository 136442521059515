import React, { Fragment, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'sonner';

import HorizontalLine from '../HorizontalLine';
import { classNames } from '../../common/utils';
import { calendlyDemoLink } from '../../common/constants';
import InputNumber from '../ui/InputNumber';
import { Button } from '../ui/Button';
import { createPaymentLink } from '../../api';
import { useAppSelector } from '../../core/store';

type PricingTableModalProps = {
  open: boolean;
  onClose: () => void;
};

const tiers = [
  {
    name: 'Free Plan',
    id: 'free_plan',
    href: '#',
    price: { monthly: 0, annually: 0 },
    description: 'Perfect for small teams up to 15 members.',
    features: ['Full access to the dashboard', 'Up to 15 organization members'],
    featured: false,
    flexible: false,
    current: true,
    cta: 'Current plan'
  },
  {
    name: 'Pro Plan',
    id: 'pro_plan',
    href: '#',
    price: { monthly: 9, annually: 90 },
    description: 'For teams and businesses ready to fully unlock the power of career development.',
    features: ['Full access to the dashboard', 'Priority customer support'],
    featured: false,
    flexible: true,
    quantity: {
      min: 16,
      max: 999
    },
    cta: 'Get started'
  },
  {
    name: 'Enterprise',
    id: 'enterprise',
    href: calendlyDemoLink,
    price: 'Custom',
    description:
      'Custom solutions for large-scale organizations focused on in-depth career development strategies.',
    features: [
      'Full access to the dashboard',
      'Unlimited number of organization members',
      'Early access to new features',
      'Custom integrations',
      'Founder support 24/7'
    ],
    featured: true,
    flexible: false,
    cta: 'Contact us'
  }
];

const PricingTableModal = ({ open, onClose }: PricingTableModalProps): JSX.Element => {
  const params = useParams();

  const cancelButtonRef = useRef(null);

  const employees = useAppSelector((state) => state.employees.employees);

  const [numberOfTeamMembers, setNumberOfTeamMembers] = useState(Math.max(employees.length, 16));

  const organizationSlug = params.organizationSlug ?? '';

  async function redirectToStripeCheckout(): Promise<void> {
    try {
      const url = await createPaymentLink({
        organizationSlug,
        numOfTeamSeats: numberOfTeamMembers
      });

      window.open(url, '_blank', 'noopener');
    } catch (err) {
      toast.error("Couldn't create payment link. Please try again.");
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-[75rem] sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Pricing Table
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                      {tiers.map((tier) => (
                        <div
                          key={tier.id}
                          className={classNames(
                            tier.featured ? 'bg-gray-900 ring-gray-900' : 'ring-gray-200',
                            'flex flex-col justify-between rounded-3xl p-8 ring-1 xl:p-10'
                          )}>
                          <div>
                            <h3
                              id={tier.id}
                              className={classNames(
                                tier.featured ? 'text-white' : 'text-gray-900',
                                'text-lg font-semibold leading-8'
                              )}>
                              {tier.name}
                            </h3>
                            <p
                              className={classNames(
                                tier.featured ? 'text-gray-300' : 'text-gray-600',
                                'mt-4 text-sm leading-6 sm:min-h-[72px]'
                              )}>
                              {tier.description}
                            </p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                              <span
                                className={classNames(
                                  tier.featured ? 'text-white' : 'text-gray-900',
                                  'text-4xl font-bold tracking-tight'
                                )}>
                                {typeof tier.price === 'string'
                                  ? tier.price
                                  : `$${
                                      tier.flexible
                                        ? tier.price.monthly * numberOfTeamMembers
                                        : tier.price.monthly
                                    }`}
                              </span>
                              {typeof tier.price !== 'string' ? (
                                <span
                                  className={classNames(
                                    tier.featured ? 'text-gray-300' : 'text-gray-600',
                                    'text-sm font-semibold leading-6'
                                  )}>
                                  /month
                                </span>
                              ) : null}
                            </p>

                            <ul
                              role="list"
                              className={classNames(
                                tier.featured ? 'text-gray-300' : 'text-gray-600',
                                tier.flexible ? 'mt-6 xl:mt-7' : 'mt-8 xl:mt-10',
                                'space-y-3 text-sm leading-6'
                              )}>
                              <li
                                className={`${
                                  tier.flexible ? 'flex' : 'hidden'
                                } flex-row justify-between items-center gap-3 mt-4`}>
                                <div className="flex flex-row justify-start items-center gap-2.5">
                                  <UsersIcon
                                    className="size-5 text-indigo-600"
                                    aria-hidden="true"
                                  />
                                  <InputNumber
                                    value={numberOfTeamMembers}
                                    onChange={setNumberOfTeamMembers}
                                    min={tier.quantity?.min}
                                    max={tier.quantity?.max}
                                  />
                                  <p className="text-sm text-gray-700">team members</p>
                                </div>
                              </li>
                              {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                  <CheckIcon
                                    className={classNames(
                                      tier.featured ? 'text-white' : 'text-indigo-600',
                                      'h-6 w-5 flex-none'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>

                          <Button
                            disabled={tier.current}
                            className={classNames(
                              tier.featured &&
                                'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                              'mt-6 w-full'
                            )}
                            onClick={() => {
                              if (tier.featured) {
                                window.open(tier.href, '_blank', 'noopener');
                              }
                              if (tier.flexible) {
                                redirectToStripeCheckout();
                              }
                            }}>
                            {tier.cta}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PricingTableModal;
