import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '../core/store';
import { AppLoader } from '../components';

const HomePage = (): JSX.Element => {
  const navigate = useNavigate();

  const areOrganizationsPending = useAppSelector(
    (state) => state.organization.areOrganizationsPending
  );
  const organizations = useAppSelector((state) => state.organization.organizations);

  useEffect(() => {
    if (areOrganizationsPending) {
      return;
    }
    if (!areOrganizationsPending && organizations.length === 0) {
      navigate('/onboarding');
      return;
    }

    if (organizations.length > 0) {
      navigate(`/${organizations[0].slug}`);
      return;
    }

    navigate('/onboarding');
  }, [areOrganizationsPending]);

  return <AppLoader />;
};

export default HomePage;
