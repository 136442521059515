import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { GiArcheryTarget } from 'react-icons/gi';
import { TbArcheryArrow } from 'react-icons/tb';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import {
  BuildingOffice2Icon,
  HomeModernIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  LockClosedIcon,
  LockOpenIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import ProgressBar from '@ramonak/react-progress-bar';
import moment from 'moment-timezone';

import { useAppDispatch } from '../../hooks';
import {
  AppLoader,
  Avatar,
  Button,
  CheckInsOverviewModal,
  CreateCheckInModal,
  CreateNewKeyResultModal,
  CreateNewObjectiveModal,
  EditGoalModal,
  GoalStatusListbox,
  HorizontalLine,
  Menu,
  NotFoundComponent,
  VerticalLine,
  WysiwygEditor
} from '../../components';
import { useAppSelector } from '../../core/store';
import { deleteGoal, fetchGoal, updateGoal } from '../../core/goal/goalsSlice';
import Goal, {
  GOAL_STATUS_COLORS_MAP,
  GOAL_STATUS_MAP,
  GoalData,
  GoalDomain,
  GoalMeasurementType,
  GoalStatus,
  GoalType
} from '../../core/goal/Goal.model';
import { emerald500, gray600, green500, orange500, red500 } from '../../common/colors';
import { checkPermissionAccess, timeAgo } from '../../common/utils';
import { defaultDateFormat } from '../../common/timezone';
import _ from 'lodash';

const GoalOverviewPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const isGoalPending = useAppSelector((state) => state.goals.isGoalPending);
  const goal = useAppSelector((state) => state.goals.goal);

  const [openCreateCheckInModal, setOpenCreateCheckInModal] = useState(false);
  const [openEditGoalModal, setOpenEditGoalModal] = useState(false);
  const [openShowAllCheckInsModal, setOpenShowAllCheckInsModal] = useState(false);
  const [openCreateNewObjectiveModal, setOpenCreateNewObjectiveModal] = useState(false);
  const [openCreateNewKeyResultModal, setOpenCreateNewKeyResultModal] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';
  const goalID = params.goalID ?? '';

  useEffect(() => {
    dispatch(fetchGoal({ organizationSlug, goalID }));
  }, [params.goalID]);

  const updateGoalDescriptionDebounced = useMemo(() => {
    return _.debounce((content: string) => {
      dispatch(
        updateGoal({
          organizationSlug,
          goalID,
          data: { description: content }
        })
      );
    }, 500);
  }, [params.goalID]);

  function showGoalSettings(goal: GoalData): JSX.Element {
    const canEditGoal =
      goal.owner?.id === authUser.id ||
      goal.ownerManagerID === authUser.id ||
      checkPermissionAccess(authUser.permissionRole, 'editor');

    if (!canEditGoal) {
      <></>;
    }

    return (
      <div className="flex flex-row items-center gap-3">
        {goal.type === GoalType.KEY_RESULT && (
          <Button size={'sm'} onClick={() => setOpenCreateCheckInModal(true)}>
            Update progress
          </Button>
        )}
        <Menu
          buttonName={
            <div className="flex flex-row items-center gap-1 px-3 py-[5px] border border-gray-200 rounded-md hover:bg-gray-50">
              <Cog6ToothIcon className="size-5" aria-hidden="true" />
            </div>
          }
          topOffset="top-10"
          items={[
            {
              name: `Edit ${goal.type === GoalType.OBJECTIVE ? 'objective' : 'key result'}`,
              onClick: () => setOpenEditGoalModal(true)
            },
            {
              name: `Delete ${goal.type === GoalType.OBJECTIVE ? 'objective' : 'key result'}`,
              color: 'text-red-600',
              onClick: () => {
                dispatch(deleteGoal({ organizationSlug, goalID })).then(() => {
                  navigate(-1);
                });
              }
            }
          ]}
        />
      </div>
    );
  }

  function getProgressBarColor(goal: GoalData): string {
    if (goal.status === GoalStatus.ON_TRACK) {
      return green500;
    }
    if (goal.status === GoalStatus.AT_RISK) {
      return orange500;
    }
    if (goal.status === GoalStatus.OFF_TRACK) {
      return red500;
    }
    if (goal.status === GoalStatus.COMPLETED) {
      return emerald500;
    }
    return gray600;
  }

  if (isGoalPending) {
    return <AppLoader />;
  }

  if (!goal) {
    return (
      <NotFoundComponent
        title="Goal not found"
        description="The goal you are looking for does not exist."
      />
    );
  }

  const canEditGoal =
    goal.owner?.id === authUser.id ||
    goal.ownerManagerID === authUser.id ||
    checkPermissionAccess(authUser.permissionRole, 'editor');

  return (
    <>
      <div className="flex flex-row sm:gap-6 lg:gap-8 w-full h-full">
        <div className="flex flex-col gap-5 mt-6 w-full sm:w-2/3">
          <div className="flex flex-row justify-between items-center gap-4">
            <button
              className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600 -ml-1"
              onClick={() => {
                navigate('../');
              }}>
              <ChevronLeftIcon className="size-5 text-gray-600" />
              Back to all goals
            </button>

            <div className="flex-initial flex sm:hidden">{showGoalSettings(goal)}</div>
          </div>

          {/* Title and status */}
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between items-end gap-4">
              <div className="flex-1 flex flex-col">
                <div className="flex flex-row items-center gap-1 text-sm leading-6 text-gray-700">
                  {goal.type === GoalType.OBJECTIVE ? (
                    <>
                      <GiArcheryTarget className="size-4 text-gray-700" />
                      Objective
                    </>
                  ) : (
                    <>
                      <TbArcheryArrow className="size-4 rotate-180 transform text-gray-700" />
                      Key Result
                    </>
                  )}
                </div>

                <div className="flex-1 flex flex-row items-center">
                  <p className="w-full text-base font-medium text-gray-900">{goal.title}</p>
                </div>
              </div>

              <div className="flex-initial hidden sm:flex">{showGoalSettings(goal)}</div>
            </div>

            <div className="flex flex-col gap-1.5">
              <ProgressBar
                animateOnRender={true}
                completed={new Goal(goal).calculateProgress(goal)}
                bgColor={getProgressBarColor(goal)}
                height="10px"
                isLabelVisible={false}
              />

              <div className="flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-3">
                <div className="flex flex-row items-center gap-3">
                  <p className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                    Start:
                    <span className="text-gray-700">
                      {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                      {goal.type === GoalType.KEY_RESULT ? goal.startValue ?? 0 : '0%'}
                      {goal.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                    </span>
                  </p>
                  <p className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                    Current:
                    <span className="text-gray-700">
                      {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                      {goal.type === GoalType.KEY_RESULT
                        ? (goal.currentProgress ?? 0) + (goal.startValue ?? 0)
                        : `${new Goal(goal).calculateProgress(goal)}%`}
                      {goal.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                    </span>
                  </p>
                  <p className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                    Goal:
                    <span className="text-gray-700">
                      {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                      {goal.type === GoalType.KEY_RESULT ? goal.targetValue ?? 0 : '100%'}
                      {goal.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                    </span>
                  </p>
                </div>

                <div className="flex flex-row justify-end sm:justify-start items-center gap-3">
                  <p className={`text-sm font-medium ${GOAL_STATUS_COLORS_MAP[goal.status]}`}>
                    {new Goal(goal).calculateProgress(goal)}%
                  </p>
                  {canEditGoal ? (
                    <GoalStatusListbox
                      status={goal.status}
                      onChange={(newStatus) => {
                        dispatch(
                          updateGoal({
                            organizationSlug,
                            goalID,
                            data: { status: newStatus as GoalStatus }
                          })
                        );
                      }}
                    />
                  ) : (
                    <p className={`text-sm font-medium ${GOAL_STATUS_COLORS_MAP[goal.status]}`}>
                      {GOAL_STATUS_MAP[goal.status]}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="flex flex-col gap-1.5">
            <p className="text-md font-medium text-gray-700">Description</p>
            <WysiwygEditor
              initialContent={goal.description}
              placeholder="Write goal description here..."
              stringHandler="html"
              editable={canEditGoal}
              className="block w-full min-h-fit rounded-md border-none py-2 px-3 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400"
              onChange={(content) => {
                updateGoalDescriptionDebounced(content);
              }}
            />
          </div>

          {/* Latest check-in */}
          {goal.type === GoalType.KEY_RESULT && goal.checkIns.length > 0 && (
            <div className="flex flex-col gap-1.5">
              <div className="flex flex-row justify-between items-end gap-3">
                <p className="text-md font-medium text-gray-700">Latest check-in</p>

                <Button
                  size={'sm'}
                  variant={'outline'}
                  onClick={() => setOpenShowAllCheckInsModal(true)}>
                  Show all check-ins
                </Button>
              </div>

              <div className="flex flex-col gap-2 p-3 border border-gray-200 rounded-md shadow w-full">
                <div className="flex flex-row items-center gap-8">
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-medium text-gray-700">Progress</p>
                    <p className="text-sm text-gray-600">
                      {`Added `}
                      <span
                        className={`${
                          GOAL_STATUS_COLORS_MAP[goal.checkIns[0].status]
                        } font-medium`}>
                        {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                        {goal.checkIns[0].progressIncreaseBy ?? 0}
                        {goal.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                      </span>
                      {` to the goal of: `}
                      <span className="text-gray-900 font-medium">
                        {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                        {goal.targetValue ?? 0}
                        {goal.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                      </span>
                    </p>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-medium text-gray-700">Status</p>
                    <p
                      className={`text-sm font-medium ${
                        GOAL_STATUS_COLORS_MAP[goal.checkIns[0].status]
                      }`}>
                      {GOAL_STATUS_MAP[goal.checkIns[0].status]}
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <p className="text-sm font-medium text-gray-700">Progress description</p>
                  <p className="text-gray-900 text-sm">
                    {goal.checkIns[0].progressDescription || 'No description'}
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Sub-goals */}
          <div className="flex flex-col gap-0.5">
            <div className="flex flex-row gap-3 justify-between">
              <p className="text-md font-medium text-gray-700">Sub-goals</p>
              {goal.children.length > 0 && (
                <div className="flex flex-row items-center gap-3 pr-2">
                  <p className="min-w-28 w-28 text-xs font-medium text-gray-600">Domain</p>
                  <p className="min-w-10 w-10 text-xs font-medium text-gray-600">Owner</p>
                  <p className="min-w-[52px] w-[52px] text-xs font-medium text-gray-600 whitespace-nowrap">
                    Due date
                  </p>
                  <p className="min-w-9 w-9 text-xs font-medium text-gray-600">Status</p>
                </div>
              )}
            </div>

            <HorizontalLine />

            <div className="flex flex-col gap-2 w-full mt-1.5">
              {goal.children.length === 0 && (
                <div className="flex flex-row justify-center items-center gap-3">
                  <p className="text-sm text-gray-500">No sub-goals yet</p>
                </div>
              )}
              {goal.children.map((subGoal) => (
                <NavLink
                  to={`../${subGoal.id}`}
                  key={subGoal.id}
                  className="flex-1 flex flex-row justify-start items-center gap-2 p-2 w-full text-left border border-gray-200 rounded-md shadow hover:border-gray-300 hover:shadow-md">
                  <div className="flex-1 flex flex-row justify-start text-left items-center w-full cursor-pointer">
                    <div className="flex-initial flex flex-row items-center gap-1.5">
                      {subGoal.type === GoalType.OBJECTIVE ? (
                        <GiArcheryTarget className="size-5 text-gray-700" />
                      ) : (
                        <TbArcheryArrow className="size-5 rotate-180 transform text-gray-700" />
                      )}
                    </div>

                    <div className="flex-1 flex flex-row items-center ml-1.5 w-full mr-4">
                      <p className="flex-1 text-sm text-gray-900">{subGoal.title}</p>
                    </div>

                    <div className="flex flex-row justify-end items-center gap-3">
                      <div className="flex items-center min-w-28 w-28">
                        {subGoal.domain === GoalDomain.ORGANIZATION && (
                          <div className="flex flex-row items-center gap-1.5">
                            <BuildingOffice2Icon className="size-5 text-gray-700" />
                            <p className="text-sm text-gray-900 max-w-[86px] truncate">
                              Organization
                            </p>
                          </div>
                        )}
                        {subGoal.domain === GoalDomain.DEPARTMENT && (
                          <div className="flex flex-row items-center gap-1.5">
                            <HomeModernIcon className="size-5 text-gray-700" />
                            <p className="text-sm text-gray-900 max-w-[86px] truncate">
                              {subGoal.department?.name}
                            </p>
                          </div>
                        )}
                        {subGoal.domain === GoalDomain.TEAM && (
                          <div className="flex flex-row items-center gap-1.5">
                            <UserGroupIcon className="size-5 text-gray-700" />
                            <p className="text-sm text-gray-900 max-w-[86px] truncate">
                              {subGoal.team?.name}
                            </p>
                          </div>
                        )}
                        {subGoal.domain === GoalDomain.INDIVIDUAL && (
                          <div className="flex flex-row items-center gap-1.5">
                            <UserIcon className="size-5 text-gray-700" />
                            <p className="text-sm text-gray-900 max-w-[86px] truncate">
                              Individual
                            </p>
                          </div>
                        )}
                      </div>
                      <p className="min-w-10 w-10">
                        <Avatar user={subGoal.owner} size={6} />
                      </p>
                      <p className="min-w-[52px] w-[52px] text-sm text-gray-900 whitespace-nowrap">
                        {moment(subGoal.dueDate).format('MMM D')}
                      </p>
                      <p
                        className={`text-sm font-medium min-w-9 w-9 ${
                          GOAL_STATUS_COLORS_MAP[subGoal.status]
                        }`}>
                        {new Goal(subGoal).calculateProgress(subGoal)}%
                      </p>
                    </div>
                  </div>
                </NavLink>
              ))}

              <div className="flex flex-row gap-3 justify-center items-center mt-1">
                <Button
                  variant={'outline'}
                  size={'sm'}
                  className="flex flex-row items-center gap-1.5 mr-1 text-gray-700"
                  onClick={() => setOpenCreateNewObjectiveModal(true)}>
                  <GiArcheryTarget className="size-5 shrink-0" aria-hidden="true" />
                  Add objective
                </Button>
                <p className="text-sm text-gray-700">or</p>
                <Button
                  variant={'outline'}
                  size={'sm'}
                  className="flex flex-row items-center gap-1.5 mr-1 text-gray-700"
                  onClick={() => setOpenCreateNewKeyResultModal(true)}>
                  <TbArcheryArrow
                    className="size-5 shrink-0 rotate-180 transform"
                    aria-hidden="true"
                  />
                  Add key result
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:flex mt-16">
          <VerticalLine />
        </div>

        {/* About this goal */}
        <div className="hidden sm:flex flex-col gap-3 w-1/3 mt-16">
          <p className="font-medium text-gray-900">About this goal</p>

          {/* Owner, accountable, privacy, due date, latest update */}
          <div className="flex flex-col md:flex-row gap-3 md:gap-12">
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-2 items-center">
                <Avatar size={8} user={goal.owner} />
                <div className="flex flex-col">
                  <p className="text-xs font-medium text-gray-700 whitespace-nowrap">Owned by</p>
                  <p className="text-sm font-medium text-gray-900 whitespace-nowrap">
                    {goal.owner ? `${goal.owner?.firstName} ${goal.owner?.lastName}` : 'No owner'}
                  </p>
                </div>
              </div>

              {goal.domain !== GoalDomain.INDIVIDUAL && (
                <div className="flex flex-row gap-2 items-center">
                  {goal.domain === GoalDomain.ORGANIZATION && (
                    <BuildingOffice2Icon className="size-8 text-gray-700" />
                  )}
                  {goal.domain === GoalDomain.DEPARTMENT && (
                    <HomeModernIcon className="size-8 text-gray-700" />
                  )}
                  {goal.domain === GoalDomain.TEAM && (
                    <UserGroupIcon className="size-8 text-gray-700" />
                  )}
                  <div className="flex flex-col">
                    <p className="text-xs font-medium text-gray-700 whitespace-nowrap">
                      Accountable {goal.domain === GoalDomain.ORGANIZATION ? '' : goal.domain}
                    </p>
                    <p className="text-sm font-medium text-gray-900 whitespace-nowrap">
                      {goal.domain === GoalDomain.ORGANIZATION && 'Organization'}
                      {goal.domain === GoalDomain.DEPARTMENT &&
                        (goal.department?.name ?? 'No department')}
                      {goal.domain === GoalDomain.TEAM && (goal.team?.name ?? 'No team')}
                    </p>
                  </div>
                </div>
              )}

              <div className="flex flex-row gap-2 items-center">
                {goal.private ? (
                  <LockClosedIcon className="size-8 text-gray-700" />
                ) : (
                  <LockOpenIcon className="size-8 text-gray-700" />
                )}

                <div className="flex flex-col">
                  <p className="text-xs font-medium text-gray-700">Privacy</p>
                  <p className="text-sm font-medium text-gray-900">
                    {goal.private ? 'Private' : 'Public'}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="flex flex-col">
                <p className="text-xs font-medium text-gray-700 whitespace-nowrap">Due date</p>
                <p className="text-sm font-medium text-gray-900 whitespace-nowrap">
                  {moment(goal.dueDate).format(defaultDateFormat)}
                </p>
              </div>

              <div className="flex flex-col">
                <p className="text-xs font-medium text-gray-700 whitespace-nowrap">Latest update</p>
                <p className="text-sm font-medium text-gray-900 whitespace-nowrap">
                  {timeAgo(new Date(), new Date(goal.updatedAt))}
                </p>
              </div>
            </div>
          </div>

          <div className="py-2">
            <HorizontalLine />
          </div>

          {/* Parent goal card */}
          {goal.parent && (
            <div className="flex flex-col gap-1.5">
              <p className="text-xs font-medium text-gray-700 whitespace-nowrap">Parent goal</p>
              <NavLink
                to={`../${goal.parent.id}`}
                className="flex flex-col gap-2 p-3 border border-gray-200 rounded-md shadow w-full hover:border-gray-300 hover:shadow-md">
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between items-center gap-4">
                    {goal.parent.type === GoalType.OBJECTIVE ? (
                      <div className="flex flex-row items-center gap-1 text-sm leading-6 text-gray-700">
                        <GiArcheryTarget className="size-4 text-gray-700" />
                        Objective
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-1 text-sm leading-6 text-gray-700">
                        <TbArcheryArrow className="size-4 rotate-180 transform text-gray-700" />
                        Key Result
                      </div>
                    )}

                    <div className="flex flex-row justify-start items-center gap-3">
                      <p
                        className={`text-sm font-medium ${
                          GOAL_STATUS_COLORS_MAP[goal.parent.status]
                        }`}>
                        {new Goal(goal.parent).calculateProgress(goal.parent)}%
                      </p>
                      <p
                        className={`text-sm font-medium ${
                          GOAL_STATUS_COLORS_MAP[goal.parent.status]
                        }`}>
                        {GOAL_STATUS_MAP[goal.parent.status]}
                      </p>
                    </div>
                  </div>

                  <p className="w-full text-base font-medium text-gray-900">{goal.parent.title}</p>
                </div>

                <div className="flex flex-col gap-1.5">
                  <ProgressBar
                    animateOnRender={true}
                    completed={new Goal(goal.parent).calculateProgress(goal.parent)}
                    bgColor={getProgressBarColor(goal.parent)}
                    height="10px"
                    isLabelVisible={false}
                  />

                  <div className="flex flex-row items-center justify-between gap-3">
                    <div className="flex flex-row items-center gap-3">
                      <p className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                        Start:
                        <span className="text-gray-700">
                          {goal.parent.measurementType === GoalMeasurementType.CURRENCY && '$'}
                          {goal.parent.type === GoalType.KEY_RESULT
                            ? goal.parent.startValue ?? 0
                            : '0%'}
                          {goal.parent.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                        </span>
                      </p>
                      <p className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                        Current:
                        <span className="text-gray-700">
                          {goal.parent.measurementType === GoalMeasurementType.CURRENCY && '$'}
                          {goal.parent.type === GoalType.KEY_RESULT
                            ? (goal.parent.currentProgress ?? 0) + (goal.parent.startValue ?? 0)
                            : `${new Goal(goal.parent).calculateProgress(goal.parent)}%`}
                          {goal.parent.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                        </span>
                      </p>
                    </div>

                    <p className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                      Goal:
                      <span className="text-gray-700">
                        {goal.parent.measurementType === GoalMeasurementType.CURRENCY && '$'}
                        {goal.parent.type === GoalType.KEY_RESULT
                          ? goal.parent.targetValue ?? 0
                          : '100%'}
                        {goal.parent.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                      </span>
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <CreateCheckInModal
        goal={goal}
        open={openCreateCheckInModal}
        onClose={() => setOpenCreateCheckInModal(false)}
      />
      <EditGoalModal
        goal={goal}
        open={openEditGoalModal}
        onClose={() => setOpenEditGoalModal(false)}
      />
      <CheckInsOverviewModal
        goal={goal}
        editable={canEditGoal}
        open={openShowAllCheckInsModal}
        onClose={() => setOpenShowAllCheckInsModal(false)}
      />
      <CreateNewObjectiveModal
        parent={goal}
        open={openCreateNewObjectiveModal}
        onClose={() => setOpenCreateNewObjectiveModal(false)}
      />
      <CreateNewKeyResultModal
        parent={goal}
        open={openCreateNewKeyResultModal}
        onClose={() => setOpenCreateNewKeyResultModal(false)}
      />
    </>
  );
};

export default GoalOverviewPage;
