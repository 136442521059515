import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';

type AvatarProps = {
  user: {
    firstName: string;
    lastName: string;
    picture?: string | null;
  } | null;
  size?: number;
  emptyBorderDashed?: boolean;
  enableHover?: boolean;
};

const Avatar = ({ user, size = 8, emptyBorderDashed, enableHover }: AvatarProps): JSX.Element => {
  if (user?.picture) {
    return (
      <img
        className={`w-${size} h-${size} flex-shrink-0 rounded-full ${
          enableHover && 'hover:opacity-80'
        }`}
        src={user?.picture}
        alt="user image"
        referrerPolicy="no-referrer"
      />
    );
  }

  if (user?.firstName) {
    return (
      <span
        className={`inline-flex w-${size} h-${size} items-center justify-center rounded-full bg-gray-500 align-top ${
          enableHover && 'hover:opacity-80'
        }`}>
        <span className={`${size < 8 ? 'text-xs' : 'text-sm'} font-medium leading-none text-white`}>
          {`${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}`}
        </span>
      </span>
    );
  }

  if (emptyBorderDashed) {
    return (
      <UserIcon
        className={`size-${size} flex-shrink-0 rounded-full border border-dashed border-gray-400 text-gray-400 ${
          enableHover && 'hover:opacity-80'
        }`}
      />
    );
  }

  return (
    <UserIcon
      className={`size-${size} flex-shrink-0 rounded-full text-gray-400 bg-gray-100 ${
        enableHover && 'hover:opacity-80'
      }`}
    />
  );
};

export default Avatar;
