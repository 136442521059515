import React from 'react';

type SettingsItemProps = {
  labelName: string;
  value: string;
};

const SettingsItem = ({ labelName, value }: SettingsItemProps): JSX.Element => {
  return (
    <div className="pt-6 sm:flex">
      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{labelName}</dt>
      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
        <div className="text-gray-900">{value}</div>
      </dd>
    </div>
  );
};

export default SettingsItem;
