import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { fetchLibrarySkills, setActiveTab } from '../../core/library/skill/skillsSlice';
import { useAppDispatch, useAppSelector } from '../../core/store';
import {
  AppLoader,
  Button,
  CreateNewSkillModal,
  HorizontalLine,
  PermissionProtectedAccess,
  SkillLibraryTabs
} from '../../components';

const SkillLibraryPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState('');
  const [createNewSkillModalOpen, setCreateNewSkillModalOpen] = useState(false);

  const activeTab = useAppSelector((state) => state.librarySkills.activeTab);
  const areSkillsFetched = useAppSelector((state) => state.librarySkills.areSkillsFetched);
  const areSkillsPending = useAppSelector((state) => state.librarySkills.areSkillsPending);
  const librarySkills = useAppSelector((state) => state.librarySkills.skills);
  const orgLibrarySkills = librarySkills.filter((skill) => skill.private);
  const skillLibrarySkills = librarySkills.filter((skill) => !skill.private);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areSkillsFetched) {
      return;
    }
    dispatch(fetchLibrarySkills({ organizationSlug }));
  }, []);

  useEffect(() => {
    setSearch(searchParams.get('search') ?? '');
  }, [searchParams]);

  function closeCreateNewSkillModal(): void {
    setCreateNewSkillModalOpen(false);
  }

  function openCreateNewSkillModal(): void {
    setCreateNewSkillModalOpen(true);
  }

  function renderTabContent(): JSX.Element {
    if (activeTab === 'skill-library') {
      return (
        <div className="flex flex-col gap-4 h-full w-full">
          <div className="flex-initial flex flex-col sm:flex-row gap-4 sm:justify-between">
            <input
              type="search"
              name="search"
              id="search"
              autoComplete="off"
              placeholder="Search skills..."
              value={search}
              className="flex-initial w-full sm:w-96 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchParams({ search: e.target.value });
              }}
            />
          </div>

          <HorizontalLine />

          <div className="relative w-full h-full mb-4">
            <ul
              role="list"
              className="absolute grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 2xl:grid-cols-4 max-h-full pr-1 overflow-y-auto">
              {skillLibrarySkills
                .filter(
                  (skill) =>
                    skill.name.toLowerCase().includes(search.toLowerCase()) || // serach by name or
                    skill.description.toLowerCase().includes(search.toLowerCase()) // search by description
                )
                .map((skill) => (
                  <NavLink
                    key={skill.id}
                    to={skill.id}
                    className="flex flex-col gap-2 justify-between hover:bg-gray-50 min-h-[76px] p-4 cursor-pointer rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                    <div className="flex flex-col gap-1">
                      <p className="truncate text-base font-semibold text-gray-900">{skill.name}</p>
                      <p className="line-clamp-3 text-sm text-gray-600">{skill.description}</p>
                    </div>

                    <div className="flex flex-row gap-3">
                      <p className="truncate text-sm text-gray-600 max-w-[100px]">
                        @{skill.organization.name}
                      </p>
                    </div>
                  </NavLink>
                ))}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4 h-full w-full">
        <div className="flex-initial flex flex-col sm:flex-row gap-4 sm:justify-between">
          <input
            type="search"
            name="search"
            id="search"
            autoComplete="off"
            placeholder="Search skills..."
            value={search}
            className="flex-initial w-full sm:w-96 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
            onChange={(e) => {
              setSearch(e.target.value);
              setSearchParams({ search: e.target.value });
            }}
          />

          <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
            <Button onClick={() => openCreateNewSkillModal()}>Create New Skill</Button>
          </PermissionProtectedAccess>
        </div>

        <HorizontalLine />

        <div className="relative w-full h-full mb-4">
          {orgLibrarySkills.length === 0 ? (
            <div className="absolute flex flex-col items-center justify-start h-full w-full mt-20">
              <ExclamationTriangleIcon className="size-12 text-gray-400" />
              <p className="px-6 py-2 text-gray-600 text-sm italic text-center max-w-[35rem]">
                {`We've noticed your organization library currently doesn't include any skills.`}
                <br />
                <br />
                {`To bring your competencies to life, go to our `}
                <button
                  className="italic font-semibold text-indigo-600 hover:text-indigo-500"
                  onClick={() => {
                    dispatch(setActiveTab('skill-library'));
                  }}>
                  {'Skill Library'}
                </button>
                {`, where you can choose from a variety of predefined skills and add them to your organization library.`}
              </p>
              <Button
                className="mt-2"
                onClick={() => {
                  dispatch(setActiveTab('skill-library'));
                }}>
                Go to Skill Library
              </Button>
            </div>
          ) : (
            <ul
              role="list"
              className="absolute grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 2xl:grid-cols-4 max-h-full pr-1 overflow-y-auto">
              {orgLibrarySkills
                .filter(
                  (skill) =>
                    skill.name.toLowerCase().includes(search.toLowerCase()) || // serach by name or
                    skill.description.toLowerCase().includes(search.toLowerCase()) // search by description
                )
                .map((skill) => (
                  <NavLink
                    key={skill.id}
                    to={skill.id}
                    className="flex flex-col gap-2 justify-between hover:bg-gray-50 min-h-[76px] p-4 cursor-pointer rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                    <div className="flex flex-col gap-1">
                      <p className="truncate text-base font-semibold text-gray-900">{skill.name}</p>
                      <p className="line-clamp-3 text-sm text-gray-600">{skill.description}</p>
                    </div>

                    <div className="flex flex-row gap-3">
                      <p className="truncate text-sm text-gray-600 max-w-[100px]">
                        @{skill.organization.name}
                      </p>
                    </div>
                  </NavLink>
                ))}
            </ul>
          )}
        </div>
      </div>
    );
  }

  if (areSkillsPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex flex-col justify-start h-full w-full">
        <div className="flex-none flex flex-row mb-5 justify-between">
          <SkillLibraryTabs
            defaultTab={activeTab}
            onChange={(selectedTab) => {
              setSearch('');
              setSearchParams({ search: '' });
              dispatch(setActiveTab(selectedTab));
            }}
          />
          <div className="sm:hidden flex flex-row gap-4 justify-end">
            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
              <Button onClick={() => openCreateNewSkillModal()}>Create New Skill</Button>
            </PermissionProtectedAccess>
          </div>
        </div>

        <div className="flex-1">{renderTabContent()}</div>
      </div>

      <CreateNewSkillModal open={createNewSkillModalOpen} onClose={closeCreateNewSkillModal} />
    </>
  );
};

export default SkillLibraryPage;
