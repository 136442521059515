import Department, { DepartmentData } from '../core/cdf/Department.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetDepartmentsArgs = GetOrganizationArgs;

async function getDepartments({ organizationSlug }: GetDepartmentsArgs): Promise<DepartmentData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/departments`);
    const departmentsData: DepartmentData[] = data.departments.map((department: any) =>
      Department.buildDepartmentDataFromResponse(department)
    );

    // sort departments by name
    departmentsData.sort((a, b) => a.name.localeCompare(b.name));

    return Promise.resolve(departmentsData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetDepartmentArgs = GetDepartmentsArgs & {
  departmentID: string;
};

async function getDepartment({
  organizationSlug,
  departmentID
}: GetDepartmentArgs): Promise<DepartmentData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/departments/${departmentID}`
    );
    const departmentData = Department.buildDepartmentDataFromResponse(data);
    return Promise.resolve(departmentData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateDepartmentArgs = GetDepartmentsArgs & {
  name: string;
};

async function createDepartment({
  organizationSlug,
  name
}: CreateDepartmentArgs): Promise<DepartmentData> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/departments`, { name });
    const departmentData = Department.buildDepartmentDataFromResponse(data);
    return Promise.resolve(departmentData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateDepartmentArgs = GetDepartmentArgs & {
  name: string;
};

async function updateDepartment({
  organizationSlug,
  departmentID,
  name
}: UpdateDepartmentArgs): Promise<DepartmentData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/departments/${departmentID}`,
      { name }
    );
    const departmentData = Department.buildDepartmentDataFromResponse(data);
    return Promise.resolve(departmentData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteDepartment({
  organizationSlug,
  departmentID
}: GetDepartmentArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/departments/${departmentID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export { getDepartments, getDepartment, createDepartment, updateDepartment, deleteDepartment };
