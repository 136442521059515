import DevelopmentPath, { DevelopmentPathData } from '../core/cdf/DevelopmentPath.model';
import { Api } from './api';
import { parseError } from './error';
import { GetDepartmentArgs } from './department';

export type CreateDevelopmentPathArgs = GetDepartmentArgs & {
  name: string;
};

async function createDevelopmentPath({
  organizationSlug,
  departmentID,
  name
}: CreateDevelopmentPathArgs): Promise<DevelopmentPathData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/departments/${departmentID}/paths`,
      { name }
    );
    const developmentPathData = DevelopmentPath.buildDevelopmentPathDataFromResponse(data);
    return Promise.resolve(developmentPathData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function duplicateDevelopmentPath({
  organizationSlug,
  departmentID,
  developmentPathID
}: GetDevelopmentPathArgs): Promise<void> {
  try {
    await Api.post(
      `/organizations/${organizationSlug}/departments/${departmentID}/paths/${developmentPathID}/duplicate`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateDevelopmentPathArgs = GetDevelopmentPathArgs & {
  name: string;
};

async function updateDevelopmentPath({
  organizationSlug,
  departmentID,
  developmentPathID,
  name
}: UpdateDevelopmentPathArgs): Promise<DevelopmentPathData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/departments/${departmentID}/paths/${developmentPathID}`,
      { name }
    );
    const developmentPathData = DevelopmentPath.buildDevelopmentPathDataFromResponse(data);
    return Promise.resolve(developmentPathData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetDevelopmentPathArgs = GetDepartmentArgs & {
  developmentPathID: string;
};

async function deleteDevelopmentPath({
  organizationSlug,
  departmentID,
  developmentPathID
}: GetDevelopmentPathArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/departments/${departmentID}/paths/${developmentPathID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  createDevelopmentPath,
  duplicateDevelopmentPath,
  updateDevelopmentPath,
  deleteDevelopmentPath
};
