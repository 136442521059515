import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type CreatePaymentLinkArgs = GetOrganizationArgs & {
  numOfTeamSeats: number;
};

async function createPaymentLink({
  organizationSlug,
  numOfTeamSeats
}: CreatePaymentLinkArgs): Promise<string> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/billing/payment-link`, {
      num_of_team_seats: numOfTeamSeats
    });
    return Promise.resolve(data.url);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export { createPaymentLink };
