export interface EmployeeDepartmentData {
  /** @type {Generics.UUID} */
  id: string;
  name: string;
  level: {
    /** @type {Generics.UUID} */
    id: string;
    name: string;
  };
}

class EmployeeDepartment implements EmployeeDepartmentData {
  id: string;
  name: string;
  level: {
    id: string;
    name: string;
  };

  constructor(data: EmployeeDepartmentData) {
    this.id = data.id;
    this.name = data.name;
    this.level = data.level;
  }

  static buildEmployeeDepartmentDataFromResponse(response: any): EmployeeDepartmentData {
    return {
      id: response.id,
      name: response.name,
      level: {
        id: response.level.id,
        name: response.level.name
      }
    };
  }
}

export default EmployeeDepartment;
