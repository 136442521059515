import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'sonner';

import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';
import UsersCombobox, { ComboboxUser } from '../UsersCombobox';
import { Button } from '../ui/Button';
import { addTeamMember } from '../../core/team/teamsSlice';
import { TeamData } from '../../core/team/Team.model';
import HorizontalLine from '../HorizontalLine';

interface AddNewTeamMemberModalProps {
  team: TeamData;
  open: boolean;
  onClose: () => void;
}

const AddNewTeamMemberModal = ({
  team,
  open,
  onClose: _onClose
}: AddNewTeamMemberModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);

  const cancelButtonRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState<ComboboxUser>();

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (!open) {
      return;
    }
    if (areEmployeesFetched) {
      return;
    }

    dispatch(fetchOrganizationUsers({ organizationSlug }));
  }, [open]);

  async function addNewTeamMemberHandler(): Promise<void> {
    if (!selectedUser || selectedUser.id === '') {
      toast.warning('Please select a team memeber');
      return;
    }

    const res: any = await dispatch(
      addTeamMember({ organizationSlug, teamID: team.id, memberID: selectedUser.id })
    );
    if (res.error) {
      return;
    }

    onClose();
  }

  function onClose(): void {
    setSelectedUser(undefined);
    _onClose();
  }

  // Filter out the members that are already in the team
  let orgMembers = employees.filter((employee) => {
    return !team.members.some((member) => member.id === employee.id);
  });
  if (team.manager?.id) {
    orgMembers = orgMembers.filter((employee) => {
      return team.manager?.id !== employee.id;
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-[480px] sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Create new team
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    <UsersCombobox
                      users={orgMembers}
                      placeholder="Select team member"
                      removeAuthUser={false}
                      onChange={(user) => {
                        setSelectedUser(user);
                      }}
                    />
                  </div>
                </div>

                <div className="flex sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1">
                  <Button
                    onClick={() => {
                      addNewTeamMemberHandler();
                    }}>
                    Add
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddNewTeamMemberModal;
