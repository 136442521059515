import React, { useEffect, useRef, useState } from 'react';
import { BookOpenIcon, BuildingLibraryIcon } from '@heroicons/react/20/solid';

import { classNames } from '../../../common/utils';
import BasicListbox from '../../BasicListbox';

type SkillLibraryTabsProps = {
  defaultTab?: 'organization-skills' | 'skill-library';
  onChange: (selectedTab: 'organization-skills' | 'skill-library') => void;
};

const SkillLibraryTabs = ({
  defaultTab = 'organization-skills',
  onChange
}: SkillLibraryTabsProps): JSX.Element => {
  const tabs = useRef([
    {
      id: 'organization-skills',
      name: 'Organization Skills',
      slug: 'organization-skills',
      icon: BookOpenIcon
    },
    { id: 'skill-library', name: 'Skill Library', slug: 'skill-library', icon: BuildingLibraryIcon }
  ]);

  const [selectedTab, setSelectedTab] = useState(
    tabs.current.findIndex((tab) => tab.slug === defaultTab)
  );

  function handleOnChange(selectedTabSlug: string): void {
    switch (selectedTabSlug) {
      case 'organization-skills':
        onChange('organization-skills');
        break;
      case 'skill-library':
        onChange('skill-library');
        break;
      default:
        onChange('organization-skills');
        break;
    }
  }

  useEffect(() => {
    setSelectedTab(tabs.current.findIndex((tab) => tab.slug === defaultTab));
  }, [defaultTab]);

  return (
    <div>
      <div className="sm:hidden w-40">
        <BasicListbox
          items={tabs.current}
          defaultItem={tabs.current[selectedTab]}
          onChange={(selected) => {
            const tab = tabs.current.find((tab) => tab.id === selected.id);
            handleOnChange(tab?.slug ?? 'organization-skills');
          }}
        />
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.current.map((tab, index) => (
              <button
                key={tab.name}
                className={classNames(
                  selectedTab === index
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
                onClick={() => {
                  setSelectedTab(index);
                  handleOnChange(tab?.slug ?? 'organization-skills');
                }}>
                <tab.icon
                  className={classNames(
                    selectedTab === index
                      ? 'text-indigo-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 size-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SkillLibraryTabs;
