import { GOOGLE_OAUTH_REDIRECT_URI } from '../common/constants';
import { Api, PublicApi } from './api';
import { parseError } from './error';

type LoginUserArgs = {
  email: string;
  password: string;
};

async function loginUser({ email, password }: LoginUserArgs): Promise<string> {
  try {
    const response = await PublicApi.post('/auth/login', { email, password });
    return Promise.resolve(response.data.token);
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

type RegisterUserArgs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isElementAI?: boolean;
};

async function registerUser({
  firstName,
  lastName,
  email,
  password,
  isElementAI = false
}: RegisterUserArgs): Promise<string> {
  try {
    const { data } = await PublicApi.post('/auth/register', {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      is_element_ai: isElementAI
    });
    return Promise.resolve(data.token);
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

type AuthenticateOAuthArgs = {
  code: string;
  isElementAI?: boolean;
};

async function googleOAuthUser({
  code,
  isElementAI = false
}: AuthenticateOAuthArgs): Promise<string> {
  try {
    const { data } = await PublicApi.get(
      `/auth/google-callback?code=${code}&redirect_uri=${GOOGLE_OAUTH_REDIRECT_URI}&is_element_ai=${isElementAI}`
    );
    return Promise.resolve(data.token);
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export type SendResetPasswordEmailArgs = {
  email: string;
  isElementAI?: boolean;
};

async function sendResetPasswordEmail({
  email,
  isElementAI = false
}: SendResetPasswordEmailArgs): Promise<void> {
  try {
    await PublicApi.post('/auth/reset-password', { email, is_element_ai: isElementAI });
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export type ResetPasswordArgs = {
  code: string;
  newPassword: string;
};

async function resetPassword({ code, newPassword }: ResetPasswordArgs): Promise<string> {
  try {
    const { data } = await PublicApi.post('/auth/reset-password-set', {
      code,
      new_password: newPassword
    });
    return Promise.resolve(data.token);
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export type AcceptInviteArgs = {
  token: string;
};

async function acceptInvite({ token }: AcceptInviteArgs): Promise<void> {
  try {
    await Api.post('/accept-invite', { token });
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export {
  loginUser,
  registerUser,
  googleOAuthUser,
  sendResetPasswordEmail,
  resetPassword,
  acceptInvite
};
