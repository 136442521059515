import React, { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams, NavLink } from 'react-router-dom';
import { GiCrane } from 'react-icons/gi';

import { companySizesList } from '../../common/constants';
import {
  AppLoader,
  BasicListbox,
  Button,
  CreateNewFrameworkModal,
  HorizontalLine,
  PermissionProtectedAccess,
  VerticalLine
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { fetchLibraryFrameworks } from '../../core/library/framework/frameworksSlice';
import { Badge } from '../../components/ui/Badge';

const FrameworkLibraryPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const companySizeList = useRef(companySizesList);

  const areFrameworksPending = useAppSelector(
    (state) => state.libraryFrameworks.areFrameworksPending
  );
  const areFrameworksFetched = useAppSelector(
    (state) => state.libraryFrameworks.areFrameworksFetched
  );
  const frameworks = useAppSelector((state) => state.libraryFrameworks.frameworks);

  const [search, setSearch] = useState('');
  const [searchCompanySize, setSearchCompanySize] = useState({
    companySizeMin: 0,
    companySizeMax: Infinity
  });
  const [createNewFrameworkModalOpen, setCreateNewFrameworkModalOpen] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areFrameworksFetched) {
      return;
    }
    dispatch(fetchLibraryFrameworks({ organizationSlug }));
  }, []);

  useEffect(() => {
    setSearch(searchParams.get('search') ?? '');
  }, [searchParams]);

  function closeCreateNewSkillModal(): void {
    setCreateNewFrameworkModalOpen(false);
  }

  function openCreateNewSkillModal(): void {
    setCreateNewFrameworkModalOpen(true);
  }

  if (areFrameworksPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex-1 flex flex-col gap-4 h-full w-full mt-6">
        <div className="flex-initial flex flex-col sm:flex-row gap-4 sm:justify-between">
          <div className="flex flex-col sm:flex-row gap-3">
            <input
              type="search"
              autoComplete="off"
              placeholder="Search frameworks..."
              value={search}
              className="flex-initial w-full sm:w-96 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
              onChange={(e) => {
                setSearch(e.target.value);
                setSearchParams({ search: e.target.value });
              }}
            />
            <BasicListbox
              placeholder="Select Company Size"
              items={companySizeList.current}
              onChange={(selectedOption) => {
                if ((selectedOption.name as string).endsWith('+')) {
                  setSearchCompanySize({
                    companySizeMin: +(selectedOption.name as string).split('+')[0],
                    companySizeMax: Infinity
                  });
                  return;
                }

                const [min, max] = (selectedOption.name as string).split('-');
                setSearchCompanySize({ companySizeMin: +min, companySizeMax: +max });
              }}
            />
          </div>

          <PermissionProtectedAccess minimalRequiredPermissionRole="editor" internalAccessRequired>
            <Button
              onClick={() => {
                openCreateNewSkillModal();
              }}>
              Create New Framework
            </Button>
          </PermissionProtectedAccess>
        </div>

        <HorizontalLine />

        <div className="relative w-full h-full mb-4">
          {frameworks.length === 0 ? (
            <div className="flex flex-col items-center justify-start mt-20">
              <GiCrane className="size-16 text-gray-800" />
              <p className="px-6 py-2 text-gray-600 text-xl font-medium text-center">
                Frameworks in Progress
              </p>
              <p className="px-6 py-2 text-gray-600 text-sm italic text-center max-w-[35rem]">
                Our team is currently developing a range of competency frameworks tailored for your
                success.
              </p>
              <p className="px-6 py-2 text-gray-600 text-sm italic text-center max-w-[35rem]">
                {`We're working hard to bring you a comprehensive collection of tools and resources. Stay tuned for updates and expect them soon!`}
              </p>
            </div>
          ) : (
            <ul
              role="list"
              className="absolute grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8 2xl:grid-cols-3 max-h-full pr-1 overflow-y-auto">
              {frameworks
                .filter(
                  (framework) =>
                    framework.name.toLowerCase().includes(search.toLowerCase()) &&
                    framework.companySizeMin >= searchCompanySize.companySizeMin &&
                    framework.companySizeMax <= searchCompanySize.companySizeMax
                )
                .map((framework) => (
                  <NavLink
                    key={framework.id}
                    to={framework.id}
                    className="flex flex-col gap-2 justify-between hover:bg-gray-50 min-h-[76px] p-4 cursor-pointer rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                    <div className="flex flex-col gap-1">
                      <p className="truncate text-base font-semibold text-gray-900">
                        {framework.name}
                      </p>
                      <p className="line-clamp-3 text-sm text-gray-600">{framework.description}</p>
                    </div>

                    <div className="flex flex-row gap-4">
                      <p className="truncate text-sm text-gray-600 max-w-[100px]">
                        @{framework.organization.name}
                      </p>

                      <VerticalLine />

                      <p className="text-sm text-gray-600">
                        {framework.companySizeMin}-{framework.companySizeMax} employees
                      </p>

                      {framework.public && (
                        <PermissionProtectedAccess
                          minimalRequiredPermissionRole="editor"
                          internalAccessRequired>
                          <VerticalLine />
                          <Badge variant={'green'}>Published</Badge>
                        </PermissionProtectedAccess>
                      )}
                    </div>
                  </NavLink>
                ))}
            </ul>
          )}
        </div>
      </div>

      <CreateNewFrameworkModal
        open={createNewFrameworkModalOpen}
        onClose={closeCreateNewSkillModal}
      />
    </>
  );
};

export default FrameworkLibraryPage;
