import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';

interface InputNumberProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
}

const InputNumber = ({ value, onChange, min, max }: InputNumberProps): JSX.Element => {
  return (
    <div className="py-2 px-3 inline-block bg-white border border-gray-200 rounded-lg dark:bg-slate-900 dark:border-gray-700">
      <div className="flex items-center gap-x-1.5">
        <button
          type="button"
          className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
          disabled={!!(min && value <= min)}
          onClick={() => {
            if (min && value <= min) {
              return;
            }
            onChange(value - 1);
          }}>
          <MinusIcon className="size-6" aria-hidden="true" />
        </button>
        <input
          className="p-0 w-7 bg-transparent border-0 text-gray-800 text-center focus:ring-0 dark:text-white"
          type="text"
          value={value}
          onChange={(e) => {
            if (isNaN(+e.target.value)) {
              return;
            }
            if (min && +e.target.value < min) {
              onChange(min);
              return;
            }
            if (max && +e.target.value > max) {
              onChange(max);
              return;
            }
            onChange(+e.target.value);
          }}
        />
        <button
          type="button"
          className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
          disabled={!!(max && value >= max)}
          onClick={() => {
            if (max && value >= max) {
              return;
            }
            onChange(value + 1);
          }}>
          <PlusIcon className="size-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default InputNumber;
