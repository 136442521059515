import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button } from '../ui/Button';
import { useAppDispatch } from '../../hooks';
import { classNames } from '../../common/utils';
import { SkillLevelData } from '../../core/library/skill/SkillLevel.model';
import SkillChangeLevelModal from '../library/skill/SkillChangeLevelModal';
import SkillLevelDescription from '../library/skill/SkillLevelDescription';
import { unassignSkillFromLevel } from '../../core/level/levelSlice';
import { unassignSkillFromFrameworkLevel } from '../../core/library/framework/frameworksSlice';
import EllipsisMenu from '../ui/EllipsisMenu';

type SkillCardProps = {
  usedFor?: 'organization' | 'frameworkLibrary';
  skillLevel: SkillLevelData;
  editable?: boolean;
};

const SkillCard = ({
  usedFor = 'organization',
  skillLevel,
  editable = true
}: SkillCardProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const organizationSlug = params.organizationSlug ?? '';
  const departmentID = params.departmentID ?? '';
  const levelID = params.levelID ?? '';

  const [skillChangeLevelModalOpen, setSkillChangeLevelModalOpen] = useState(false);

  function removeSkillFromLevel(): void {
    if (usedFor === 'frameworkLibrary') {
      const frameworkID = params.frameworkID ?? '';
      const frameworkDepartmentID = searchParams.get('departmentID') ?? '';
      const frameworkLevelID = searchParams.get('levelID') ?? '';

      dispatch(
        unassignSkillFromFrameworkLevel({
          organizationSlug,
          frameworkID,
          departmentID: frameworkDepartmentID,
          levelID: frameworkLevelID,
          skillLevelID: skillLevel.id
        })
      );
      return;
    }

    dispatch(
      unassignSkillFromLevel({
        organizationSlug,
        departmentID,
        levelID,
        skillLevelID: skillLevel.id
      })
    );
  }

  function closeSkillChangeLevelModal(): void {
    setSkillChangeLevelModalOpen(false);
  }

  function openSkillChangeLevelModal(): void {
    setSkillChangeLevelModalOpen(true);
  }

  return (
    <div className="flex flex-col gap-2 p-3 rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
      <div className="flex-1 flex flex-row justify-between">
        <div className="flex-1 flex flex-row gap-3 justify-start items-center">
          <h2 className="font-semibold text-base">{skillLevel.skillName}</h2>
          <div className={editable ? undefined : 'hidden'}>
            <EllipsisMenu
              items={[
                {
                  name: 'Remove',
                  onClick: () => removeSkillFromLevel(),
                  color: 'text-red-600'
                }
              ]}
            />
          </div>
        </div>
        <div className="flex-none text-right">
          <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
            <div
              className={classNames(
                editable ? 'rounded-l-md' : 'rounded-md',
                'inline-flex items-center gap-x-1.5 bg-indigo-600 px-[10px] py-1 text-white shadow-sm'
              )}>
              <p className="text-sm">L{skillLevel.seniority}</p>
            </div>
            <Button
              size={'sm'}
              onClick={() => openSkillChangeLevelModal()}
              className={classNames(editable ? '' : 'hidden', 'rounded-l-none rounded-r-md')}>
              Edit
            </Button>
          </div>
        </div>
      </div>
      <SkillLevelDescription skillLevelData={skillLevel} />
      <SkillChangeLevelModal
        usedFor={usedFor}
        skillLevel={skillLevel}
        open={skillChangeLevelModalOpen}
        onClose={closeSkillChangeLevelModal}
      />
    </div>
  );
};

export default SkillCard;
