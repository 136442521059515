import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { Tooltip } from 'flowbite-react';
import Xarrow from 'react-xarrows';
import ProgressBar from '@ramonak/react-progress-bar';

import { indigo600 } from '../../common/colors';
import { alignSkills, areProgressionSkillCompleted } from '../../common/utils';
import {
  AppLoader,
  Button,
  ChooseNextLevelListbox,
  ConfirmationDialog,
  HorizontalLine,
  ProgressionSkillCard,
  Switch,
  Avatar,
  LevelsCombobox
} from '../../components';
import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import { ProgressionData } from '../../core/progression/Progression.model';
import { ProgressionLevelData } from '../../core/progression/ProgressionLevel.model';
import { ProgressionLevelSkillData } from '../../core/progression/ProgressionLevelSkill.model';
import {
  fetchProgression,
  fetchProgressionLevel,
  assignProgressionNextLevel,
  completeProgression,
  updateProgressionSkillStatus,
  assignUserProgressionCurrentLevel
} from '../../core/progression/progressionSlice';
import { fetchOrganizationUser } from '../../core/employee/employeesSlice';

const GrowPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const isProgressionPending = useAppSelector((state) => state.progression.isProgressionPending);
  const activeProgression = useAppSelector((state) => state.progression.activeProgression);
  const selectedNextLevel = useAppSelector((state) => state.progression.selectedNextLevel);

  const [showCurrentLevelSkills, setShowCurrentLevelSkills] = useState(true);
  const [showNextLevelSkills, setShowNextLevelSkills] = useState(true);
  const [promoteBtnModalOpen, setPromoteBtnModalOpen] = useState(false);
  const [showSkillArrows, setShowSkillArrows] = useState(
    showCurrentLevelSkills && showNextLevelSkills
  );
  const [progressionSkills, setProgressionSkills] = useState(
    alignSkills(activeProgression?.currentLevel?.skills ?? [], selectedNextLevel?.skills ?? [])
  );

  const organizationSlug = params.organizationSlug ?? '';
  const memberID = params.memberID ?? '';

  useEffect(() => {
    if (memberID === '') {
      return;
    }
    dispatch(fetchProgression({ organizationSlug, progressionUserID: memberID }));
  }, [memberID]);

  useEffect(() => {
    setShowSkillArrows(false);
    // Trigger update for Xarrow when connected elements change
    // This is needed because Xarrow does not renders automatically if one of the connected elements is rendered
    if (showCurrentLevelSkills && showNextLevelSkills) {
      setTimeout(() => {
        setShowSkillArrows(true);
      }, 350);
      return;
    }
  }, [
    showCurrentLevelSkills,
    showNextLevelSkills,
    memberID,
    activeProgression?.id,
    activeProgression?.currentLevel?.id,
    selectedNextLevel?.id
  ]);

  useEffect(() => {
    if (showCurrentLevelSkills && !showNextLevelSkills) {
      setProgressionSkills(alignSkills(activeProgression?.currentLevel?.skills ?? [], []));
      return;
    }
    if (!showCurrentLevelSkills && showNextLevelSkills) {
      setProgressionSkills(alignSkills([], selectedNextLevel?.skills ?? []));
      return;
    }

    setProgressionSkills(
      alignSkills(activeProgression?.currentLevel?.skills ?? [], selectedNextLevel?.skills ?? [])
    );
  }, [
    showCurrentLevelSkills,
    showNextLevelSkills,
    activeProgression?.currentLevel,
    selectedNextLevel
  ]);

  function numberOfSkillsMeetExpectationsString(skills: ProgressionLevelSkillData[] = []): string {
    const meetExpectations = skills.filter((skill) =>
      areProgressionSkillCompleted(skill.status)
    ).length;
    return `${`${meetExpectations}/${skills.length}`}`;
  }

  function meetExpectationsPercentage(skills: ProgressionLevelSkillData[] = []): number {
    if (skills.length === 0) {
      return 0;
    }

    const meetExpectations = skills.filter((skill) =>
      areProgressionSkillCompleted(skill.status)
    ).length;
    return (meetExpectations * 100) / skills.length;
  }

  function updateProgressionDefaultNextLevel(nextLevel: ProgressionLevelData): void {
    if (!activeProgression) {
      return;
    }

    dispatch(
      assignProgressionNextLevel({
        organizationSlug,
        progressionUserID: activeProgression.user.id,
        nextLevelID: nextLevel.id
      })
    );
  }

  async function handlePromotion(progression: ProgressionData): Promise<void> {
    const res: any = await dispatch(
      completeProgression({ organizationSlug, progressionUserID: progression.user.id })
    );
    if (res.error) {
      return;
    }

    const promises: any[] = [];
    for (const skill of progression.nextLevel?.skills ?? []) {
      if (!skill.isStarred) {
        continue;
      }

      promises.push(
        dispatch(
          updateProgressionSkillStatus({
            organizationSlug,
            progressionUserID: progression.user.id,
            skillLevelID: skill.skillLevelID,
            isStarred: false
          })
        )
      );
    }
    await Promise.all(promises);

    dispatch(
      fetchProgression({
        organizationSlug,
        progressionUserID: progression.user.id
      })
    );
  }

  if (isProgressionPending) {
    return <AppLoader />;
  }

  if (!activeProgression) {
    return (
      <div className="flex flex-col items-center h-full w-full mt-5 sm:mt-20">
        <div className="items-start text-center space-y-4 max-w-[40rem]">
          <h3 className="text-xl font-medium text-gray-600">Welcome to Your Growth Journey!</h3>
          <p className="text-lg font-medium italic text-gray-600">{"🌟 You're Almost There!"}</p>
          <p className="text-sm italic text-gray-600">
            {`Right now, your growth path is a blank canvas, awaiting your unique journey. But before
            you embark on this exciting adventure, there's just one small step left.`}
          </p>
          <p className="text-lg font-medium text-gray-600">
            Action Needed: Level Assignment Pending
          </p>
          <p className="text-sm italic text-gray-600">
            {`You haven't been assigned to a company level yet. This is an essential step to unlock
            your personalized growth plan.`}
          </p>
          <p className="text-lg font-medium italic text-gray-600">🔔 What to Do Next?</p>
          <p className="text-sm italic text-gray-600">
            {`Please reach out to your manager to get placed at the right level. Once you're assigned,
            a world of opportunities and tailored experiences will open up here!`}
          </p>
          <p className="text-lg font-medium text-gray-600">🚀 Ready, Set, Grow!</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-3 justify-start h-full w-full">
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-8">
            <div className="flex flex-row gap-2">
              <p className="text-sm font-medium text-gray-900">
                {activeProgression.user?.id === authUser.id
                  ? 'Your Manager:'
                  : `${activeProgression.user.firstName}'s Manager:`}
              </p>
              {activeProgression.manager ? (
                <div className="flex flex-row gap-1">
                  <Avatar user={activeProgression.manager} size={5} />
                  <p className="text-sm font-medium text-gray-800">
                    {activeProgression.manager.firstName} {activeProgression.manager.lastName}
                  </p>
                </div>
              ) : (
                <p className="text-sm italic text-gray-600">no manager</p>
              )}
            </div>

            <div className="flex flex-row gap-2">
              <p className="text-sm font-medium text-gray-900">
                {activeProgression.user?.id === authUser.id
                  ? 'Your Team:'
                  : `${activeProgression.user.firstName}'s Team:`}
              </p>
              <div>
                {activeProgression.teamMembers.length > 0 ? (
                  <div className="flex -space-x-0.5">
                    {activeProgression.teamMembers.map((user) => (
                      <dd key={user.id}>
                        <Tooltip
                          content={`${user.firstName} ${user.lastName}`}
                          placement="top"
                          className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                          <Avatar user={user} size={5} />
                        </Tooltip>
                      </dd>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm italic text-gray-600">no team members</p>
                )}
              </div>
            </div>
          </div>

          {activeProgression.manager?.id === authUser.id && activeProgression?.nextLevel && (
            <div className="hidden sm:block flex-none ml-2 sm:ml-16">
              <Button onClick={() => setPromoteBtnModalOpen(true)}>Promote to next level</Button>
              <ConfirmationDialog
                open={promoteBtnModalOpen}
                title={'Are you sure?'}
                description={
                  <>
                    Are you sure you want to promote{' '}
                    <span className="font-semibold">
                      {activeProgression.user.firstName} {activeProgression.user.lastName}
                    </span>{' '}
                    to <span className="font-semibold">{activeProgression.nextLevel.name}</span>?
                    Promoting an employee to the next level will complete their current progression
                    and start a new one.
                  </>
                }
                onClose={() => setPromoteBtnModalOpen(false)}
                onConfirm={() => {
                  handlePromotion(activeProgression);
                  setPromoteBtnModalOpen(false);
                }}
              />
            </div>
          )}
        </div>

        <HorizontalLine />

        <div className="flex-1 flex flex-col gap-2 h-full">
          <div className="flex-none mb-2">
            <div className="flex justify-end mb-1">
              <p className="text-sm text-gray-500">
                {`${numberOfSkillsMeetExpectationsString(
                  selectedNextLevel?.skills
                )} skills meet expectations for next level`}
              </p>
            </div>
            <ProgressBar
              animateOnRender={true}
              completed={meetExpectationsPercentage(selectedNextLevel?.skills)}
              bgColor={indigo600}
              height="10px"
              isLabelVisible={false}
            />
          </div>

          <div className="flex-none grid grid-flow-row sm:grid-flow-col sm:justify-around sm:justify-items-center gap-7 sm:gap-0">
            <div
              id="current-level"
              className="flex flex-col relative min-h-[5rem] min-w-[18rem] rounded-md border-2">
              <div className="absolute top-1 right-1">
                <Tooltip
                  content="Show skills"
                  placement="top"
                  className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                  <Switch enabled={showCurrentLevelSkills} onChange={setShowCurrentLevelSkills} />
                </Tooltip>
              </div>
              <p className="pl-2 pt-2 text-sm text-gray-900">Current level:</p>
              {activeProgression.manager?.id === authUser.id ? (
                <div className="flex flex-row gap-2 px-2 pb-2 mt-2 self-center">
                  <LevelsCombobox
                    defaultValue={activeProgression.currentLevel ?? undefined}
                    placeholder="Select current level"
                    onChange={(selected) => {
                      dispatch(
                        assignUserProgressionCurrentLevel({
                          organizationSlug,
                          progressionUserID: activeProgression.user.id,
                          currentLevelID: selected.id
                        })
                      ).then(() => {
                        dispatch(
                          fetchProgression({
                            organizationSlug,
                            progressionUserID: activeProgression.user.id
                          })
                        );
                        dispatch(
                          fetchOrganizationUser({
                            organizationSlug,
                            userID: activeProgression.user.id
                          })
                        );
                      });
                      setShowCurrentLevelSkills(true);
                    }}
                  />
                </div>
              ) : (
                <p className="pt-4 self-center text-sm font-medium text-gray-900">
                  {activeProgression.currentLevel?.name}
                </p>
              )}
            </div>

            <div
              id="next-level"
              className="flex flex-col relative min-h-[5rem] min-w-[18rem] rounded-md border-2">
              <div className="absolute top-1 right-1">
                <Tooltip
                  content="Show skills"
                  placement="top"
                  className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                  <Switch enabled={showNextLevelSkills} onChange={setShowNextLevelSkills} />
                </Tooltip>
              </div>
              <p className="pl-2 pt-2 text-sm text-gray-900">Next level:</p>
              <div className="flex flex-row gap-2 px-2 pb-2 mt-2 self-center">
                <ChooseNextLevelListbox
                  onChange={(selected) => {
                    dispatch(
                      fetchProgressionLevel({
                        organizationSlug,
                        progressionUserID: activeProgression.user.id,
                        levelID: selected.id
                      })
                    );
                    setShowNextLevelSkills(true);
                  }}
                  onStarredNextLevel={(selected) => {
                    updateProgressionDefaultNextLevel(selected);
                  }}
                />
              </div>
            </div>

            <Xarrow
              start="current-level"
              end="next-level"
              animateDrawing={0.5}
              strokeWidth={3}
              headSize={4}
              color={indigo600}
              dashness={true}
            />
          </div>

          <div className="flex-1 flex flex-col sm:flex-row gap-2 justify-center mb-2">
            <div className="sm:relative w-full h-full">
              <ul className="sm:absolute flex flex-col gap-4 pt-2 sm:pr-1 sm:max-h-full sm:overflow-auto">
                {progressionSkills.map((skillPair) => (
                  <div
                    key={`${skillPair.currentLevel?.skillLevelID}-${skillPair.nextLevel?.skillLevelID}`}
                    className={` flex flex-col gap-4`}>
                    <li className="flex flex-col sm:flex-row w-full">
                      <Transition
                        show={showCurrentLevelSkills}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                        className="w-full">
                        {!skillPair.currentLevel ? (
                          <div className="flex flex-col justify-center h-full w-full">
                            <p className="text-sm italic text-center text-gray-600">
                              No current skill
                            </p>
                          </div>
                        ) : (
                          <div
                            id={`current-skill-${skillPair.currentLevel.skillLevelID}`}
                            className="flex flex-col justify-center h-full w-full">
                            <ProgressionSkillCard
                              progressionUser={activeProgression.user}
                              progressionSkill={skillPair.currentLevel}
                            />
                          </div>
                        )}
                      </Transition>

                      {showCurrentLevelSkills && showNextLevelSkills && (
                        <div className="my-4 sm:my-0 mx-0 sm:mx-4"></div>
                      )}

                      <Transition
                        show={showNextLevelSkills}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                        className="w-full">
                        {!skillPair.nextLevel ? (
                          <div className="flex flex-col justify-center h-full w-full">
                            <p className="text-sm italic text-center text-gray-600">
                              Not required skill
                            </p>
                          </div>
                        ) : (
                          <div
                            id={`next-skill-${skillPair.nextLevel.skillLevelID}`}
                            className="flex flex-col justify-center h-full w-full">
                            <ProgressionSkillCard
                              progressionUser={activeProgression.user}
                              progressionSkill={skillPair.nextLevel}
                            />
                          </div>
                        )}
                      </Transition>

                      <Transition
                        show={showSkillArrows && !!skillPair.currentLevel && !!skillPair.nextLevel}>
                        <Xarrow
                          start={`current-skill-${skillPair.currentLevel?.skillLevelID}`}
                          end={`next-skill-${skillPair.nextLevel?.skillLevelID}`}
                          animateDrawing={0.5}
                          strokeWidth={3}
                          headSize={4}
                          color={indigo600}
                          dashness={true}
                        />
                      </Transition>
                    </li>

                    <HorizontalLine dashed />
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrowPage;
