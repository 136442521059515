import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { GrowAreaData } from './GrowArea.model';
import { HttpStatusCode } from 'axios';

type GrowAreasState = {
  growAreas: GrowAreaData[];
  selectedGrowArea: GrowAreaData | null;
  areGrowAreasPending: boolean;
  isGrowAreaPending: boolean;
  error: api.ApiError;
};

const initialState: GrowAreasState = {
  growAreas: [],
  selectedGrowArea: null,
  areGrowAreasPending: true,
  isGrowAreaPending: true,
  error: null
};

const fetchGrowAreas = createAsyncThunk(
  'growAreas/getGrowAreas',
  async (args: api.GetGrowAreasArgs, { rejectWithValue }) => {
    try {
      return await api.getGrowAreas(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchGrowArea = createAsyncThunk(
  'growAreas/getGrowArea',
  async (args: api.GetGrowAreaArgs, { rejectWithValue }) => {
    try {
      return await api.getGrowArea(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchProgressionSkillGrowArea = createAsyncThunk(
  'growAreas/getProgressionSkillGrowArea',
  async (args: api.GetProgressionSkillArgs, { rejectWithValue }) => {
    try {
      return await api.getProgressionSkillGrowArea(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createGrowArea = createAsyncThunk(
  'growAreas/createGrowArea',
  async (args: api.CreateGrowAreaArgs, { rejectWithValue }) => {
    try {
      return await api.createGrowArea(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateGrowArea = createAsyncThunk(
  'growAreas/updateGrowArea',
  async (args: api.UpdateGrowAreaArgs, { rejectWithValue }) => {
    try {
      return await api.updateGrowArea(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createGrowAreaAction = createAsyncThunk(
  'growAreas/createGrowAreaAction',
  async (args: api.CreateGrowAreaActionArgs, { rejectWithValue }) => {
    try {
      return await api.createGrowAreaAction(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateGrowAreaAction = createAsyncThunk(
  'growAreas/updateGrowAreaAction',
  async (args: api.UpdateGrowAreaActionArgs, { rejectWithValue }) => {
    try {
      return await api.updateGrowAreaAction(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteGrowAreaAction = createAsyncThunk(
  'growAreas/deleteGrowAreaAction',
  async (args: api.GetGrowAreaActionArgs, { rejectWithValue }) => {
    try {
      return await api.deleteGrowAreaAction(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createGrowAreaUpdate = createAsyncThunk(
  'growAreas/createGrowAreaUpdate',
  async (args: api.CreateGrowAreaUpdateArgs, { rejectWithValue }) => {
    try {
      return await api.createGrowAreaUpdate(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateGrowAreaUpdate = createAsyncThunk(
  'growAreas/updateGrowAreaUpdate',
  async (args: api.UpdateGrowAreaUpdateArgs, { rejectWithValue }) => {
    try {
      return await api.updateGrowAreaUpdate(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteGrowAreaUpdate = createAsyncThunk(
  'growAreas/deleteGrowAreaUpdate',
  async (args: api.GetGrowAreaUpdateArgs, { rejectWithValue }) => {
    try {
      return await api.deleteGrowAreaUpdate(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const growAreasSlice = createSlice({
  name: 'growAreas',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch grow areas
    builder.addCase(fetchGrowAreas.pending, (state) => {
      state.areGrowAreasPending = true;
      state.error = null;
    });
    builder.addCase(fetchGrowAreas.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.areGrowAreasPending = false;
      toast.error(getCustomUserError(state.error, 'Failed to fetch grow areas'));
    });
    builder.addCase(fetchGrowAreas.fulfilled, (state, action) => {
      state.growAreas = action.payload;
      state.areGrowAreasPending = false;
      state.error = null;
    });

    // Fetch grow area
    builder.addCase(fetchGrowArea.pending, (state, action) => {
      state.selectedGrowArea = null;
      state.isGrowAreaPending = true;
      state.error = null;
    });
    builder.addCase(fetchGrowArea.rejected, (state, action) => {
      state.isGrowAreaPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch grow area'));
    });
    builder.addCase(fetchGrowArea.fulfilled, (state, action) => {
      state.selectedGrowArea = action.payload;
      state.isGrowAreaPending = false;
      state.error = null;
    });

    // Fetch the progression skill grow area
    builder.addCase(fetchProgressionSkillGrowArea.pending, (state, action) => {
      state.selectedGrowArea = null;
      state.isGrowAreaPending = true;
      state.error = null;
    });
    builder.addCase(fetchProgressionSkillGrowArea.rejected, (state, action) => {
      state.isGrowAreaPending = false;
      state.error = action.payload as api.ApiError;
      if (state.error?.statusCode === HttpStatusCode.NotFound) {
        return;
      }

      toast.error(getCustomUserError(state.error, 'Failed to fetch progression skill grow area'));
    });
    builder.addCase(fetchProgressionSkillGrowArea.fulfilled, (state, action) => {
      state.selectedGrowArea = action.payload;
      state.isGrowAreaPending = false;
      state.error = null;
    });

    // Create grow area
    builder.addCase(createGrowArea.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create grow area'));
    });
    builder.addCase(createGrowArea.fulfilled, (state, action) => {
      state.growAreas.push(action.payload);
      state.selectedGrowArea = action.payload;
    });

    // Update grow area
    builder.addCase(updateGrowArea.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update grow area'));
    });
    builder.addCase(updateGrowArea.fulfilled, (state, action) => {
      for (let i = 0; i < state.growAreas.length; i++) {
        if (state.growAreas[i].id === action.payload.id) {
          state.growAreas[i] = action.payload;
          break;
        }
      }

      if (state.selectedGrowArea && state.selectedGrowArea.id === action.payload.id) {
        state.selectedGrowArea = action.payload;
      }
    });

    // Create grow area action
    builder.addCase(createGrowAreaAction.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create grow area action'));
    });
    builder.addCase(createGrowAreaAction.fulfilled, (state, action) => {
      if (!state.selectedGrowArea) {
        return;
      }

      state.selectedGrowArea.actions.push(action.payload);
    });

    // Update grow area action
    builder.addCase(updateGrowAreaAction.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update grow area action'));
    });
    builder.addCase(updateGrowAreaAction.fulfilled, (state, action) => {
      if (!state.selectedGrowArea) {
        return;
      }

      for (let i = 0; i < state.selectedGrowArea.actions.length; i++) {
        if (state.selectedGrowArea.actions[i].id === action.payload.id) {
          state.selectedGrowArea.actions[i] = action.payload;
          break;
        }
      }
    });

    // Delete grow area action
    builder.addCase(deleteGrowAreaAction.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete grow area action'));
    });
    builder.addCase(deleteGrowAreaAction.fulfilled, (state, action) => {
      if (!state.selectedGrowArea) {
        return;
      }

      state.selectedGrowArea.actions = state.selectedGrowArea.actions.filter(
        (growAreaAction) => growAreaAction.id !== action.meta.arg.growAreaActionID
      );
    });

    // Create grow area update
    builder.addCase(createGrowAreaUpdate.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create grow area update'));
    });
    builder.addCase(createGrowAreaUpdate.fulfilled, (state, action) => {
      if (!state.selectedGrowArea) {
        return;
      }

      state.selectedGrowArea.updates.unshift(action.payload);
    });

    // Update grow area update
    builder.addCase(updateGrowAreaUpdate.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update grow area update'));
    });
    builder.addCase(updateGrowAreaUpdate.fulfilled, (state, action) => {
      if (!state.selectedGrowArea) {
        return;
      }

      for (let i = 0; i < state.selectedGrowArea.updates.length; i++) {
        if (state.selectedGrowArea.updates[i].id === action.payload.id) {
          state.selectedGrowArea.updates[i] = action.payload;
          break;
        }
      }
    });

    // Delete grow area update
    builder.addCase(deleteGrowAreaUpdate.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete grow area update'));
    });
    builder.addCase(deleteGrowAreaUpdate.fulfilled, (state, action) => {
      if (!state.selectedGrowArea) {
        return;
      }

      state.selectedGrowArea.updates = state.selectedGrowArea.updates.filter(
        (growAreaUpdate) => growAreaUpdate.id !== action.meta.arg.growAreaUpdateID
      );
    });
  }
});

export default growAreasSlice.reducer;

export {
  // Grow Areas
  fetchGrowAreas,
  fetchGrowArea,
  fetchProgressionSkillGrowArea,
  createGrowArea,
  updateGrowArea,

  // Grow Area Actions
  createGrowAreaAction,
  updateGrowAreaAction,
  deleteGrowAreaAction,

  // Grow Area Updates
  createGrowAreaUpdate,
  updateGrowAreaUpdate,
  deleteGrowAreaUpdate
};
