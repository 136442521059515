import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { classNames } from '../common/utils';

type BasicListboxItem = {
  id: string;
  name: string | JSX.Element;
};

interface BasicListboxProps {
  items: BasicListboxItem[];
  defaultItem?: BasicListboxItem;
  placeholder?: string;
  onChange?: (item: BasicListboxItem) => void;
  fetchItemsCb?: () => Promise<BasicListboxItem[]>;
}

const BasicListbox = ({
  items: _items,
  defaultItem,
  placeholder = 'Select an item',
  onChange = () => {},
  fetchItemsCb
}: BasicListboxProps): JSX.Element => {
  const [items, setItems] = useState(_items);
  const [selected, setSelected] = useState<BasicListboxItem>(
    defaultItem
      ? items.find((item) => item.id === defaultItem.id) ?? defaultItem
      : { id: '', name: placeholder }
  );

  useEffect(() => {
    if (defaultItem) {
      setSelected(items.find((item) => item.id === defaultItem.id) ?? defaultItem);
    }
  }, [defaultItem, items]);

  useEffect(() => {
    if (_items) {
      setItems(_items);
    }
  }, [_items]);

  return (
    <Listbox
      value={selected}
      onChange={(selected) => {
        setSelected(selected);
        onChange(selected);
      }}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm leading-6"
              onClick={(e) => {
                e.stopPropagation();

                if (fetchItemsCb) {
                  fetchItemsCb().then((newItems) => {
                    setItems(newItems);
                  });
                }
              }}>
              <span
                className={classNames(
                  selected.id === '' ? 'text-sm text-gray-500 py-0.5 pr-1' : 'text-gray-900',
                  'block truncate'
                )}>
                {selected.name}
              </span>

              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-fit max-w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {items.length === 0 ? (
                  <p className="text-center italic text-gray-500 w-full py-2 px-3">
                    No items found
                  </p>
                ) : (
                  items.map((item) => (
                    <Listbox.Option
                      key={item.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-pointer select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={item}>
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}>
                            {item.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}>
                              <CheckIcon className="size-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default BasicListbox;
