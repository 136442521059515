import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';
import _ from 'lodash';

import {
  AppLoader,
  AssignSalaryRangeModal,
  AssignUserToLevelModal,
  HorizontalLine,
  NotFoundComponent,
  PermissionProtectedAccess,
  SkillCard,
  SkillLibrarySlideover,
  Avatar,
  WysiwygEditor,
  Button,
  ConfirmationDialog
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { deleteLevel, fetchLevel, updateLevel } from '../../core/level/levelSlice';
import { LevelData } from '../../core/level/Level.model';
import { checkPermissionAccess } from '../../common/utils';
import {
  fetchDepartment,
  updateDepartmentLevelNameAndSeniority
} from '../../core/cdf/departmentsSlice';

const CDFLevelDefinitionPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const isLevelPending = useAppSelector((state) => state.level.isLevelPending);
  const level = useAppSelector((state) => state.level.level);

  const [openSlideover, setOpenSlideover] = useState(false);
  const [openSalaryRangeModal, setOpenSalaryRangeModal] = useState(false);
  const [openAssignUserModal, setOpenAssignUserModal] = useState(false);
  const [deleteLevelBtnModalOpen, setDeleteLevelBtnModalOpen] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';
  const departmentID = params.departmentID ?? '';
  const levelID = params.levelID ?? '';

  useEffect(() => {
    dispatch(fetchLevel({ organizationSlug, departmentID, levelID }));
  }, [levelID]);

  const updateLevelDebouncedFn = useMemo(() => {
    return _.debounce((_levelID: string, data: LevelData) => {
      dispatch(updateLevel({ organizationSlug, departmentID, levelID: _levelID, levelData: data }));
    }, 500);
  }, [level?.id]);

  async function deleteDevelopmentPathLevel(levelID: string): Promise<void> {
    const res: any = await dispatch(deleteLevel({ organizationSlug, departmentID, levelID }));
    if (res.error) {
      return;
    }

    dispatch(fetchDepartment({ organizationSlug, departmentID }));
  }

  function closeAssignSalaryRangeModal(): void {
    setOpenSalaryRangeModal(false);
  }

  function openAssignSalaryRangeModal(): void {
    setOpenSalaryRangeModal(true);
  }

  function closeAssignUserToLevelModal(): void {
    setOpenAssignUserModal(false);
  }

  function openAssignUserToLevelModal(): void {
    setOpenAssignUserModal(true);
  }

  if (isLevelPending) {
    return <AppLoader />;
  }

  if (!level) {
    return (
      <NotFoundComponent
        title="Level not found"
        description="The level you are looking for does not exist."
      />
    );
  }

  return (
    <>
      <div className="relative h-full w-full">
        <div className="absolute flex flex-col gap-2 justify-start h-full w-full overflow-auto">
          <div className="flex flex-row w-full pr-8">
            <div className="flex-1">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between mt-1">
                  <PermissionProtectedAccess
                    minimalRequiredPermissionRole="editor"
                    fallbackElement={
                      <h3 className="font-semibold text-lg text-gray-900">{level.name}</h3>
                    }>
                    <input
                      type="text"
                      defaultValue={level.name}
                      autoComplete="off"
                      className="font-semibold rounded-md text-lg h-8 text-gray-900 ml-2 border-0 p-0 focus:p-1 w-[25rem]"
                      onChange={(e) => {
                        if (e.target.value.length < 2) {
                          return;
                        }
                        dispatch(
                          updateDepartmentLevelNameAndSeniority({
                            departmentID,
                            developmentPathID: level.developmentPathID,
                            levelID,
                            name: e.target.value,
                            seniority: level.seniority
                          })
                        );
                        updateLevelDebouncedFn(level.id, { ...level, name: e.target.value });
                      }}
                    />
                  </PermissionProtectedAccess>

                  <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                    <>
                      <Button
                        variant={'destructive'}
                        className="h-8"
                        onClick={() => {
                          setDeleteLevelBtnModalOpen(true);
                        }}>
                        Delete
                      </Button>
                      <ConfirmationDialog
                        open={deleteLevelBtnModalOpen}
                        title={'Are you absolutely sure?'}
                        description={
                          <span>
                            This action cannot be undone. Confirming this action will permanently
                            delete <span className="font-semibold">{level.name}</span> definition.
                          </span>
                        }
                        variant="destructive"
                        onClose={() => setDeleteLevelBtnModalOpen(false)}
                        onConfirm={() => {
                          deleteDevelopmentPathLevel(level.id);
                          setDeleteLevelBtnModalOpen(false);
                          navigate('../');
                        }}
                      />
                    </>
                  </PermissionProtectedAccess>
                </div>

                <div
                  className={`flex-none flex flex-col md:flex-row mt-2 gap-2 md:gap-8 ${
                    checkPermissionAccess(authUser.permissionRole, 'editor') && 'pl-2'
                  }`}>
                  <div className="flex-initial flex flex-row gap-2">
                    <p className="whitespace-nowrap text-sm font-medium text-gray-900">
                      Seniority Level:
                    </p>
                    <PermissionProtectedAccess
                      minimalRequiredPermissionRole="editor"
                      fallbackElement={<p className="text-sm text-gray-800">{level.seniority}</p>}>
                      <input
                        type="number"
                        defaultValue={level.seniority}
                        className="-mr-5 h-5 w-11 text-sm text-gray-800 px-1 rounded-md border-0 bg-transparent outline-none transition-all duration-200 ease-linear"
                        onChange={(e) => {
                          dispatch(
                            updateDepartmentLevelNameAndSeniority({
                              departmentID,
                              developmentPathID: level.developmentPathID,
                              levelID,
                              name: level.name,
                              seniority: parseInt(e.target.value)
                            })
                          );
                          updateLevelDebouncedFn(level.id, {
                            ...level,
                            seniority: parseInt(e.target.value)
                          });
                        }}
                      />
                    </PermissionProtectedAccess>
                  </div>

                  {!level.salaryMin && !level.salaryMax ? (
                    <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                      <div className="flex-initial flex flex-row gap-2">
                        <p className="text-sm font-medium text-gray-900">Salary:</p>
                        <Button
                          size={'sm'}
                          variant={'link'}
                          className="h-5 pl-0"
                          onClick={() => openAssignSalaryRangeModal()}>
                          Add salary range
                        </Button>
                      </div>
                    </PermissionProtectedAccess>
                  ) : (
                    <PermissionProtectedAccess
                      minimalRequiredPermissionRole="editor"
                      fallbackElement={
                        <div className="flex-initial flex flex-row gap-2">
                          <p className="text-sm font-medium text-gray-900">Salary:</p>
                          <p className="text-sm text-gray-800">
                            {level.salaryCurrency} {level.salaryMin} - {level.salaryMax}
                          </p>
                        </div>
                      }>
                      <div className="flex-initial flex flex-row gap-2">
                        <p className="text-sm font-medium text-gray-900">Salary:</p>
                        <Button
                          size={'sm'}
                          variant={'link'}
                          className="h-5 -ml-3"
                          onClick={() => openAssignSalaryRangeModal()}>
                          {level.salaryCurrency} {level.salaryMin} - {level.salaryMax}
                        </Button>
                      </div>
                    </PermissionProtectedAccess>
                  )}

                  <div className="flex-initial flex flex-row gap-2">
                    <p className="whitespace-nowrap text-sm font-medium text-gray-900">
                      Assigned to:
                    </p>
                    <div className="flex flex-row -space-x-0.5">
                      <dt className="sr-only">Empolyees</dt>
                      <div className="flex flex-row -space-x-0.5 max-w-40 truncate">
                        {level.assignedUsers.map((user) => (
                          <dd key={user.id}>
                            <Tooltip
                              content={`${user.firstName} ${user.lastName}`}
                              placement="bottom"
                              className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                              <Avatar user={user} size={5} />
                            </Tooltip>
                          </dd>
                        ))}
                      </div>
                      <PermissionProtectedAccess minimalRequiredPermissionRole="manager">
                        <dd>
                          <Tooltip
                            content="Assign employee to level"
                            placement="bottom"
                            className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                            <button
                              type="button"
                              className="size-5 rounded-full border-2 border-gray-300 text-center hover:border-indigo-500 ring-2 ring-white"
                              onClick={() => openAssignUserToLevelModal()}>
                              <UserPlusIcon
                                className="size-4 text-gray-400 hover:text-indigo-500"
                                aria-hidden="true"
                              />
                            </button>
                          </Tooltip>
                        </dd>
                      </PermissionProtectedAccess>
                    </div>
                  </div>
                </div>

                <div className="flex-1">
                  <WysiwygEditor
                    placeholder={
                      checkPermissionAccess(authUser.permissionRole, 'editor')
                        ? `Add your ${level.name} description here...`
                        : 'Level description not available'
                    }
                    initialContent={level.description}
                    stringHandler="html"
                    className={`${
                      checkPermissionAccess(authUser.permissionRole, 'editor') && 'p-2'
                    } text-base text-gray-700`}
                    editable={checkPermissionAccess(authUser.permissionRole, 'editor')}
                    onChange={(html) => {
                      updateLevelDebouncedFn(level.id, { ...level, description: html });
                    }}
                  />
                </div>

                <HorizontalLine />

                <div
                  className={`flex-none flex flex-col gap-4 ${
                    checkPermissionAccess(authUser.permissionRole, 'editor') && 'pl-2'
                  }`}>
                  <div className="flex-1 flex flex-row">
                    <div className="flex-none mr-4 font-semibold text-base text-gray-700">
                      Required Skills
                    </div>
                    <div className="flex-1 flex flex-row justify-between">
                      <div className="flex-initial flex items-center justify-center w-6 rounded-md text-base text-gray-800 bg-gray-100">
                        {level.skills.length}
                      </div>
                      <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                        <Button
                          size={'sm'}
                          variant={'link'}
                          className="h-6 font-semibold text-base"
                          onClick={() => setOpenSlideover(true)}>
                          Add Skill
                        </Button>
                      </PermissionProtectedAccess>
                    </div>
                  </div>
                  <div className="flex-1 flex flex-col gap-3">
                    {level.skills.map((skill) => (
                      <div key={skill.id} className="flex-1">
                        <SkillCard
                          skillLevel={skill}
                          editable={checkPermissionAccess(authUser.permissionRole, 'editor')}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SkillLibrarySlideover open={openSlideover} setOpen={setOpenSlideover} />
      <AssignSalaryRangeModal
        levelData={level}
        open={openSalaryRangeModal}
        onClose={closeAssignSalaryRangeModal}
      />
      <AssignUserToLevelModal
        levelData={level}
        open={openAssignUserModal}
        onClose={closeAssignUserToLevelModal}
      />
    </>
  );
};

export default CDFLevelDefinitionPage;
