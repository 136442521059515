import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

import {
  AppLoader,
  Badge,
  Button,
  HorizontalLine,
  PermissionProtectedAccess,
  PricingTableModal
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';

const PLAN_MAP = {
  free_plan: 'Free Plan',
  pro_plan: 'Pro Plan',
  enterprise: 'Enterprise Plan',
  internal: 'Internal Plan'
};

const BillingPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const isOrganizationPending = useAppSelector((state) => state.organization.isOrganizationPending);
  const organization = useAppSelector((state) => state.organization.selectedOrganization);
  const areEmployeesPending = useAppSelector((state) => state.employees.areEmployeesPending);
  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);

  const [openPricingTableModal, setOpenPricingTableModal] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areEmployeesFetched) {
      return;
    }
    dispatch(fetchOrganizationUsers({ organizationSlug }));
  }, []);

  if (isOrganizationPending || areEmployeesPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex flex-row justify-center mt-6">
        <div className="flex flex-col gap-3 w-full max-w-[60rem]">
          <h3 className="text-lg font-medium text-gray-900">Billing</h3>
          <div className="flex flex-col justify-start gap-3 w-full border border-gray-300 shadow rounded-md p-4">
            <div className="flex flex-row justify-between items-center gap-3">
              <p className="text-gray-900 font-semibold">Plan Overview</p>

              <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                <PermissionProtectedAccess minimalRequiredPermissionRole="admin">
                  {organization.stripeCustomerPortalURL ? (
                    <Button
                      size={'sm'}
                      onClick={() => {
                        window.open(organization.stripeCustomerPortalURL, '_blank', 'noopener');
                      }}>
                      Manage
                    </Button>
                  ) : (
                    <Button
                      size={'sm'}
                      onClick={() => {
                        setOpenPricingTableModal(true);
                      }}>
                      Select Plan
                    </Button>
                  )}
                </PermissionProtectedAccess>

                {!organization.stripeCustomerPortalURL && (
                  <a
                    className="group flex flex-row items-center gap-1 text-sm text-indigo-600 hover:text-indigo-500 whitespace-nowrap"
                    href="https://sprout-hr.com/#pricing"
                    target="_blank"
                    rel="noreferrer">
                    View Pricing
                    <ArrowTopRightOnSquareIcon className="size-4 group-hover:text-indigo-500" />
                  </a>
                )}
              </div>
            </div>
            <HorizontalLine />
            <div className="flex flex-col sm:flex-row sm:items-center gap-x-10 gap-y-3">
              <div className="flex flex-col gap-1">
                <p className="text-sm text-gray-700">Current Plan</p>
                <div className="flex flex-row justify-start items-center gap-3">
                  <p className="text-xl text-gray-900 font-semibold">
                    {PLAN_MAP[organization.plan] ?? 'Free Plan'}
                  </p>
                  <Badge
                    variant={organization.isPlanActive ? 'green' : 'red'}
                    className="p-3 rounded-md">
                    {organization.isPlanActive ? 'Active' : 'Inactive'}
                  </Badge>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <p className="text-sm text-gray-700">Team Members</p>
                <div className="flex flex-row justify-start items-center gap-3">
                  <p className="text-xl text-gray-900 font-semibold">
                    {employees.length}
                    <span className="text-gray-700">/{organization.userQuota} available</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PricingTableModal
        open={openPricingTableModal}
        onClose={() => setOpenPricingTableModal(false)}
      />
    </>
  );
};

export default BillingPage;
