import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { GoalData } from './Goal.model';

type GoalsState = {
  goals: GoalData[];
  goal: GoalData | null;
  areGoalsPending: boolean;
  isGoalPending: boolean;
  error: api.ApiError;
};

const initialState: GoalsState = {
  goals: [],
  goal: null,
  areGoalsPending: true,
  isGoalPending: true,
  error: null
};

const fetchGoals = createAsyncThunk(
  'goals/getGoals',
  async (args: api.GetGoalsArgs, { rejectWithValue }) => {
    try {
      return await api.getGoals(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchGoal = createAsyncThunk(
  'goals/getGoal',
  async (args: api.GetGoalArgs, { rejectWithValue }) => {
    try {
      return await api.getGoal(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createGoal = createAsyncThunk(
  'goals/createGoal',
  async (args: api.CreateGoalArgs, { rejectWithValue }) => {
    try {
      return await api.createGoal(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateGoal = createAsyncThunk(
  'goals/updateGoal',
  async (args: api.UpdateGoalArgs, { rejectWithValue }) => {
    try {
      return await api.updateGoal(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteGoal = createAsyncThunk(
  'goals/deleteGoal',
  async (args: api.GetGoalArgs, { rejectWithValue }) => {
    try {
      return await api.deleteGoal(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createGoalCheckIn = createAsyncThunk(
  'goals/createGoalCheckIn',
  async (args: api.CreateGoalCheckInArgs, { rejectWithValue }) => {
    try {
      return await api.createGoalCheckIn(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateGoalCheckIn = createAsyncThunk(
  'goals/updateGoalCheckIn',
  async (args: api.UpdateGoalCheckInArgs, { rejectWithValue }) => {
    try {
      return await api.updateGoalCheckIn(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteGoalCheckIn = createAsyncThunk(
  'goals/deleteGoalCheckIn',
  async (args: api.GetGoalCheckInArgs, { rejectWithValue }) => {
    try {
      return await api.deleteGoalCheckIn(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

function linkGoalToParentGoal(goals: GoalData[], goal: GoalData): void {
  for (const g of goals) {
    if (g.id === goal.parentID) {
      g.children.push(goal);
      return;
    }

    if (g.children.length) {
      linkGoalToParentGoal(g.children, goal);
    }
  }
}

function findAndUpdateGoal(goals: GoalData[], goal: GoalData): void {
  for (let i = 0; i < goals.length; i++) {
    if (goals[i].id === goal.id) {
      goals[i] = goal;
      return;
    }

    if (goals[i].children.length) {
      findAndUpdateGoal(goals[i].children, goal);
    }
  }
}

function findAndDeleteGoal(goals: GoalData[], goalID: string): void {
  for (let i = 0; i < goals.length; i++) {
    if (goals[i].id === goalID) {
      goals.splice(i, 1);
      return;
    }

    if (goals[i].children.length) {
      findAndDeleteGoal(goals[i].children, goalID);
    }
  }
}

const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch goals
    builder.addCase(fetchGoals.pending, (state) => {
      state.areGoalsPending = true;
      state.error = null;
    });
    builder.addCase(fetchGoals.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.areGoalsPending = false;
      toast.error(getCustomUserError(state.error, 'Failed to fetch goals'));
    });
    builder.addCase(fetchGoals.fulfilled, (state, action) => {
      state.goals = action.payload;
      state.areGoalsPending = false;
      state.error = null;
    });

    // Fetch goal
    builder.addCase(fetchGoal.pending, (state) => {
      state.goal = null;
      state.isGoalPending = true;
      state.error = null;
    });
    builder.addCase(fetchGoal.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.isGoalPending = false;
      toast.error(getCustomUserError(state.error, 'Failed to fetch goal'));
    });
    builder.addCase(fetchGoal.fulfilled, (state, action) => {
      state.goal = action.payload;
      state.isGoalPending = false;
      state.error = null;
    });

    // Create goal
    builder.addCase(createGoal.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create goal'));
    });
    builder.addCase(createGoal.fulfilled, (state, action) => {
      if (!action.payload.parentID) {
        state.goals.push(action.payload);
        return;
      }

      if (state.goal?.id === action.payload.parentID) {
        state.goal.children.push(action.payload);
        return;
      }

      linkGoalToParentGoal(state.goals, action.payload);
    });

    // Update goal
    builder.addCase(updateGoal.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update goal'));
    });
    builder.addCase(updateGoal.fulfilled, (state, action) => {
      if (!state.goal) {
        return;
      }

      const newGoal = {
        ...action.payload,
        children: state.goal.children,
        checkIns: state.goal.checkIns
      };

      state.goal = newGoal;
      findAndUpdateGoal(state.goals, state.goal);
    });

    // Delete goal
    builder.addCase(deleteGoal.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete goal'));
    });
    builder.addCase(deleteGoal.fulfilled, (state, action) => {
      if (state.goal?.id === action.meta.arg.goalID) {
        state.goal = null;
      }
      findAndDeleteGoal(state.goals, action.meta.arg.goalID);
    });

    // Create goal check-in
    builder.addCase(createGoalCheckIn.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create goal check-in'));
    });
    builder.addCase(createGoalCheckIn.fulfilled, (state, action) => {
      if (!state.goal) {
        return;
      }

      state.goal.checkIns.unshift(action.payload);
    });

    // Update goal check-in
    builder.addCase(updateGoalCheckIn.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update goal check-in'));
    });
    builder.addCase(updateGoalCheckIn.fulfilled, (state, action) => {
      if (!state.goal) {
        return;
      }

      for (let i = 0; i < state.goal.checkIns.length; i++) {
        if (state.goal.checkIns[i].id === action.payload.id) {
          state.goal.checkIns[i] = action.payload;
          return;
        }
      }
    });

    // Delete goal check-in
    builder.addCase(deleteGoalCheckIn.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete goal check-in'));
    });
    builder.addCase(deleteGoalCheckIn.fulfilled, (state, action) => {
      if (!state.goal) {
        return;
      }

      state.goal.checkIns = state.goal.checkIns.filter((ci) => ci.id !== action.meta.arg.checkInID);
    });
  }
});

export default goalsSlice.reducer;

export {
  fetchGoals,
  fetchGoal,
  createGoal,
  updateGoal,
  deleteGoal,
  createGoalCheckIn,
  updateGoalCheckIn,
  deleteGoalCheckIn
};
