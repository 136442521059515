import ProgressionLevelSkill, {
  ProgressionLevelSkillData
} from '../progression/ProgressionLevelSkill.model';
import User, { UserData } from '../user/User.model';
import ActionItem, { ActionItemData } from './ActionItem.model';
import AgendaItem, { AgendaItemData } from './AgendaItem.model';
import MeetingNotes, { MeetingNoteData } from './MeetingNotes.model';

export type MeetingProgressionData = {
  /** @type {Generics.UUID} */
  id: string;
  user: UserData;
  starredSkills: ProgressionLevelSkillData[];
};

export type MeetingData = {
  /** @type {Generics.UUID} */
  id: string;

  scheduledTime: string;
  canceled: boolean;

  agendaItems: AgendaItemData[];
  actionItems: ActionItemData[];
  notes: MeetingNoteData[];

  progression?: MeetingProgressionData;
};

class Meeting implements MeetingData {
  id: string;
  scheduledTime: string;
  canceled: boolean;

  agendaItems: AgendaItemData[];
  actionItems: ActionItemData[];
  notes: MeetingNoteData[];

  progression?: MeetingProgressionData;

  constructor(data: MeetingData) {
    this.id = data.id;
    this.scheduledTime = data.scheduledTime;
    this.canceled = data.canceled;
    this.agendaItems = data.agendaItems;
    this.actionItems = data.actionItems;
    this.notes = data.notes;
    this.progression = data.progression;
  }

  static buildMeetingDataFromResponse(response: any): MeetingData {
    const starredSkills: ProgressionLevelSkillData[] = (
      response.progression?.starred_skills ?? []
    ).map((starredSkill: any) =>
      ProgressionLevelSkill.buildProgressionLevelSkillDataFromResponse(starredSkill)
    );

    const sortedStarredSkills = starredSkills.sort((a, b) =>
      (a.skillName ?? '').localeCompare(b.skillName ?? '')
    );

    return {
      id: response.id,
      scheduledTime: response.scheduled_time,
      canceled: response.canceled,
      agendaItems: (response.agenda_items ?? []).map((item: any) =>
        AgendaItem.buildAgendaItemDataFromResponse(item)
      ),
      actionItems: (response.action_items ?? []).map((item: any) =>
        ActionItem.buildActionItemDataFromResponse(item)
      ),
      notes: (response.meeting_notes ?? []).map((note: any) =>
        MeetingNotes.buildMeetingNoteDataFromResponse(note)
      ),
      progression: response.progression
        ? {
            id: response.progression.id,
            user: User.buildUserDataFromResponse(response.progression.user),
            starredSkills: sortedStarredSkills
          }
        : undefined
    };
  }
}

export default Meeting;
