import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox, Transition } from '@headlessui/react';

import { GoalData } from '../../core/goal/Goal.model';
import { useAppDispatch } from '../../hooks';
import { fetchGoals } from '../../core/goal/goalsSlice';
import { classNames } from '../../common/utils';

type GoalsComboboxProps = {
  defaultValue?: GoalData;
  placeholder?: string;
  onChange?: (goal?: GoalData) => void;
};

const GoalsCombobox = ({
  defaultValue,
  placeholder,
  onChange = () => {}
}: GoalsComboboxProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState('');
  const [goals, setGoals] = useState<GoalData[]>([]);
  const [selectedLevel, setSelectedLevel] = useState(defaultValue);

  const organizationSlug = params.organizationSlug ?? '';

  const filteredGoals =
    query === ''
      ? goals
      : goals.filter((g) => {
          return g.title.toLowerCase().includes(query.toLowerCase());
        });

  async function fetchAllGoalsHandler(): Promise<void> {
    const res: any = await dispatch(fetchGoals({ organizationSlug }));
    if (res.error) {
      return;
    }

    setGoals(goalsTreeToFlatArray(res.payload));
  }

  function goalsTreeToFlatArray(goals: GoalData[]): GoalData[] {
    return goals.reduce((acc: GoalData[], goal) => {
      acc.push(goal);
      if (goal.children && goal.children.length > 0) {
        acc.push(...goalsTreeToFlatArray(goal.children));
      }
      return acc;
    }, []);
  }

  return (
    <Combobox
      as="div"
      defaultValue={selectedLevel}
      onChange={(selectedItem: GoalData) => {
        setSelectedLevel(selectedItem);
        onChange(selectedItem);
      }}>
      <div className="relative">
        <div>
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(goal: GoalData) => goal.title}
            placeholder={placeholder ?? 'Select a level'}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              fetchAllGoalsHandler();
            }}>
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}>
          <Combobox.Options className="absolute z-10 mt-1 max-h-52 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredGoals.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found
              </div>
            ) : (
              <>
                <Combobox.Option
                  value={{
                    id: 'none',
                    title: 'No parent goal'
                  }}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }>
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>
                        No parent goal
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}>
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
                {filteredGoals.map((goal) => (
                  <Combobox.Option
                    key={goal.id}
                    value={goal}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }>
                    {({ active, selected }) => (
                      <>
                        <span className={classNames('block truncate', selected && 'font-semibold')}>
                          {goal.title}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}>
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </>
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default GoalsCombobox;
