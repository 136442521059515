import Organization, { OrganizationData } from '../core/organization/Organization.model';
import { Api } from './api';
import { parseError } from './error';

export type GetOrganizationArgs = {
  organizationSlug: string;
};

async function getOrganizations(): Promise<OrganizationData[]> {
  try {
    const { data } = await Api.get('/organizations');
    const organizationsData = data.organizations.map((org: any) =>
      Organization.buildOrganizationDataFromResponse(org)
    );
    return Promise.resolve(organizationsData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetOrganizationBySlugArgs = {
  organizationSlug: string;
};

async function getOrganizationBySlug({
  organizationSlug
}: GetOrganizationBySlugArgs): Promise<OrganizationData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}`);
    const orgData = Organization.buildOrganizationDataFromResponse(data);
    return Promise.resolve(orgData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

type CreateOrganizationArgs = {
  organizationSlug: string;
  organizationName: string;
};

async function createOrganization({
  organizationSlug,
  organizationName
}: CreateOrganizationArgs): Promise<OrganizationData> {
  try {
    const { data } = await Api.post('/organizations', {
      slug: organizationSlug,
      name: organizationName
    });
    const orgData = Organization.buildOrganizationDataFromResponse(data);
    return Promise.resolve(orgData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function updateOrganization(orgData: OrganizationData): Promise<OrganizationData> {
  try {
    const { data } = await Api.patch(`/organizations/${orgData.id}`, {
      owner_id: orgData.owner.id || null,
      slug: orgData.slug || null,
      name: orgData.name || null,
      logo: orgData.logo
    });
    return Promise.resolve(Organization.buildOrganizationDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export { getOrganizations, getOrganizationBySlug, createOrganization, updateOrganization };
