import React from 'react';

type ScreenErrorComponentProps = {
  statusCode: number;
  title: string;
  description: string;
};

const ScreenErrorComponent = ({
  statusCode,
  title,
  description
}: ScreenErrorComponentProps): JSX.Element => {
  return (
    <div className="text-center grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <p className="text-base font-semibold text-indigo-600">{statusCode}</p>
      <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
      <p className="mt-6 text-base leading-7 text-gray-600">{description}</p>
      <div className="mt-6">
        <a
          href="/profile"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:w-auto">
          Go to my Profile
        </a>
      </div>
    </div>
  );
};

export default ScreenErrorComponent;
