import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment-timezone';
import { toast } from 'sonner';

import { useAppDispatch } from '../../hooks';
import { Button } from '../ui/Button';
import HorizontalLine from '../HorizontalLine';
import UsersCombobox from '../UsersCombobox';
import { useAppSelector } from '../../core/store';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';
import BasicListbox from '../BasicListbox';
import { updateGoal } from '../../core/goal/goalsSlice';
import { GoalData, GoalDomain, GoalMeasurementType, GoalType } from '../../core/goal/Goal.model';
import { domainTypes, privacyTypes } from './CreateNewObjectiveModal';
import { fetchTeams } from '../../core/team/teamsSlice';
import { fetchDepartments } from '../../core/cdf/departmentsSlice';
import { goalMeasurementTypes } from './CreateNewKeyResultModal';
import { UpdateGoalArgs } from '../../api';
import GoalsCombobox from './GoalsCombobox';

interface EditGoalModalProps {
  goal: GoalData;
  open: boolean;
  onClose: () => void;
}

const EditGoalModal = ({ goal, open, onClose: _onClose }: EditGoalModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);
  const areTeamsFetched = useAppSelector((state) => state.teams.areTeamsFetched);
  const teams = useAppSelector((state) => state.teams.teams);
  const areDepartmentsFetched = useAppSelector((state) => state.departments.areDepartmentsFetched);
  const departments = useAppSelector((state) => state.departments.departments);

  const [parentGoal, setParentGoal] = useState(goal.parent);
  const [title, setTitle] = useState(goal.title);
  const [selectedMeasurementType, setSelectedMeasurementType] = useState(goal.measurementType);
  const [startValue, setStartValue] = useState(goal.startValue);
  const [targetValue, setTargetValue] = useState(goal.targetValue);
  const [selectedDomain, setSelectedDomain] = useState(goal.domain);
  const [selectedTeam, setSelectedTeam] = useState(goal.team);
  const [selectedDepartment, setSelectedDepartment] = useState(goal.department);
  const [ownerID, setOwnerID] = useState(goal.owner?.id ?? '');
  const [isPrivate, setIsPrivate] = useState(goal.private);
  const [startDate, setStartDate] = useState<DateValueType>({
    startDate: new Date(goal.startDate),
    endDate: new Date(goal.startDate)
  });
  const [dueDate, setDueDate] = useState<DateValueType>({
    startDate: new Date(goal.dueDate),
    endDate: new Date(goal.dueDate)
  });

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (!areEmployeesFetched) {
      dispatch(fetchOrganizationUsers({ organizationSlug }));
    }

    if (!open) {
      return;
    }

    setTitle(goal.title);
    setSelectedMeasurementType(goal.measurementType);
    setStartValue(goal.startValue);
    setTargetValue(goal.targetValue);
    setSelectedDomain(goal.domain);
    setSelectedTeam(goal.team);
    setSelectedDepartment(goal.department);
    setOwnerID(goal.owner?.id ?? '');
    setIsPrivate(goal.private);
    setStartDate({ startDate: new Date(goal.startDate), endDate: new Date(goal.startDate) });
    setDueDate({ startDate: new Date(goal.dueDate), endDate: new Date(goal.dueDate) });
  }, [open]);

  useEffect(() => {
    if (selectedDomain === GoalDomain.TEAM && !areTeamsFetched) {
      dispatch(fetchTeams({ organizationSlug }));
    }
    if (selectedDomain === GoalDomain.DEPARTMENT && !areDepartmentsFetched) {
      dispatch(fetchDepartments({ organizationSlug }));
    }
  }, [selectedDomain]);

  async function updateGoalHandler(): Promise<void> {
    if (!title) {
      toast.warning('Please enter a title');
      return;
    }
    if (goal.type === GoalType.KEY_RESULT && !selectedMeasurementType) {
      toast.warning('Please select a measurement type');
      return;
    }
    if (!selectedDomain) {
      toast.warning('Please select a domain');
      return;
    }
    if (selectedDomain === GoalDomain.TEAM && !selectedTeam) {
      toast.warning('Please select a team');
      return;
    }
    if (selectedDomain === GoalDomain.DEPARTMENT && !selectedDepartment) {
      toast.warning('Please select a department');
      return;
    }
    if (!ownerID) {
      toast.warning('Please select an owner');
      return;
    }
    if (!startDate?.startDate) {
      toast.warning('Please select a start date');
      return;
    }
    if (!dueDate?.startDate) {
      toast.warning('Please select a due date');
      return;
    }

    const req: UpdateGoalArgs = {
      organizationSlug,
      goalID: goal.id,
      data: {
        title,
        ownerID,
        domain: selectedDomain,
        private: isPrivate,
        startDate: moment(startDate.startDate).toDate(),
        dueDate: moment(dueDate.startDate).toDate(),
        departmentID: selectedDepartment?.id,
        teamID: selectedTeam?.id,
        parentID: parentGoal?.id
      }
    };

    if (goal.type === GoalType.KEY_RESULT) {
      req.data.measurementType = selectedMeasurementType;
      req.data.startValue = startValue;
      req.data.targetValue = targetValue;
    }

    const res: any = await dispatch(updateGoal(req));
    if (res.error) {
      return;
    }

    onClose();
  }

  function onClose(): void {
    _onClose();
  }

  function maxMeasurementValue(): number | undefined {
    switch (selectedMeasurementType) {
      case GoalMeasurementType.PERCENTAGE:
        return 100;
      case GoalMeasurementType.BINARY:
        return 1;
      default:
        return undefined;
    }
  }

  function checkMeasurementValue(value: number): number {
    if (value < 0) {
      return 0;
    }

    const maxValue = maxMeasurementValue();
    if (maxValue && value > maxValue) {
      return maxValue;
    }
    return value;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="flex flex-row items-center gap-1 text-base font-semibold leading-6 text-gray-900">
                      {`Edit ${goal.type === GoalType.OBJECTIVE ? 'Objective' : 'Key Result'}`}
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    <div className="flex flex-col gap-1">
                      <p className="block text-sm font-medium text-gray-700">Parent goal</p>
                      <GoalsCombobox
                        defaultValue={parentGoal}
                        placeholder="Select parent goal"
                        onChange={(parentGoal) => {
                          setParentGoal(parentGoal);
                        }}
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="block text-sm font-medium text-gray-700">Goal title</p>
                      <TextareaAutosize
                        className="text-gray-900 h-9 rounded-md border-gray-300 text-sm resize-none"
                        value={title}
                        placeholder="What is the goal or key result you want accomplish?"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    {goal.type === GoalType.KEY_RESULT && (
                      <>
                        <div className="flex flex-col gap-1">
                          <p className="block text-sm font-medium text-gray-700">
                            How will you measure progress?
                          </p>
                          <BasicListbox
                            items={goalMeasurementTypes}
                            defaultItem={goalMeasurementTypes.find(
                              (item) => item.id === selectedMeasurementType
                            )}
                            placeholder="Select measurement unit"
                            onChange={(measurementType) => {
                              setSelectedMeasurementType(measurementType.id as GoalMeasurementType);

                              switch (measurementType.id) {
                                case GoalMeasurementType.PERCENTAGE:
                                  setStartValue(0);
                                  setTargetValue(100);
                                  break;
                                case GoalMeasurementType.CURRENCY:
                                  setStartValue(0);
                                  setTargetValue(1000000);
                                  break;

                                default:
                                  setStartValue(0);
                                  setTargetValue(1);
                              }
                            }}
                          />
                        </div>

                        <div
                          className={`${
                            selectedMeasurementType === GoalMeasurementType.BINARY
                              ? 'hidden'
                              : 'flex'
                          } flex-col sm:flex-row sm:justify-between gap-6`}>
                          <div className="flex-1">
                            <p className="text-sm font-medium text-gray-700 mb-1">Start value</p>
                            <input
                              type="number"
                              value={startValue}
                              min={0}
                              max={maxMeasurementValue()}
                              className="text-gray-900 h-9 rounded-md border-gray-300 text-sm w-full"
                              onChange={(e) => {
                                setStartValue(checkMeasurementValue(parseInt(e.target.value, 10)));
                              }}
                            />
                          </div>

                          <div className="flex-1">
                            <p className="text-sm font-medium text-gray-700 mb-1">Target value</p>
                            <input
                              type="number"
                              value={targetValue}
                              min={1}
                              max={maxMeasurementValue()}
                              className="text-gray-900 h-9 rounded-md border-gray-300 text-sm w-full"
                              onChange={(e) => {
                                setTargetValue(checkMeasurementValue(parseInt(e.target.value, 10)));
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Goal domain</p>
                        <BasicListbox
                          items={domainTypes}
                          defaultItem={domainTypes.find((item) => item.id === selectedDomain)}
                          placeholder="Select goal domain"
                          onChange={(domainType) => setSelectedDomain(domainType.id as GoalDomain)}
                        />
                      </div>

                      <div
                        className={`${
                          selectedDomain === GoalDomain.TEAM ? 'flex-1 w-full' : 'hidden'
                        }`}>
                        <p className="block text-sm font-medium text-gray-700 mb-1">
                          Accountable team
                        </p>
                        <BasicListbox
                          items={teams}
                          placeholder="Select team"
                          onChange={(team) => setSelectedTeam(teams.find((t) => t.id === team.id))}
                        />
                      </div>

                      <div
                        className={`${
                          selectedDomain === GoalDomain.DEPARTMENT ? 'flex-1 w-full' : 'hidden'
                        }`}>
                        <p className="block text-sm font-medium text-gray-700 mb-1">
                          Accountable department
                        </p>
                        <BasicListbox
                          items={departments}
                          placeholder="Select department"
                          onChange={(department) => {
                            setSelectedDepartment(departments.find((d) => d.id === department.id));
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Goal owner</p>
                        <UsersCombobox
                          removeAuthUser={false}
                          users={employees}
                          defaultValue={employees.find((user) => user.id === ownerID)}
                          placeholder="Select goal owner"
                          onChange={(user) => setOwnerID(user.id)}
                        />
                      </div>

                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Privacy</p>
                        <BasicListbox
                          items={privacyTypes}
                          defaultItem={privacyTypes.find(
                            (item) => item.id === (isPrivate ? 'private' : 'public')
                          )}
                          onChange={(privacyType) => setIsPrivate(privacyType.id === 'private')}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Start date</p>
                        <Datepicker
                          primaryColor={'indigo'}
                          inputClassName={
                            'w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm'
                          }
                          placeholder="yyyy-mm-dd"
                          useRange={false}
                          asSingle={true}
                          value={startDate}
                          onChange={(newDate) => setStartDate(newDate)}
                        />
                      </div>

                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Due date</p>
                        <Datepicker
                          primaryColor={'indigo'}
                          inputClassName={
                            'w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm'
                          }
                          placeholder="yyyy-mm-dd"
                          useRange={false}
                          asSingle={true}
                          value={dueDate}
                          onChange={(newDate) => setDueDate(newDate)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1">
                  <Button
                    onClick={() => {
                      updateGoalHandler();
                    }}>
                    Update
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditGoalModal;
