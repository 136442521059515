import authReducer from './auth/authSlice';
import breadcrumbsReducer from './breadcrumb/breadcrumbsSlice';
import departmentsReducer from './cdf/departmentsSlice';
import employeesReducer from './employee/employeesSlice';
import growAreasReducer from './grow-area/growAreasSlice';
import levelReducer from './level/levelSlice';
import libraryFrameworksReducer from './library/framework/frameworksSlice';
import librarySkillsReducer from './library/skill/skillsSlice';
import userReducer from './user/userSlice';
import userIntegrationsReducer from './user/userIntegrationsSlice';
import organizationReducer from './organization/organizationSlice';
import progressionReducer from './progression/progressionSlice';
import oneOnOnesReducer from './one-on-one/oneOnOneSlice';
import meetingsReducer from './one-on-one/meetingSlice';
import teamsReducer from './team/teamsSlice';
import goalsReducer from './goal/goalsSlice';

const reducers = {
  auth: authReducer,
  breadcrumbs: breadcrumbsReducer,
  departments: departmentsReducer,
  employees: employeesReducer,
  growAreas: growAreasReducer,
  level: levelReducer,
  libraryFrameworks: libraryFrameworksReducer,
  librarySkills: librarySkillsReducer,
  user: userReducer,
  userIntegrations: userIntegrationsReducer,
  organization: organizationReducer,
  progression: progressionReducer,
  oneOnOnes: oneOnOnesReducer,
  meetings: meetingsReducer,
  teams: teamsReducer,
  goals: goalsReducer
};

export default reducers;
