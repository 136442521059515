import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import TimePicker from 'rc-time-picker';
import moment from 'moment-timezone';
import { toast } from 'sonner';

import '../../assets/rc-time-picker.css';
import GoogleCalendarIcon from '../../assets/images/google-calendar-icon.svg';
// import OutlookCalendarIcon from '../../assets/images/outlook-calendar-icon.svg';
import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';
import UsersCombobox, { ComboboxUser } from '../UsersCombobox';
import { Button } from '../ui/Button';
import HorizontalLine from '../HorizontalLine';
import DropdownButton from '../ui/DropdownButton';
import BasicListbox from '../BasicListbox';
import { timezoneList, timezoneMap } from '../../common/timezone';
import { createOneOnOne } from '../../core/one-on-one/oneOnOneSlice';
import { googleCalendarOAuthHandler } from '../../common/auth';
import {
  connectUserGoogleCalendar,
  fetchUserCalendarEvents,
  fetchUserIntegrations
} from '../../core/user/userIntegrationsSlice';
import { INTEGRATION_TYPES } from '../../core/user/UserIntegration.model';
import { CalendarEventData } from '../../core/calendar/CalendarEvent.model';
import { OneOnOneData } from '../../core/one-on-one/OneOnOne.model';

interface CreateNewOneOnOneModalProps {
  open: boolean;
  onClose: () => void;
  afterSuccessfullCreate?: (oneOnOne: OneOnOneData) => void;
}

const CreateNewOneOnOneModal = ({
  open,
  onClose: _onClose,
  afterSuccessfullCreate
}: CreateNewOneOnOneModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);

  const cancelButtonRef = useRef(null);
  const calenderConnectionOptions = useRef([
    {
      id: '1',
      name: (
        <div className="flex flex-row gap-2">
          <img className="size-6" src={GoogleCalendarIcon} alt="GoogleCalendarIcon" />
          <p className="flex flex-col justify-center">Google Calendar</p>
        </div>
      ),
      onClick: googleCalendarOAuthHandler({
        email: authUser.email,
        onSuccess: (code) => {
          dispatch(connectUserGoogleCalendar({ code }));
        }
      })
    }
    // {
    //   id: '2',
    //   name: (
    //     <div className="flex flex-row gap-2">
    //       <img className="size-6" src={OutlookCalendarIcon} alt="OutlookCalendarIcon" />
    //       <p className="flex flex-col justify-center">Outlook Calendar</p>
    //     </div>
    //   ),
    //   onClick: () => {}
    // }
  ]);

  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);
  const areUserIntegrationsFetched = useAppSelector(
    (state) => state.userIntegrations.areUserIntegrationsFetched
  );
  const userIntegrations = useAppSelector((state) => state.userIntegrations.integrations);
  const calendarEvents = useAppSelector((state) => state.userIntegrations.calendarEvents);

  const localTimezone = moment.tz.guess();

  const [selectedUser, setSelectedUser] = useState<ComboboxUser>();
  const [meetingDate, setMeetingDate] = useState<DateValueType>({ startDate: null, endDate: null });
  const [meetingTime, setMeetingTime] = useState({ hour: 12, minute: 0 });
  const [meetingTimezone, setMeetingTimezone] = useState(timezoneMap.get(localTimezone));
  const [meetingFrequency, setMeetingFrequency] = useState(2);
  const [calendarType, setCalendarType] = useState<string>();
  const [calendarEvent, setCalendarEvent] = useState<CalendarEventData>();

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (open && !areUserIntegrationsFetched) {
      dispatch(fetchUserIntegrations());
    }

    if (open && !areEmployeesFetched) {
      dispatch(fetchOrganizationUsers({ organizationSlug }));
    }
  }, [open]);

  useEffect(() => {
    if (open && userIntegrations.googleCalendar) {
      setCalendarType(INTEGRATION_TYPES.GOOGLE_CALENDAR);
      dispatch(fetchUserCalendarEvents({ integrationID: INTEGRATION_TYPES.GOOGLE_CALENDAR }));
    }
  }, [open, userIntegrations.googleCalendar]);

  async function createOneOnOneMeetingHandler(): Promise<void> {
    if (calendarType && calendarEvent) {
      const res: any = await dispatch(
        createOneOnOne({ organizationSlug, calendarType, calendarEvent })
      );
      if (res.error) {
        return;
      }

      if (afterSuccessfullCreate) {
        afterSuccessfullCreate(res.payload as OneOnOneData);
      }

      onClose();
      return;
    }

    if (!selectedUser) {
      toast.warning('Please select a team member');
      return;
    }
    if (!meetingDate?.startDate) {
      toast.warning('Please select a meeting date');
      return;
    }
    if (!meetingTimezone) {
      toast.warning('Please select a timezone');
      return;
    }
    if (meetingFrequency <= 0) {
      toast.warning('Meeting frequency need to be at least 1 week');
      return;
    }

    const meetingDateTime = moment(meetingDate.startDate)
      .tz(meetingTimezone.tzCode)
      .hour(meetingTime.hour)
      .minute(meetingTime.minute);

    if (meetingDateTime.isBefore(moment())) {
      toast.warning('Meeting date and time should be in the future');
      return;
    }

    const res: any = await dispatch(
      createOneOnOne({
        organizationSlug,
        guestUserID: selectedUser.id,
        scheduledTime: meetingDateTime.format(),
        meetingFrequency
      })
    );
    if (res.error) {
      return;
    }

    if (afterSuccessfullCreate) {
      afterSuccessfullCreate(res.payload as OneOnOneData);
    }

    onClose();
  }

  function onClose(): void {
    // Clear all the fields
    setSelectedUser(undefined);
    setMeetingDate({ startDate: null, endDate: null });
    setMeetingTime({ hour: 12, minute: 0 });
    setMeetingTimezone(timezoneMap.get(localTimezone));
    setMeetingFrequency(2);
    setCalendarType(undefined);
    setCalendarEvent(undefined);

    _onClose();
  }

  if (!areEmployeesFetched) {
    return <></>;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-[480px] sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Create new 1-on-1 meeting
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    {/* Calendar Connection Buttons */}
                    <div className="flex flex-row justify-center">
                      {!userIntegrations.googleCalendar && (
                        <DropdownButton
                          variant={'outline'}
                          name="Connect to an existing calendar event"
                          items={calenderConnectionOptions.current}
                        />
                      )}
                      {userIntegrations.googleCalendar && (
                        <div className="w-[340px]">
                          <BasicListbox
                            items={calendarEvents.map((event, index): any => {
                              return { id: index, name: event.title };
                            })}
                            onChange={(selectedEvent) => {
                              setCalendarType(INTEGRATION_TYPES.GOOGLE_CALENDAR);
                              setCalendarEvent(calendarEvents[+selectedEvent.id]);
                            }}
                            placeholder="Select calendar event"
                          />
                        </div>
                      )}
                    </div>

                    <Transition
                      show={!calendarEvent}
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="flex flex-col gap-5">
                      {/* Divider */}
                      <div>
                        <p className="text-sm text-gray-600 italic text-center mb-1">
                          or create manually
                        </p>
                        <HorizontalLine />
                      </div>

                      {/* Manual Meeting Setup */}
                      <div className="flex flex-col gap-5">
                        {/* Invite Team Member */}
                        <div className="flex flex-col gap-1">
                          <p className="block text-sm font-medium text-gray-700">Invite</p>
                          <UsersCombobox
                            users={employees}
                            placeholder="Select team member"
                            showEmail
                            onChange={(user) => {
                              setSelectedUser(user);
                            }}
                          />
                        </div>

                        <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                          {/* Meeting Date */}
                          <div className="flex-1 w-full">
                            <p className="block text-sm font-medium text-gray-700">
                              Next meeting date
                            </p>
                            <div className="mt-1 w-full">
                              <Datepicker
                                primaryColor={'indigo'}
                                inputClassName={
                                  'w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm'
                                }
                                placeholder="yyyy-mm-dd"
                                useRange={false}
                                asSingle={true}
                                value={meetingDate}
                                onChange={(newDate) => setMeetingDate(newDate)}
                              />
                            </div>
                          </div>

                          {/* Meeting Time */}
                          <div className="flex-1 w-full">
                            <p className="block text-sm font-medium text-gray-700">Meeting time</p>
                            <TimePicker
                              showSecond={false}
                              defaultValue={moment().hour(12).minute(0)}
                              placeholder="Select time"
                              minuteStep={15}
                              className="mt-1 w-full"
                              onChange={(value) => {
                                if (!value) {
                                  return;
                                }
                                setMeetingTime({
                                  hour: value.hour(),
                                  minute: value.minute()
                                });
                              }}
                              format={'h:mm a'}
                              use12Hours
                              inputReadOnly
                            />
                          </div>
                        </div>

                        {/* Timezone Selection */}
                        <div className="flex-1 w-full">
                          <p className="block text-sm font-medium text-gray-700">Timezone</p>
                          <div className="mt-1 w-full">
                            <BasicListbox
                              items={timezoneList}
                              defaultItem={timezoneList.find((tz) => tz.id === localTimezone)}
                              placeholder="Select region"
                              onChange={(newTimezone) => {
                                setMeetingTimezone(timezoneMap.get(newTimezone.id));
                              }}
                            />
                          </div>
                        </div>

                        {/* Meeting Frequency */}
                        <div className="flex-1 w-full">
                          <p className="block text-sm font-medium text-gray-700">
                            Meeting frequency
                          </p>
                          <div className="flex flex-row gap-3 text-sm text-gray-700 whitespace-nowrap mt-1 w-full h-9">
                            <p className="flex flex-col justify-center">Repeat every </p>
                            <input
                              type="number"
                              className="w-14 h-8 rounded-md mt-1 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                              value={meetingFrequency}
                              onChange={(e) => setMeetingFrequency(Number(e.target.value))}
                            />
                            <p className="flex flex-col justify-center">
                              {meetingFrequency > 1 ? 'weeks' : 'week'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>
                <div className="flex sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1">
                  <Button
                    onClick={() => {
                      createOneOnOneMeetingHandler();
                    }}>
                    Create
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNewOneOnOneModal;
