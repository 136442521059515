import User, { UserData } from '../user/User.model';

export type MeetingNoteData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  meetingID: string;

  createdBy?: UserData;

  content: string;
  private: boolean;
};

class MeetingNote implements MeetingNoteData {
  id: string;
  meetingID: string;
  createdBy?: UserData;
  content: string;
  private: boolean;

  constructor(data: MeetingNoteData) {
    this.id = data.id;
    this.meetingID = data.meetingID;
    this.createdBy = data.createdBy;
    this.content = data.content;
    this.private = data.private;
  }

  static buildMeetingNoteDataFromResponse(response: any): MeetingNoteData {
    return {
      id: response.id,
      meetingID: response.meeting_id,
      createdBy: response.created_by
        ? User.buildUserDataFromResponse(response.created_by)
        : undefined,
      content: response.content,
      private: response.private
    };
  }
}

export default MeetingNote;
