import React, { useEffect, useRef, useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { GiArcheryTarget } from 'react-icons/gi';
import { TbArcheryArrow } from 'react-icons/tb';
import { LuDot } from 'react-icons/lu';
import {
  BuildingOffice2Icon,
  HomeModernIcon,
  UserGroupIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import moment from 'moment-timezone';

import {
  AppLoader,
  Avatar,
  CreateNewKeyResultModal,
  CreateNewObjectiveModal,
  DropdownButton,
  GoalsFilters,
  HorizontalLine,
  VerticalLine
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { fetchTeams } from '../../core/team/teamsSlice';
import { fetchDepartments } from '../../core/cdf/departmentsSlice';
import { fetchGoals } from '../../core/goal/goalsSlice';
import { defaultDateFormat } from '../../common/timezone';
import Goal, {
  GOAL_STATUS_COLORS_MAP,
  GOAL_STATUS_MAP,
  GoalData,
  GoalDomain,
  GoalStatus,
  GoalType
} from '../../core/goal/Goal.model';

const GoalsPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const createGoalOptions = useRef([
    {
      id: '1',
      name: (
        <div className="flex flex-row gap-2 mr-1">
          <GiArcheryTarget className="size-6 shrink-0" aria-hidden="true" />
          Objective
        </div>
      ),
      onClick: () => setOpenCreateNewObjectiveModal(true)
    },
    {
      id: '2',
      name: (
        <div className="flex flex-row gap-2 mr-1">
          <TbArcheryArrow className="size-6 shrink-0 rotate-180 transform" aria-hidden="true" />
          Key result
        </div>
      ),
      onClick: () => setOpenCreateNewKeyResultModal(true)
    }
  ]);

  const authUser = useAppSelector((state) => state.user);

  const areDepartmentsPending = useAppSelector((state) => state.departments.areDepartmentsPending);
  const areDepartmentsFetched = useAppSelector((state) => state.departments.areDepartmentsFetched);
  const departments = useAppSelector((state) => state.departments.departments);

  const areTeamsFetched = useAppSelector((state) => state.teams.areTeamsFetched);
  const areTeamsPending = useAppSelector((state) => state.teams.areTeamsPending);
  const teams = useAppSelector((state) => state.teams.teams);

  const areGoalsPending = useAppSelector((state) => state.goals.areGoalsPending);
  const goals = useAppSelector((state) => state.goals.goals);

  const [selectedStatus, setSelectedStatus] = useState([
    GoalStatus.ON_TRACK,
    GoalStatus.AT_RISK,
    GoalStatus.OFF_TRACK
  ]);
  const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [openCreateNewObjectiveModal, setOpenCreateNewObjectiveModal] = useState(false);
  const [openCreateNewKeyResultModal, setOpenCreateNewKeyResultModal] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (!areDepartmentsFetched) {
      dispatch(fetchDepartments({ organizationSlug }));
    }
    if (!areTeamsFetched) {
      dispatch(fetchTeams({ organizationSlug }));
    }
    dispatch(fetchGoals({ organizationSlug }));
  }, []);

  function renderGoal(goal: GoalData, levelDepth: number): JSX.Element {
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <div className="flex-1 flex flex-row justify-start text-left items-center w-full">
              {levelDepth > 0 && (
                <div className="flex-initial flex flex-col justify-center w-4">
                  <HorizontalLine />
                </div>
              )}

              <Disclosure.Button
                disabled={goal.children.length === 0}
                className="flex-initial flex flex-row items-center gap-1.5">
                {goal.children.length > 0 ? (
                  <ChevronRightIcon
                    className={`size-5 text-gray-700 ${open ? 'rotate-90 transform' : ''}`}
                  />
                ) : (
                  <LuDot className="size-5 text-gray-700" />
                )}
                {goal.type === GoalType.OBJECTIVE ? (
                  <GiArcheryTarget className="size-5 text-gray-700" />
                ) : (
                  <TbArcheryArrow className="size-5 rotate-180 transform text-gray-700" />
                )}
              </Disclosure.Button>

              <NavLink
                to={goal.id}
                className="flex-1 flex flex-row justify-start text-left items-center w-full cursor-pointer">
                <div
                  className="flex-1 flex flex-row items-center ml-1.5 w-full mr-4"
                  style={{ minWidth: `${338 - levelDepth * 26}px` }}>
                  <p className="flex-1 text-sm text-gray-900">{goal.title}</p>
                </div>

                <div className="flex flex-row justify-end items-center gap-4">
                  <div className="flex items-center min-w-32 w-32">
                    {goal.domain === GoalDomain.ORGANIZATION && (
                      <div className="flex flex-row items-center gap-1.5">
                        <BuildingOffice2Icon className="size-5 text-gray-700" />
                        <p className="text-sm text-gray-900 max-w-[102px] truncate">Organization</p>
                      </div>
                    )}
                    {goal.domain === GoalDomain.DEPARTMENT && (
                      <div className="flex flex-row items-center gap-1.5">
                        <HomeModernIcon className="size-5 text-gray-700" />
                        <p className="text-sm text-gray-900 max-w-[102px] truncate">
                          {goal.department?.name}
                        </p>
                      </div>
                    )}
                    {goal.domain === GoalDomain.TEAM && (
                      <div className="flex flex-row items-center gap-1.5">
                        <UserGroupIcon className="size-5 text-gray-700" />
                        <p className="text-sm text-gray-900 max-w-[102px] truncate">
                          {goal.team?.name}
                        </p>
                      </div>
                    )}
                    {goal.domain === GoalDomain.INDIVIDUAL && (
                      <div className="flex flex-row items-center gap-1.5">
                        <UserIcon className="size-5 text-gray-700" />
                        <p className="text-sm text-gray-900 max-w-[102px] truncate">Individual</p>
                      </div>
                    )}
                  </div>
                  <p className="min-w-12 w-12">
                    <Avatar user={goal.owner} size={6} />
                  </p>
                  <p className="min-w-20 w-20 text-sm text-gray-900 whitespace-nowrap">
                    {moment(goal.dueDate).format(defaultDateFormat)}
                  </p>
                  <p className="min-w-20 w-20 text-sm text-gray-900 whitespace-nowrap">
                    {moment(goal.updatedAt).format(defaultDateFormat)}
                  </p>
                  <div className="flex fle-row items-center gap-3 min-w-36 w-36">
                    <p
                      className={`text-sm font-medium min-w-9 ${
                        GOAL_STATUS_COLORS_MAP[goal.status]
                      }`}>
                      {new Goal(goal).calculateProgress(goal)}%
                    </p>
                    <p
                      className={`py-1 px-3 text-sm font-medium bg-gray-50 rounded-md ${
                        GOAL_STATUS_COLORS_MAP[goal.status]
                      }`}>
                      {GOAL_STATUS_MAP[goal.status]}
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>

            <Disclosure.Panel
              className={`flex flex-row -mt-2 ${levelDepth > 0 ? 'ml-[26px]' : 'ml-2.5'}`}>
              <VerticalLine />

              <div className="flex-1 flex flex-col gap-2 mt-2">
                {goal.children.map((child) => (
                  <div key={child.id} className="flex flex-col gap-2">
                    {renderGoal(child, levelDepth + 1)}
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  function filterGoals(goals: GoalData[], filteredGoals: GoalData[] = []): GoalData[] {
    for (const goal of goals) {
      let meetsSearchCriteria = true;
      let meetsStatusCriteria = true;
      let meetsDomainCriteria = true;

      if (search.length > 0) {
        if (
          !goal.title.toLowerCase().includes(search.toLowerCase()) &&
          (!goal.owner ||
            !`${goal.owner.firstName} ${goal.owner.lastName}`
              .toLowerCase()
              .includes(search.toLowerCase()))
        ) {
          meetsSearchCriteria = false;
        }
      }

      if (selectedStatus.length > 0 && !selectedStatus.includes(goal.status)) {
        meetsStatusCriteria = false;
      }

      if (selectedDomain.length > 0) {
        let match = false;
        for (const domain of selectedDomain) {
          if (domain === GoalDomain.INDIVIDUAL && goal.domain === GoalDomain.INDIVIDUAL) {
            match = true;
            break;
          } else if (domain === 'my_goals' && goal.owner && goal.owner.id === authUser.id) {
            match = true;
            break;
          } else if (domain === 'my_team' && goal.team) {
            const team = teams.find((team) => team.id === goal.team?.id);
            if (
              team &&
              (team.manager?.id === authUser.id ||
                team.members.find((member) => member.id === authUser.id))
            ) {
              match = true;
              break;
            }
          } else {
            if (goal.domain === GoalDomain.TEAM && goal.team?.id === domain) {
              match = true;
              break;
            }
            if (goal.domain === GoalDomain.DEPARTMENT && goal.department?.id === domain) {
              match = true;
              break;
            }
          }
        }

        if (!match) {
          meetsDomainCriteria = false;
        }
      }

      if (meetsSearchCriteria && meetsStatusCriteria && meetsDomainCriteria) {
        filteredGoals.push(goal);
        continue;
      }

      filterGoals(goal.children, filteredGoals);
    }

    return filteredGoals;
  }

  if (areTeamsPending || areDepartmentsPending || areGoalsPending) {
    return <AppLoader />;
  }

  const filteredGoals = filterGoals(goals);

  return (
    <>
      <div className="flex flex-col w-full gap-6 mt-6">
        <div className="flex flex-row justify-between items-center gap-4">
          <p className="text-lg font-medium text-gray-900">Goals and OKRs</p>

          <DropdownButton name="Create" items={createGoalOptions.current} />
        </div>

        <div className="block sm:hidden">
          <input
            type="search"
            autoComplete="off"
            placeholder="Search owners and goals..."
            value={search}
            className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="flex flex-row gap-5 items-center">
          <GoalsFilters
            teams={teams}
            departments={departments}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            selectedDomain={selectedDomain}
            setSelectedDomain={setSelectedDomain}
          />
          <div className="flex-1 hidden sm:block items-center">
            <input
              type="search"
              autoComplete="off"
              placeholder="Search owners and goals..."
              value={search}
              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="flex-1 relative w-full">
          <div className="absolute flex flex-col gap-1 w-full h-full overflow-auto">
            <div className="flex flex-row items-center gap-4">
              <p className="flex-1 min-w-96 text-sm font-medium text-gray-600">Title</p>

              <div className="flex flex-row justify-end items-center gap-4 px-2">
                <p className="min-w-32 w-32 text-sm font-medium text-gray-600">Domain</p>
                <p className="min-w-12 w-12 text-sm font-medium text-gray-600">Owner</p>
                <p className="min-w-20 w-20 text-sm font-medium text-gray-600">Due date</p>
                <p className="min-w-20 w-20 text-sm font-medium text-gray-600">Updated</p>
                <p className="min-w-36 w-36 text-sm font-medium text-gray-600">Status</p>
              </div>
            </div>

            <div className="min-w-[952px] w-full">
              <HorizontalLine />
            </div>

            <div className="flex-1 flex flex-col gap-2 mt-1 min-w-[952px] w-full">
              {filteredGoals.map((goal) => (
                <div
                  key={goal.id}
                  className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md shadow">
                  {renderGoal(goal, 0)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <CreateNewObjectiveModal
        open={openCreateNewObjectiveModal}
        onClose={() => setOpenCreateNewObjectiveModal(false)}
      />
      <CreateNewKeyResultModal
        open={openCreateNewKeyResultModal}
        onClose={() => setOpenCreateNewKeyResultModal(false)}
      />
    </>
  );
};

export default GoalsPage;
