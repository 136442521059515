import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { UserData } from '../user/User.model';
import { TeamData } from './Team.model';

type TeamState = {
  teams: TeamData[];
  myTeamMembers: UserData[];

  areTeamsPending: boolean;
  areTeamsFetched: boolean;
  areMyTeamMembersPending: boolean;

  error: api.ApiError;
};

const initialState: TeamState = {
  teams: [],
  myTeamMembers: [],

  areTeamsPending: true,
  areTeamsFetched: false,
  areMyTeamMembersPending: true,

  error: null
};

const fetchTeams = createAsyncThunk(
  'teams/getTeams',
  async (args: api.GetTeamsArgs, { rejectWithValue }) => {
    try {
      return await api.getTeams(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchMyTeamMembers = createAsyncThunk(
  'teams/getMyTeamMembers',
  async (args: api.GetTeamsArgs, { rejectWithValue }) => {
    try {
      return await api.getMyTeamMembers(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createTeam = createAsyncThunk(
  'teams/createTeam',
  async (args: api.CreateTeamArgs, { rejectWithValue }) => {
    try {
      return await api.createTeam(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateTeam = createAsyncThunk(
  'teams/updateTeam',
  async (args: api.UpdateTeamArgs, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.updateTeam(args);
      await dispatch(fetchMyTeamMembers({ organizationSlug: args.organizationSlug }));
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteTeam = createAsyncThunk(
  'teams/deleteTeam',
  async (args: api.GetTeamArgs, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.deleteTeam(args);
      await dispatch(fetchMyTeamMembers({ organizationSlug: args.organizationSlug }));
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const addTeamMember = createAsyncThunk(
  'teams/addTeamMember',
  async (args: api.GetTeamMemberArgs, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.addTeamMember(args);
      await dispatch(fetchMyTeamMembers({ organizationSlug: args.organizationSlug }));
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const removeTeamMember = createAsyncThunk(
  'teams/removeTeamMember',
  async (args: api.GetTeamMemberArgs, { dispatch, rejectWithValue }) => {
    try {
      const res = await api.removeTeamMember(args);
      await dispatch(fetchMyTeamMembers({ organizationSlug: args.organizationSlug }));
      return res;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch teams
    builder.addCase(fetchTeams.pending, (state) => {
      state.areTeamsPending = true;
      state.error = null;
    });
    builder.addCase(fetchTeams.rejected, (state, action) => {
      state.areTeamsPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch teams. Please try again.'));
    });
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
      state.areTeamsPending = false;
      state.areTeamsFetched = true;
      state.error = null;
    });

    // Fetch my team members
    builder.addCase(fetchMyTeamMembers.pending, (state) => {
      state.areMyTeamMembersPending = true;
      state.error = null;
    });
    builder.addCase(fetchMyTeamMembers.rejected, (state, action) => {
      state.areMyTeamMembersPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(
        getCustomUserError(state.error, 'Failed to fetch team members. Please try again.')
      );
    });
    builder.addCase(fetchMyTeamMembers.fulfilled, (state, action) => {
      state.myTeamMembers = action.payload;
      state.areMyTeamMembersPending = false;
      state.error = null;
    });

    // Create team
    builder.addCase(createTeam.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create team.'));
    });
    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.teams.push(action.payload);
    });

    // Update team
    builder.addCase(updateTeam.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update team.'));
    });
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      for (let i = 0; i < state.teams.length; i++) {
        if (state.teams[i].id === action.payload.id) {
          state.teams[i] = action.payload;
          break;
        }
      }
    });

    // Delete team
    builder.addCase(deleteTeam.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete team.'));
    });
    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      state.teams = state.teams.filter((team) => team.id !== action.meta.arg.teamID);
    });

    // Add team member
    builder.addCase(addTeamMember.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to add team member.'));
    });
    builder.addCase(addTeamMember.fulfilled, (state, action) => {
      for (let i = 0; i < state.teams.length; i++) {
        if (state.teams[i].id === action.meta.arg.teamID) {
          state.teams[i].members.push(action.payload);
          continue;
        }

        state.teams[i].members = state.teams[i].members.filter(
          (member) => member.id !== action.payload.id
        );
      }
    });

    // Remove team member
    builder.addCase(removeTeamMember.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to remove team member.'));
    });
    builder.addCase(removeTeamMember.fulfilled, (state, action) => {
      for (let i = 0; i < state.teams.length; i++) {
        if (state.teams[i].id === action.meta.arg.teamID) {
          state.teams[i].members = state.teams[i].members.filter(
            (member) => member.id !== action.meta.arg.memberID
          );
          break;
        }
      }
    });
  }
});

export default teamsSlice.reducer;

export {
  fetchTeams,
  fetchMyTeamMembers,
  createTeam,
  updateTeam,
  deleteTeam,
  addTeamMember,
  removeTeamMember
};
