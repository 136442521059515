import React from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

type Page = {
  name: string;
  href: string;
  current?: boolean;
};

type BreadcrumbsProps = {
  pages: Page[];
};

const Breadcrumbs = ({ pages }: BreadcrumbsProps): JSX.Element => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-1 sm:space-x-3">
        <li>
          <div>
            <Link to="/home" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="size-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="size-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link
                to={page.href}
                className="ml-1 sm:ml-3 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}>
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
