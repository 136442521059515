import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Listbox, Transition } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/24/outline';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { toast } from 'sonner';

import { classNames } from '../../common/utils';
import { yellow200 } from '../../common/colors';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { fetchDepartmentLevels } from '../../core/progression/progressionSlice';
import { ProgressionLevelData } from '../../core/progression/ProgressionLevel.model';

type ChooseNextLevelListboxProps = {
  onChange: (selected: ProgressionLevelData) => void;
  onStarredNextLevel: (selected: ProgressionLevelData) => void;
};

const ChooseNextLevelListbox = ({
  onChange,
  onStarredNextLevel
}: ChooseNextLevelListboxProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const isDepartmentLevelsPending = useAppSelector(
    (state) => state.progression.isDepartmentLevelsPending
  );
  const departmentLevels = useAppSelector((state) => state.progression.departmentLevels);
  const isProgressionPending = useAppSelector((state) => state.progression.isProgressionPending);
  const activeProgression = useAppSelector((state) => state.progression.activeProgression);
  const selectedNextLevel = useAppSelector((state) => state.progression.selectedNextLevel);

  const [selected, setSelected] = useState(selectedNextLevel);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    setSelected(selectedNextLevel);
  }, [selectedNextLevel]);

  function fetchDepartmentLevelsHandler(): void {
    if (!isDepartmentLevelsPending && departmentLevels.length === 0) {
      return;
    }

    if (activeProgression?.currentLevel?.departmentID) {
      if (
        departmentLevels.length > 0 &&
        departmentLevels[0].departmentID === activeProgression.currentLevel.departmentID
      ) {
        return;
      }

      dispatch(
        fetchDepartmentLevels({
          organizationSlug,
          departmentID: activeProgression.currentLevel.departmentID
        })
      );
    }
  }

  if (isProgressionPending) {
    return <></>;
  }

  return (
    <Listbox
      value={selected}
      onChange={(selected) => {
        setSelected(selected);
        if (selected) {
          onChange(selected);
        }
      }}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className="flex flex-row justify-between gap-1 min-w-[10rem] cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm leading-6"
              onClick={(e) => {
                e.stopPropagation();

                if (!activeProgression?.currentLevel) {
                  toast.warning('Please select current level first.');
                  return;
                }

                fetchDepartmentLevelsHandler();
              }}>
              <p
                className={classNames(
                  !selected ? 'italic text-gray-700' : 'text-gray-900',
                  'flex-none pr-1 max-w-[240px] truncate'
                )}>
                {selected ? selected.name : 'Select next level'}
              </p>
              <div className="flex flex-row gap-1 justify-self-end pt-0.5">
                {selected && (
                  <Tooltip
                    placement="top"
                    content={'Star to set it as your next level goal'}
                    className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                    <StarIcon
                      className="size-5 cursor-pointer flex-shrink-0 text-gray-500 hover:fill-yellow-200"
                      aria-hidden="true"
                      fill={activeProgression?.nextLevel?.id === selected.id ? yellow200 : 'white'}
                      onClick={(e) => {
                        e.stopPropagation();
                        onStarredNextLevel(selected);
                      }}
                    />
                  </Tooltip>
                )}
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                {isDepartmentLevelsPending ? (
                  <p className="text-center italic text-gray-800 w-full py-2 pl-3 pr-9">
                    Loading...
                  </p>
                ) : departmentLevels.length === 0 ? (
                  <p className="text-center italic text-gray-800 w-full py-2 px-3">
                    No levels found
                  </p>
                ) : (
                  departmentLevels
                    .filter((departmentLevel) => {
                      if (!activeProgression?.currentLevel) {
                        return true;
                      }
                      if (
                        departmentLevel.developmentPathID ===
                          activeProgression.currentLevel.developmentPathID &&
                        departmentLevel.seniority <= activeProgression.currentLevel.seniority
                      ) {
                        return false;
                      }
                      return true;
                    })
                    .map((departmentLevel) => (
                      <Listbox.Option
                        key={departmentLevel.id}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'relative cursor-pointer select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={departmentLevel}>
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'block truncate'
                              )}>
                              {departmentLevel.name}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}>
                                <CheckIcon className="size-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default ChooseNextLevelListbox;
