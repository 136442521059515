import React from 'react';

const VerticalLine = (): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="h-full border-r border-gray-300" />
      </div>
    </div>
  );
};

export default VerticalLine;
