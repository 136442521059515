import React, { Fragment, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import TextareaAutosize from 'react-textarea-autosize';
import _ from 'lodash';

import { useAppDispatch } from '../../../hooks';
import { useAppSelector } from '../../../core/store';
import {
  fetchLibrarySkill,
  librarySkillSelector,
  updateLibrarySkill,
  updateLibrarySkillLevel
} from '../../../core/library/skill/skillsSlice';
import { SkillLevelData } from '../../../core/library/skill/SkillLevel.model';
import HorizontalLine from '../../HorizontalLine';
import {
  assignSkillToLevel,
  fetchLevel,
  unassignSkillFromLevel,
  updateLevelSkillCard
} from '../../../core/level/levelSlice';
import AppLoader from '../../AppLoader';
import { Button } from '../../ui/Button';
import {
  assignSkillToFrameworkLevel,
  unassignSkillFromFrameworkLevel
} from '../../../core/library/framework/frameworksSlice';
import { checkPermissionAccess } from '../../../common/utils';
import { SkillData } from '../../../core/library/skill/Skill.model';
import PermissionProtectedAccess from '../../PermissionProtectedAccess';
import SkillLevelDescription, { SkillLevelOnChangeFn } from './SkillLevelDescription';
interface SkillChangeLevelModalProps {
  usedFor?: 'organization' | 'frameworkLibrary';
  skillLevel: SkillLevelData;
  open: boolean;
  onClose: () => void;
}

const SkillChangeLevelModal = ({
  usedFor = 'organization',
  skillLevel,
  open,
  onClose
}: SkillChangeLevelModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const authUser = useAppSelector((state) => state.user);
  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);
  const isSkillPending = useAppSelector((state) => state.librarySkills.isSkillPending);
  const skill = useAppSelector((state) =>
    librarySkillSelector(state.librarySkills, skillLevel.skillID)
  );

  const [isSkillUpdated, setIsSkillUpdated] = React.useState(false);

  const departmentID = params.departmentID ?? '';
  const levelID = params.levelID ?? '';

  useEffect(() => {
    if (!open || skill?.isFullyLoaded) {
      return;
    }
    dispatch(fetchLibrarySkill({ organizationSlug, skillID: skillLevel.skillID ?? '' }));
  }, [open]);

  async function handleSkillLevelUpdate(newSkillLevelID: string): Promise<void> {
    if (usedFor === 'frameworkLibrary') {
      const frameworkID = params.frameworkID ?? '';
      const frameworkDepartmentID = searchParams.get('departmentID') ?? '';
      const frameworkLevelID = searchParams.get('levelID') ?? '';

      const res: any = await dispatch(
        assignSkillToFrameworkLevel({
          organizationSlug,
          frameworkID,
          departmentID: frameworkDepartmentID,
          levelID: frameworkLevelID,
          skillID: skillLevel.skillID ?? '',
          skillLevelID: newSkillLevelID
        })
      );
      if (res.error) {
        return;
      }

      dispatch(
        unassignSkillFromFrameworkLevel({
          organizationSlug,
          frameworkID,
          departmentID: frameworkDepartmentID,
          levelID: frameworkLevelID,
          skillLevelID: skillLevel.id
        })
      );
      return;
    }

    const res: any = await dispatch(
      assignSkillToLevel({
        organizationSlug,
        departmentID,
        levelID,
        skillID: skillLevel.skillID ?? '',
        skillLevelID: newSkillLevelID
      })
    );
    if (res.error) {
      return;
    }

    dispatch(
      unassignSkillFromLevel({
        organizationSlug,
        departmentID,
        levelID,
        skillLevelID: skillLevel.id
      })
    );
  }

  const updateSkillLevelData = useMemo(() => {
    return _.debounce(
      (
        skill: SkillData,
        skillLevel: SkillLevelData,
        key: 'description' | 'examples',
        value: string
      ) => {
        const newSkillLevel: SkillLevelData = { ...skillLevel, skillName: skill.name };
        newSkillLevel[key] = value;

        dispatch(
          updateLibrarySkillLevel({
            organizationSlug,
            skillID: skill.id,
            skillLevelData: newSkillLevel
          })
        );

        setIsSkillUpdated(true);
      },
      1000
    );
  }, []);

  const updateSkillData = useMemo(() => {
    return _.debounce((newSkillData: SkillData) => {
      dispatch(updateLibrarySkill({ organizationSlug, skillData: newSkillData }));
      dispatch(updateLevelSkillCard({ skillLevelID: skillLevel.id, skillName: newSkillData.name }));
    }, 1000);
  }, []);

  function onSkillLevelDataChange(
    skill: SkillData,
    skillLevel: SkillLevelData
  ): SkillLevelOnChangeFn {
    return (key: 'description' | 'examples', value: string) => {
      updateSkillLevelData(skill, skillLevel, key, value);
    };
  }

  function onCloseHandler(): void {
    if (isSkillUpdated) {
      dispatch(fetchLevel({ organizationSlug, departmentID, levelID }));
    }
    onClose();
  }

  function renderSkillTitle(name: string): JSX.Element {
    return (
      <Dialog.Title as="h3" className="flex-1 mr-5 font-semibold text-lg text-gray-900">
        {name}
      </Dialog.Title>
    );
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all max-h-full sm:max-h-[50rem] min-w-full md:min-w-[50rem] sm:max-w-lg sm:my-8 sm:p-6">
                <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-[50rem]">
                  {isSkillPending || !skill ? (
                    <AppLoader />
                  ) : (
                    <>
                      <div className="flex flex-row justify-between">
                        {!skill.private ? (
                          renderSkillTitle(skill.name)
                        ) : (
                          <PermissionProtectedAccess
                            minimalRequiredPermissionRole="editor"
                            fallbackElement={renderSkillTitle(skill.name)}>
                            <input
                              type="text"
                              defaultValue={skill.name}
                              className="flex-1 mr-5 font-semibold rounded-md text-xl h-8 text-gray-900 border-0 p-0 focus:p-1"
                              onChange={(e) => {
                                const newSkillData = { ...skill };
                                newSkillData.name = e.target.value;
                                updateSkillData(newSkillData);
                              }}
                            />
                          </PermissionProtectedAccess>
                        )}
                      </div>

                      <div className="flex flex-row gap-3 mt-1">
                        <p className="truncate text-sm text-gray-600 max-w-[100px]">
                          @{skill.organization.name}
                        </p>
                      </div>

                      {!skill.private ? (
                        <p className="text-base text-gray-600 my-4">{skill.description}</p>
                      ) : (
                        <PermissionProtectedAccess
                          minimalRequiredPermissionRole="editor"
                          fallbackElement={
                            <p className="text-base text-gray-600 my-4">{skill.description}</p>
                          }>
                          <TextareaAutosize
                            defaultValue={skill.description}
                            className="w-full text-base rounded-md text-gray-600 border-0 p-0 focus:px-0.5 max-h-[15rem] my-4"
                            onChange={(e) => {
                              const newSkillData = { ...skill };
                              newSkillData.description = e.target.value;
                              updateSkillData(newSkillData);
                            }}
                          />
                        </PermissionProtectedAccess>
                      )}

                      <div className="mb-4">
                        <HorizontalLine />
                      </div>

                      <ul role="list" className="flex flex-col flex-nowrap gap-4">
                        {skill.levels.map((level) => (
                          <li
                            key={level.id}
                            className="flex flex-col gap-2 p-3 rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                            <div className="flex flex-row justify-between">
                              <h2 className="font-semibold text-lg text-gray-900">
                                Level {level.seniority}
                              </h2>
                              {level.id === skillLevel.id ? (
                                <Button disabled size={'sm'}>
                                  Selected
                                </Button>
                              ) : (
                                <Button
                                  size={'sm'}
                                  onClick={() => {
                                    handleSkillLevelUpdate(level.id);
                                    onCloseHandler();
                                  }}>
                                  Select
                                </Button>
                              )}
                            </div>
                            <SkillLevelDescription
                              skillLevelData={level}
                              editable={
                                usedFor === 'organization' &&
                                skill.private &&
                                checkPermissionAccess(authUser.permissionRole, 'editor')
                              }
                              onChange={onSkillLevelDataChange(skill, level)}
                            />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SkillChangeLevelModal;
