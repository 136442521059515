import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { classNames } from '../../common/utils';
import { GOAL_STATUS_COLORS_MAP, GOAL_STATUS_MAP, GoalStatus } from '../../core/goal/Goal.model';

type GoalStatusListboxProps = {
  size?: 'sm' | 'md';
  status?: string;
  onChange?: (status: string) => void;
};

const GoalStatusListbox = ({
  size = 'sm',
  status,
  onChange = () => {}
}: GoalStatusListboxProps): JSX.Element => {
  const placeholder = 'Set status';

  const items = useRef([
    {
      id: GoalStatus.ON_TRACK,
      name: GOAL_STATUS_MAP[GoalStatus.ON_TRACK],
      color: GOAL_STATUS_COLORS_MAP[GoalStatus.ON_TRACK]
    },
    {
      id: GoalStatus.AT_RISK,
      name: GOAL_STATUS_MAP[GoalStatus.AT_RISK],
      color: GOAL_STATUS_COLORS_MAP[GoalStatus.AT_RISK]
    },
    {
      id: GoalStatus.OFF_TRACK,
      name: GOAL_STATUS_MAP[GoalStatus.OFF_TRACK],
      color: GOAL_STATUS_COLORS_MAP[GoalStatus.OFF_TRACK]
    },
    {
      id: GoalStatus.COMPLETED,
      name: GOAL_STATUS_MAP[GoalStatus.COMPLETED],
      color: GOAL_STATUS_COLORS_MAP[GoalStatus.COMPLETED]
    },
    {
      id: GoalStatus.CLOSED,
      name: GOAL_STATUS_MAP[GoalStatus.CLOSED],
      color: GOAL_STATUS_COLORS_MAP[GoalStatus.CLOSED]
    }
  ]);

  const [selected, setSelected] = useState(
    items.current.find((item) => item.id === status) ?? {
      id: '',
      name: placeholder,
      color: ''
    }
  );

  useEffect(() => {
    if (!status) {
      setSelected({ id: '', name: placeholder, color: '' });
      return;
    }

    setSelected(
      items.current.find((item) => item.id === status) ?? {
        id: '',
        name: placeholder,
        color: ''
      }
    );
  }, [status]);

  function handleButtonSize(size: string): string {
    switch (size) {
      case 'md':
        return 'px-3 py-1';
      default:
        return 'px-2 py-0.5';
    }
  }

  function handleItemSize(size: string): string {
    switch (size) {
      case 'md':
        return 'py-1.5';
      default:
        return 'py-1';
    }
  }

  return (
    <Listbox
      value={selected}
      onChange={(selected) => {
        setSelected(selected);
        onChange(selected.id);
      }}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`flex flex-row justify-between w-full cursor-pointer rounded-md bg-white ${handleButtonSize(
                size
              )} text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm`}>
              {selected.id === '' ? (
                <span className="flex-none italic text-sm text-gray-700 pr-1 truncate">
                  {selected.name}
                </span>
              ) : (
                <div className="flex flex-row gap-2">
                  <p className={`flex-initial text-sm font-medium ${selected.color} mr-1 truncate`}>
                    {selected.name}
                  </p>
                </div>
              )}

              <div className="flex flex-row gap-1 justify-self-end pt-0.5">
                <ChevronUpDownIcon className="size-4 text-gray-400" aria-hidden="true" />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {items.current.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-pointer select-none pl-3 pr-9',
                        handleItemSize(size)
                      )
                    }
                    value={item}>
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center max-w-fit">
                          <p
                            className={classNames(
                              active ? 'bg-indigo-600 text-white' : item.color,
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}>
                            {item.name}
                          </p>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-3'
                            )}>
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default GoalStatusListbox;
