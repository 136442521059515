import React, { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../core/store';
import {
  duplicateLibrarySkill,
  fetchLibrarySkill,
  librarySkillSelector
} from '../../../core/library/skill/skillsSlice';
import AppLoader from '../../AppLoader';
import HorizontalLine from '../../HorizontalLine';
import SkillLevelDescription from './SkillLevelDescription';
import { assignSkillToLevel } from '../../../core/level/levelSlice';
import { SkillLevelData } from '../../../core/library/skill/SkillLevel.model';
import { SkillData } from '../../../core/library/skill/Skill.model';
import { Button } from '../../ui/Button';
import { assignSkillToFrameworkLevel } from '../../../core/library/framework/frameworksSlice';

interface SkillDescriptionModalProps {
  usedFor?: 'organization' | 'frameworkLibrary';
  open: boolean;
  skillID: string;
  onClose: () => void;
}

const SkillDescriptionModal = ({
  usedFor = 'organization',
  open,
  skillID,
  onClose
}: SkillDescriptionModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const isSkillPending = useAppSelector((state) => state.librarySkills.isSkillPending);
  const skill = useAppSelector((state) => librarySkillSelector(state.librarySkills, skillID));

  const organizationSlug = params.organizationSlug ?? '';
  const departmentID = params.departmentID ?? '';
  const levelID = params.levelID ?? '';

  useEffect(() => {
    if (!open || skill?.isFullyLoaded) {
      return;
    }
    dispatch(fetchLibrarySkill({ organizationSlug, skillID }));
  }, [open]);

  async function handleAddSkillToLevel(skillLevel: SkillLevelData): Promise<void> {
    if (!skill) return;

    let _skillID = skillID;
    let _skillLevelID = skillLevel.id;

    if (usedFor === 'frameworkLibrary') {
      const frameworkID = params.frameworkID ?? '';
      const frameworkDepartmentID = searchParams.get('departmentID') ?? '';
      const frameworkLevelID = searchParams.get('levelID') ?? '';

      const res: any = await dispatch(
        assignSkillToFrameworkLevel({
          organizationSlug,
          frameworkID,
          departmentID: frameworkDepartmentID,
          levelID: frameworkLevelID,
          skillID: _skillID,
          skillLevelID: _skillLevelID
        })
      );
      if (res.error) {
        return;
      }
      onClose();
      return;
    }

    // If skill is from public library we wanna first import it to organization library and then assign it to level
    if (!skill.private) {
      const res: any = await dispatch(duplicateLibrarySkill({ organizationSlug, skillID }));
      if (res.error) {
        return;
      }

      const orgSkillData = res.payload as SkillData;
      _skillID = orgSkillData.id;

      for (const orgSkillLevel of orgSkillData.levels) {
        if (orgSkillLevel.seniority === skillLevel.seniority) {
          _skillLevelID = orgSkillLevel.id;
          break;
        }
      }
    }

    const res: any = await dispatch(
      assignSkillToLevel({
        organizationSlug,
        departmentID,
        levelID,
        skillID: _skillID,
        skillLevelID: _skillLevelID
      })
    );
    if (res.error) {
      return;
    }
    onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[52]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all max-h-full sm:max-h-[50rem] min-w-full md:min-w-[50rem] sm:max-w-lg sm:my-8 sm:p-6">
                <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-[50rem]">
                  {isSkillPending || !skill ? (
                    <AppLoader />
                  ) : (
                    <>
                      <div className="flex flex-row justify-between">
                        <Dialog.Title as="h3" className="font-semibold text-lg text-gray-900">
                          {skill.name}
                        </Dialog.Title>
                      </div>

                      <div className="flex flex-row gap-3 mt-1">
                        <p className="truncate text-sm text-gray-600 max-w-[100px]">
                          @{skill.organization.name}
                        </p>
                      </div>

                      <p className="text-base text-gray-600 my-4">{skill.description}</p>
                      <div className="mb-4">
                        <HorizontalLine />
                      </div>

                      <ul role="list" className="flex flex-col flex-nowrap gap-4">
                        {skill.levels.map((skillLevel) => (
                          <li
                            key={skillLevel.id}
                            className="flex flex-col gap-2 p-3 rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                            <div className="flex flex-row justify-between">
                              <h2 className="font-semibold text-lg text-gray-900">
                                Level {skillLevel.seniority}
                              </h2>
                              <Button
                                size={'sm'}
                                onClick={() => {
                                  handleAddSkillToLevel(skillLevel);
                                }}>
                                Add
                              </Button>
                            </div>
                            <SkillLevelDescription skillLevelData={skillLevel} />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SkillDescriptionModal;
