import React, { Fragment, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from '../../../hooks';
import { useAppSelector } from '../../../core/store';
import {
  fetchLibraryFramework,
  fetchLibraryFrameworks,
  libraryFrameworkSelector
} from '../../../core/library/framework/frameworksSlice';
import FrameworkDepartmentOverview from './FrameworkDepartmentOverview';
import FrameworkLibraryOverview from './FrameworkLibraryOverview';

type FrameworkLibraryOverviewModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose?: () => void;
  frameworkID: string;
};

const FrameworkLibraryOverviewModal = ({
  open,
  setOpen,
  onClose = () => {
    setOpen(false);
  },
  frameworkID
}: FrameworkLibraryOverviewModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const cancelButtonRef = useRef(null);

  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);
  const areFrameworksFetched = useAppSelector(
    (state) => state.libraryFrameworks.areFrameworksFetched
  );
  const framework = useAppSelector((state) =>
    libraryFrameworkSelector(state.libraryFrameworks, frameworkID)
  );

  useEffect(() => {
    (async () => {
      if (!areFrameworksFetched) {
        await dispatch(fetchLibraryFrameworks({ organizationSlug }));
      }

      if (!open || framework?.isFullyLoaded) {
        return;
      }
      dispatch(fetchLibraryFramework({ organizationSlug, frameworkID }));
    })();
  }, [open, frameworkID]);

  function renderRightScreen(): JSX.Element {
    if (searchParams.has('departmentID')) {
      const departmentID = searchParams.get('departmentID') ?? '';
      return <FrameworkDepartmentOverview frameworkID={frameworkID} departmentID={departmentID} />;
    }
    return <FrameworkLibraryOverview frameworkID={frameworkID} />;
  }

  return (
    <>
      <div className="flex flex-col gap-2 justify-start h-full w-full">
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={onClose}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <Dialog.Panel className="relative transform overflow-hidden overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all p-4 sm:p-6 min-w-[90vw] h-[90vh] max-w-[90vw] max-h-[90vh]">
                    <div className="absolute right-5 top-6 hidden sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClose}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="size-6" aria-hidden="true" />
                      </button>
                    </div>

                    {renderRightScreen()}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default FrameworkLibraryOverviewModal;
