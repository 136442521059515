import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAppSelector } from '../core/store';
import { BreadcrumbsRouter, ProtectedRoute } from '../components';
import { UserProfilePage, UserTopNavigationPage } from './user';
import { OnboardingPage } from './onboarding';
import { OrganizationSettingsPage, SettingsPage } from './organization';
import {
  LoginPage,
  RegisterPage,
  NotFoundPage,
  InternalServerErrorPage,
  AcceptInvitePage,
  TermsAndConditionsPage,
  RequestResetPasswordPage,
  ResetPasswordPage,
  PrivacyPolicyPage
} from './public';
import {
  FrameworkLibraryOverviewPage,
  FrameworkLibraryPage,
  SkillLibraryOverviewPage,
  SkillLibraryPage
} from './library';
import HomePage from './HomePage';
import { GoalOverviewPage, GoalsPage } from './goal';
import { BillingPage } from './billing';
import { GrowPage } from './progression';
import { OneOnOnePage } from './one-on-one';
import SideNavbarPage from './SideNavbarPage';
import { CompanyPage, PeoplePage, TeamPage } from './company';
import { CDFPage, CDFDepartmentPage, CDFLevelDefinitionPage } from './cdf';
import {
  ElementAILoginPage,
  ElementAIRegisterPage,
  ElementAIRequestResetPasswordPage,
  ElementAIResetPasswordPage
} from './element-ai';

const AppPages = (): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);

  return (
    <BrowserRouter>
      <Routes>
        {/* Element AI Routes */}
        <Route path="/element-ai">
          <Route path="login" element={<ElementAILoginPage />} />
          <Route path="register" element={<ElementAIRegisterPage />} />
          <Route path="forgot-password" element={<ElementAIRequestResetPasswordPage />} />
          <Route path="reset-password" element={<ElementAIResetPasswordPage />} />
        </Route>
        {/* Element AI Routes */}

        <Route path="/accept-invite" element={<AcceptInvitePage />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route element={<ProtectedRoute isAllowed={!isLoggedIn} redirectPath="/home" />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<RequestResetPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={isLoggedIn} />}>
          <Route path="/onboarding" element={<OnboardingPage />} />
          <Route path="/home" element={<HomePage />} />

          <Route
            path="/profile"
            element={
              <BreadcrumbsRouter
                pages={[{ name: 'Profile', href: '/profile' }]}
                element={<UserProfilePage />}
              />
            }
          />

          <Route path="/:organizationSlug" element={<SideNavbarPage />}>
            {/* Default page */}
            <Route index element={<Navigate to="competencies" replace />} />

            <Route path="members/:memberID" element={<UserTopNavigationPage />}>
              <Route index element={<Navigate to="grow" replace />} />

              <Route path="one-on-one" element={<OneOnOnePage />} />
              <Route path="grow" element={<GrowPage />} />
            </Route>

            <Route path="goals">
              <Route index element={<GoalsPage />} />

              <Route path=":goalID" element={<GoalOverviewPage />} />
            </Route>

            <Route path="competencies">
              <Route index element={<CDFPage />} />

              <Route path=":departmentID" element={<CDFDepartmentPage />}>
                <Route path=":levelID" element={<CDFLevelDefinitionPage />} />
              </Route>
            </Route>

            <Route path="library/skills">
              <Route index element={<SkillLibraryPage />} />

              <Route path=":skillID" element={<SkillLibraryOverviewPage />} />
            </Route>

            <Route path="library/frameworks">
              <Route index element={<FrameworkLibraryPage />} />

              <Route path=":frameworkID" element={<FrameworkLibraryOverviewPage />} />
            </Route>

            <Route path="company" element={<CompanyPage />}>
              <Route index element={<Navigate to="people" replace />} />

              <Route path="people" element={<PeoplePage />} />
              <Route path="teams" element={<TeamPage />} />
            </Route>

            <Route path="settings" element={<SettingsPage />}>
              <Route index element={<Navigate to="general" replace />} />

              <Route path="general" element={<OrganizationSettingsPage />} />
              <Route path="billing" element={<BillingPage />} />
            </Route>
          </Route>
        </Route>

        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/home" replace /> : <Navigate to="/login" replace />}
        />
        <Route path="/500" element={<InternalServerErrorPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppPages;
