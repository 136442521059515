import User, { UserData } from '../user/User.model';

export type AgendaItemData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  meetingID: string;

  createdBy: UserData | null;

  description: string;
  completed: boolean;
};

class AgendaItem implements AgendaItemData {
  id: string;
  meetingID: string;
  createdBy: UserData | null;
  description: string;
  completed: boolean;

  constructor(data: AgendaItemData) {
    this.id = data.id;
    this.meetingID = data.meetingID;
    this.createdBy = data.createdBy;
    this.description = data.description;
    this.completed = data.completed;
  }

  static buildAgendaItemDataFromResponse(response: any): AgendaItemData {
    return {
      id: response.id,
      meetingID: response.meeting_id,
      createdBy: response.created_by ? User.buildUserDataFromResponse(response.created_by) : null,
      description: response.description,
      completed: response.completed
    };
  }
}

export default AgendaItem;
