export type SkillLevelData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  skillID?: string;
  skillName?: string;
  seniority: number;
  description: string;
  examples: string;
};

class SkillLevel implements SkillLevelData {
  id: string;
  skillID?: string;
  skillName?: string;
  seniority: number;
  description: string;
  examples: string;

  constructor(data: SkillLevelData) {
    this.id = data.id;
    this.skillID = data.skillID;
    this.skillName = data.skillName;
    this.seniority = data.seniority;
    this.description = data.description;
    this.examples = data.examples;
  }

  static buildSkillLevelDataFromResponse(response: any): SkillLevelData {
    return {
      id: response.id,
      skillID: response.skill_id,
      skillName: response.skill_name,
      seniority: response.seniority,
      description: response.description,
      examples: response.examples
    };
  }
}

export default SkillLevel;
