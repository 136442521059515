import 'remirror/styles/all.css';

import React, { FC, useCallback } from 'react';
import { PlaceholderExtension, NodeFormattingExtension, wysiwygPreset } from 'remirror/extensions';
import { EditorComponent, OnChangeHTML, Remirror, useRemirror } from '@remirror/react';

import { BubbleMenu } from './BubbleMenu';
import { ReactEditorProps } from './ReactEditorProps';

export interface WysiwygEditorProps extends Partial<ReactEditorProps> {
  className?: string;
  initialContent?: string;
  onChange?: (html: string) => void;
}

const WysiwygEditor: FC<WysiwygEditorProps> = ({
  initialContent,
  placeholder,
  stringHandler = 'html',
  onChange = () => {},
  className,
  editable = true,
  ...rest
}) => {
  const extensions = useCallback(
    () => [
      new PlaceholderExtension({ placeholder }),
      new NodeFormattingExtension(),
      ...wysiwygPreset()
    ],
    [placeholder]
  );

  const { manager } = useRemirror({ extensions, stringHandler });

  const bubbleMenu = editable ? <BubbleMenu /> : <></>;

  return (
    <Remirror
      manager={manager}
      classNames={['remirror-editor rounded-md', className]}
      initialContent={initialContent}
      editable={editable}
      {...rest}>
      <OnChangeHTML onChange={onChange} />
      <EditorComponent />
      {bubbleMenu}
    </Remirror>
  );
};

export default WysiwygEditor;
