import FrameworkDepartment, { FrameworkDepartmentData } from './FrameworkDepartment.model';

export interface FrameworkData {
  /** @type {Generics.UUID} */
  id: string;
  organization: { name: string; logo?: string };
  name: string;
  description?: string;

  companySizeMin: number;
  companySizeMax: number;
  public: boolean;

  departments: FrameworkDepartmentData[];

  updatedAt: string;
  createdAt: string;
}

class Framework implements FrameworkData {
  id: string;
  organization: { name: string; logo?: string };
  name: string;
  description?: string;

  companySizeMin: number;
  companySizeMax: number;
  public: boolean;

  departments: FrameworkDepartmentData[];

  updatedAt: string;
  createdAt: string;

  constructor(data: FrameworkData) {
    this.id = data.id;
    this.organization = data.organization;
    this.name = data.name;
    this.description = data.description;
    this.companySizeMin = data.companySizeMin;
    this.companySizeMax = data.companySizeMax;
    this.public = data.public;
    this.departments = data.departments;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  static buildFrameworkDataFromResponse(response: any): FrameworkData {
    const departments: FrameworkDepartmentData[] = (response.departments ?? []).map(
      (department: any): FrameworkDepartmentData => {
        return FrameworkDepartment.buildFrameworkDepartmentDataFromResponse(department);
      }
    );

    // sort departments by name
    departments.sort((a, b) => a.name.localeCompare(b.name));

    return {
      id: response.id,
      organization: { name: response.organization?.name, logo: response.organization?.logo },
      name: response.name,
      description: response.description,
      companySizeMin: response.company_size_min,
      companySizeMax: response.company_size_max ?? Infinity,
      public: response.public,
      departments,
      updatedAt: response.updated_at,
      createdAt: response.created_at
    };
  }
}

export default Framework;
