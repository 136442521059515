import User, { UserData } from '../core/user/User.model';
import { Api } from './api';
import { parseError } from './error';

async function getUser(): Promise<UserData> {
  try {
    const { data } = await Api.get('/user');
    const userData = User.buildUserDataFromResponse(data);
    return Promise.resolve(userData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function updateUserProfile(userInfo: UserData): Promise<UserData> {
  try {
    const { data } = await Api.patch('/user', {
      first_name: userInfo.firstName,
      last_name: userInfo.lastName
    });
    const userData = User.buildUserDataFromResponse(data);
    return Promise.resolve(userData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type ChangePasswordArgs = {
  oldPassword: string;
  newPassword: string;
};

async function changePassword({ oldPassword, newPassword }: ChangePasswordArgs): Promise<void> {
  try {
    await Api.post('/user/change-password', {
      old_password: oldPassword,
      new_password: newPassword
    });
    return Promise.resolve();
  } catch (err: any) {
    return Promise.reject(parseError(err));
  }
}

export { getUser, updateUserProfile, changePassword };
