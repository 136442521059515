import moment from 'moment-timezone';
import * as _ from 'lodash';

import { PerimssionRole, permissionRoles } from './constants';
import { ProgressionLevelSkillData } from '../core/progression/ProgressionLevelSkill.model';
import { SkillData } from '../core/library/skill/Skill.model';
import { defaultDateFormat } from './timezone';

export function classNames(...classes: any[]): string {
  return classes.filter(Boolean).join(' ');
}

export function timeAgo(current: Date, previous: Date): string {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const elapsed = current.getTime() - previous.getTime();
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else {
    return moment(previous).format(defaultDateFormat);
  }
}

export function generateLabel(name: string): string {
  // Split the input string into words using spaces, hyphens, and underscores as delimiters
  const words = name.split(/[\s\-_]/);

  // Extract the first letter from each word and convert to uppercase
  return _.map(words, (word) => _.upperFirst(word)[0]).join('');
}

export function checkPermissionAccess(
  permissionRole: PerimssionRole,
  requiredPermissionRole: PerimssionRole
): boolean {
  return permissionRoles.indexOf(permissionRole) <= permissionRoles.indexOf(requiredPermissionRole);
}

export function areProgressionSkillCompleted(skillStatus: string | undefined): boolean {
  return skillStatus === 'as_expected' || skillStatus === 'above_expectation';
}

export function sortSkillsArray(skills: ProgressionLevelSkillData[]): ProgressionLevelSkillData[] {
  return skills.sort((a, b) => {
    if (a.isStarred && !b.isStarred) return -1;
    if (!a.isStarred && b.isStarred) return 1;

    if (a.isStarred && b.isStarred) {
      return a.skillName.localeCompare(b.skillName);
    }

    if (areProgressionSkillCompleted(a.status) && !areProgressionSkillCompleted(b.status)) {
      return 1;
    }

    if (!areProgressionSkillCompleted(a.status) && areProgressionSkillCompleted(b.status)) {
      return -1;
    }

    return a.skillName.localeCompare(b.skillName);
  });
}

export type ProgressionLevelSkillPair = {
  currentLevel: ProgressionLevelSkillData | undefined;
  nextLevel: ProgressionLevelSkillData | undefined;
};

export function alignSkills(
  currentLevelSkills: ProgressionLevelSkillData[],
  sortedNextLevelSkills: ProgressionLevelSkillData[]
): ProgressionLevelSkillPair[] {
  const alignedSkills: ProgressionLevelSkillPair[] = sortedNextLevelSkills.map((skill) => {
    return {
      currentLevel: undefined,
      nextLevel: skill
    };
  });

  const sortedCurrentLevelSkills = [...currentLevelSkills].sort((a, b) =>
    a.skillName.localeCompare(b.skillName)
  );
  sortedCurrentLevelSkills.forEach((skill) => {
    const nextLevelSkillIndex = alignedSkills.findIndex(
      (alignedSkill) => alignedSkill.nextLevel?.skillName === skill.skillName
    );

    if (nextLevelSkillIndex !== -1) {
      alignedSkills[nextLevelSkillIndex].currentLevel = skill;
    } else {
      alignedSkills.push({
        currentLevel: skill,
        nextLevel: undefined
      });
    }
  });

  alignedSkills.sort((a, b) => {
    if (!!a.currentLevel?.isStarred && !b.nextLevel?.isStarred) return -1;
    if (!a.currentLevel?.isStarred && b.nextLevel?.isStarred) return 1;

    if (a.currentLevel?.isStarred && b.nextLevel?.isStarred) {
      return a.currentLevel.skillName.localeCompare(b.nextLevel.skillName);
    }
    return 0;
  });

  return alignedSkills;
}

export function findAndSetNewSkills(
  currentLevel: ProgressionLevelSkillData[],
  nextLevel: ProgressionLevelSkillData[]
): ProgressionLevelSkillData[] {
  const currentLevelMap = new Map(currentLevel.map((skill) => [skill.skillName, skill]));

  return nextLevel.map((skill) => {
    const currentLevelSkill = currentLevelMap.get(skill.skillName);
    if (!currentLevelSkill) {
      return { ...skill, isNew: true };
    }
    return skill;
  });
}

export function checkIfSkillAlreadyImported(librarySkills: SkillData[], skillID: string): boolean {
  return (
    librarySkills.filter((orgSkill) => {
      return orgSkill.private && orgSkill.copyOfSkillID === skillID;
    }).length > 0
  );
}
