import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AppLoader,
  Button,
  CreateTeamModal,
  HorizontalLine,
  PermissionProtectedAccess,
  TeamViewSection
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { fetchTeams } from '../../core/team/teamsSlice';

const TeamPage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);

  const areTeamsFetched = useAppSelector((state) => state.teams.areTeamsFetched);
  const areTeamsPending = useAppSelector((state) => state.teams.areTeamsPending);
  const teams = useAppSelector((state) => state.teams.teams);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areTeamsFetched) {
      return;
    }
    dispatch(fetchTeams({ organizationSlug }));
  }, []);

  if (areTeamsPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex flex-col gap-3 justify-start h-full w-full mt-6">
        <div className="flex-initial sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-600">
              Create and manage team structures, assign managers, and add team members.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
              <Button
                onClick={() => {
                  setCreateTeamModalOpen(true);
                }}>
                Create team
              </Button>
            </PermissionProtectedAccess>
          </div>
        </div>

        <HorizontalLine />

        <div className="flex flex-col gap-1 h-full w-full">
          {teams.map((team) => (
            <TeamViewSection key={team.id} team={team} />
          ))}
        </div>
      </div>

      <CreateTeamModal open={createTeamModalOpen} onClose={() => setCreateTeamModalOpen(false)} />
    </>
  );
};

export default TeamPage;
