import Employee, { EmployeeData } from '../employee/Employee.model';
import User, { UserData } from '../user/User.model';
import GrowAreaAction, { GrowAreaActionData } from './GrowAreaAction.model';
import GrowAreaUpdate, { GrowAreaUpdateData } from './GrowAreaUpdate.model';

export type GrowAreaData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  organizationID: string;
  /** @type {Generics.UUID} */
  skillLevelID?: string;

  createdBy: UserData | null;
  createdFor: EmployeeData;

  name: string;
  description: string;
  completed: boolean;

  actions: GrowAreaActionData[];
  updates: GrowAreaUpdateData[];
};

class GrowArea implements GrowAreaData {
  id: string;
  organizationID: string;
  skillLevelID?: string;
  createdBy: UserData | null;
  createdFor: EmployeeData;
  name: string;
  description: string;
  completed: boolean;
  actions: GrowAreaActionData[];
  updates: GrowAreaUpdateData[];

  constructor(data: GrowAreaData) {
    this.id = data.id;
    this.organizationID = data.organizationID;
    this.skillLevelID = data.skillLevelID;
    this.createdBy = data.createdBy;
    this.createdFor = data.createdFor;
    this.name = data.name;
    this.description = data.description;
    this.completed = data.completed;
    this.actions = data.actions;
    this.updates = data.updates;
  }

  static buildGrowAreaDataFromResponse(response: any): GrowAreaData {
    return {
      id: response.id,
      organizationID: response.organization_id,
      skillLevelID: response.skill_level_id,
      createdBy: response.created_by ? User.buildUserDataFromResponse(response.created_by) : null,
      createdFor: Employee.buildEmployeeDataFromResponse(response.created_for),
      name: response.name,
      description: response.description,
      completed: response.completed,
      actions: (response.actions ?? []).map((action: any) =>
        GrowAreaAction.buildGrowAreaActionDataFromResponse(action)
      ),
      updates: (response.updates ?? []).map((update: any) =>
        GrowAreaUpdate.buildGrowAreaUpdateDataFromResponse(update)
      )
    };
  }
}

export default GrowArea;
