import User, { UserData } from '../user/User.model';

export type CheckInStatus = 'on_track' | 'at_risk' | 'off_track';

export type CheckInData = {
  /** @type {Generics.UUID} */
  id: string;

  author?: UserData;

  status: CheckInStatus;
  progressDescription: string;
  progressIncreaseBy: number;

  updatedAt: string;
  createdAt: string;
};

class CheckIn implements CheckInData {
  id: string;

  author?: UserData;

  status: CheckInStatus;
  progressDescription: string;
  progressIncreaseBy: number;

  updatedAt: string;
  createdAt: string;

  constructor(data: CheckInData) {
    this.id = data.id;
    this.author = data.author;
    this.status = data.status;
    this.progressDescription = data.progressDescription;
    this.progressIncreaseBy = data.progressIncreaseBy;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  static buildCheckInDataFromResponse(response: any): CheckInData {
    return {
      id: response.id,
      author: response.author ? User.buildUserDataFromResponse(response.author) : undefined,
      status: response.status,
      progressDescription: response.progress_description,
      progressIncreaseBy: response.progress_increase_by,
      updatedAt: response.updated_at,
      createdAt: response.created_at
    };
  }
}

export default CheckIn;
