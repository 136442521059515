import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import TimePicker from 'rc-time-picker';
import moment from 'moment-timezone';
import { toast } from 'sonner';

import '../../assets/rc-time-picker.css';
import GoogleCalendarIcon from '../../assets/images/google-calendar-icon.svg';
// import OutlookCalendarIcon from '../../assets/images/outlook-calendar-icon.svg';
import { useAppDispatch } from '../../hooks';
import { Button } from '../ui/Button';
import HorizontalLine from '../HorizontalLine';
import DropdownButton from '../ui/DropdownButton';
import BasicListbox from '../BasicListbox';
import { timezoneList, timezoneMap } from '../../common/timezone';
import { deleteOneOnOne, updateOneOnOne } from '../../core/one-on-one/oneOnOneSlice';
import { OneOnOneData } from '../../core/one-on-one/OneOnOne.model';
import { MeetingData } from '../../core/one-on-one/Meeting.model';
import { createMeeting, updateMeeting } from '../../core/one-on-one/meetingSlice';
import ConfirmationDialog from '../ui/ConfirmationDialog';
import { googleCalendarOAuthHandler } from '../../common/auth';
import {
  connectUserGoogleCalendar,
  fetchUserCalendarEvents,
  fetchUserIntegrations
} from '../../core/user/userIntegrationsSlice';
import { useAppSelector } from '../../core/store';
import { INTEGRATION_TYPES } from '../../core/user/UserIntegration.model';
import { CalendarEventData } from '../../core/calendar/CalendarEvent.model';

interface OneOnOneSettingsModalProps {
  organizationSlug: string;
  oneOnOne: OneOnOneData;
  meeting: MeetingData;
  open: boolean;
  onClose: () => void;
}

const OneOnOneSettingsModal = ({
  organizationSlug,
  oneOnOne,
  meeting,
  open,
  onClose: _onClose
}: OneOnOneSettingsModalProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const areUserIntegrationsFetched = useAppSelector(
    (state) => state.userIntegrations.areUserIntegrationsFetched
  );
  const userIntegrations = useAppSelector((state) => state.userIntegrations.integrations);
  const calendarEvents = useAppSelector((state) => state.userIntegrations.calendarEvents);

  const cancelButtonRef = useRef(null);
  const calenderConnectionOptions = useRef([
    {
      id: '1',
      name: (
        <div className="flex flex-row gap-2">
          <img className="size-6" src={GoogleCalendarIcon} alt="GoogleCalendarIcon" />
          <p className="flex flex-col justify-center">Google Calendar</p>
        </div>
      ),
      onClick: googleCalendarOAuthHandler({
        email: authUser.email,
        onSuccess: (code) => {
          dispatch(connectUserGoogleCalendar({ code }));
        }
      })
    }
    // {
    //   id: '2',
    //   name: (
    //     <div className="flex flex-row gap-2">
    //       <img className="size-6" src={OutlookCalendarIcon} alt="OutlookCalendarIcon" />
    //       <p className="flex flex-col justify-center">Outlook Calendar</p>
    //     </div>
    //   ),
    //   onClick: () => {}
    // }
  ]);

  const meetingScheduledTime = moment(meeting.scheduledTime).local();
  const localTimezone = moment.tz.guess();
  const dateFormat = 'YYYY-MM-DD';

  const [meetingDate, setMeetingDate] = useState<DateValueType>({
    startDate: meetingScheduledTime.format(dateFormat),
    endDate: meetingScheduledTime.format(dateFormat)
  });
  const [meetingTime, setMeetingTime] = useState({
    hour: meetingScheduledTime.hour(),
    minute: meetingScheduledTime.minute()
  });
  const [meetingTimezone, setMeetingTimezone] = useState(timezoneMap.get(localTimezone));
  const [meetingFrequency, setMeetingFrequency] = useState(oneOnOne.meetingFrequency ?? 0);

  const [showCalendarDicsonnectConfirm, setShowCalendarDicsonnectConfirm] = useState(false);
  const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [calendarType, setCalendarType] = useState<string>();
  const [calendarEvent, setCalendarEvent] = useState<CalendarEventData>();

  useEffect(() => {
    if (meeting) {
      const meetingScheduledTime = moment(meeting.scheduledTime).local();

      setMeetingDate({
        startDate: meetingScheduledTime.format(dateFormat),
        endDate: meetingScheduledTime.format(dateFormat)
      });
      setMeetingTime({
        hour: meetingScheduledTime.hour(),
        minute: meetingScheduledTime.minute()
      });
    }
  }, [meeting]);

  useEffect(() => {
    setMeetingFrequency(oneOnOne.meetingFrequency ?? 0);
  }, [oneOnOne]);

  useEffect(() => {
    if (open && !areUserIntegrationsFetched) {
      dispatch(fetchUserIntegrations());
    }
  }, [open]);

  useEffect(() => {
    if (open && userIntegrations.googleCalendar && !oneOnOne.calendarType) {
      setCalendarType(INTEGRATION_TYPES.GOOGLE_CALENDAR);
      dispatch(fetchUserCalendarEvents({ integrationID: INTEGRATION_TYPES.GOOGLE_CALENDAR }));
    }
  }, [open, userIntegrations.googleCalendar]);

  async function updateOneOnOneMeetingHandler(): Promise<void> {
    if (calendarType && calendarEvent) {
      dispatch(
        updateOneOnOne({ organizationSlug, oneOnOneID: oneOnOne.id, calendarType, calendarEvent })
      );

      const meetingScheduledTime = moment(meeting.scheduledTime);
      const meetingDateTime = moment(calendarEvent.start);
      if (!meetingScheduledTime.isSame(meetingDateTime) && meetingDateTime.isAfter(moment())) {
        if (meetingDateTime.isBefore(meetingScheduledTime)) {
          dispatch(
            updateMeeting({
              organizationSlug,
              oneOnOneID: oneOnOne.id,
              meetingID: meeting.id,
              scheduledTime: meetingDateTime.format(),
              calendarEventID: calendarEvent.id
            })
          );
        } else {
          dispatch(
            createMeeting({
              organizationSlug,
              oneOnOneID: oneOnOne.id,
              scheduledTime: meetingDateTime.format(),
              calendarEventID: calendarEvent.id
            })
          );
        }
      }

      onClose();
      return;
    }

    if (!meetingDate?.startDate) {
      toast.warning('Please select a meeting date');
      return;
    }
    if (!meetingTimezone) {
      toast.warning('Please select a timezone');
      return;
    }
    if (meetingFrequency <= 0) {
      toast.warning('Meeting frequency need to be at least 1 week');
      return;
    }

    const meetingDateTime = moment(meetingDate.startDate)
      .tz(meetingTimezone.tzCode)
      .hour(meetingTime.hour)
      .minute(meetingTime.minute);

    if (oneOnOne.meetingFrequency !== meetingFrequency) {
      dispatch(
        updateOneOnOne({
          organizationSlug,
          oneOnOneID: oneOnOne.id,
          meetingFrequency
        })
      );
    }

    const meetingScheduledTime = moment(meeting.scheduledTime);
    if (meetingDateTime.isBefore(moment())) {
      toast.warning('Meeting date and time should be in the future');
      return;
    }

    if (!meetingScheduledTime.isSame(meetingDateTime)) {
      dispatch(
        updateMeeting({
          organizationSlug,
          oneOnOneID: oneOnOne.id,
          meetingID: meeting.id,
          scheduledTime: meetingDateTime.format()
        })
      );
    }

    onClose();
  }

  function handleDisconnectCalendarFromOneOnOne(): void {
    dispatch(
      updateOneOnOne({
        organizationSlug,
        oneOnOneID: oneOnOne.id,
        active: false,
        disconnectCalendar: true
      })
    );
    setShowCalendarDicsonnectConfirm(false);
    onClose();
  }

  function handleActiveOneOnOneStatus(active: boolean): void {
    if (active && meetingFrequency <= 0) {
      toast.warning('Meeting frequency need to be at least 1 week to activate the 1-on-1');
      return;
    }

    dispatch(
      updateOneOnOne({
        organizationSlug,
        oneOnOneID: oneOnOne.id,
        active,
        meetingFrequency: active ? meetingFrequency : undefined
      })
    );
    setShowDeactivateConfirm(false);
    onClose();
  }

  function handleDeleteOneOnOne(): void {
    dispatch(deleteOneOnOne({ organizationSlug, oneOnOneID: oneOnOne.id }));
    setShowDeleteConfirm(false);
    onClose();
    navigate('/home');
  }

  function onClose(): void {
    // Clear all the fields
    setMeetingDate({
      startDate: meetingScheduledTime.format(dateFormat),
      endDate: meetingScheduledTime.format(dateFormat)
    });
    setMeetingTime({
      hour: meetingScheduledTime.hour(),
      minute: meetingScheduledTime.minute()
    });
    setMeetingTimezone(timezoneMap.get(localTimezone));
    setMeetingFrequency(oneOnOne.meetingFrequency ?? 0);
    setCalendarType(undefined);
    setCalendarEvent(undefined);

    _onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-[520px] sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      1-on-1 Settings
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    {/* Calendar Connection Buttons */}
                    <div
                      className={`${
                        oneOnOne.calendarType ? 'hidden' : 'flex'
                      } flex-row justify-center`}>
                      {!oneOnOne.calendarType && !userIntegrations.googleCalendar && (
                        <DropdownButton
                          variant={'outline'}
                          name="Connect to an existing calendar event"
                          items={calenderConnectionOptions.current}
                        />
                      )}
                      {!oneOnOne.calendarType && userIntegrations.googleCalendar && (
                        <div className="w-[340px]">
                          <BasicListbox
                            items={calendarEvents.map((event, index): any => {
                              return { id: index, name: event.title };
                            })}
                            onChange={(selectedEvent) => {
                              setCalendarType(INTEGRATION_TYPES.GOOGLE_CALENDAR);
                              setCalendarEvent(calendarEvents[+selectedEvent.id]);
                            }}
                            placeholder="Select calendar event"
                          />
                        </div>
                      )}
                    </div>

                    <Transition
                      show={!calendarEvent}
                      enter="transition-opacity ease-linear duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity ease-linear duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className={oneOnOne.calendarType ? 'hidden' : 'flex flex-col gap-5'}>
                      {/* Divider */}
                      <div className={oneOnOne.calendarType ? 'hidden' : undefined}>
                        <p className="text-sm text-gray-600 italic text-center mb-1">
                          or update manually
                        </p>
                        <HorizontalLine />
                      </div>

                      {/* Manual Meeting Setup */}
                      <div
                        className={`${oneOnOne.calendarType ? 'hidden' : 'flex'} flex-col gap-5`}>
                        <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                          {/* Meeting Date */}
                          <div className="flex-1 w-full">
                            <p className="block text-sm font-medium text-gray-700">
                              Next meeting date
                            </p>
                            <div className="mt-1 w-full">
                              <Datepicker
                                primaryColor={'indigo'}
                                inputClassName={
                                  'w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm'
                                }
                                placeholder="yyyy-mm-dd"
                                useRange={false}
                                asSingle={true}
                                value={meetingDate}
                                onChange={(newDate) => setMeetingDate(newDate)}
                              />
                            </div>
                          </div>

                          {/* Meeting Time */}
                          <div className="flex-1 w-full">
                            <p className="block text-sm font-medium text-gray-700">Meeting time</p>
                            <TimePicker
                              showSecond={false}
                              defaultValue={moment()
                                .hour(meetingTime.hour)
                                .minute(meetingTime.minute)}
                              placeholder="Select time"
                              minuteStep={15}
                              className="mt-1 w-full"
                              onChange={(value) => {
                                if (!value) {
                                  return;
                                }
                                setMeetingTime({
                                  hour: value.hour(),
                                  minute: value.minute()
                                });
                              }}
                              format={'h:mm a'}
                              use12Hours
                              inputReadOnly
                            />
                          </div>
                        </div>

                        {/* Timezone Selection */}
                        <div className="flex-1 w-full">
                          <p className="block text-sm font-medium text-gray-700">Timezone</p>
                          <div className="mt-1 w-full">
                            <BasicListbox
                              items={timezoneList}
                              defaultItem={timezoneList.find((tz) => tz.id === localTimezone)}
                              placeholder="Select region"
                              onChange={(newTimezone) => {
                                setMeetingTimezone(timezoneMap.get(newTimezone.id));
                              }}
                            />
                          </div>
                        </div>

                        {/* Meeting Frequency */}
                        <div className="flex-1 w-full">
                          <p className="block text-sm font-medium text-gray-700">
                            Meeting frequency
                          </p>
                          <div className="flex flex-row gap-3 text-sm text-gray-700 whitespace-nowrap mt-1 w-full h-9">
                            <p className="flex flex-col justify-center">Repeat every </p>
                            <input
                              type="number"
                              className="w-14 h-8 rounded-md mt-1 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
                              value={meetingFrequency}
                              onChange={(e) => setMeetingFrequency(Number(e.target.value))}
                            />
                            <p className="flex flex-col justify-center">
                              {meetingFrequency > 1 ? 'weeks' : 'week'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Transition>

                    {/* Danger zone */}
                    <div>
                      <p className="font-medium text-gray-700">Danger zone</p>
                      <div className="flex flex-col gap-3 rounded-md border border-red-400 p-3 mt-1">
                        <div
                          className={`${
                            oneOnOne.calendarType ? 'flex' : 'hidden'
                          } flex-row justify-between items-center gap-2`}>
                          <p className="text-sm font-medium text-gray-700">
                            Disconnect this 1-on-1 from calendar event
                          </p>
                          <Button
                            variant="outline-destructive"
                            onClick={() => setShowCalendarDicsonnectConfirm(true)}>
                            Disconnect
                          </Button>
                          <ConfirmationDialog
                            open={showCalendarDicsonnectConfirm}
                            title={'Are you absolutely sure?'}
                            description={
                              'Disconnecting this 1-on-1 from the calendar event will automatically deactivate it. You can reactivate it later if needed.'
                            }
                            variant="destructive"
                            onClose={() => setShowCalendarDicsonnectConfirm(false)}
                            onConfirm={() => {
                              handleDisconnectCalendarFromOneOnOne();
                            }}
                          />
                        </div>

                        <HorizontalLine />

                        <div
                          className={`${
                            oneOnOne.active ? 'flex' : 'hidden'
                          } flex-row justify-between items-center gap-2`}>
                          <p className="text-sm font-medium text-gray-700">
                            Deactivate this 1-on-1
                          </p>
                          <Button
                            variant="outline-destructive"
                            onClick={() => setShowDeactivateConfirm(true)}>
                            Deactivate
                          </Button>
                          <ConfirmationDialog
                            open={showDeactivateConfirm}
                            title={'Are you absolutely sure?'}
                            description={
                              'Are you sure you want to deactivate this 1-on-1 session? You can reactivate it later if needed.'
                            }
                            variant="destructive"
                            onClose={() => setShowDeactivateConfirm(false)}
                            onConfirm={() => {
                              handleActiveOneOnOneStatus(false);
                            }}
                          />
                        </div>

                        <div
                          className={`${
                            oneOnOne.active ? 'hidden' : 'flex'
                          } flex-row justify-between items-center gap-2`}>
                          <p className="text-sm font-medium text-gray-700">Activate this 1-on-1</p>
                          <Button
                            variant={'outline'}
                            onClick={() => {
                              handleActiveOneOnOneStatus(true);
                            }}>
                            Activate
                          </Button>
                        </div>

                        <HorizontalLine />

                        <div className="flex flex-row justify-between items-center gap-2">
                          <p className="text-sm font-medium text-gray-700">Delete this 1-on-1</p>
                          <Button
                            variant="outline-destructive"
                            onClick={() => setShowDeleteConfirm(true)}>
                            Delete
                          </Button>
                          <ConfirmationDialog
                            open={showDeleteConfirm}
                            title={'Are you absolutely sure?'}
                            description={
                              'Are you sure you want to permanently delete this 1-on-1? This action cannot be undone.'
                            }
                            variant="destructive"
                            onClose={() => setShowDeleteConfirm(false)}
                            onConfirm={handleDeleteOneOnOne}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`${
                    !oneOnOne.calendarType ? 'flex' : 'hidden'
                  } sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1`}>
                  <Button
                    onClick={() => {
                      updateOneOnOneMeetingHandler();
                    }}>
                    Update
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OneOnOneSettingsModal;
