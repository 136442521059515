import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from '../../../hooks';
import { checkIfSkillAlreadyImported, classNames } from '../../../common/utils';
import { useAppSelector } from '../../../core/store';
import { fetchLibrarySkills } from '../../../core/library/skill/skillsSlice';
import AppLoader from '../../AppLoader';
import SkillDescriptionModal from './SkillDescriptionModal';
import { Button } from '../../ui/Button';
import PermissionProtectedAccess from '../../PermissionProtectedAccess';
import CreateNewSkillModal from './CreateNewSkillModal';

type SkillLibrarySlideoverProps = {
  usedFor?: 'organization' | 'frameworkLibrary';
  open: boolean;
  setOpen: (open: boolean) => void;
};

const SkillLibrarySlideover = ({
  usedFor = 'organization',
  open,
  setOpen
}: SkillLibrarySlideoverProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const tabs = useRef(
    usedFor === 'frameworkLibrary'
      ? [{ id: 'skill-library', name: 'Skill Library' }]
      : [
          { id: 'organization-skills', name: 'Organization Skills' },
          { id: 'skill-library', name: 'Skill Library' }
        ]
  );

  const [selectedTab, setSelectedTab] = useState(tabs.current[0]);
  const [search, setSearch] = useState('');
  const [selectedSkillID, setSelectedSkillID] = useState('');
  const [skillDescriptionModalOpen, setSkillDescriptionModalOpen] = useState(false);
  const [createNewSkillModalOpen, setCreateNewSkillModalOpen] = useState(false);

  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);
  const areSkillsFetched = useAppSelector((state) => state.librarySkills.areSkillsFetched);
  const areSkillsPending = useAppSelector((state) => state.librarySkills.areSkillsPending);
  const skills = useAppSelector((state) => state.librarySkills.skills);

  const orgSkills = skills.filter((skill) => skill.private);
  const librarySkills = skills.filter(
    (skill) =>
      !skill.private &&
      (usedFor === 'frameworkLibrary' ? true : !checkIfSkillAlreadyImported(orgSkills, skill.id))
  );

  useEffect(() => {
    if (!open || areSkillsFetched) {
      return;
    }
    dispatch(fetchLibrarySkills({ organizationSlug }));
  }, [open]);

  function renderTabContent(): JSX.Element {
    if (areSkillsPending) {
      return <AppLoader />;
    }

    let _skills = orgSkills;
    if (selectedTab.id === 'organization-skills') {
      if (_skills.length === 0) {
        return (
          <div className="flex flex-col items-center justify-center h-full">
            <ExclamationTriangleIcon className="size-12 text-gray-400" />
            <p className="pl-5 pr-3 py-2 text-gray-600 text-sm italic text-center">
              {`We've noticed your organization library currently doesn't include any skills.`}
              <br />
              <br />
              {`To bring your competencies to life, go to our `}
              <br />
              <button
                className="italic font-semibold text-indigo-600 hover:text-indigo-500"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(tabs.current[1]);
                }}>
                {'Skill Library'}
              </button>
              {`, where you can choose from a variety of predefined skills and add them to your organization library.`}
            </p>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(tabs.current[1]);
              }}>
              Go to Skill Library
            </Button>
          </div>
        );
      }
    }

    if (selectedTab.id === 'skill-library') {
      _skills = librarySkills;
    }

    return (
      <>
        {_skills
          .filter(
            (skill) =>
              skill.name.toLowerCase().includes(search.toLowerCase()) || // serach by name or
              skill.description.toLowerCase().includes(search.toLowerCase()) // search by description
          )
          .map((skill) => (
            <li
              key={skill.id}
              className="hover:bg-gray-50 min-h-[76px] cursor-pointer"
              onClick={(e) => {
                e.preventDefault();

                setSelectedSkillID(skill.id);
                openSkillDescriptionModal();
              }}>
              <div className="flex flex-row justify-between pl-5 pr-3 py-3">
                <div className="flex-1 flex flex-row justify-start gap-4">
                  <div className="flex flex-col gap-1">
                    <p className="truncate text-base font-semibold text-gray-900">{skill.name}</p>
                    <p className="line-clamp-3 text-sm text-gray-600">{skill.description}</p>
                    <div className="flex flex-row gap-3">
                      <p className="truncate text-sm text-gray-600 max-w-[100px]">
                        @{skill.organization.name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
      </>
    );
  }

  function closeSkillDescriptionModal(): void {
    setSkillDescriptionModalOpen(false);
  }

  function openSkillDescriptionModal(): void {
    setSkillDescriptionModalOpen(true);
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[51]"
          onClose={() => {
            if (skillDescriptionModalOpen) {
              return;
            }
            if (createNewSkillModalOpen) {
              return;
            }
            setOpen(false);
          }}>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="pl-5 pr-3 pt-6">
                        <div className="flex items-start justify-between">
                          <div className="border-b border-gray-200">
                            <div>
                              <nav className="flex space-x-6">
                                {tabs.current.map((tab) => (
                                  <button
                                    key={tab.id}
                                    type="button"
                                    className={classNames(
                                      tab.id === selectedTab.id
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                      'whitespace-nowrap border-b-2 pb-4 text-sm font-medium'
                                    )}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setSelectedTab(tab);
                                    }}>
                                    {tab.name}
                                  </button>
                                ))}
                              </nav>
                            </div>
                          </div>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="size-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-gray-200">
                        <div className="flex flex-row gap-3 pl-5 pr-3 py-2">
                          <div className="flex-1 items-center">
                            <input
                              type="search"
                              autoComplete="off"
                              placeholder="Search skills..."
                              value={search}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                          {selectedTab.id === 'organization-skills' && (
                            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                              <Button
                                size={'sm'}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCreateNewSkillModalOpen(true);
                                }}>
                                Create Skill
                              </Button>
                            </PermissionProtectedAccess>
                          )}
                        </div>
                      </div>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        {renderTabContent()}
                      </ul>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
          <SkillDescriptionModal
            usedFor={usedFor}
            open={skillDescriptionModalOpen}
            skillID={selectedSkillID}
            onClose={closeSkillDescriptionModal}
          />
          <CreateNewSkillModal
            open={createNewSkillModalOpen}
            onClose={() => setCreateNewSkillModalOpen(false)}
          />
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default SkillLibrarySlideover;
