import { useGoogleLogin } from '@react-oauth/google';

interface GoogleCalendarOAuthHandlerProps {
  email: string;
  onSuccess: (code: string) => void;
}

export function googleCalendarOAuthHandler({
  email,
  onSuccess = () => {}
}: GoogleCalendarOAuthHandlerProps): () => void {
  function responseGoogle(response: any): void {
    const { code, error } = response;
    if (error || !code) {
      return;
    }
    onSuccess(code);
  }

  return useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    hint: email
  });
}
