import ActionItem, { ActionItemData } from '../core/one-on-one/ActionItem.model';
import AgendaItem, { AgendaItemData } from '../core/one-on-one/AgendaItem.model';
import Meeting, { MeetingData } from '../core/one-on-one/Meeting.model';
import MeetingNote, { MeetingNoteData } from '../core/one-on-one/MeetingNotes.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOneOnOneArgs } from './oneOnOne';

export type GetMeetingsArgs = GetOneOnOneArgs;

async function getMeetings({
  organizationSlug,
  oneOnOneID
}: GetMeetingsArgs): Promise<MeetingData[]> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings`
    );
    const meetingsData = data.meetings.map((meeting: any) =>
      Meeting.buildMeetingDataFromResponse(meeting)
    );
    return Promise.resolve(meetingsData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateMeetingArgs = GetMeetingsArgs & {
  scheduledTime: string;
  calendarEventID?: string;
};

async function createMeeting({
  organizationSlug,
  oneOnOneID,
  scheduledTime,
  calendarEventID
}: CreateMeetingArgs): Promise<MeetingData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings`,
      {
        scheduled_time: scheduledTime,
        calendar_event_id: calendarEventID
      }
    );
    return Promise.resolve(Meeting.buildMeetingDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetMeetingArgs = GetMeetingsArgs & {
  meetingID: string;
};

async function getMeeting({
  organizationSlug,
  oneOnOneID,
  meetingID
}: GetMeetingArgs): Promise<MeetingData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}`
    );
    return Promise.resolve(Meeting.buildMeetingDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateMeetingArgs = GetMeetingArgs & {
  scheduledTime?: string;
  calendarEventID?: string;
  canceled?: boolean;
};

async function updateMeeting({
  organizationSlug,
  oneOnOneID,
  meetingID,
  scheduledTime,
  calendarEventID,
  canceled
}: UpdateMeetingArgs): Promise<MeetingData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}`,
      {
        scheduled_time: scheduledTime ?? null,
        calendar_event_id: calendarEventID ?? null,
        canceled: canceled ?? null
      }
    );
    return Promise.resolve(Meeting.buildMeetingDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateActionItemArgs = GetMeetingArgs & {
  description: string;
};

async function createActionItem({
  organizationSlug,
  oneOnOneID,
  meetingID,
  description
}: CreateActionItemArgs): Promise<ActionItemData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/action-items`,
      {
        description
      }
    );
    return Promise.resolve(ActionItem.buildActionItemDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetActionItemsArgs = GetMeetingArgs & {
  actionItemID: string;
};

export type UpdateActionItemArgs = GetActionItemsArgs & {
  assignToUserID?: string;
  description?: string;
  completed?: boolean;
};

async function updateActionItem({
  organizationSlug,
  oneOnOneID,
  meetingID,
  actionItemID,
  assignToUserID,
  description,
  completed
}: UpdateActionItemArgs): Promise<ActionItemData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/action-items/${actionItemID}`,
      {
        assign_to_user_id: assignToUserID ?? null,
        description: description ?? null,
        completed: completed ?? null
      }
    );
    return Promise.resolve(ActionItem.buildActionItemDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteActionItem({
  organizationSlug,
  oneOnOneID,
  meetingID,
  actionItemID
}: GetActionItemsArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/action-items/${actionItemID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateAgendaItemArgs = GetMeetingArgs & {
  description: string;
};

async function createAgendaItem({
  organizationSlug,
  oneOnOneID,
  meetingID,
  description
}: CreateAgendaItemArgs): Promise<AgendaItemData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/agenda-items`,
      {
        description
      }
    );
    return Promise.resolve(AgendaItem.buildAgendaItemDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetAgendaItemsArgs = GetMeetingArgs & {
  agendaItemID: string;
};

export type UpdateAgendaItemArgs = GetAgendaItemsArgs & {
  description?: string;
  completed?: boolean;
};

async function updateAgendaItem({
  organizationSlug,
  oneOnOneID,
  meetingID,
  agendaItemID,
  description,
  completed
}: UpdateAgendaItemArgs): Promise<AgendaItemData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/agenda-items/${agendaItemID}`,
      {
        description: description ?? null,
        completed: completed ?? null
      }
    );
    return Promise.resolve(AgendaItem.buildAgendaItemDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteAgendaItem({
  organizationSlug,
  oneOnOneID,
  meetingID,
  agendaItemID
}: GetAgendaItemsArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/agenda-items/${agendaItemID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type SaveMeetingNoteArgs = GetMeetingArgs & {
  content: string;
  isPrivate: boolean;
};

async function saveMeetingNote({
  organizationSlug,
  oneOnOneID,
  meetingID,
  content,
  isPrivate
}: SaveMeetingNoteArgs): Promise<MeetingNoteData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}/meetings/${meetingID}/notes`,
      {
        content,
        private: isPrivate
      }
    );
    return Promise.resolve(MeetingNote.buildMeetingNoteDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  // Meetings
  getMeetings,
  createMeeting,
  getMeeting,
  updateMeeting,

  // Action Items
  createActionItem,
  updateActionItem,
  deleteActionItem,

  // Agenda Items
  createAgendaItem,
  updateAgendaItem,
  deleteAgendaItem,

  // Notes
  saveMeetingNote
};
