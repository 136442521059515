import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'flowbite-react';
import { StarIcon } from '@heroicons/react/24/outline';
import { TbTargetArrow } from 'react-icons/tb';

import { yellow200 } from '../../common/colors';
import { areProgressionSkillCompleted } from '../../common/utils';
import { useAppDispatch } from '../../hooks';
import { updateProgressionSkillStatus } from '../../core/progression/progressionSlice';
import { ProgressionLevelSkillData } from '../../core/progression/ProgressionLevelSkill.model';
import SkillLevelDescription from '../library/skill/SkillLevelDescription';
import SkillStatusListbox from './SkillStatusListbox';
import VerticalLine from '../VerticalLine';
import SkillGrowAreasSlideover from './SkillGrowAreasSlideover';
import { Badge } from '../ui/Badge';
import { UserData } from '../../core/user/User.model';

type ProgressionSkillCardProps = {
  progressionUser: UserData;
  progressionSkill: ProgressionLevelSkillData;
  hiddeActions?: boolean;
  expandable?: boolean;
};

const ProgressionSkillCard = ({
  progressionUser,
  progressionSkill,
  hiddeActions = false,
  expandable = false
}: ProgressionSkillCardProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [openSkillGrowAreasSlideover, setOpenSkillGrowAreasSlideover] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  return (
    <div
      className={`relative flex flex-col gap-2 p-3 rounded-lg shadow-md border border-gray-200 ${
        areProgressionSkillCompleted(progressionSkill.status)
          ? 'bg-neutral-100'
          : 'hover:border-gray-400'
      }`}>
      <div className="absolute left-2 -top-2.5 flex flex-row gap-1.5">
        {areProgressionSkillCompleted(progressionSkill.status) ? (
          <Badge variant={'green'}>Achieved</Badge>
        ) : (
          progressionSkill.isNew && <Badge variant={'indigo'}>New</Badge>
        )}
      </div>

      <div className="flex-1 flex flex-row justify-between max-h-[24px] gap-2">
        <div className="flex-1 flex flex-row justify-start gap-2">
          <p className="flex-initial font-semibold text-base line-clamp-1">
            {progressionSkill.skillName}
          </p>

          <VerticalLine />
          <span className="flex-initial flex items-center justify-center text-base font-medium text-indigo-700 bg-transparent">
            L{progressionSkill.seniority}
          </span>
        </div>

        <div className="flex-initial flex flex-row justify-end gap-2">
          <div className={hiddeActions ? 'hidden' : 'flex-initial'}>
            <SkillStatusListbox
              status={progressionSkill.status}
              onChange={(newStatus) => {
                dispatch(
                  updateProgressionSkillStatus({
                    organizationSlug,
                    progressionUserID: progressionUser.id,
                    skillLevelID: progressionSkill.skillLevelID,
                    status: newStatus,
                    isStarred: areProgressionSkillCompleted(newStatus)
                      ? false
                      : progressionSkill.isStarred
                  })
                );
              }}
            />
          </div>

          <div
            className={`flex-initial ${
              hiddeActions ? 'hidden' : 'flex'
            } flex-col justify-center h-full`}>
            <div className="relative">
              <Tooltip
                content={'Star to set this as your next goal'}
                placement="top"
                className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                <StarIcon
                  className="size-5 cursor-pointer flex-shrink-0 text-gray-500 hover:fill-yellow-200 hover:scale-[1.2]"
                  aria-hidden="true"
                  fill={progressionSkill.isStarred ? yellow200 : 'transparent'}
                  onClick={() => {
                    dispatch(
                      updateProgressionSkillStatus({
                        organizationSlug,
                        progressionUserID: progressionUser.id,
                        skillLevelID: progressionSkill.skillLevelID,
                        isStarred: !progressionSkill.isStarred
                      })
                    );
                  }}
                />
              </Tooltip>
            </div>
          </div>

          <div className="flex-initial flex flex-col justify-center h-full">
            <div className="relative">
              <Tooltip
                content={'Manage growth areas for this skill'}
                placement="top"
                className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                <TbTargetArrow
                  className="size-5 cursor-pointer flex-shrink-0 text-gray-500 hover:text-indigo-700 hover:scale-[1.2]"
                  aria-hidden="true"
                  onClick={(e) => {
                    setOpenSkillGrowAreasSlideover(true);
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <SkillLevelDescription
        expandable={expandable}
        skillLevelData={{
          id: progressionSkill.skillLevelID,
          skillName: progressionSkill.skillName,
          seniority: progressionSkill.seniority,
          description: progressionSkill.description ?? '',
          examples: progressionSkill.examples ?? ''
        }}
      />
      <SkillGrowAreasSlideover
        progressionUser={progressionUser}
        skillLevel={progressionSkill}
        open={openSkillGrowAreasSlideover}
        setOpen={setOpenSkillGrowAreasSlideover}
      />
    </div>
  );
};

export default ProgressionSkillCard;
