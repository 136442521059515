import User, { UserData } from '../user/User.model';
import Team, { TeamData } from '../team/Team.model';
import Department, { DepartmentData } from '../cdf/Department.model';
import CheckIn, { CheckInData } from './CheckIn.model';

export enum GoalType {
  OBJECTIVE = 'objective',
  KEY_RESULT = 'key_result'
}

export enum GoalDomain {
  ORGANIZATION = 'organization',
  DEPARTMENT = 'department',
  TEAM = 'team',
  INDIVIDUAL = 'individual'
}

export enum GoalStatus {
  ON_TRACK = 'on_track',
  AT_RISK = 'at_risk',
  OFF_TRACK = 'off_track',
  COMPLETED = 'completed',
  CLOSED = 'closed'
}

export enum GoalMeasurementType {
  BINARY = 'binary',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency'
}

export const GOAL_STATUS_COLORS_MAP = {
  [GoalStatus.ON_TRACK]: 'text-green-500',
  [GoalStatus.AT_RISK]: 'text-orange-500',
  [GoalStatus.OFF_TRACK]: 'text-red-500',
  [GoalStatus.COMPLETED]: 'text-emerald-500',
  [GoalStatus.CLOSED]: 'text-gray-600'
};

export const GOAL_STATUS_MAP = {
  [GoalStatus.ON_TRACK]: 'On track',
  [GoalStatus.AT_RISK]: 'At risk',
  [GoalStatus.OFF_TRACK]: 'Off track',
  [GoalStatus.COMPLETED]: 'Completed',
  [GoalStatus.CLOSED]: 'Closed'
};

export type GoalData = {
  /** @type {Generics.UUID} */
  id: string;
  type: GoalType;

  department?: DepartmentData;
  team?: TeamData;
  owner: UserData | null;
  ownerManagerID?: string;
  parentID?: string;
  parent?: GoalData;

  title: string;
  description: string;
  domain: GoalDomain;
  private: boolean;
  status: GoalStatus;

  // Key result specific fields
  measurementType?: GoalMeasurementType;
  startValue?: number;
  targetValue?: number;
  currentProgress?: number;

  startDate: string;
  dueDate: string;

  checkIns: CheckInData[];
  children: GoalData[];

  updatedAt: string;
  createdAt: string;
};

class Goal implements GoalData {
  id: string;
  type: GoalType;

  department?: DepartmentData;
  team?: TeamData;
  owner: UserData | null;
  ownerManagerID?: string;
  parentID?: string;
  parent?: GoalData;

  title: string;
  description: string;
  domain: GoalDomain;
  private: boolean;
  status: GoalStatus;

  measurementType?: GoalMeasurementType;
  startValue?: number;
  targetValue?: number;

  startDate: string;
  dueDate: string;

  checkIns: CheckInData[];
  children: GoalData[];

  updatedAt: string;
  createdAt: string;

  constructor(data: GoalData) {
    this.id = data.id;
    this.type = data.type;

    this.department = data.department;
    this.team = data.team;
    this.owner = data.owner;
    this.ownerManagerID = data.ownerManagerID;
    this.parentID = data.parentID;
    this.parent = data.parent;

    this.title = data.title;
    this.description = data.description;
    this.domain = data.domain;
    this.private = data.private;
    this.status = data.status;

    this.measurementType = data.measurementType;
    this.startValue = data.startValue;
    this.targetValue = data.targetValue;

    this.startDate = data.startDate;
    this.dueDate = data.dueDate;

    this.checkIns = data.checkIns;
    this.children = data.children;

    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  calculateProgress(goal: GoalData): number {
    if (goal.status === GoalStatus.COMPLETED) {
      return 100;
    }

    if (goal.type === GoalType.OBJECTIVE) {
      let progressSum = 0;
      let keyResultsCount = 0;
      for (const keyResult of goal.children ?? []) {
        if (keyResult.type === GoalType.KEY_RESULT) {
          progressSum += this.calculateProgress(keyResult);
          keyResultsCount++;
        }
      }

      if (keyResultsCount === 0) {
        return 0;
      }
      return Math.round(progressSum / keyResultsCount);
    }

    if (
      goal.startValue !== undefined &&
      goal.targetValue !== undefined &&
      goal.currentProgress !== undefined
    ) {
      return Math.round((goal.currentProgress * 100) / (goal.targetValue - goal.startValue));
    }

    return 0;
  }

  static buildGoalDataFromResponse(response: any): GoalData {
    return {
      id: response.id,
      type: response.type,
      department: response.department
        ? Department.buildDepartmentDataFromResponse(response.department)
        : undefined,
      team: response.team ? Team.buildTeamDataFromResponse(response.team) : undefined,
      owner: response.owner ? User.buildUserDataFromResponse(response.owner) : null,
      ownerManagerID: response.owner_manager_id ?? undefined,
      parentID: response.parent_id ?? undefined,
      parent: response.parent ? Goal.buildGoalDataFromResponse(response.parent) : undefined,
      title: response.title,
      description: response.description,
      domain: response.domain,
      private: response.private,
      status: response.status,
      measurementType: response.measurement_type ?? undefined,
      startValue: response.start_value ?? undefined,
      targetValue: response.target_value ?? undefined,
      currentProgress: response.current_progress ?? undefined,
      startDate: response.start_date,
      dueDate: response.due_date,
      checkIns: response.check_ins.map((checkIn: any) =>
        CheckIn.buildCheckInDataFromResponse(checkIn)
      ),
      children: response.children.map((child: any) => Goal.buildGoalDataFromResponse(child)),
      updatedAt: response.updated_at,
      createdAt: response.created_at
    };
  }
}

export default Goal;
