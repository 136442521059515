import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';

import { Button } from './ui/Button';

interface HorizontalLineWithButtonProps {
  justifyButton?: string;
  buttonText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const HorizontalLineWithButton = ({
  justifyButton = 'justify-center',
  buttonText,
  onClick
}: HorizontalLineWithButtonProps): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className={`relative flex ${justifyButton}`}>
        <Button
          size={'sm'}
          variant="outline"
          className="rounded-full ring-1 ring-gray-300"
          onClick={(e) => onClick(e)}>
          <PlusIcon className="-ml-1 size-5 text-gray-400 mr-1" aria-hidden="true" />
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

export default HorizontalLineWithButton;
