import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from '../../core/store';
import OneOnOne, { OneOnOneData } from '../../core/one-on-one/OneOnOne.model';
import { saveMeetingNote } from '../../core/one-on-one/meetingSlice';
import WysiwygEditor from '../wysiwyg/WysiwygEditor';

type MeetingNotesSectionProps = {
  oneOnOne: OneOnOneData;
};

const MeetingNotesSection = ({ oneOnOne }: MeetingNotesSectionProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const meeting = useAppSelector((state) => state.meetings.meeting);

  const organizationSlug = params.organizationSlug ?? '';

  const updateMySharedNotes = useMemo(() => {
    return _.debounce((meetingID: string, content: string) => {
      dispatch(
        saveMeetingNote({
          organizationSlug,
          oneOnOneID: oneOnOne.id,
          meetingID,
          content,
          isPrivate: false
        })
      );
    }, 500);
  }, [oneOnOne.id]);

  const updateMyPrivateNotes = useMemo(() => {
    return _.debounce((meetingID: string, content: string) => {
      dispatch(
        saveMeetingNote({
          organizationSlug,
          oneOnOneID: oneOnOne.id,
          meetingID,
          content,
          isPrivate: true
        })
      );
    }, 500);
  }, [oneOnOne.id]);

  if (!meeting) {
    return <></>;
  }

  const guestSharedNotes = meeting.notes.find(
    (note) => !note.private && note.createdBy?.id !== authUser.id
  );
  const mySharedNotes = meeting.notes.find(
    (note) => !note.private && note.createdBy?.id === authUser.id
  );
  const myPrivateNotes = meeting.notes.find(
    (note) => note.private && note.createdBy?.id === authUser.id
  );

  return (
    <div className="flex flex-col gap-6">
      <div
        className={`${
          guestSharedNotes && guestSharedNotes?.content.length > 0 ? 'flex' : 'hidden'
        } flex-col gap-2`}>
        <p className="font-medium">{`${
          guestSharedNotes?.createdBy?.firstName
            ? `${guestSharedNotes?.createdBy?.firstName}'s`
            : `Guest user`
        } shared notes`}</p>
        <WysiwygEditor
          initialContent={guestSharedNotes?.content}
          stringHandler="html"
          editable={false}
          className="block w-full min-h-fit rounded-md border-none p-2 text-md text-gray-900 bg-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400"
        />
      </div>

      <div className="flex flex-col gap-2">
        <p className="font-medium">Your shared notes</p>
        <WysiwygEditor
          initialContent={mySharedNotes?.content ?? ''}
          placeholder={`Shared notes will be visible to both you and ${
            new OneOnOne(oneOnOne).getNonSelfUser(authUser.id)?.firstName ?? 'guest'
          }`}
          stringHandler="html"
          editable={true}
          className="block w-full min-h-32 rounded-md border-none p-2 text-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400"
          onChange={(content) => {
            updateMySharedNotes(meeting.id, content);
          }}
        />
      </div>

      <div className="flex flex-col gap-2">
        <p className="font-medium">Private notes</p>
        <WysiwygEditor
          initialContent={myPrivateNotes?.content ?? ''}
          placeholder="Private notes will be visible only to you"
          stringHandler="html"
          editable={true}
          className="block w-full min-h-32 rounded-md border-none p-2 text-md text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400"
          onChange={(content) => {
            updateMyPrivateNotes(meeting.id, content);
          }}
        />
      </div>
    </div>
  );
};

export default MeetingNotesSection;
