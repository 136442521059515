import React, { useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';

import HorizontalLine from '../HorizontalLine';
import Avatar from '../ui/Avatar';
import CheckboxInputItem from './CheckboxInputItem';
import { useAppDispatch } from '../../hooks';
import { OneOnOneData } from '../../core/one-on-one/OneOnOne.model';
import { useAppSelector } from '../../core/store';
import {
  createAgendaItem,
  deleteAgendaItem,
  updateAgendaItem
} from '../../core/one-on-one/meetingSlice';

type AgendaSectionProps = {
  organizationSlug: string;
  oneOnOne: OneOnOneData;
};

const AgendaSection = ({ organizationSlug, oneOnOne }: AgendaSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const meeting = useAppSelector((state) => state.meetings.meeting);

  const [autoFocusItem, setAutoFocusItem] = useState(-1);

  const updateAgendaItemDebounced = useMemo(() => {
    return _.debounce((agendaItemID: string, content: string) => {
      dispatch(
        updateAgendaItem({
          organizationSlug,
          oneOnOneID: oneOnOne.id,
          meetingID: meeting?.id ?? '',
          agendaItemID,
          description: content
        })
      );
    }, 500);
  }, [meeting?.id]);

  if (!meeting) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-1">
        <p className="font-medium mb-1">Agenda</p>
        <HorizontalLine />
      </div>

      {meeting.agendaItems.map((agendaItem, index) => (
        <div key={agendaItem.id} className="flex flex-row justify-between gap-6 items-start">
          <CheckboxInputItem
            checked={agendaItem.completed}
            onCheck={(checked) => {
              dispatch(
                updateAgendaItem({
                  organizationSlug,
                  oneOnOneID: oneOnOne.id,
                  meetingID: meeting.id,
                  agendaItemID: agendaItem.id,
                  completed: checked
                })
              );
            }}
            value={agendaItem.description}
            placeholder="Write talking point..."
            autoFocus={autoFocusItem === index}
            changeable={authUser.id === agendaItem?.createdBy?.id}
            onChange={(value) => {
              updateAgendaItemDebounced(agendaItem.id, value);
            }}
          />

          <div className="flex flex-row items-center gap-2">
            <Avatar size={6} user={agendaItem.createdBy} />
            {authUser.id === agendaItem?.createdBy?.id ? (
              <button
                className="rounded-md items-center p-0.5 text-red-500 hover:text-red-600 hover:bg-gray-50"
                onClick={() => {
                  dispatch(
                    deleteAgendaItem({
                      organizationSlug,
                      oneOnOneID: oneOnOne.id,
                      meetingID: meeting.id,
                      agendaItemID: agendaItem.id
                    })
                  );
                }}>
                <XMarkIcon className="size-5" />
              </button>
            ) : (
              <div className="w-6"></div>
            )}
          </div>
        </div>
      ))}

      {/* Add talking point button */}
      <div className="flex flex-row gap-2 items-center">
        <button
          className="flex flex-row gap-2 items-center pr-1.5 text-gray-500 hover:text-gray-900 whitespace-nowrap text-md"
          onClick={() => {
            setAutoFocusItem(meeting.agendaItems.length);
            dispatch(
              createAgendaItem({
                organizationSlug,
                oneOnOneID: oneOnOne.id,
                meetingID: meeting.id,
                description: ''
              })
            );
          }}>
          <PlusIcon className="size-5" />
          <span>Add talking point</span>
        </button>
      </div>
    </div>
  );
};

export default AgendaSection;
