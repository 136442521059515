import { GOOGLE_OAUTH_REDIRECT_URI } from '../common/constants';
import CalendarEvent, { CalendarEventData } from '../core/calendar/CalendarEvent.model';
import UserIntegration, { UserIntegrationData } from '../core/user/UserIntegration.model';
import { Api } from './api';
import { parseError } from './error';

async function getUserIntegrations(): Promise<UserIntegrationData> {
  try {
    const { data } = await Api.get('/user/integrations');
    return Promise.resolve(UserIntegration.buildUserIntegrationDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GoogleCalendarOAuthUserArgs = {
  code: string;
};

async function googleCalendarOAuthUser({ code }: GoogleCalendarOAuthUserArgs): Promise<void> {
  try {
    await Api.get(
      `/user/integrations/google-calendar-callback?code=${code}&redirect_uri=${GOOGLE_OAUTH_REDIRECT_URI}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetUserIntegrationArgs = {
  integrationID: string;
};

async function getUserCalendarEvents({
  integrationID
}: GetUserIntegrationArgs): Promise<CalendarEventData[]> {
  try {
    const { data } = await Api.get(`/user/integrations/${integrationID}/calendar-events`);
    return Promise.resolve(
      data.events.map((event: any) => CalendarEvent.buildCalendarEventDataFromResponse(event))
    );
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function disconnectUserIntegration({ integrationID }: GetUserIntegrationArgs): Promise<void> {
  try {
    await Api.delete(`/user/integrations/${integrationID}/disconnect`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  getUserIntegrations,
  googleCalendarOAuthUser,
  getUserCalendarEvents,
  disconnectUserIntegration
};
