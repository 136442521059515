import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../core/store';
import { organizationActions } from '../../core/actions';
import {
  Button,
  HorizontalLine,
  SettingsItemWithEditButton,
  UsersCombobox
} from '../../components';
import { OrganizationData } from '../../core/organization/Organization.model';
import { checkPermissionAccess } from '../../common/utils';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';
import { ComboboxUser } from '../../components/UsersCombobox';

const OrganizationSettingsPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const organization = useAppSelector((state) => state.organization.selectedOrganization);
  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);

  const [transferOrganizationOwnerOn, setTransferOrganizationOwnerOn] = useState(false);
  const [newOrganizationOwner, setNewOrganizationOwner] = useState<ComboboxUser>();

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areEmployeesFetched) {
      return;
    }
    dispatch(fetchOrganizationUsers({ organizationSlug }));
  }, []);

  async function updateOrganizationSlug(slug: string): Promise<void> {
    const res: any = await dispatch(
      organizationActions.updateOrganizationSettings({ ...organization, slug })
    );
    if (res.error) {
      return;
    }

    const organizationData: OrganizationData = res.payload;
    navigate(`/${organizationData.slug}/settings`);
  }

  return (
    <div className="flex flex-row justify-center mt-6">
      <div className="flex flex-col gap-3 w-full max-w-[60rem]">
        <h3 className="text-lg font-medium text-gray-900">Organization Settings</h3>
        <HorizontalLine />

        <div>
          <dl className="space-y-6 divide-y divide-gray-100 text-sm leading-6">
            <SettingsItemWithEditButton
              labelName="Organization Name"
              value={organization.name}
              showButton={checkPermissionAccess(authUser.permissionRole, 'admin')}
              onClick={(newValue) => {
                dispatch(
                  organizationActions.updateOrganizationSettings({
                    ...organization,
                    name: newValue
                  })
                );
              }}
            />
            <SettingsItemWithEditButton
              labelName="Organization Slug"
              value={organization.slug}
              showButton={checkPermissionAccess(authUser.permissionRole, 'admin')}
              onClick={(newValue) => {
                updateOrganizationSlug(newValue);
              }}
            />

            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 self-center">
                Organization Owner
              </dt>
              <dd className="flex-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                {transferOrganizationOwnerOn ? (
                  <div className="w-full">
                    <UsersCombobox
                      users={employees}
                      placeholder="Select new organization owner"
                      showEmail
                      onChange={(user) => {
                        setNewOrganizationOwner(user);
                      }}
                    />
                  </div>
                ) : (
                  <p className="text-gray-900 self-center">
                    {`${organization.owner.firstName} ${organization.owner.lastName} <`}
                    <span className="text-gray-700">{organization.owner.email}</span>
                    {'>'}
                  </p>
                )}

                <div className={authUser.id === organization.owner.id ? undefined : 'hidden'}>
                  {transferOrganizationOwnerOn ? (
                    <div className="flex flex-row">
                      <Button
                        variant={'link'}
                        className="text-red-500 hover:decoration-red-600 pr-0"
                        onClick={(e) => {
                          setTransferOrganizationOwnerOn(false);
                          setNewOrganizationOwner(undefined);
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant={'link'}
                        onClick={(e) => {
                          setTransferOrganizationOwnerOn(false);
                          if (!newOrganizationOwner) {
                            return;
                          }

                          dispatch(
                            organizationActions.updateOrganizationSettings({
                              ...organization,
                              owner: newOrganizationOwner
                            })
                          );
                        }}>
                        Confirm
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant={'link'}
                      onClick={(e) => {
                        setTransferOrganizationOwnerOn(true);
                      }}>
                      Transfer
                    </Button>
                  )}
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettingsPage;
