import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon, ChevronUpIcon, ChevronDoubleUpIcon } from '@heroicons/react/24/outline';
import { VscCircleSlash } from 'react-icons/vsc';
import { Tooltip } from 'flowbite-react';

import { classNames } from '../../common/utils';
import { useAppSelector } from '../../core/store';

type SkillStatusListboxProps = {
  status?: string;
  onChange?: (status: string) => void;
};

const SkillStatusListbox = ({
  status,
  onChange = () => {}
}: SkillStatusListboxProps): JSX.Element => {
  const placeholder = 'Set status';

  const items = useRef([
    {
      id: 'not_applicable',
      name: 'Not applicable',
      icon: VscCircleSlash,
      color: 'text-gray-600'
    },
    {
      id: 'needs_improvement',
      name: 'Needs improvement',
      icon: ChevronDownIcon,
      color: 'text-orange-500'
    },
    {
      id: 'as_expected',
      name: 'As expected',
      icon: ChevronUpIcon,
      color: 'text-green-500'
    },
    {
      id: 'above_expectation',
      name: 'Above expectation',
      icon: ChevronDoubleUpIcon,
      color: 'text-emerald-500'
    }
  ]);

  const [selected, setSelected] = useState(
    items.current.find((item) => item.id === status) ?? {
      id: '',
      name: placeholder,
      icon: VscCircleSlash,
      color: ''
    }
  );

  const authUser = useAppSelector((state) => state.user);
  const activeProgression = useAppSelector((state) => state.progression.activeProgression);

  useEffect(() => {
    if (!status) {
      setSelected({ id: '', name: placeholder, icon: VscCircleSlash, color: '' });
      return;
    }

    setSelected(
      items.current.find((item) => item.id === status) ?? {
        id: '',
        name: placeholder,
        icon: VscCircleSlash,
        color: ''
      }
    );
  }, [status]);

  if (activeProgression?.manager?.id !== authUser?.id) {
    return (
      <div className="flex flex-col justify-center h-full">
        {selected.id === '' ? (
          <p className="italic text-sm text-gray-700 truncate">No status</p>
        ) : (
          <div className="flex flex-row gap-2 sm:ml-3">
            <Tooltip
              content={selected.name}
              placement="top"
              className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
              <selected.icon className={`size-5 ${selected.color}`} aria-hidden="true" />
            </Tooltip>
            <p className={`hidden xl:block text-sm font-medium ${selected.color} truncate`}>
              {selected.name}
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <Listbox
      value={selected}
      onChange={(selected) => {
        setSelected(selected);
        onChange(selected.id);
      }}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="flex flex-row justify-between w-full cursor-pointer rounded-md bg-white px-1 py-0.5 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm">
              {selected.id === '' ? (
                <span className="flex-none italic text-sm text-gray-700 pr-1 truncate">
                  {selected.name}
                </span>
              ) : (
                <div className="flex flex-row gap-2">
                  <Tooltip
                    content={selected.name}
                    placement="top"
                    className="text-xs text-center whitespace-nowrap bg-gray-600 opacity-90">
                    <selected.icon className={`size-5 ${selected.color}`} aria-hidden="true" />
                  </Tooltip>
                  <p
                    className={`hidden xl:block flex-initial text-sm font-medium ${selected.color} mr-1 truncate`}>
                    {selected.name}
                  </p>
                </div>
              )}

              <div className="flex flex-row gap-1 justify-self-end pt-0.5">
                <ChevronUpDownIcon className="size-4 text-gray-400" aria-hidden="true" />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute right-0 z-10 mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {items.current.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={item}>
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center max-w-fit">
                          <item.icon
                            className={classNames(
                              active ? 'bg-indigo-600 text-white' : item.color,
                              `size-5`
                            )}
                            aria-hidden="true"
                          />
                          <p
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate'
                            )}>
                            {item.name}
                          </p>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SkillStatusListbox;
