import timezones, { TimeZone } from 'timezones-list';

export const timezoneList = timezones.map((timezone) => {
  return {
    id: timezone.tzCode,
    name: timezone.name
  };
});

export const timezoneMap = new Map<string, TimeZone>(
  timezones.map((timezone) => [timezone.tzCode, timezone])
);

export const meetingDateFormat = 'ddd, MMM D, YYYY - h:mm A';
export const defaultDateFormat = 'MMM D, YYYY';
