import DevelopmentPath, { DevelopmentPathData } from '../../cdf/DevelopmentPath.model';

export interface FrameworkDepartmentData {
  /** @type {Generics.UUID} */
  id: string;
  name: string;
  developmentPaths: DevelopmentPathData[];
  isFullyLoaded?: boolean;
}

class FrameworkDepartment implements FrameworkDepartmentData {
  id: string;
  name: string;
  developmentPaths: DevelopmentPathData[];

  constructor(data: FrameworkDepartmentData) {
    this.id = data.id;
    this.name = data.name;
    this.developmentPaths = data.developmentPaths;
  }

  static buildFrameworkDepartmentDataFromResponse(response: any): FrameworkDepartmentData {
    const developmentPaths: DevelopmentPathData[] = (response.development_paths ?? []).map(
      (developmentPath: any) => {
        return DevelopmentPath.buildDevelopmentPathDataFromResponse(developmentPath);
      }
    );

    return {
      id: response.id,
      name: response.name,
      developmentPaths
    };
  }
}

export default FrameworkDepartment;
