import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { OneOnOneData } from './OneOnOne.model';

type OneOnOnesState = {
  oneOnOnes: OneOnOneData[];
  selectedOneOnOne: OneOnOneData | null;
  isSelectedOneOnOnePending: boolean;
  areOneOnOnesPending: boolean;
  areOneOnOnesFetched: boolean;
  error: api.ApiError;
};

const initialState: OneOnOnesState = {
  oneOnOnes: [],
  selectedOneOnOne: null,
  isSelectedOneOnOnePending: true,
  areOneOnOnesPending: true,
  areOneOnOnesFetched: false,
  error: null
};

const fetchOneOnOnes = createAsyncThunk(
  'oneOnOnes/getOneOnOnes',
  async (args: api.GetOneOnOnesArgs, { rejectWithValue }) => {
    try {
      return await api.getOneOnOnes(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchOneOnOne = createAsyncThunk(
  'oneOnOnes/getOneOnOne',
  async (args: api.GetOneOnOneArgs, { rejectWithValue }) => {
    try {
      return await api.getOneOnOne(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createOneOnOne = createAsyncThunk(
  'oneOnOnes/createOneOnOne',
  async (args: api.CreateOneOnOneArgs, { rejectWithValue }) => {
    try {
      return await api.createOneOnOne(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateOneOnOne = createAsyncThunk(
  'oneOnOnes/updateOneOnOne',
  async (args: api.UpdateOneOnOneArgs, { rejectWithValue }) => {
    try {
      return await api.updateOneOnOne(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteOneOnOne = createAsyncThunk(
  'oneOnOnes/deleteOneOnOne',
  async (args: api.GetOneOnOneArgs, { rejectWithValue }) => {
    try {
      return await api.deleteOneOnOne(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const oneOnOnesSlice = createSlice({
  name: 'oneOnOnes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch one on ones
    builder.addCase(fetchOneOnOnes.pending, (state) => {
      state.areOneOnOnesPending = true;
    });
    builder.addCase(fetchOneOnOnes.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.areOneOnOnesPending = false;
      toast.error(getCustomUserError(state.error, 'Failed to fetch 1-on-1s'));
    });
    builder.addCase(fetchOneOnOnes.fulfilled, (state, action) => {
      state.oneOnOnes = action.payload;
      state.areOneOnOnesPending = false;
      state.areOneOnOnesFetched = true;
      state.error = null;
    });

    // Fetch one on one
    builder.addCase(fetchOneOnOne.pending, (state) => {
      state.isSelectedOneOnOnePending = true;
    });
    builder.addCase(fetchOneOnOne.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.isSelectedOneOnOnePending = false;
      toast.error(getCustomUserError(state.error, 'Failed to fetch 1-on-1'));
    });
    builder.addCase(fetchOneOnOne.fulfilled, (state, action) => {
      state.selectedOneOnOne = action.payload;
      state.isSelectedOneOnOnePending = false;
      state.error = null;
    });

    // Create one on one
    builder.addCase(createOneOnOne.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create 1-on-1'));
    });
    builder.addCase(createOneOnOne.fulfilled, (state, action) => {
      state.oneOnOnes.push(action.payload);
    });

    // Update one on one
    builder.addCase(updateOneOnOne.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update 1-on-1'));
    });
    builder.addCase(updateOneOnOne.fulfilled, (state, action) => {
      for (let i = 0; i < state.oneOnOnes.length; i++) {
        if (state.oneOnOnes[i].id === action.payload.id) {
          state.oneOnOnes[i] = action.payload;
          break;
        }
      }
      if (state.selectedOneOnOne?.id === action.payload.id) {
        state.selectedOneOnOne = action.payload;
      }
    });

    // Delete one on one
    builder.addCase(deleteOneOnOne.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete 1-on-1'));
    });
    builder.addCase(deleteOneOnOne.fulfilled, (state, action) => {
      state.oneOnOnes = state.oneOnOnes.filter(
        (oneOnOne) => oneOnOne.id !== action.meta.arg.oneOnOneID
      );
      state.selectedOneOnOne = null;
    });
  }
});

export default oneOnOnesSlice.reducer;

export { fetchOneOnOnes, fetchOneOnOne, createOneOnOne, updateOneOnOne, deleteOneOnOne };
