import { toast } from 'sonner';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { EmployeeData } from './Employee.model';

type EmployeeState = {
  employees: EmployeeData[];
  selectedEmployee: EmployeeData | null;

  areEmployeesPending: boolean;
  isEmployeePending: boolean;
  areEmployeesFetched: boolean;

  error: api.ApiError;
};

const initialState: EmployeeState = {
  employees: [],
  selectedEmployee: null,

  areEmployeesPending: true,
  isEmployeePending: true,
  areEmployeesFetched: false,

  error: null
};

const inviteUserToOrganization = createAsyncThunk(
  'employees/inviteUserToOrganization',
  async (args: api.InviteUserToOrganizationArgs, { rejectWithValue }) => {
    try {
      return await api.inviteUserToOrganization(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const inviteUserToOrganizationBulk = createAsyncThunk(
  'employees/inviteUserToOrganizationBulk',
  async (args: api.InviteUserToOrganizationBulkArgs, { rejectWithValue }) => {
    try {
      return await api.inviteUserToOrganizationBulk(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchOrganizationUsers = createAsyncThunk(
  'employees/getOrganizationUsers',
  async (args: api.GetOrganizationArgs, { rejectWithValue }) => {
    try {
      return await api.getOrganizationUsers(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createOrganizationUser = createAsyncThunk(
  'employees/createOrganizationUser',
  async (args: api.CreateOrganizationUserArgs, { rejectWithValue }) => {
    try {
      return await api.createOrganizationUser(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchOrganizationUser = createAsyncThunk(
  'employees/getOrganizationUser',
  async (args: api.GetOrganizationUserArgs, { rejectWithValue }) => {
    try {
      return await api.getOrganizationUser(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const removeOrganizationUser = createAsyncThunk(
  'employees/removeOrganizationUser',
  async (args: api.GetOrganizationUserArgs, { rejectWithValue }) => {
    try {
      return await api.removeOrganizationUser(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateOrganizationUserPermissionRole = createAsyncThunk(
  'employees/updateOrganizationUserPermissionRole',
  async (args: api.UpdateOrganizationUserPermissionRoleArgs, { rejectWithValue }) => {
    try {
      return await api.updateOrganizationUserPermissionRole(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type AssignEmployeeCurrentLevelArgs = api.GetProgressionArgs & {
  currentLevelID: string;
};

const assignEmployeeCurrentLevel = createAsyncThunk(
  'employees/assignEmployeeCurrentLevel',
  async (args: AssignEmployeeCurrentLevelArgs, { rejectWithValue }) => {
    try {
      return await api.assignUserProgressionManagerOrLevel({
        ...args,
        levelID: args.currentLevelID
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Invite organization employee
    builder.addCase(inviteUserToOrganization.pending, (state, action) => {
      for (const employee of state.employees) {
        if (employee.email === action.meta.arg.email) {
          employee.status = 'invitation_sent';
          break;
        }
      }
    });
    builder.addCase(inviteUserToOrganization.rejected, (state, action) => {
      for (const employee of state.employees) {
        if (employee.email === action.meta.arg.email) {
          employee.status = 'not_invited';
          break;
        }
      }
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to send organization invite'));
    });
    builder.addCase(inviteUserToOrganization.fulfilled, (state, action) => {
      state.error = null;
      toast.success('Successfully sent organization invite');
    });

    // Invite organization employee bulk
    builder.addCase(inviteUserToOrganizationBulk.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to send organization invite'));
    });
    builder.addCase(inviteUserToOrganizationBulk.fulfilled, (state, action) => {
      const emails: string[] = action.meta.arg.users.map((user) => user.email);
      for (const employee of state.employees) {
        if (emails.includes(employee.email)) {
          employee.status = 'invitation_sent';
        }
      }

      state.error = null;
      toast.success('Successfully sent all organization invites');
    });

    // Fetch organization employees
    builder.addCase(fetchOrganizationUsers.pending, (state) => {
      state.areEmployeesPending = true;
      state.areEmployeesFetched = false;
      state.error = null;
    });
    builder.addCase(fetchOrganizationUsers.rejected, (state, action) => {
      state.areEmployeesPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch organization members'));
    });
    builder.addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
      state.employees = action.payload;
      state.areEmployeesFetched = true;
      state.areEmployeesPending = false;
      state.error = null;
    });

    // Create organization employee
    builder.addCase(createOrganizationUser.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to create organization member'));
    });

    // Fetch organization employee
    builder.addCase(fetchOrganizationUser.pending, (state) => {
      state.isEmployeePending = true;
      state.error = null;
    });
    builder.addCase(fetchOrganizationUser.rejected, (state, action) => {
      state.isEmployeePending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch organization member'));
    });
    builder.addCase(fetchOrganizationUser.fulfilled, (state, action) => {
      state.selectedEmployee = action.payload;
      state.isEmployeePending = false;
      state.error = null;
    });

    // Remove organization employee
    builder.addCase(removeOrganizationUser.pending, (state) => {
      state.error = null;
    });
    builder.addCase(removeOrganizationUser.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to remove organization member'));
    });
    builder.addCase(removeOrganizationUser.fulfilled, (state, action) => {
      state.employees = state.employees.filter(
        (employee) => employee.id !== action.meta.arg.userID
      );
      state.error = null;
    });

    // Update organization employee permission role
    builder.addCase(updateOrganizationUserPermissionRole.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to update permission role')
      );
    });
    builder.addCase(updateOrganizationUserPermissionRole.fulfilled, (state, action) => {
      const index = state.employees.findIndex((e) => e.id === action.meta.arg.userID);
      if (index === -1) {
        return;
      }
      state.employees[index].permissionRole = action.meta.arg.permissionRole;
    });

    // Assign employee current level
    builder.addCase(assignEmployeeCurrentLevel.rejected, (state, action) => {
      toast.error(
        getCustomUserError(
          action.payload as api.ApiError,
          'Failed to assign current level to employee'
        )
      );
    });
  }
});

export default employeesSlice.reducer;

export {
  inviteUserToOrganization,
  inviteUserToOrganizationBulk,
  fetchOrganizationUsers,
  createOrganizationUser,
  fetchOrganizationUser,
  removeOrganizationUser,
  updateOrganizationUserPermissionRole,
  assignEmployeeCurrentLevel
};
