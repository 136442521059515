import { PerimssionRole } from '../../common/constants';

export interface UserData {
  /** @type {Generics.UUID} */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string | null;
  permissionRole: PerimssionRole;

  isInternal: boolean;
}

class User implements UserData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string | null;
  permissionRole: PerimssionRole;

  isInternal: boolean;

  constructor(data: UserData) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.picture = data.picture;
    this.permissionRole = data.permissionRole;
    this.isInternal = data.isInternal;
  }

  static buildUserDataFromResponse(response: any): UserData {
    return {
      id: response.id,
      firstName: response.first_name,
      lastName: response.last_name,
      email: response.email,
      picture: response.picture,
      permissionRole: 'member',
      isInternal: response.is_internal
    };
  }
}

export default User;
