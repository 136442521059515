import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../core/store';
import { EmployeeData } from '../../core/employee/Employee.model';
import {
  AddEmployeeModal,
  AppLoader,
  BasicListbox,
  Button,
  PermissionProtectedAccess,
  Avatar,
  LevelsCombobox,
  EllipsisMenu
} from '../../components';
import {
  assignEmployeeCurrentLevel,
  fetchOrganizationUsers,
  inviteUserToOrganization,
  inviteUserToOrganizationBulk,
  removeOrganizationUser,
  updateOrganizationUserPermissionRole
} from '../../core/employee/employeesSlice';
import { PerimssionRole, permissionRoles } from '../../common/constants';
import { getOrganizationUserPermissionRole } from '../../core/user/userSlice';

const PeoplePage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const areEmployeesPending = useAppSelector((state) => state.employees.areEmployeesPending);
  const organizationEmployees = useAppSelector((state) => state.employees.employees);

  const checkbox = useRef<any>({});
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeData[]>([]);
  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);

  const permissionRolesItems = useRef(
    permissionRoles.map((role) => {
      return { id: role, name: role };
    })
  );

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    dispatch(fetchOrganizationUsers({ organizationSlug }));
  }, []);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedEmployees.length > 0 && selectedEmployees.length < organizationEmployees.length;
    setChecked(selectedEmployees.length === organizationEmployees.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedEmployees, organizationEmployees]);

  function toggleAll(): void {
    setSelectedEmployees(checked || indeterminate ? [] : organizationEmployees);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  async function sendOrganizationInvite(email: string): Promise<void> {
    await dispatch(inviteUserToOrganization({ organizationSlug, email }));
  }

  async function handleUserPermissionRoleChange(
    userID: string,
    newPermissionRole: PerimssionRole
  ): Promise<void> {
    await dispatch(
      updateOrganizationUserPermissionRole({
        organizationSlug,
        userID,
        permissionRole: newPermissionRole
      })
    );

    if (userID === authUser.id) {
      dispatch(getOrganizationUserPermissionRole({ organizationSlug }));
    }
  }

  function closeEmployeeModal(): void {
    setAddEmployeeModalOpen(false);
  }

  function openEmployeeModal(): void {
    setAddEmployeeModalOpen(true);
  }

  if (areEmployeesPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex flex-col gap-2 justify-start h-full w-full mt-6">
        <div className="flex-initial sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <p className="mt-2 text-sm text-gray-600">
              A list of all people in the company including their name, email and position.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
              <Button
                onClick={() => {
                  openEmployeeModal();
                }}>
                Create user
              </Button>
            </PermissionProtectedAccess>
          </div>
        </div>
        <div className="mt-4 flow-root h-full w-full">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {selectedEmployees.length > 0 && (
                  <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                    <Button
                      variant={'secondary'}
                      size={'sm'}
                      onClick={() => {
                        dispatch(
                          inviteUserToOrganizationBulk({
                            organizationSlug,
                            users: selectedEmployees
                          })
                        );
                      }}>
                      Invite selected
                    </Button>
                  </div>
                )}
                <table className="min-w-full table-fixed divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          ref={checkbox}
                          checked={checked}
                          onChange={toggleAll}
                        />
                      </th>
                      <th
                        scope="col"
                        className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Position
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Role
                      </th>
                      <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                      </PermissionProtectedAccess>
                      <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                          <span className="sr-only">Options</span>
                        </th>
                      </PermissionProtectedAccess>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {organizationEmployees.map((employee: EmployeeData) => (
                      <tr
                        key={employee.id}
                        className={selectedEmployees.includes(employee) ? 'bg-gray-50' : undefined}>
                        <td className="relative px-7 sm:w-12 sm:px-6">
                          {selectedEmployees.includes(employee) && (
                            <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                          )}
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            value={employee.email}
                            checked={selectedEmployees.includes(employee)}
                            onChange={(e) =>
                              setSelectedEmployees(
                                e.target.checked
                                  ? [...selectedEmployees, employee]
                                  : selectedEmployees.filter((p) => p !== employee)
                              )
                            }
                          />
                        </td>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0">
                              <Avatar user={employee} size={11} />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {employee.firstName} {employee.lastName}
                              </div>
                              <div className="mt-1 text-gray-500">{employee.email}</div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <PermissionProtectedAccess
                            minimalRequiredPermissionRole="editor"
                            fallbackElement={
                              <>
                                <div className="text-gray-900">
                                  {employee.department?.level?.name ?? 'Not provided'}
                                </div>
                                <div className="mt-1 text-gray-500">
                                  {employee.department?.name ?? 'Not provided'}
                                </div>
                              </>
                            }>
                            <div className="w-56">
                              <LevelsCombobox
                                defaultValue={employee.department?.level}
                                onChange={(newLevel) => {
                                  dispatch(
                                    assignEmployeeCurrentLevel({
                                      organizationSlug,
                                      progressionUserID: employee.id,
                                      currentLevelID: newLevel.id
                                    })
                                  ).then(() => {
                                    dispatch(fetchOrganizationUsers({ organizationSlug }));
                                  });
                                }}
                              />
                            </div>
                          </PermissionProtectedAccess>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <PermissionProtectedAccess
                            minimalRequiredPermissionRole="editor"
                            fallbackElement={
                              <p className="text-gray-900">{employee.permissionRole ?? 'member'}</p>
                            }>
                            <div className="w-28">
                              <BasicListbox
                                items={permissionRolesItems.current}
                                defaultItem={{
                                  id: employee.permissionRole,
                                  name: employee.permissionRole
                                }}
                                onChange={(newPermissionRole) => {
                                  handleUserPermissionRoleChange(
                                    employee.id,
                                    newPermissionRole.name as PerimssionRole
                                  );
                                }}
                              />
                            </div>
                          </PermissionProtectedAccess>
                        </td>
                        <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                          <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                            {employee.status === 'not_invited' ? (
                              <Button
                                variant={'secondary'}
                                size={'sm'}
                                onClick={() => {
                                  sendOrganizationInvite(employee.email);
                                }}>
                                Send invite
                              </Button>
                            ) : employee.status === 'invitation_sent' ? (
                              <p className="text-orange-400 text-sm font-medium">Invitation sent</p>
                            ) : (
                              <p className="text-green-500 text-sm font-medium">
                                Invitation accepted
                              </p>
                            )}
                          </td>
                        </PermissionProtectedAccess>
                        <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                          <td className="relative">
                            {(() => {
                              const employeeOptions = [];
                              if (employee.status !== 'invitation_accepted') {
                                employeeOptions.push({
                                  name: 'Resend invitation',
                                  onClick: () => {
                                    sendOrganizationInvite(employee.email);
                                  }
                                });
                              }

                              employeeOptions.push({
                                name: 'Remove',
                                onClick: () => {
                                  dispatch(
                                    removeOrganizationUser({
                                      organizationSlug,
                                      userID: employee.id
                                    })
                                  );
                                },
                                color: 'text-red-600'
                              });

                              return <EllipsisMenu items={employeeOptions} />;
                            })()}
                          </td>
                        </PermissionProtectedAccess>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEmployeeModal open={addEmployeeModalOpen} onClose={closeEmployeeModal} />
    </>
  );
};

export default PeoplePage;
