import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import { LevelData } from '../../core/level/Level.model';
import { assignUserToLevel } from '../../core/level/levelSlice';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';
import UsersCombobox, { ComboboxUser } from '../UsersCombobox';
import AppLoader from '../AppLoader';
import { Button } from '../ui/Button';

interface AssignUserToLevelModalProps {
  levelData: LevelData;
  open: boolean;
  onClose: () => void;
}

const AssignUserToLevelModal = ({
  levelData,
  open,
  onClose
}: AssignUserToLevelModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);

  const cancelButtonRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState<ComboboxUser>();

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areEmployeesFetched) {
      return;
    }

    dispatch(fetchOrganizationUsers({ organizationSlug }));
  }, [open]);

  async function handleAssignNewUserToLevel(user: ComboboxUser | undefined): Promise<void> {
    if (!user || user.id === '') {
      toast.warning('Please select a employee');
      return;
    }

    if (levelData.assignedUsers.filter((assignedUser) => assignedUser.id === user.id).length > 0) {
      setSelectedUser(undefined);
      onClose();
      return;
    }

    const res: any = await dispatch(
      assignUserToLevel({ organizationSlug, progressionUserID: user.id, levelID: levelData.id })
    );
    if (res.error) {
      return;
    }

    setSelectedUser(undefined);
    onClose();
  }

  if (!areEmployeesFetched) {
    return <AppLoader />;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Assign Employee to Level
                  </Dialog.Title>
                  <div className="flex flex-col sm:flex-row mt-4 gap-4">
                    <UsersCombobox
                      users={employees}
                      onChange={(user) => {
                        setSelectedUser(user);
                      }}
                    />
                  </div>
                </div>
                <div className="flex mt-16"></div>
                <div className="flex mt-5 sm:mt-4 sm:flex-row-reverse gap-3">
                  <Button
                    onClick={() => {
                      handleAssignNewUserToLevel(selectedUser);
                    }}>
                    Assign
                  </Button>
                  <Button ref={cancelButtonRef} variant={'outline'} onClick={() => onClose()}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AssignUserToLevelModal;
