import GrowArea, { GrowAreaData } from '../core/grow-area/GrowArea.model';
import GrowAreaAction, { GrowAreaActionData } from '../core/grow-area/GrowAreaAction.model';
import GrowAreaUpdate, { GrowAreaUpdateData } from '../core/grow-area/GrowAreaUpdate.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetGrowAreasArgs = GetOrganizationArgs & {
  createdForID?: string;
};

async function getGrowAreas({
  organizationSlug,
  createdForID
}: GetGrowAreasArgs): Promise<GrowAreaData[]> {
  try {
    let url = `/organizations/${organizationSlug}/grow-areas`;
    if (createdForID) {
      url = `${url}?createdForID=${createdForID}`;
    }

    const { data } = await Api.get(url);

    const growAreasData = data.grow_areas.map((growArea: any) =>
      GrowArea.buildGrowAreaDataFromResponse(growArea)
    );
    return Promise.resolve(growAreasData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateGrowAreaArgs = GetOrganizationArgs & {
  skillLevelID?: string;
  createdForID: string;
  name: string;
};

async function createGrowArea({
  organizationSlug,
  skillLevelID,
  createdForID,
  name
}: CreateGrowAreaArgs): Promise<GrowAreaData> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/grow-areas`, {
      skill_level_id: skillLevelID ?? null,
      created_for_id: createdForID,
      name
    });
    return Promise.resolve(GrowArea.buildGrowAreaDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetGrowAreaArgs = GetOrganizationArgs & {
  growAreaID: string;
};

async function getGrowArea({
  organizationSlug,
  growAreaID
}: GetGrowAreaArgs): Promise<GrowAreaData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/grow-areas/${growAreaID}`);
    return Promise.resolve(GrowArea.buildGrowAreaDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateGrowAreaArgs = GetGrowAreaArgs & {
  name?: string;
  description?: string;
  completed?: boolean;
};

async function updateGrowArea({
  organizationSlug,
  growAreaID,
  name,
  description,
  completed
}: UpdateGrowAreaArgs): Promise<GrowAreaData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}`,
      {
        name: name ?? null,
        description: description ?? null,
        completed: completed ?? null
      }
    );
    return Promise.resolve(GrowArea.buildGrowAreaDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateGrowAreaActionArgs = GetGrowAreaArgs & {
  description: string;
};

async function createGrowAreaAction({
  organizationSlug,
  growAreaID,
  description
}: CreateGrowAreaActionArgs): Promise<GrowAreaActionData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}/actions`,
      {
        description
      }
    );
    return Promise.resolve(GrowAreaAction.buildGrowAreaActionDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetGrowAreaActionArgs = GetGrowAreaArgs & {
  growAreaActionID: string;
};

export type UpdateGrowAreaActionArgs = GetGrowAreaActionArgs & {
  description?: string;
  completed?: boolean;
};

async function updateGrowAreaAction({
  organizationSlug,
  growAreaID,
  growAreaActionID,
  description,
  completed
}: UpdateGrowAreaActionArgs): Promise<GrowAreaActionData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}/actions/${growAreaActionID}`,
      {
        description: description ?? null,
        completed: completed ?? null
      }
    );
    return Promise.resolve(GrowAreaAction.buildGrowAreaActionDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteGrowAreaAction({
  organizationSlug,
  growAreaID,
  growAreaActionID
}: GetGrowAreaActionArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}/actions/${growAreaActionID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateGrowAreaUpdateArgs = GetGrowAreaArgs & {
  content: string;
};

async function createGrowAreaUpdate({
  organizationSlug,
  growAreaID,
  content
}: CreateGrowAreaUpdateArgs): Promise<GrowAreaUpdateData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}/updates`,
      {
        content
      }
    );
    return Promise.resolve(GrowAreaUpdate.buildGrowAreaUpdateDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetGrowAreaUpdateArgs = GetGrowAreaArgs & {
  growAreaUpdateID: string;
};

export type UpdateGrowAreaUpdateArgs = GetGrowAreaUpdateArgs & {
  content: string;
};

async function updateGrowAreaUpdate({
  organizationSlug,
  growAreaID,
  growAreaUpdateID,
  content
}: UpdateGrowAreaUpdateArgs): Promise<GrowAreaUpdateData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}/updates/${growAreaUpdateID}`,
      {
        content
      }
    );
    return Promise.resolve(GrowAreaUpdate.buildGrowAreaUpdateDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteGrowAreaUpdate({
  organizationSlug,
  growAreaID,
  growAreaUpdateID
}: GetGrowAreaUpdateArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/grow-areas/${growAreaID}/updates/${growAreaUpdateID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  //  Grow Areas
  getGrowAreas,
  createGrowArea,
  getGrowArea,
  updateGrowArea,

  // Grow Area Actions
  createGrowAreaAction,
  updateGrowAreaAction,
  deleteGrowAreaAction,

  // Grow Area Updates
  createGrowAreaUpdate,
  updateGrowAreaUpdate,
  deleteGrowAreaUpdate
};
