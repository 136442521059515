import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { cva, type VariantProps } from 'class-variance-authority';

import { classNames } from '../../common/utils';
import { cn } from '../../lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'shadow text-white bg-indigo-600 ring-1 ring-inset ring-indigo-700 hover:bg-indigo-600/90',
        outline: 'shadow-sm text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

interface ButtonItemProps {
  id: string;
  name: string | JSX.Element;
  onClick: () => void;
}

interface DropdownButtonProps extends VariantProps<typeof buttonVariants> {
  name: string | JSX.Element;
  onClick?: () => void;
  items: ButtonItemProps[];
}

const DropdownButton = ({ name, onClick, items, variant }: DropdownButtonProps): JSX.Element => {
  const ringColor = variant === 'outline' ? 'ring-gray-300' : 'ring-indigo-700';

  return (
    <div className="inline-flex rounded-md shadow-sm">
      {onClick && (
        <button
          type="button"
          className={`relative px-3 py-2 rounded-l-md focus:z-10 whitespace-nowrap ${cn(
            buttonVariants({ variant })
          )}`}
          onClick={onClick}>
          {name}
        </button>
      )}
      <Menu as="div" className="relative -ml-px whitespace-nowrap">
        {onClick ? (
          <Menu.Button
            className={`relative px-2 py-2 rounded-r-md focus:z-10 whitespace-nowrap ${cn(
              buttonVariants({ variant })
            )}`}>
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        ) : (
          <Menu.Button
            className={`block pl-3 font-semibold rounded-md focus:z-10 align-top ${cn(
              buttonVariants({ variant })
            )}`}>
            {name}

            <div
              className={`block px-2 py-2 ml-3 font-semibold rounded-r-md focus:z-10 ring-1 ring-inset ${ringColor}`}>
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </Menu.Button>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 min-w-28 w-fit origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1 w-full">
              {items.map((item) => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm font-medium w-full text-left'
                      )}
                      onClick={item.onClick}>
                      {item.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropdownButton;
