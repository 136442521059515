import User, { UserData } from '../core/user/User.model';
import Team, { TeamData } from '../core/team/Team.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetTeamsArgs = GetOrganizationArgs;

async function getTeams({ organizationSlug }: GetTeamsArgs): Promise<TeamData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/teams`);
    const teamsData = data.teams.map((team: any) => Team.buildTeamDataFromResponse(team));
    return Promise.resolve(teamsData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function getMyTeamMembers({ organizationSlug }: GetTeamsArgs): Promise<UserData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/teams/my/members`);
    const teamMembersData = data.members.map((member: any) =>
      User.buildUserDataFromResponse(member)
    );
    return Promise.resolve(teamMembersData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateTeamArgs = GetTeamsArgs & {
  name: string;
  departmentID?: string;
  managerID?: string;
};

async function createTeam({
  organizationSlug,
  name,
  departmentID,
  managerID
}: CreateTeamArgs): Promise<TeamData> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/teams`, {
      name,
      department_id: departmentID ?? null,
      manager_id: managerID ?? null
    });
    return Promise.resolve(Team.buildTeamDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetTeamArgs = GetTeamsArgs & {
  teamID: string;
};

export type UpdateTeamArgs = GetTeamArgs & {
  name?: string;
  departmentID?: string;
  managerID?: string;
};

async function updateTeam({
  organizationSlug,
  teamID,
  name,
  departmentID,
  managerID
}: UpdateTeamArgs): Promise<TeamData> {
  try {
    const { data } = await Api.patch(`/organizations/${organizationSlug}/teams/${teamID}`, {
      name: name ?? null,
      department_id: departmentID ?? null,
      manager_id: managerID ?? null
    });
    return Promise.resolve(Team.buildTeamDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteTeam({ organizationSlug, teamID }: GetTeamArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/teams/${teamID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetTeamMemberArgs = GetTeamArgs & {
  memberID: string;
};

async function addTeamMember({
  organizationSlug,
  teamID,
  memberID
}: GetTeamMemberArgs): Promise<UserData> {
  try {
    const { data } = await Api.put(
      `/organizations/${organizationSlug}/teams/${teamID}/members/${memberID}`
    );
    return Promise.resolve(User.buildUserDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function removeTeamMember({
  organizationSlug,
  teamID,
  memberID
}: GetTeamMemberArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/teams/${teamID}/members/${memberID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  getTeams,
  getMyTeamMembers,
  createTeam,
  updateTeam,
  deleteTeam,
  addTeamMember,
  removeTeamMember
};
