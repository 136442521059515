import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { Button, ErrorAlert, GoogleLoginButton } from '../../components';
import { toast } from 'sonner';

type FormDataType = {
  email: string;
  password: string;
};

const ElementAILoginPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<FormDataType>({
    email: '',
    password: ''
  });
  const [loginFailed, setLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormUpdate = (field: string, value: any): void => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleLoginSubmit = async (): Promise<void> => {
    try {
      const token = await api.loginUser(formData);

      const redirectToVsCode = searchParams.get('state');
      if (!redirectToVsCode) {
        toast.error('Something went wrong. Please try again later.');
        return;
      }

      window.location.href = `${decodeURIComponent(redirectToVsCode)}&access_token=${token}`;
    } catch (err: any) {
      setLoginFailed(true);
      setErrorMessage(err?.message ?? 'Login failed');
    }
  };

  const handleOAuth = async (code: string): Promise<void> => {
    try {
      const token = await api.googleOAuthUser({ code, isElementAI: true });

      const redirectToVsCode = searchParams.get('state');
      if (!redirectToVsCode) {
        toast.error('Something went wrong. Please try again later.');
        return;
      }

      window.location.href = `${decodeURIComponent(redirectToVsCode)}&access_token=${token}`;
    } catch (err: any) {
      setLoginFailed(true);
      setErrorMessage(getCustomUserError(err?.message, 'Login failed'));
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Element AI - Sign In
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div
          className={`bg-white px-6 ${
            loginFailed ? 'pb-12 pt-3' : 'py-12'
          } shadow sm:rounded-lg sm:px-12`}>
          {loginFailed && (
            <div className="my-4">
              <ErrorAlert>{errorMessage}</ErrorAlert>
            </div>
          )}

          <form
            className="space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              handleLoginSubmit();
            }}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={(e) => handleFormUpdate('email', e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <Link
                  to="/element-ai/forgot-password"
                  className="text-sm text-indigo-600 hover:underline dark:text-indigo-500">
                  Forgot password?
                </Link>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="off"
                  value={formData.password}
                  onChange={(e) => handleFormUpdate('password', e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                />
              </div>
            </div>

            <div>
              <Button type="submit" className="w-full">
                Sign In
              </Button>
            </div>
          </form>

          <div className="mt-6">
            <p className="text-sm font-light text-gray-600 dark:text-gray-600">
              {"Don't have an account? "}
              <Link
                to={`/element-ai/register?${searchParams.toString()}`}
                className="font-medium text-indigo-600 hover:underline dark:text-indigo-500">
                Register here
              </Link>
            </p>
          </div>

          <div>
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">Or continue with</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-4">
              <GoogleLoginButton label="Sign In with Google" onAuthentication={handleOAuth} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElementAILoginPage;
