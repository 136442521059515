import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Outlet, NavLink, useParams, useNavigate } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/20/solid';
import _ from 'lodash';

import { classNames, generateLabel } from '../../common/utils';
import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import {
  createDevelopmentPath,
  deleteDevelopmentPath,
  departmentSelector,
  duplicateDevelopmentPath,
  fetchDepartment,
  updateDepartment,
  updateDevelopmentPath
} from '../../core/cdf/departmentsSlice';
import {
  AddNewDevelopmentPathModal,
  EditDevelopmentPathModal,
  AppLoader,
  CreateNewLevelModal,
  NotFoundComponent,
  PermissionProtectedAccess,
  HorizontalLine,
  VerticalLine,
  Button,
  ConfirmationDialog
} from '../../components';
import { DevelopmentPathData } from '../../core/cdf/DevelopmentPath.model';
import { createLevel } from '../../core/level/levelSlice';

const CDFDepartmentPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isDepartmentPending = useAppSelector((state) => state.departments.isDepartmentPending);
  const department = useAppSelector((state) =>
    departmentSelector(state.departments, params.departmentID)
  );

  const [addNewDevelopmentPathModalOpen, setAddNewDevelopmentPathModalOpen] = useState(false);
  const [editDevelopmentPathModalOpen, setEditDevelopmentPathModalOpen] = useState(false);
  const [deleteDevelopmentPathModalOpen, setDeleteDevelopmentPathModalOpen] = useState(false);
  const [selectedDevelopmentPathData, setSelectedDevelopmentPathData] =
    useState<DevelopmentPathData>();
  const [selectedNewLevelSeniority, setSelectedNewLevelSeniority] = useState(1);
  const [createNewLevelModalOpen, setCreateNewLevelModalOpen] = useState(false);
  const [open, setOpen] = useState(true);

  const organizationSlug = params.organizationSlug ?? '';
  const departmentID = params.departmentID ?? '';

  useEffect(() => {
    dispatch(fetchDepartment({ organizationSlug, departmentID }));
  }, []);

  useEffect(() => {
    if (
      !params.levelID &&
      department &&
      department.developmentPaths.length > 0 &&
      department.developmentPaths[0].levels.length > 0
    ) {
      navigate(department.developmentPaths[0].levels[0].id);
    }
  }, [department]);

  const updateDepartmentName = useMemo(() => {
    return _.debounce((newName: string) => {
      dispatch(
        updateDepartment({
          organizationSlug,
          departmentID,
          name: newName
        })
      );
    }, 1000);
  }, [departmentID]);

  async function onCreateNewDevelopmentPath(name: string): Promise<boolean> {
    const res: any = await dispatch(
      createDevelopmentPath({
        organizationSlug,
        departmentID,
        name
      })
    );
    return !res.error;
  }

  function closeAddNewDevelopmentPathModal(): void {
    setAddNewDevelopmentPathModalOpen(false);
  }

  function openAddNewDevelopmentPathModal(): void {
    setAddNewDevelopmentPathModalOpen(true);
  }

  async function onUpdateDevelopmentPathName(newName: string): Promise<boolean> {
    if (!selectedDevelopmentPathData) {
      return false;
    }

    const res: any = await dispatch(
      updateDevelopmentPath({
        organizationSlug,
        departmentID,
        developmentPathID: selectedDevelopmentPathData.id,
        name: newName
      })
    );
    return !res.error;
  }

  function closeEditDevelopmentPathModal(): void {
    setEditDevelopmentPathModalOpen(false);
  }

  function openEditDevelopmentPathModal(): void {
    setEditDevelopmentPathModalOpen(true);
  }

  async function onCreateNewLevel(name: string): Promise<boolean> {
    if (!selectedDevelopmentPathData) {
      return false;
    }

    const res: any = await dispatch(
      createLevel({
        organizationSlug,
        departmentID,
        developmentPathID: selectedDevelopmentPathData.id,
        levelData: {
          label: `${generateLabel(selectedDevelopmentPathData.name)}${selectedNewLevelSeniority}`,
          name,
          seniority: selectedNewLevelSeniority
        }
      })
    );
    if (res.error) {
      return false;
    }

    dispatch(fetchDepartment({ organizationSlug, departmentID }));
    return true;
  }

  async function duplicateDevelopmentPathHandler(developmentPathID: string): Promise<void> {
    const res: any = await dispatch(
      duplicateDevelopmentPath({ organizationSlug, departmentID, developmentPathID })
    );
    if (res.error) {
      return;
    }
    dispatch(fetchDepartment({ organizationSlug, departmentID }));
  }

  function closeNewLevelModal(): void {
    setCreateNewLevelModalOpen(false);
  }

  function openNewLevelModal(): void {
    setCreateNewLevelModalOpen(true);
  }

  if (isDepartmentPending) {
    return <AppLoader />;
  }

  if (!department) {
    return (
      <NotFoundComponent
        title="Department not found"
        description="Department not found. Please try again."
      />
    );
  }

  return (
    <>
      <div className="flex flex-row h-full w-full -ml-2">
        <Transition
          show={!open}
          as={Fragment}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100">
          <div className="flex flex-col justify-start w-8 pr-3 border-r border-gray-300">
            <div className="flex flex-col justify-center h-7">
              <button
                type="button"
                className="flex-none rounded-md text-gray-600 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen(true)}>
                <span className="sr-only">Open panel</span>
                <ChevronDoubleRightIcon className="size-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </Transition>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full">
          <div className="flex-none w-64 border-r border-gray-300 pr-3">
            <div className="relative w-full h-full">
              <div className="absolute w-full h-full overflow-auto">
                <div className="flex flex-col justify-start gap-2 pt-0.5 pl-0.5 pr-1">
                  <div className="flex flex-row justify-start gap-2">
                    <div className="flex-1">
                      <PermissionProtectedAccess
                        minimalRequiredPermissionRole="editor"
                        fallbackElement={
                          <h3 className="font-semibold text-lg text-gray-900">{department.name}</h3>
                        }>
                        <input
                          type="text"
                          defaultValue={department.name}
                          autoComplete="off"
                          className="font-semibold rounded-md text-lg h-7 text-gray-900 border-0 p-0 focus:p-1"
                          onChange={(e) => {
                            if (e.target.value.length < 3) {
                              return;
                            }
                            updateDepartmentName(e.target.value);
                          }}
                        />
                      </PermissionProtectedAccess>
                    </div>

                    <div className="flex-none flex flex-col justify-center">
                      <button
                        type="button"
                        className={`${
                          !open && 'hidden'
                        } rounded-md text-gray-600 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500`}
                        onClick={() => setOpen(false)}>
                        <span className="sr-only">Close panel</span>
                        <ChevronDoubleLeftIcon className="size-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <HorizontalLine />

                  <ul role="list" className="space-y-3 mt-2">
                    {department.developmentPaths.map((developmentPath) => (
                      <li key={developmentPath.id} className="space-y-1">
                        <Disclosure defaultOpen>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="w-full">
                                <div className="group flex flex-row justify-start w-full font-medium text-gray-900 hover:text-gray-700">
                                  <span className="flex-initial whitespace-nowrap truncate mr-2">
                                    {developmentPath.name}
                                  </span>

                                  <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                                    <Menu as="div" className="flex-initial relative">
                                      <Menu.Button
                                        as="div"
                                        className="text-gray-400 hover:text-gray-500 mt-0.5">
                                        <EllipsisHorizontalIcon
                                          className="size-5"
                                          aria-hidden="true"
                                        />
                                      </Menu.Button>
                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items
                                          className={`absolute cursor-pointer ${
                                            developmentPath.name.length > 13 ? 'right-0' : 'left-0'
                                          } z-10 mt-0.5 w-28 origin-top-left items-start rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  duplicateDevelopmentPathHandler(
                                                    developmentPath.id
                                                  );
                                                }}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block px-3 py-1 text-sm leading-6 text-gray-900 text-start'
                                                )}>
                                                Duplicate
                                              </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setSelectedDevelopmentPathData(developmentPath);
                                                  openEditDevelopmentPathModal();
                                                }}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block px-3 py-1 text-sm leading-6 text-gray-900 text-start'
                                                )}>
                                                Edit
                                              </a>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <a
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setSelectedDevelopmentPathData(developmentPath);
                                                  setDeleteDevelopmentPathModalOpen(true);
                                                }}
                                                className={classNames(
                                                  active ? 'bg-gray-50' : '',
                                                  'block px-3 py-1 text-sm leading-6 text-red-600 text-start'
                                                )}>
                                                Delete
                                              </a>
                                            )}
                                          </Menu.Item>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </PermissionProtectedAccess>

                                  <div className="flex-1 flex flex-row justify-end pl-1">
                                    <ChevronDownIcon
                                      className={`${
                                        open ? 'rotate-180 transform' : ''
                                      } size-6 text-gray-600`}
                                    />
                                  </div>
                                </div>
                              </Disclosure.Button>
                              <Disclosure.Panel className="flex flex-row ml-1">
                                <VerticalLine />

                                <div className="flex-1">
                                  <ul>
                                    {developmentPath.levels.map((level) => (
                                      <NavLink key={level.id} to={level.id}>
                                        {({ isActive }) => (
                                          <div
                                            className={classNames(
                                              isActive
                                                ? 'text-black font-medium'
                                                : 'text-gray-800 hover:text-black hover:font-medium',
                                              'flex gap-x-3 rounded-md py-1.5 pr-2 text-sm'
                                            )}>
                                            <div className="flex-initial flex flex-col justify-center w-2 -mr-2">
                                              <HorizontalLine />
                                            </div>
                                            <span className="flex items-center">{level.name}</span>
                                          </div>
                                        )}
                                      </NavLink>
                                    ))}
                                  </ul>
                                  <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                                    <div className="flex flex-row">
                                      <div className="flex-none flex flex-col justify-center w-2">
                                        <HorizontalLine />
                                      </div>
                                      <Button
                                        size={'sm'}
                                        variant={'link'}
                                        className="text-sm px-1 h-7"
                                        onClick={() => {
                                          setSelectedDevelopmentPathData(developmentPath);
                                          setSelectedNewLevelSeniority(
                                            developmentPath.levels.length + 1
                                          );
                                          openNewLevelModal();
                                        }}>
                                        Add new Level
                                      </Button>
                                    </div>
                                  </PermissionProtectedAccess>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </li>
                    ))}
                  </ul>
                  <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                    <div className="flex-initial text-start -ml-3">
                      <Button
                        size={'sm'}
                        variant={'link'}
                        className="text-sm pr-0"
                        onClick={openAddNewDevelopmentPathModal}>
                        Add new Career Development Path
                      </Button>
                    </div>
                  </PermissionProtectedAccess>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="flex-1 flex ml-8 -mr-8">{<Outlet />}</div>
      </div>
      <AddNewDevelopmentPathModal
        open={addNewDevelopmentPathModalOpen}
        onCreate={onCreateNewDevelopmentPath}
        onClose={closeAddNewDevelopmentPathModal}
      />
      <EditDevelopmentPathModal
        developmentPath={selectedDevelopmentPathData}
        open={editDevelopmentPathModalOpen}
        onUpdate={onUpdateDevelopmentPathName}
        onClose={closeEditDevelopmentPathModal}
      />
      <CreateNewLevelModal
        open={createNewLevelModalOpen}
        onCreate={onCreateNewLevel}
        onClose={closeNewLevelModal}
      />
      <ConfirmationDialog
        open={deleteDevelopmentPathModalOpen}
        title={'Are you absolutely sure?'}
        description={
          <span>
            This action cannot be undone. Confirming this action will permanently delete{' '}
            <span className="font-semibold">{selectedDevelopmentPathData?.name}</span> career
            development path.
          </span>
        }
        variant="destructive"
        onClose={() => setDeleteDevelopmentPathModalOpen(false)}
        onConfirm={() => {
          if (!selectedDevelopmentPathData) {
            return;
          }
          dispatch(
            deleteDevelopmentPath({
              organizationSlug,
              departmentID,
              developmentPathID: selectedDevelopmentPathData.id
            })
          );
          setSelectedDevelopmentPathData(undefined);
          setDeleteDevelopmentPathModalOpen(false);
        }}
      />
    </>
  );
};

export default CDFDepartmentPage;
