import { createSlice } from '@reduxjs/toolkit';

export type Page = {
  name: string;
  href: string;
  current?: boolean;
};

type BreadcrumbsState = {
  pages: Page[];
};

const initialState: BreadcrumbsState = {
  pages: []
};

const BreadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbsPages: (state, action) => {
      state.pages = action.payload;
      state.pages[state.pages.length - 1].current = true;
    }
  }
});

export const { setBreadcrumbsPages } = BreadcrumbsSlice.actions;

export default BreadcrumbsSlice.reducer;
