/**
 * @enum {string}
 */
export const ApiErrorTypes = {
  UNAUTHORIZED: 'unauthorized',
  SESSION_LIMIT_EXCEEDED: 'session_limit_exceeded',
  SESSION_EXPIRED: 'session_expired'
};

export const IS_PROD = process.env.REACT_APP_NODE_ENV === 'production';
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:8000';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';
export const GOOGLE_OAUTH_REDIRECT_URI =
  process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI ?? 'http://localhost:3000';
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN ?? '';

export const currencyAcronyms = [
  'USD',
  'EUR',
  'GBP',
  'AUD',
  'JPY',
  'CHF',
  'AFN',
  'ALL',
  'DZD',
  'AOA',
  'ARS',
  'AMD',
  'AWG',
  'AZN',
  'BSD',
  'BHD',
  'BDT',
  'BBD',
  'BYR',
  'BZD',
  'BMD',
  'BTN',
  'BOB',
  'BAM',
  'BWP',
  'BRL',
  'BND',
  'BGN',
  'BIF',
  'XOF',
  'XAF',
  'XPF',
  'KHR',
  'CAD',
  'CVE',
  'KYD',
  'CLP',
  'CNY',
  'COP',
  'KMF',
  'CDF',
  'CRC',
  'HRK',
  'CUC',
  'CUP',
  'CZK',
  'DKK',
  'DJF',
  'DOP',
  'XCD',
  'EGP',
  'SVC',
  'ETB',
  'FKP',
  'FJD',
  'GMD',
  'GEL',
  'GHS',
  'GIP',
  'GTQ',
  'GNF',
  'GYD',
  'HTG',
  'HNL',
  'HKD',
  'HUF',
  'ISK',
  'INR',
  'IDR',
  'IRR',
  'IQD',
  'ILS',
  'JMD',
  'JOD',
  'KZT',
  'KES',
  'KWD',
  'KGS',
  'LAK',
  'LBP',
  'LSL',
  'LRD',
  'LYD',
  'MOP',
  'MKD',
  'MGA',
  'MWK',
  'MYR',
  'MVR',
  'MRO',
  'MUR',
  'MXN',
  'MDL',
  'MNT',
  'MAD',
  'MZN',
  'MMK',
  'ANG',
  'NAD',
  'NPR',
  'NZD',
  'NIO',
  'NGN',
  'KPW',
  'NOK',
  'OMR',
  'PKR',
  'PAB',
  'PGK',
  'PYG',
  'PEN',
  'PHP',
  'PLN',
  'QAR',
  'RON',
  'RUB',
  'RWF',
  'WST',
  'STD',
  'SAR',
  'RSD',
  'SCR',
  'SLL',
  'SGD',
  'SBD',
  'SOS',
  'ZAR',
  'KRW',
  'LKR',
  'SHP',
  'SDG',
  'SRD',
  'SZL',
  'SEK',
  'SYP',
  'TWD',
  'TZS',
  'THB',
  'TOP',
  'TTD',
  'TND',
  'TRY',
  'TMM',
  'UGX',
  'UAH',
  'UYU',
  'AED',
  'VUV',
  'VEB',
  'VND',
  'YER',
  'ZMK',
  'ZWD'
];

// DO NOT CHANGE THE ORDER OF THESE ROLES IT WILL BREAK THE PERMISSIONS
export const permissionRoles = ['admin', 'editor', 'manager', 'member'];
export type PerimssionRole = 'admin' | 'editor' | 'manager' | 'member';

export const companySizesList = [
  { id: '0-50', name: '0-50' },
  { id: '51-150', name: '51-150' },
  { id: '151-300', name: '151-300' },
  { id: '301-500', name: '301-500' },
  { id: '500+', name: '500+' }
];

export const calendlyDemoLink = 'https://calendly.com/yegemberdin/sprout-demo';
