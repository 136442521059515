import React, { Fragment, useRef, useState } from 'react';
import { Button } from '../ui/Button';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'sonner';
import { useAppDispatch } from '../../hooks';
import { changePassword } from '../../core/user/userSlice';

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal = ({ open, onClose }: ChangePasswordModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  async function changePasswordHandler(): Promise<void> {
    if (newPassword !== confirmNewPassword) {
      toast.warning('New password and confirm new password must be the same');
      return;
    }

    const res: any = await dispatch(changePassword({ oldPassword: currentPassword, newPassword }));
    if (res.error) {
      return;
    }
    onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Change Password
                  </Dialog.Title>
                  <div className="flex flex-col mt-6 space-y-6">
                    <div>
                      <label
                        htmlFor="old-password"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Old Password
                      </label>
                      <div className="mt-2">
                        <input
                          type="password"
                          name="old-password"
                          id="old-password"
                          autoComplete="off"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="new-password"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        New Password
                      </label>
                      <div className="mt-2">
                        <input
                          type="password"
                          name="new-password"
                          id="new-password"
                          autoComplete="off"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="confirm-new-password"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Confirm New Password
                      </label>
                      <div className="mt-2">
                        <input
                          type="password"
                          name="confirm-new-password"
                          id="confirm-new-password"
                          autoComplete="off"
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-5 sm:mt-4 sm:flex-row-reverse gap-3">
                  <Button
                    onClick={() => {
                      changePasswordHandler();
                    }}>
                    Change
                  </Button>
                  <Button ref={cancelButtonRef} variant={'outline'} onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ChangePasswordModal;
