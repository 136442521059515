import React, { Fragment, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'sonner';

import { useAppDispatch } from '../../../hooks';
import BasicListbox from '../../BasicListbox';
import { createLibraryFramework } from '../../../core/library/framework/frameworksSlice';
import { companySizesList } from '../../../common/constants';
import { Button } from '../../ui/Button';

interface CreateNewFrameworkModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateNewFrameworkModal = ({ open, onClose }: CreateNewFrameworkModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const companySizeList = useRef(
    companySizesList.map((size) => ({ id: size.id, name: `${size.name} employees` }))
  );

  const cancelButtonRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    companySizeMin: 0,
    companySizeMax: 0
  });

  const organizationSlug = params.organizationSlug ?? '';

  async function handleCreate(): Promise<void> {
    if (formData.companySizeMax <= formData.companySizeMin) {
      toast.warning('Select company size!');
      return;
    }

    const res: any = await dispatch(createLibraryFramework({ organizationSlug, data: formData }));
    if (res.error) {
      return;
    }

    resetFormData();
    onClose();
  }

  function resetFormData(): void {
    setFormData({
      name: '',
      description: '',
      companySizeMin: 0,
      companySizeMax: 0
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          resetFormData();
          onClose();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all max-h-full sm:max-h-[50rem] min-w-full md:min-w-[50rem] sm:max-w-lg sm:my-8 sm:p-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-[50rem]">
                  <Dialog.Title as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                    Create New Framework
                  </Dialog.Title>
                  <form
                    className="flex flex-col justify-start gap-4 mt-4"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleCreate();
                    }}>
                    <div className="flex-initial flex flex-row gap-8">
                      <input
                        type="text"
                        placeholder="Framework Name"
                        autoComplete="off"
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        required
                        className="flex-initial w-[20rem] max-w-[20rem] rounded-md text-gray-900 p-1"
                      />

                      <BasicListbox
                        placeholder="Select Company Size"
                        items={companySizeList.current}
                        onChange={(selectedOption) => {
                          if (selectedOption.id.endsWith('+')) {
                            setFormData({
                              ...formData,
                              companySizeMin: +selectedOption.id.split('+')[0],
                              companySizeMax: Infinity
                            });
                            return;
                          }

                          const [min, max] = selectedOption.id.split('-');
                          setFormData({ ...formData, companySizeMin: +min, companySizeMax: +max });
                        }}
                      />
                    </div>

                    <textarea
                      placeholder="Add Description..."
                      autoComplete="off"
                      rows={4}
                      className="flex-initial rounded-md text-gray-600 p-1"
                      onChange={(e) => {
                        setFormData({ ...formData, description: e.target.value });
                      }}
                    />

                    <div className="flex mt-5 sm:mt-4 sm:flex-row-reverse gap-3">
                      <Button type="submit">Create</Button>
                      <Button ref={cancelButtonRef} variant={'outline'} onClick={() => onClose()}>
                        Cancel
                      </Button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNewFrameworkModal;
