import { PerimssionRole } from '../common/constants';
import Employee, { EmployeeData } from '../core/employee/Employee.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type InviteUserToOrganizationArgs = GetOrganizationArgs & {
  email: string;
};

async function inviteUserToOrganization({
  organizationSlug,
  email
}: InviteUserToOrganizationArgs): Promise<void> {
  try {
    await Api.post(`/organizations/${organizationSlug}/send-invite`, {
      email
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

type InviteUserData = {
  email: string;
};

export type InviteUserToOrganizationBulkArgs = GetOrganizationArgs & {
  users: InviteUserData[];
};

async function inviteUserToOrganizationBulk({
  organizationSlug,
  users
}: InviteUserToOrganizationBulkArgs): Promise<void> {
  try {
    await Api.post(`/organizations/${organizationSlug}/send-invite-bulk`, {
      emails: users.map((user) => user.email)
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function getOrganizationUserPermissionRole({
  organizationSlug
}: GetOrganizationArgs): Promise<PerimssionRole> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/permissions`);
    return Promise.resolve(data.permission_role ?? 'member');
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateOrganizationUserPermissionRoleArgs = GetOrganizationUserArgs & {
  permissionRole: PerimssionRole;
};

async function updateOrganizationUserPermissionRole({
  organizationSlug,
  userID,
  permissionRole
}: UpdateOrganizationUserPermissionRoleArgs): Promise<void> {
  try {
    await Api.patch(`/organizations/${organizationSlug}/permissions/${userID}`, {
      permission_role: permissionRole
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function getOrganizationUsers({
  organizationSlug
}: GetOrganizationArgs): Promise<EmployeeData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/users`);
    const orgUsersData = data.users.map((user: any) =>
      Employee.buildEmployeeDataFromResponse(user)
    );
    return Promise.resolve(orgUsersData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateOrganizationUserArgs = GetOrganizationArgs & {
  data: {
    firstName: string;
    lastName: string;
    email: string;
    levelID: string;
  };
};

async function createOrganizationUser({
  organizationSlug,
  data
}: CreateOrganizationUserArgs): Promise<void> {
  try {
    await Api.post(`/organizations/${organizationSlug}/users`, {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      level_id: data.levelID || null
    });
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetOrganizationUserArgs = GetOrganizationArgs & {
  userID: string;
};

async function getOrganizationUser({
  organizationSlug,
  userID
}: GetOrganizationUserArgs): Promise<EmployeeData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/users/${userID}`);
    return Promise.resolve(Employee.buildEmployeeDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function removeOrganizationUser({
  organizationSlug,
  userID
}: GetOrganizationUserArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/users/${userID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  inviteUserToOrganization,
  inviteUserToOrganizationBulk,
  getOrganizationUserPermissionRole,
  updateOrganizationUserPermissionRole,
  getOrganizationUsers,
  createOrganizationUser,
  getOrganizationUser,
  removeOrganizationUser
};
