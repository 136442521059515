import { CalendarEventData } from '../core/calendar/CalendarEvent.model';
import OneOnOne, { OneOnOneData } from '../core/one-on-one/OneOnOne.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetOneOnOnesArgs = GetOrganizationArgs;

async function getOneOnOnes({ organizationSlug }: GetOneOnOnesArgs): Promise<OneOnOneData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/one-on-ones`);
    const oneOnOnesData = data.one_on_ones.map((oneOnOne: any) =>
      OneOnOne.buildOneOnOneDataFromResponse(oneOnOne)
    );
    return Promise.resolve(oneOnOnesData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateOneOnOneArgs = GetOrganizationArgs & {
  guestUserID?: string;
  meetingFrequency?: number;
  scheduledTime?: string;
  calendarType?: string;
  calendarEvent?: CalendarEventData;
};

async function createOneOnOne({
  organizationSlug,
  guestUserID,
  meetingFrequency,
  scheduledTime,
  calendarType,
  calendarEvent
}: CreateOneOnOneArgs): Promise<OneOnOneData> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/one-on-ones`, {
      guest_user_id: guestUserID ?? null,
      meeting_frequency: meetingFrequency ?? null,
      scheduled_time: scheduledTime ?? null,
      calendar_type: calendarType ?? null,
      calendar_event: calendarEvent
        ? {
            id: calendarEvent.id,
            title: calendarEvent.title,
            host_email: calendarEvent.hostEmail,
            guest_email: calendarEvent.guestEmail,
            start: calendarEvent.start,
            recurring_event_id: calendarEvent.recurringEventID
          }
        : null
    });
    return Promise.resolve(OneOnOne.buildOneOnOneDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetOneOnOneArgs = GetOrganizationArgs & {
  oneOnOneID: string;
};

async function getOneOnOne({
  organizationSlug,
  oneOnOneID
}: GetOneOnOneArgs): Promise<OneOnOneData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}`);
    return Promise.resolve(OneOnOne.buildOneOnOneDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateOneOnOneArgs = GetOneOnOneArgs & {
  active?: boolean;
  // meetingFrequency is number of weeks, that means 1 means weekly, 2 means bi-weekly, etc.
  meetingFrequency?: number;
  calendarType?: string;
  calendarEvent?: CalendarEventData;
  disconnectCalendar?: boolean;
};

async function updateOneOnOne({
  organizationSlug,
  oneOnOneID,
  active,
  meetingFrequency,
  calendarType,
  calendarEvent,
  disconnectCalendar
}: UpdateOneOnOneArgs): Promise<OneOnOneData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}`,
      {
        active: active ?? null,
        meeting_frequency: meetingFrequency ?? null,
        calendar_type: calendarType ?? null,
        calendar_event: calendarEvent
          ? {
              id: calendarEvent.id,
              title: calendarEvent.title,
              host_email: calendarEvent.hostEmail,
              guest_email: calendarEvent.guestEmail,
              start: calendarEvent.start,
              recurring_event_id: calendarEvent.recurringEventID
            }
          : null,
        disconnect_calendar: disconnectCalendar ?? null
      }
    );
    return Promise.resolve(OneOnOne.buildOneOnOneDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteOneOnOne({ organizationSlug, oneOnOneID }: GetOneOnOneArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/one-on-ones/${oneOnOneID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export { getOneOnOnes, createOneOnOne, getOneOnOne, updateOneOnOne, deleteOneOnOne };
