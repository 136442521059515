import React, { PropsWithChildren } from 'react';

import { useAppSelector } from '../core/store';
import { checkPermissionAccess } from '../common/utils';
import { PerimssionRole } from '../common/constants';

type PermissionProtectedAccessProps = {
  minimalRequiredPermissionRole: PerimssionRole;
  internalAccessRequired?: boolean;
  fallbackElement?: JSX.Element;
};

const PermissionProtectedAccess = ({
  minimalRequiredPermissionRole,
  internalAccessRequired = false,
  fallbackElement = <></>,
  children
}: PropsWithChildren<PermissionProtectedAccessProps>): JSX.Element => {
  const authUser = useAppSelector((state) => state.user);

  if (!internalAccessRequired || !!authUser.isInternal) {
    if (checkPermissionAccess(authUser.permissionRole, minimalRequiredPermissionRole)) {
      return <>{children}</>;
    }
  }

  return fallbackElement;
};

export default PermissionProtectedAccess;
