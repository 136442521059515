import User, { UserData } from '../user/User.model';

export type GrowAreaUpdateData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  growAreaID: string;

  author: UserData | null;

  content: string;

  updatedAt: string;
  createdAt: string;
};

class GrowAreaUpdate implements GrowAreaUpdateData {
  id: string;
  growAreaID: string;
  author: UserData | null;
  content: string;
  updatedAt: string;
  createdAt: string;

  constructor(data: GrowAreaUpdateData) {
    this.id = data.id;
    this.growAreaID = data.growAreaID;
    this.author = data.author;
    this.content = data.content;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  static buildGrowAreaUpdateDataFromResponse(response: any): GrowAreaUpdateData {
    return {
      id: response.id,
      growAreaID: response.grow_area_id,
      author: response.author ? User.buildUserDataFromResponse(response.author) : null,
      content: response.content,
      updatedAt: response.updated_at,
      createdAt: response.created_at
    };
  }
}

export default GrowAreaUpdate;
