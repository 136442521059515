import React, { Fragment, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from '../../hooks';
import {
  GOAL_STATUS_COLORS_MAP,
  GOAL_STATUS_MAP,
  GoalData,
  GoalMeasurementType
} from '../../core/goal/Goal.model';
import HorizontalLine from '../HorizontalLine';
import EllipsisMenu from '../ui/EllipsisMenu';
import { deleteGoalCheckIn, fetchGoal } from '../../core/goal/goalsSlice';

interface CheckInsOverviewModalProps {
  goal: GoalData;
  editable?: boolean;
  open: boolean;
  onClose: () => void;
}

const CheckInsOverviewModal = ({
  goal,
  editable = false,
  open,
  onClose
}: CheckInsOverviewModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const organizationSlug = params.organizationSlug ?? '';
  const goalID = params.goalID ?? '';

  async function removeCheckinHandler(checkInID: string): Promise<void> {
    const res: any = await dispatch(
      deleteGoalCheckIn({
        organizationSlug,
        goalID,
        checkInID
      })
    );
    if (res.error) {
      return;
    }

    dispatch(fetchGoal({ organizationSlug, goalID }));

    onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:pb-6 h-[568px] max-h-[568px] sm:h-[768px] sm:max-h-[768px]">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="flex flex-row items-center gap-1 text-base font-semibold leading-6 text-gray-900">
                      List of Check-ins
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="relative h-full w-full">
                    <div className="absolute bg-white max-h-[568px] sm:max-h-[768px] w-full overflow-y-auto rounded-lg">
                      <div className="flex flex-col gap-2 p-4 sm:px-6">
                        {goal.checkIns.map((checkIn) => (
                          <div
                            key={checkIn.id}
                            className="flex flex-col gap-2 p-3 border border-gray-200 rounded-md shadow w-full">
                            <div className="flex flex-row justify-between items-start gap-8">
                              <div className="flex flex-row justify-start items-center gap-8">
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-medium text-gray-700">Progress</p>
                                  <p className="text-sm text-gray-600">
                                    {`Added `}
                                    <span
                                      className={`${
                                        GOAL_STATUS_COLORS_MAP[checkIn.status]
                                      } font-medium`}>
                                      {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                                      {checkIn.progressIncreaseBy ?? 0}
                                      {goal.measurementType === GoalMeasurementType.PERCENTAGE &&
                                        '%'}
                                    </span>
                                    {` to the goal of: `}
                                    <span className="text-gray-900 font-medium">
                                      {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                                      {goal.targetValue ?? 0}
                                      {goal.measurementType === GoalMeasurementType.PERCENTAGE &&
                                        '%'}
                                    </span>
                                  </p>
                                </div>

                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-medium text-gray-700">Status</p>
                                  <p
                                    className={`text-sm font-medium ${
                                      GOAL_STATUS_COLORS_MAP[checkIn.status]
                                    }`}>
                                    {GOAL_STATUS_MAP[checkIn.status]}
                                  </p>
                                </div>
                              </div>

                              {editable && (
                                <EllipsisMenu
                                  items={[
                                    {
                                      name: 'Remove',
                                      onClick: () => {
                                        removeCheckinHandler(checkIn.id);
                                      },
                                      color: 'text-red-600'
                                    }
                                  ]}
                                />
                              )}
                            </div>

                            <div className="flex flex-col gap-1">
                              <p className="text-sm font-medium text-gray-700">
                                Progress description
                              </p>
                              <p className="text-gray-900 text-sm">
                                {checkIn.progressDescription || 'No description'}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CheckInsOverviewModal;
