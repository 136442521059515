import User, { UserData } from '../user/User.model';

export type ActionItemData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  meetingID: string;

  assignedTo: UserData | null;

  description: string;
  completed: boolean;
};

class ActionItem implements ActionItemData {
  id: string;
  meetingID: string;
  assignedTo: UserData | null;
  description: string;
  completed: boolean;

  constructor(data: ActionItemData) {
    this.id = data.id;
    this.meetingID = data.meetingID;
    this.assignedTo = data.assignedTo;
    this.description = data.description;
    this.completed = data.completed;
  }

  static buildActionItemDataFromResponse(response: any): ActionItemData {
    return {
      id: response.id,
      meetingID: response.meeting_id,
      assignedTo: response.assigned_to
        ? User.buildUserDataFromResponse(response.assigned_to)
        : null,
      description: response.description,
      completed: response.completed
    };
  }
}

export default ActionItem;
