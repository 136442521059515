import React from 'react';

const PrivacyPolicyPage = (): JSX.Element => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      <h1 className="text-lg font-bold mb-4">Privacy Policy of sprout-hr.com</h1>
      <p className="text-sm">This Application collects some Personal Data from its Users.</p>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Owner and Data Controller</h2>
        <p className="text-sm">
          Sprout HR, Inc., a Delaware C Corporation incorporated on February 29, 2024.
        </p>
        <h2 className="text-base font-semibold mt-4">Owner contact email:</h2>
        <p className="text-sm">boris@sprout-hr.com</p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Types of Data Collected</h2>
        <p className="text-sm">
          {`Among the types of Personal Data that this Application collects, by itself or through
          third parties, there are: Tracker; Usage Data; financial information.Complete details on
          each type of Personal Data collected are provided in the dedicated sections of this
          privacy policy or by specific explanation texts displayed prior to the Data
          collection.Personal Data may be freely provided by the User, or, in case of Usage Data,
          collected automatically when using this Application.Unless specified otherwise, all Data
          requested by this Application is mandatory and failure to provide this Data may make it
          impossible for this Application to provide its services. In cases where this Application
          specifically states that some Data is not mandatory, Users are free not to communicate
          this Data without consequences to the availability or the functioning of the Service.Users
          who are uncertain about which Personal Data is mandatory are welcome to contact the
          Owner.Any use of Cookies - or of other tracking tools - by this Application or by the
          owners of third-party services used by this Application serves the purpose of providing
          the Service required by the User, in addition to any other purposes described in the
          present document and in the Cookie Policy, if available.Users are responsible for any
          third-party Personal Data obtained, published or shared through this Application and
          confirm that they have the third party's consent to provide the Data to the Owner.`}
        </p>
      </section>

      <section className="mt-6 mb-2 space-y-3">
        <h2 className="text-base font-bold">Information You Provide</h2>
        <p className="text-sm">
          When you sign up, we create an account for you on our servers (“Account”) to access our
          Services. We will store information such as your first name, last name, job title,
          department and email address.
        </p>

        <p className="text-sm">
          {`Sprout's primary objective is to help you manage your work performance through the logging
          of discussions, feedback, goals/priorities and more with others that you work with
          (whether individually or in groups). Sprout also offers a way to track all your notes and
          meetings on its 1-on-1 feature, enabling seamless integration with your calendar events.
          We track if you have rescheduled, canceled, or deleted any events, and update that
          information in our app accordingly. In order to offer these Services, we require access to
          the following information:`}
        </p>

        <ul className="text-sm list-disc ml-8">
          <li>
            Calendar: We access data about the events that you participate in such as organizer,
            title, attendees and optional attendees (names and emails), date, time, duration,
            calendar resources (meeting rooms) and recurrence of events.
          </li>
        </ul>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          Google User Data and Limited Use Policy Compliance
        </h2>
        <p className="text-sm">
          In compliance with the Google API Services User Data Policy, including the Limited Use
          requirements, we hereby disclose that {"Sprout's"} use information received from Google
          APIs adheres strictly to the{' '}
          <a
            className="font-medium text-indigo-600 hover:underline dark:text-primary-500"
            href="https://developers.google.com/terms/api-services-user-data-policy"
            target="_blank"
            rel="noreferrer">
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements. This commitment ensures the protection and
          confidentiality of user data obtained via Restricted and Sensitive Scopes, maintaining its
          use within the bounds set by Google. Our application is designed to be transparent and
          secure in handling user data, providing you with control over your information.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-6">Mode and place of processing the Data</h2>
        <h2 className="text-base font-bold mb-2">Methods of Processing</h2>
        <p className="text-sm">
          The Owner takes appropriate security measures to prevent unauthorized access, disclosure,
          modification, or unauthorized destruction of the Data.The Data processing is carried out
          using computers and/or IT enabled tools, following organizational procedures and modes
          strictly related to the purposes indicated. In addition to the Owner, in some cases, the
          Data may be accessible to certain types of persons in charge, involved with the operation
          of this Application (administration, sales, marketing, legal, system administration) or
          external parties (such as third-party technical service providers, mail carriers, hosting
          providers, IT companies, communications agencies) appointed, if necessary, as Data
          Processors by the Owner. The updated list of these parties may be requested from the Owner
          at any time.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Legal basis of processing</h2>
        <p className="text-sm">
          The Owner may process Personal Data relating to Users if one of the following applies:
          Users have given their consent for one or more specific purposes; provision of Data is
          necessary for the performance of an agreement with the User and/or for any pre-contractual
          obligations thereof; processing is necessary for compliance with a legal obligation to
          which the Owner is subject; processing is related to a task that is carried out in the
          public interest or in the exercise of official authority vested in the Owner; processing
          is necessary for the purposes of the legitimate interests pursued by the Owner or by a
          third party.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Place</h2>
        <p className="text-sm">
          {`The Data is processed at the Owner's operating offices and in any other places where the
          parties involved in the processing are located. Depending on the User's location, data
          transfers may involve transferring the User's Data to a country other than their own. To
          find out more about the place of processing of such transferred Data, Users can check the
          section containing details about the processing of Personal Data.Users are also entitled
          to learn about the legal basis of Data transfers to a country outside the European Union
          or to any international organization governed by public international law or set up by two
          or more countries, such as the UN, and about the security measures taken by the Owner to
          safeguard their Data. If any such transfer takes place, Users can find out more by
          checking the relevant sections of this document or inquire with the Owner using the
          information provided in the contact section.`}
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Retention time</h2>
        <p className="text-sm">
          {`Personal Data shall be processed and stored for as long as required by the purpose they
          have been collected for.Therefore:Personal Data collected for purposes related to the
          performance of a contract between the Owner and the User shall be retained until such
          contract has been fully performed.Personal Data collected for the purposes of the Owner's
          legitimate interests shall be retained as long as needed to fulfill such purposes. Users
          may find specific information regarding the legitimate interests pursued by the Owner
          within the relevant sections of this document or by contacting the Owner.The Owner may be
          allowed to retain Personal Data for a longer period whenever the User has given consent to
          such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be
          obliged to retain Personal Data for a longer period whenever required to do so for the
          performance of a legal obligation or upon order of an authority. Once the retention period
          expires, Personal Data shall be deleted. Therefore, the right of access, the right to
          erasure, the right to rectification and the right to data portability cannot be enforced
          after expiration of the retention period.`}
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">The purposes of processing</h2>
        <p className="text-sm">
          The Data concerning the User is collected to allow the Owner to provide its Service,
          comply with its legal obligations, respond to enforcement requests, protect its rights and
          interests (or those of its Users or third parties), detect any malicious or fraudulent
          activity, as well as the following: Analytics.For specific information about the Personal
          Data used for each purpose, the User may refer to the section “Detailed information on the
          processing of Personal Data”.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          Detailed information on the processing of Personal Data
        </h2>
        <p className="text-sm">
          Personal Data is collected for the following purposes and using the following services:
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Analytics</h2>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">The rights of Users</h2>
        <p className="text-sm">
          Users may exercise certain rights regarding their Data processed by the Owner.In
          particular, Users have the right to do the following:
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Withdraw their consent at any time.</h2>
        <p className="text-sm">
          Users have the right to withdraw consent where they have previously given their consent to
          the processing of their Personal Data.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Object to processing of their Data.</h2>
        <p className="text-sm">
          Users have the right to object to the processing of their Data if the processing is
          carried out on a legal basis other than consent. Further details are provided in the
          dedicated section below.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Access their Data.</h2>
        <p className="text-sm">
          Users have the right to learn if Data is being processed by the Owner, obtain disclosure
          regarding certain aspects of the processing and obtain a copy of the Data undergoing
          processing.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Access their Data.</h2>
        <p className="text-sm">
          Users have the right to learn if Data is being processed by the Owner, obtain disclosure
          regarding certain aspects of the processing and obtain a copy of the Data undergoing
          processing.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Verify and seek rectification.</h2>
        <p className="text-sm">
          Users have the right to verify the accuracy of their Data and ask for it to be updated or
          corrected.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Restrict the processing of their Data.</h2>
        <p className="text-sm">
          Users have the right, under certain circumstances, to restrict the processing of their
          Data. In this case, the Owner will not process their Data for any purpose other than
          storing it.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          Have their Personal Data deleted or otherwise removed.
        </h2>
        <p className="text-sm">
          Users have the right, under certain circumstances, to obtain the erasure of their Data
          from the Owner.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          Receive their Data and have it transferred to another controller.
        </h2>
        <p className="text-sm">
          {`Users have the right to receive their Data in a structured, commonly used and machine
          readable format and, if technically feasible, to have it transmitted to another controller
          without any hindrance. This provision is applicable provided that the Data is processed by
          automated means and that the processing is based on the User's consent, on a contract
          which the User is part of or on pre-contractual obligations thereof.`}
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Lodge a complaint.</h2>
        <p className="text-sm">
          Users have the right to bring a claim before their competent data protection authority.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          Details about the right to object to processing
        </h2>
        <p className="text-sm">
          Where Personal Data is processed for a public interest, in the exercise of an official
          authority vested in the Owner or for the purposes of the legitimate interests pursued by
          the Owner, Users may object to such processing by providing a ground related to their
          particular situation to justify the objection.Users must know that, however, should their
          Personal Data be processed for direct marketing purposes, they can object to that
          processing at any time without providing any justification. To learn, whether the Owner is
          processing Personal Data for direct marketing purposes, Users may refer to the relevant
          sections of this document.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">How to exercise these rights</h2>
        <p className="text-sm">
          Any requests to exercise User rights can be directed to the Owner through the contact
          details provided in this document. These requests can be exercised free of charge and will
          be addressed by the Owner as early as possible and always within one month.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Cookie Policy</h2>
        <p className="text-sm">This Application uses Trackers.</p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          Additional information about Data collection and processingLegal action
        </h2>
        <p className="text-sm">
          {`The User's Personal Data may be used for legal purposes by the Owner in Court or in the
          stages leading to possible legal action arising from improper use of this Application or
          the related Services.The User declares to be aware that the Owner may be required to
          reveal personal data upon request of public authorities.`}
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">
          {`Additional information about User's Personal Data`}
        </h2>
        <p className="text-sm">
          In addition to the information contained in this privacy policy, this Application may
          provide the User with additional and contextual information concerning particular Services
          or the collection and processing of Personal Data upon request.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">System logs and maintenance</h2>
        <p className="text-sm">
          For operation and maintenance purposes, this Application and any third-party services may
          collect files that record interaction with this Application (System logs) use other
          Personal Data (such as the IP Address) for this purpose.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Information not contained in this policy</h2>
        <p className="text-sm">
          More details concerning the collection or processing of Personal Data may be requested
          from the Owner at any time. Please see the contact information at the beginning of this
          document.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">How “Do Not Track” requests are handled</h2>
        <p className="text-sm">
          This Application does not support “Do Not Track” requests.To determine whether any of the
          third-party services it uses honor the “Do Not Track” requests, please read their privacy
          policies.
        </p>
      </section>

      <section>
        <h2 className="text-base font-bold mt-6 mb-2">Changes to this privacy policy</h2>
        <p className="text-sm">
          {`The Owner reserves the right to make changes to this privacy policy at any time by
          notifying its Users on this page and possibly within this Application and/or - as far as
          technically and legally feasible - sending a notice to Users via any contact information
          available to the Owner. It is strongly recommended to check this page often, referring to
          the date of the last modification listed at the bottom. Should the changes affect
          processing activities performed on the basis of the User's consent, the Owner shall
          collect new consent from the User, where required.`}
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
