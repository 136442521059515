import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../../api';
import { getCustomUserError } from '../../../api/error';
import { FrameworkData } from './Framework.model';
import { LevelData } from '../../level/Level.model';
import { FrameworkDepartmentData } from './FrameworkDepartment.model';

type FrameworkDataWithState = FrameworkData & {
  isFullyLoaded: boolean;
};

type FrameworksState = {
  frameworks: FrameworkDataWithState[];
  level: LevelData | null;

  areFrameworksFetched: boolean;
  areFrameworksPending: boolean;
  isFrameworkPending: boolean;
  isFrameworkDepartmentPending: boolean;
  isFrameworkLevelPending: boolean;

  error: api.ApiError;
};

const initialState: FrameworksState = {
  frameworks: [],
  level: null,
  areFrameworksFetched: false,
  areFrameworksPending: true,
  isFrameworkPending: false,
  isFrameworkDepartmentPending: false,
  isFrameworkLevelPending: false,
  error: null
};

const fetchLibraryFrameworks = createAsyncThunk(
  'libraryFrameworks/getLibraryFrameworks',
  async (args: api.GetLibraryFrameworksArgs, { rejectWithValue }) => {
    try {
      return await api.getLibraryFrameworks(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchLibraryFramework = createAsyncThunk(
  'libraryFrameworks/getLibraryFramework',
  async (args: api.GetLibraryFrameworkArgs, { rejectWithValue }) => {
    try {
      return await api.getLibraryFramework(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const cloneLibraryFramework = createAsyncThunk(
  'libraryFrameworks/cloneLibraryFramework',
  async (args: api.GetLibraryFrameworkArgs, { rejectWithValue }) => {
    try {
      return await api.cloneLibraryFramework(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createLibraryFramework = createAsyncThunk(
  'libraryFrameworks/createLibraryFramework',
  async (args: api.CreateLibraryFrameworkArgs, { rejectWithValue }) => {
    try {
      return await api.createLibraryFramework(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateLibraryFramework = createAsyncThunk(
  'libraryFrameworks/updateLibraryFramework',
  async (args: api.UpdateLibraryFrameworkArgs, { rejectWithValue }) => {
    try {
      return await api.updateLibraryFramework(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteLibraryFramework = createAsyncThunk(
  'libraryFrameworks/deleteLibraryFramework',
  async (args: api.GetLibraryFrameworkArgs, { rejectWithValue }) => {
    try {
      return await api.deleteLibraryFramework(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createFrameworkDepartment = createAsyncThunk(
  'libraryFrameworks/createFrameworkDepartment',
  async (args: api.CreateFrameworkDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.createFrameworkDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchFrameworkDepartment = createAsyncThunk(
  'libraryFrameworks/getFrameworkDepartment',
  async (args: api.GetFrameworkDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.getFrameworkDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateFrameworkDepartment = createAsyncThunk(
  'libraryFrameworks/updateFrameworkDepartment',
  async (args: api.UpdateFrameworkDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.updateFrameworkDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteFrameworkDepartment = createAsyncThunk(
  'libraryFrameworks/deleteFrameworkDepartment',
  async (args: api.GetFrameworkDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.deleteFrameworkDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createFrameworkDevelopmentPath = createAsyncThunk(
  'libraryFrameworks/createFrameworkDevelopmentPath',
  async (args: api.CreateFrameworkDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.createFrameworkDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateFrameworkDevelopmentPath = createAsyncThunk(
  'libraryFrameworks/updateFrameworkDevelopmentPath',
  async (args: api.UpdateFrameworkDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.updateFrameworkDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteFrameworkDevelopmentPath = createAsyncThunk(
  'libraryFrameworks/deleteFrameworkDevelopmentPath',
  async (args: api.GetFrameworkDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.deleteFrameworkDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchFrameworkLevel = createAsyncThunk(
  'libraryFrameworks/getFrameworkLevel',
  async (args: api.GetFrameworkLevelArgs, { rejectWithValue }) => {
    try {
      return await api.getFrameworkLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createFrameworkLevel = createAsyncThunk(
  'libraryFrameworks/createFrameworkLevel',
  async (args: api.CreateFrameworkLevelArgs, { rejectWithValue }) => {
    try {
      return await api.createFrameworkLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateFrameworkLevel = createAsyncThunk(
  'libraryFrameworks/updateFrameworkLevel',
  async (args: api.UpdateFrameworkLevelArgs, { rejectWithValue }) => {
    try {
      return await api.updateFrameworkLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteFrameworkLevel = createAsyncThunk(
  'libraryFrameworks/deleteFrameworkLevel',
  async (args: api.GetFrameworkLevelArgs, { rejectWithValue }) => {
    try {
      return await api.deleteFrameworkLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const assignSkillToFrameworkLevel = createAsyncThunk(
  'libraryFrameworks/assignSkillToFrameworkLevel',
  async (args: api.AssignSkillToFrameworkLevelArgs, { rejectWithValue }) => {
    try {
      return await api.assignSkillToFrameworkLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const unassignSkillFromFrameworkLevel = createAsyncThunk(
  'libraryFrameworks/unassignSkillFromFrameworkLevel',
  async (args: api.UnassignSkillFromFrameworkLevelArgs, { rejectWithValue }) => {
    try {
      return await api.unassignSkillFromFrameworkLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const libraryFrameworkSelector = (
  state: FrameworksState,
  frameworkID?: string
): FrameworkDataWithState | undefined => {
  return state.frameworks.find((framework) => framework.id === frameworkID);
};

const frameworkDepartmentSelector = (
  state: FrameworksState,
  frameworkID?: string,
  departmentID?: string
): FrameworkDepartmentData | undefined => {
  return libraryFrameworkSelector(state, frameworkID)?.departments.find(
    (department) => department.id === departmentID
  );
};

type UpdateDepartmentLevelName = {
  frameworkID: string;
  departmentID: string;
  developmentPathID: string;
  levelID: string;
  name: string;
};

const frameworksSlice = createSlice({
  name: 'libraryFrameworks',
  initialState,
  reducers: {
    updateFrameworkDepartmentLevelName(state, action: PayloadAction<UpdateDepartmentLevelName>) {
      let i = 0;
      let j = 0;
      let k = 0;
      let l = 0;

      // eslint-disable-next-line no-labels
      outerloop: for (; i < state.frameworks.length; i++) {
        const framework = state.frameworks[i];
        if (framework.id === action.payload.frameworkID) {
          for (; j < framework.departments.length; j++) {
            const department = framework.departments[j];
            if (department.id === action.payload.departmentID) {
              for (; k < department.developmentPaths.length; k++) {
                const developmentPath = department.developmentPaths[k];
                if (developmentPath.id === action.payload.developmentPathID) {
                  for (; l < developmentPath.levels.length; l++) {
                    const level = developmentPath.levels[l];

                    if (level.id === action.payload.levelID) {
                      level.name = action.payload.name;
                      // eslint-disable-next-line no-labels
                      break outerloop;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  extraReducers: (builder) => {
    // Fetch library frameworks
    builder.addCase(fetchLibraryFrameworks.pending, (state) => {
      state.areFrameworksPending = true;
      state.error = null;
    });
    builder.addCase(fetchLibraryFrameworks.rejected, (state, action) => {
      state.areFrameworksPending = false;
      state.error = action.payload as api.ApiError;

      toast.error(getCustomUserError(state.error, 'Failed to fetch library frameworks'));
    });
    builder.addCase(fetchLibraryFrameworks.fulfilled, (state, action) => {
      state.frameworks = action.payload.map((framework) => ({
        ...framework,
        isFullyLoaded: false
      }));
      state.areFrameworksPending = false;
      state.areFrameworksFetched = true;
      state.error = null;
    });

    // Fetch library framework
    builder.addCase(fetchLibraryFramework.pending, (state) => {
      state.isFrameworkPending = true;
      state.error = null;
    });
    builder.addCase(fetchLibraryFramework.rejected, (state, action) => {
      state.isFrameworkPending = false;
      state.error = action.payload as api.ApiError;

      toast.error(getCustomUserError(state.error, 'Failed to fetch library framework'));
    });
    builder.addCase(fetchLibraryFramework.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.payload.id ? { ...action.payload, isFullyLoaded: true } : framework
      );
      state.isFrameworkPending = false;
      state.error = null;
    });

    // Clone library framework
    builder.addCase(cloneLibraryFramework.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to clone library framework'));
    });

    // Create library framework
    builder.addCase(createLibraryFramework.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create library framework'));
    });
    builder.addCase(createLibraryFramework.fulfilled, (state, action) => {
      state.frameworks.push({ ...action.payload, isFullyLoaded: false });
    });

    // Update library framework
    builder.addCase(updateLibraryFramework.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update library framework'));
    });
    builder.addCase(updateLibraryFramework.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.payload.id ? { ...action.payload, isFullyLoaded: true } : framework
      );
      toast.success('Framework updated successfully!');
    });

    // Delete library framework
    builder.addCase(deleteLibraryFramework.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete library framework'));
    });
    builder.addCase(deleteLibraryFramework.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.filter(
        (framework) => framework.id !== action.meta.arg.frameworkID
      );
    });

    // Create framework department
    builder.addCase(createFrameworkDepartment.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create framework department'));
    });
    builder.addCase(createFrameworkDepartment.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: [...framework.departments, action.payload]
            }
          : framework
      );
    });

    // Get framework department
    builder.addCase(fetchFrameworkDepartment.pending, (state) => {
      state.isFrameworkDepartmentPending = true;
      state.error = null;
    });
    builder.addCase(fetchFrameworkDepartment.rejected, (state, action) => {
      state.isFrameworkDepartmentPending = false;
      state.error = action.payload as api.ApiError;

      toast.error(getCustomUserError(state.error, 'Failed to get framework department'));
    });
    builder.addCase(fetchFrameworkDepartment.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: framework.departments.map((department) =>
                department.id === action.payload.id
                  ? { ...action.payload, isFullyLoaded: true }
                  : department
              )
            }
          : framework
      );
      state.isFrameworkDepartmentPending = false;
      state.error = null;
    });

    // Update framework department
    builder.addCase(updateFrameworkDepartment.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update framework department'));
    });
    builder.addCase(updateFrameworkDepartment.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: framework.departments.map((department) =>
                department.id === action.payload.id
                  ? {
                      ...department,
                      name: action.payload.name
                    }
                  : department
              )
            }
          : framework
      );
      toast.success('Department updated successfully!');
    });

    // Delete framework department
    builder.addCase(deleteFrameworkDepartment.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete framework department'));
    });
    builder.addCase(deleteFrameworkDepartment.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: framework.departments.filter(
                (department) => department.id !== action.meta.arg.departmentID
              )
            }
          : framework
      );
    });

    // Create framework development path
    builder.addCase(createFrameworkDevelopmentPath.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create framework career development path'));
    });
    builder.addCase(createFrameworkDevelopmentPath.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: framework.departments.map((department) =>
                department.id === action.meta.arg.departmentID
                  ? {
                      ...department,
                      developmentPaths: [...department.developmentPaths, action.payload]
                    }
                  : department
              )
            }
          : framework
      );
    });

    // Update framework development path
    builder.addCase(updateFrameworkDevelopmentPath.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update framework career development path'));
    });
    builder.addCase(updateFrameworkDevelopmentPath.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: framework.departments.map((department) =>
                department.id === action.meta.arg.departmentID
                  ? {
                      ...department,
                      developmentPaths: department.developmentPaths.map((path) =>
                        path.id === action.payload.id
                          ? {
                              ...path,
                              name: action.payload.name
                            }
                          : path
                      )
                    }
                  : department
              )
            }
          : framework
      );
    });

    // Delete framework development path
    builder.addCase(deleteFrameworkDevelopmentPath.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete framework career development path'));
    });
    builder.addCase(deleteFrameworkDevelopmentPath.fulfilled, (state, action) => {
      state.frameworks = state.frameworks.map((framework) =>
        framework.id === action.meta.arg.frameworkID
          ? {
              ...framework,
              departments: framework.departments.map((department) =>
                department.id === action.meta.arg.departmentID
                  ? {
                      ...department,
                      developmentPaths: department.developmentPaths.filter(
                        (path) => path.id !== action.meta.arg.developmentPathID
                      )
                    }
                  : department
              )
            }
          : framework
      );
    });

    // Get framework level
    builder.addCase(fetchFrameworkLevel.pending, (state) => {
      state.isFrameworkLevelPending = true;
      state.error = null;
    });
    builder.addCase(fetchFrameworkLevel.rejected, (state, action) => {
      state.isFrameworkLevelPending = false;
      state.error = action.payload as api.ApiError;

      toast.error(getCustomUserError(state.error, 'Failed to get framework level'));
    });
    builder.addCase(fetchFrameworkLevel.fulfilled, (state, action) => {
      state.level = action.payload;
      state.isFrameworkLevelPending = false;
      state.error = null;
    });

    // Create framework level
    builder.addCase(createFrameworkLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create framework level'));
    });
    builder.addCase(createFrameworkLevel.fulfilled, (state, action) => {
      state.level = action.payload;
    });

    // Update framework level
    builder.addCase(updateFrameworkLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update framework level'));
    });
    builder.addCase(updateFrameworkLevel.fulfilled, (state, action) => {
      state.level = action.payload;
    });

    // Delete framework level
    builder.addCase(deleteFrameworkLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete framework level'));
    });
    builder.addCase(deleteFrameworkLevel.fulfilled, (state, action) => {
      state.level = null;
    });

    // Assign skill to framework level
    builder.addCase(assignSkillToFrameworkLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to assign skill to framework level'));
    });
    builder.addCase(assignSkillToFrameworkLevel.fulfilled, (state, action) => {
      if (!state.level) {
        return;
      }
      state.level.skills.push(action.payload);
      state.level.skills.sort((a, b) => (a.skillName ?? '').localeCompare(b.skillName ?? ''));
    });

    // Unassign skill from framework level
    builder.addCase(unassignSkillFromFrameworkLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to unassign skill from framework level'));
    });
    builder.addCase(unassignSkillFromFrameworkLevel.fulfilled, (state, action) => {
      if (!state.level) {
        return;
      }
      state.level.skills = state.level.skills.filter(
        (skillLevel) => skillLevel.id !== action.meta.arg.skillLevelID
      );
    });
  }
});

export const { updateFrameworkDepartmentLevelName } = frameworksSlice.actions;

export default frameworksSlice.reducer;

export {
  // Framework
  fetchLibraryFrameworks,
  fetchLibraryFramework,
  cloneLibraryFramework,
  createLibraryFramework,
  updateLibraryFramework,
  deleteLibraryFramework,

  // Framework department
  createFrameworkDepartment,
  fetchFrameworkDepartment,
  updateFrameworkDepartment,
  deleteFrameworkDepartment,

  // Framework development path
  createFrameworkDevelopmentPath,
  updateFrameworkDevelopmentPath,
  deleteFrameworkDevelopmentPath,

  // Framework level
  fetchFrameworkLevel,
  createFrameworkLevel,
  updateFrameworkLevel,
  deleteFrameworkLevel,
  assignSkillToFrameworkLevel,
  unassignSkillFromFrameworkLevel
};

export { libraryFrameworkSelector, frameworkDepartmentSelector };
