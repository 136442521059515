import Goal, {
  GoalData,
  GoalDomain,
  GoalMeasurementType,
  GoalStatus,
  GoalType
} from '../core/goal/Goal.model';
import CheckIn, { CheckInData, CheckInStatus } from '../core/goal/CheckIn.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetGoalsArgs = GetOrganizationArgs;

async function getGoals({ organizationSlug }: GetGoalsArgs): Promise<GoalData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/goals`);
    const goalsData = data.goals.map((goal: any) => Goal.buildGoalDataFromResponse(goal));
    return Promise.resolve(goalsData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateGoalArgs = GetOrganizationArgs & {
  data: {
    type: GoalType;
    ownerID: string;
    title: string;
    description: string;
    domain: GoalDomain;
    private: boolean;
    startDate: Date;
    dueDate: Date;

    // Key result specific fields
    measurementType?: GoalMeasurementType;
    startValue?: number;
    targetValue?: number;

    // Optional fields
    departmentID?: string;
    teamID?: string;
    parentID?: string;
  };
};

async function createGoal({ organizationSlug, data: reqData }: CreateGoalArgs): Promise<GoalData> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/goals`, {
      type: reqData.type,
      owner_id: reqData.ownerID,
      title: reqData.title,
      description: reqData.description,
      domain: reqData.domain,
      private: reqData.private,
      start_date: reqData.startDate.toISOString(),
      due_date: reqData.dueDate.toISOString(),

      // Key result specific fields
      measurement_type: reqData.measurementType ?? null,
      start_value: reqData.startValue ?? null,
      target_value: reqData.targetValue ?? null,

      // Optional fields
      department_id: reqData.departmentID ?? null,
      team_id: reqData.teamID ?? null,
      parent_id: reqData.parentID ?? null
    });

    return Promise.resolve(Goal.buildGoalDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetGoalArgs = GetOrganizationArgs & {
  goalID: string;
};

async function getGoal({ organizationSlug, goalID }: GetGoalArgs): Promise<GoalData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/goals/${goalID}`);
    return Promise.resolve(Goal.buildGoalDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateGoalArgs = GetGoalArgs & {
  data: {
    departmentID?: string;
    teamID?: string;
    ownerID?: string;
    parentID?: string;

    title?: string;
    description?: string;
    domain?: GoalDomain;
    private?: boolean;
    status?: GoalStatus;
    startDate?: Date;
    dueDate?: Date;

    // Key result specific fields
    measurementType?: GoalMeasurementType;
    startValue?: number;
    targetValue?: number;
  };
};

async function updateGoal({
  organizationSlug,
  goalID,
  data: reqData
}: UpdateGoalArgs): Promise<GoalData> {
  try {
    const { data } = await Api.patch(`/organizations/${organizationSlug}/goals/${goalID}`, {
      department_id: reqData.departmentID ?? null,
      team_id: reqData.teamID ?? null,
      owner_id: reqData.ownerID ?? null,
      parent_id: reqData.parentID ?? null,

      title: reqData.title ?? null,
      description: reqData.description ?? null,
      domain: reqData.domain ?? null,
      private: reqData.private ?? null,
      status: reqData.status ?? null,
      start_date: reqData.startDate ? reqData.startDate.toISOString() : null,
      due_date: reqData.dueDate ? reqData.dueDate.toISOString() : null,

      // Key result specific fields
      measurement_type: reqData.measurementType ?? null,
      start_value: reqData.startValue ?? null,
      target_value: reqData.targetValue ?? null
    });

    return Promise.resolve(Goal.buildGoalDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteGoal({ organizationSlug, goalID }: GetGoalArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/goals/${goalID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateGoalCheckInArgs = GetGoalArgs & {
  data: {
    status: CheckInStatus;
    progressDescription: string;
    progressIncreaseBy: number;
  };
};

async function createGoalCheckIn({
  organizationSlug,
  goalID,
  data: reqData
}: CreateGoalCheckInArgs): Promise<CheckInData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/goals/${goalID}/check-ins`,
      {
        status: reqData.status,
        progress_description: reqData.progressDescription,
        progress_increase_by: reqData.progressIncreaseBy
      }
    );

    return Promise.resolve(CheckIn.buildCheckInDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetGoalCheckInArgs = GetGoalArgs & {
  checkInID: string;
};

export type UpdateGoalCheckInArgs = GetGoalCheckInArgs & {
  data: {
    status?: CheckInStatus;
    progressDescription?: string;
    progressIncreaseBy?: number;
  };
};

async function updateGoalCheckIn({
  organizationSlug,
  goalID,
  checkInID,
  data: reqData
}: UpdateGoalCheckInArgs): Promise<CheckInData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/goals/${goalID}/check-ins/${checkInID}`,
      {
        status: reqData.status ?? null,
        progress_description: reqData.progressDescription ?? null,
        progress_increase_by: reqData.progressIncreaseBy ?? null
      }
    );

    return Promise.resolve(CheckIn.buildCheckInDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteGoalCheckIn({
  organizationSlug,
  goalID,
  checkInID
}: GetGoalCheckInArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/goals/${goalID}/check-ins/${checkInID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  getGoals,
  createGoal,
  getGoal,
  updateGoal,
  deleteGoal,
  createGoalCheckIn,
  updateGoalCheckIn,
  deleteGoalCheckIn
};
