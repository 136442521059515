import React from 'react';

import HorizontalLine from '../HorizontalLine';
import { MeetingProgressionData } from '../../core/one-on-one/Meeting.model';
import { useAppSelector } from '../../core/store';
import ProgressionSkillCard from '../progression/ProgressionSkillCard';

type StarredGrowthSkillsSectionProps = {
  meetingProgression: MeetingProgressionData;
};

const StarredGrowthSkillsSection = ({
  meetingProgression
}: StarredGrowthSkillsSectionProps): JSX.Element => {
  const authUser = useAppSelector((state) => state.user);

  return (
    <div className="flex flex-col gap-3">
      <div className="mb-1">
        <p className="font-medium mb-1">
          {meetingProgression.user.id === authUser.id
            ? 'Your'
            : `${meetingProgression.user.firstName}'s`}{' '}
          starred growth skills
        </p>
        <HorizontalLine />
      </div>

      {meetingProgression.starredSkills.map((starredSkill) => (
        <div key={starredSkill.skillLevelID} className="flex flex-col justify-center h-full w-full">
          <ProgressionSkillCard
            progressionUser={meetingProgression.user}
            progressionSkill={starredSkill}
            hiddeActions
            expandable
          />
        </div>
      ))}
    </div>
  );
};

export default StarredGrowthSkillsSection;
