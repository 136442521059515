export interface CalendarEventData {
  id: string;
  title: string;

  hostEmail: string;
  guestEmail: string;

  start: string; // Date
  recurringEventID: string;
}

class CalendarEvent implements CalendarEventData {
  id: string;
  title: string;
  hostEmail: string;
  guestEmail: string;
  start: string;
  recurringEventID: string;

  constructor(data: CalendarEventData) {
    this.id = data.id;
    this.title = data.title;
    this.hostEmail = data.hostEmail;
    this.guestEmail = data.guestEmail;
    this.start = data.start;
    this.recurringEventID = data.recurringEventID;
  }

  static buildCalendarEventDataFromResponse(response: any): CalendarEventData {
    return {
      id: response.id,
      title: response.title,
      hostEmail: response.host_email,
      guestEmail: response.guest_email,
      start: response.start,
      recurringEventID: response.recurring_event_id
    };
  }
}

export default CalendarEvent;
