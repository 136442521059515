import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from '../../hooks';
import { currencyAcronyms } from '../../common/constants';
import { LevelData } from '../../core/level/Level.model';
import { updateLevel } from '../../core/level/levelSlice';
import CurrencyAcronymsCombobox from '../CurrencyAcronymsCombobox';
import { Button } from '../ui/Button';

interface AssignSalaryRangeModalProps {
  levelData: LevelData;
  open: boolean;
  onClose: () => void;
}

const AssignSalaryRangeModal = ({
  levelData,
  open,
  onClose
}: AssignSalaryRangeModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);
  const [salaryRange, setSalaryRange] = useState({
    currency: levelData.salaryCurrency ?? currencyAcronyms[0],
    min: levelData.salaryMin ?? 0,
    max: levelData.salaryMax ?? 0
  });

  const organizationSlug = params.organizationSlug ?? '';
  const departmentID = params.departmentID ?? '';

  async function handleSetNewSalaryRange(): Promise<void> {
    const res: any = await dispatch(
      updateLevel({
        organizationSlug,
        departmentID,
        levelID: levelData.id,
        levelData: {
          ...levelData,
          salaryCurrency: salaryRange.currency,
          salaryMin: salaryRange.min,
          salaryMax: salaryRange.max
        }
      })
    );
    if (res.error) {
      return;
    }

    onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Salary Range
                  </Dialog.Title>
                  <div className="flex flex-col sm:flex-row mt-4 gap-4">
                    <CurrencyAcronymsCombobox
                      defaultValue={salaryRange.currency}
                      onChange={(newCurrency) => {
                        setSalaryRange({ ...salaryRange, currency: newCurrency });
                      }}
                    />
                    <div>
                      <label
                        htmlFor="minSalary"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Minimum Salary
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="minSalary"
                          id="minSalary"
                          defaultValue={salaryRange.min === 0 ? '' : salaryRange.min}
                          placeholder="70000"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                          onChange={(e) => {
                            setSalaryRange({ ...salaryRange, min: parseInt(e.target.value) });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="maxSalary"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Maximum Salary
                      </label>
                      <div className="mt-2">
                        <input
                          type="number"
                          name="maxSalary"
                          id="maxSalary"
                          defaultValue={salaryRange.max === 0 ? '' : salaryRange.max}
                          placeholder="80000"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                          onChange={(e) => {
                            setSalaryRange({ ...salaryRange, max: parseInt(e.target.value) });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-16"></div>
                <div className="flex mt-5 sm:mt-4 sm:flex-row-reverse gap-3">
                  <Button
                    onClick={() => {
                      handleSetNewSalaryRange();
                    }}>
                    Confirm
                  </Button>
                  <Button ref={cancelButtonRef} variant={'outline'} onClick={() => onClose()}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AssignSalaryRangeModal;
