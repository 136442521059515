import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { DepartmentData } from './Department.model';

type DepartmentState = {
  departments: DepartmentData[];
  areDepartmentsPending: boolean;
  areDepartmentsFetched: boolean;
  isDepartmentPending: boolean;
  error: api.ApiError;
};

const initialState: DepartmentState = {
  departments: [],
  areDepartmentsPending: true,
  areDepartmentsFetched: false,
  isDepartmentPending: false,
  error: null
};

const fetchDepartments = createAsyncThunk(
  'departments/getDepartments',
  async (args: api.GetDepartmentsArgs, { rejectWithValue }) => {
    try {
      return await api.getDepartments(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchDepartment = createAsyncThunk(
  'departments/getDepartment',
  async (args: api.GetDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.getDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createDepartment = createAsyncThunk(
  'departments/createDepartment',
  async (args: api.CreateDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.createDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateDepartment = createAsyncThunk(
  'departments/updateDepartment',
  async (args: api.UpdateDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.updateDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteDepartment = createAsyncThunk(
  'departments/deleteDepartment',
  async (args: api.GetDepartmentArgs, { rejectWithValue }) => {
    try {
      return await api.deleteDepartment(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createDevelopmentPath = createAsyncThunk(
  'departments/createDevelopmentPath',
  async (args: api.CreateDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.createDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const duplicateDevelopmentPath = createAsyncThunk(
  'departments/duplicateDevelopmentPath',
  async (args: api.GetDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.duplicateDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateDevelopmentPath = createAsyncThunk(
  'departments/updateDevelopmentPath',
  async (args: api.UpdateDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.updateDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteDevelopmentPath = createAsyncThunk(
  'departments/deleteDevelopmentPath',
  async (args: api.GetDevelopmentPathArgs, { rejectWithValue }) => {
    try {
      return await api.deleteDevelopmentPath(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const departmentSelector = (
  state: DepartmentState,
  departmentID: string | undefined
): DepartmentData | undefined => {
  return state.departments.find((department) => department.id === departmentID);
};

type UpdateDepartmentLevelName = {
  departmentID: string;
  developmentPathID: string;
  levelID: string;
  name: string;
  seniority: number;
};

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    updateDepartmentLevelNameAndSeniority(state, action: PayloadAction<UpdateDepartmentLevelName>) {
      let i = 0;
      let j = 0;
      let k = 0;

      let isSeniorityUpdated = false;
      let isSeniorityDecreased = false;
      // eslint-disable-next-line no-labels
      outerloop: for (; i < state.departments.length; i++) {
        const department = state.departments[i];
        if (department.id === action.payload.departmentID) {
          for (; j < department.developmentPaths.length; j++) {
            const developmentPath = department.developmentPaths[j];
            if (developmentPath.id === action.payload.developmentPathID) {
              for (; k < developmentPath.levels.length; k++) {
                const level = developmentPath.levels[k];

                if (level.id === action.payload.levelID) {
                  level.name = action.payload.name;
                  if (level.seniority !== action.payload.seniority) {
                    isSeniorityUpdated = true;
                    isSeniorityDecreased = level.seniority > action.payload.seniority;
                    level.seniority = action.payload.seniority;
                  }
                  // eslint-disable-next-line no-labels
                  break outerloop;
                }
              }
            }
          }
        }
      }

      if (isSeniorityUpdated) {
        let tmpLevel = state.departments[i].developmentPaths[j].levels[k];
        if (isSeniorityDecreased) {
          for (const developmentPathLevel of state.departments[i].developmentPaths[j].levels) {
            if (developmentPathLevel.seniority === tmpLevel.seniority) {
              developmentPathLevel.seniority++;
              tmpLevel = developmentPathLevel;
            }
          }
        } else {
          for (let l = state.departments[i].developmentPaths[j].levels.length - 1; l >= 0; l--) {
            if (
              state.departments[i].developmentPaths[j].levels[l].seniority === tmpLevel.seniority
            ) {
              state.departments[i].developmentPaths[j].levels[l].seniority--;
              tmpLevel = state.departments[i].developmentPaths[j].levels[l];
            }
          }
        }
      }

      state.departments[i].developmentPaths[j].levels.sort((a, b) => a.seniority - b.seniority);
    }
  },
  extraReducers: (builder) => {
    // Fetch departments
    builder.addCase(fetchDepartments.pending, (state) => {
      state.areDepartmentsPending = true;
      state.areDepartmentsFetched = false;
      state.error = null;
    });
    builder.addCase(fetchDepartments.rejected, (state, action) => {
      state.areDepartmentsPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch departments'));
    });
    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      state.departments = action.payload;
      state.areDepartmentsFetched = true;
      state.areDepartmentsPending = false;
      state.error = null;
    });

    // Fetch department
    builder.addCase(fetchDepartment.pending, (state) => {
      state.isDepartmentPending = true;
      state.error = null;
    });
    builder.addCase(fetchDepartment.rejected, (state, action) => {
      state.isDepartmentPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch department'));
    });
    builder.addCase(fetchDepartment.fulfilled, (state, action) => {
      let isUpdated = false;
      for (let i = 0; i < state.departments.length; i++) {
        if (state.departments[i].id === action.payload.id) {
          state.departments[i] = {
            ...state.departments[i],
            developmentPaths: action.payload.developmentPaths
          };
          isUpdated = true;
          break;
        }
      }
      if (!isUpdated) {
        state.departments.push({ ...action.payload });
      }

      state.isDepartmentPending = false;
      state.error = null;
    });

    // Create department
    builder.addCase(createDepartment.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to create department')
      );
    });
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      state.departments.push({ ...action.payload });
    });

    // Update department
    builder.addCase(updateDepartment.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to update department')
      );
    });
    builder.addCase(updateDepartment.fulfilled, (state, action) => {
      for (let i = 0; i < state.departments.length; i++) {
        if (state.departments[i].id === action.payload.id) {
          state.departments[i] = {
            ...state.departments[i],
            name: action.payload.name,
            updatedAt: action.payload.updatedAt
          };
          break;
        }
      }
    });

    // Delete department
    builder.addCase(deleteDepartment.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to delete department')
      );
    });
    builder.addCase(deleteDepartment.fulfilled, (state, action) => {
      state.departments = state.departments.filter(
        (department) => department.id !== action.meta.arg.departmentID
      );
    });

    // Create development path
    builder.addCase(createDevelopmentPath.rejected, (state, action) => {
      toast.error(
        getCustomUserError(
          action.payload as api.ApiError,
          'Failed to create career development path'
        )
      );
    });
    builder.addCase(createDevelopmentPath.fulfilled, (state, action) => {
      for (let i = 0; i < state.departments.length; i++) {
        if (state.departments[i].id === action.meta.arg.departmentID) {
          state.departments[i].developmentPaths.push({ ...action.payload });
          break;
        }
      }
    });

    // Duplicate development path
    builder.addCase(duplicateDevelopmentPath.rejected, (state, action) => {
      toast.error(
        getCustomUserError(
          action.payload as api.ApiError,
          'Failed to duplicate career development path'
        )
      );
    });

    // Update development path
    builder.addCase(updateDevelopmentPath.rejected, (state, action) => {
      toast.error(
        getCustomUserError(
          action.payload as api.ApiError,
          'Failed to update career development path'
        )
      );
    });
    builder.addCase(updateDevelopmentPath.fulfilled, (state, action) => {
      for (let i = 0; i < state.departments.length; i++) {
        if (state.departments[i].id === action.meta.arg.departmentID) {
          for (let j = 0; j < state.departments[i].developmentPaths.length; j++) {
            if (state.departments[i].developmentPaths[j].id === action.payload.id) {
              state.departments[i].developmentPaths[j] = {
                ...state.departments[i].developmentPaths[j],
                name: action.payload.name
              };
              break;
            }
          }
          break;
        }
      }
    });

    // Delete development path
    builder.addCase(deleteDevelopmentPath.rejected, (state, action) => {
      toast.error(
        getCustomUserError(
          action.payload as api.ApiError,
          'Failed to delete career development path'
        )
      );
    });
    builder.addCase(deleteDevelopmentPath.fulfilled, (state, action) => {
      for (let i = 0; i < state.departments.length; i++) {
        if (state.departments[i].id === action.meta.arg.departmentID) {
          state.departments[i].developmentPaths = state.departments[i].developmentPaths.filter(
            (developmentPath) => developmentPath.id !== action.meta.arg.developmentPathID
          );
          break;
        }
      }
    });
  }
});

export const { updateDepartmentLevelNameAndSeniority } = departmentsSlice.actions;

export default departmentsSlice.reducer;

export {
  fetchDepartments,
  fetchDepartment,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  createDevelopmentPath,
  duplicateDevelopmentPath,
  updateDevelopmentPath,
  deleteDevelopmentPath
};

export { departmentSelector };
