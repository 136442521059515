import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/24/outline';

import { classNames } from '../../common/utils';
import HorizontalLine from '../HorizontalLine';
import Avatar from './Avatar';

type AssignUser = {
  id: string;
  firstName: string;
  lastName: string;
  picture?: string | null;
};

interface AssignToUserMenuProps {
  users: Array<AssignUser | null>;
  defaultUser?: AssignUser | null;
  onChange: (user: AssignUser | null) => void;
}

const AssignToUserMenu = ({ users, defaultUser, onChange }: AssignToUserMenuProps): JSX.Element => {
  return (
    <Menu as="div" className="relative flex flex-col justify-center">
      <Menu.Button onClick={(e) => e.stopPropagation()} className="block">
        <Avatar size={6} user={defaultUser ?? null} emptyBorderDashed enableHover />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute cursor-pointer top-5 right-0 z-10 mt-0.5 w-fit min-w-44 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <p className="px-3 py-1 text-sm text-gray-900 font-medium">Assign to</p>
          <HorizontalLine />
          <Menu.Item>
            {({ active, close }) => (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                  close();
                }}
                className={classNames(
                  active ? 'bg-gray-50' : '',
                  `block px-3 py-1 text-sm leading-6 text-left text-gray-900`
                )}>
                <div className="flex flex-row items-center gap-2 whitespace-nowrap">
                  <UserIcon className="size-5 rounded-full border border-dashed border-gray-400 text-gray-400" />
                  <p>No Assigne</p>
                </div>
              </a>
            )}
          </Menu.Item>
          {users
            .filter((user) => user !== null)
            .map((user) => (
              <Menu.Item key={user?.id}>
                {({ active, close }) => (
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(user);
                      close();
                    }}
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      `block px-3 py-1 text-sm leading-6 text-left text-gray-900`
                    )}>
                    <div className="flex flex-row items-center gap-2 whitespace-nowrap">
                      <Avatar size={5} user={user} />
                      <p>
                        {user?.firstName} {user?.lastName}
                      </p>
                    </div>
                  </a>
                )}
              </Menu.Item>
            ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AssignToUserMenu;
