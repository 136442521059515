import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'sonner';

import { useAppSelector } from '../../core/store';
import { useAppDispatch } from '../../hooks';
import { Button } from '../ui/Button';
import {
  createOrganizationUser,
  fetchOrganizationUsers,
  inviteUserToOrganization
} from '../../core/employee/employeesSlice';
import LabeledInput from '../ui/LabeledInput';
import LevelsCombobox from '../LevelsCombobox';

interface AddEmployeeModalProps {
  open: boolean;
  onClose: () => void;
}

// TODO(boris): Add posability to invite multiple employees at once
const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();

  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);

  const cancelButtonRef = useRef(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    levelID: '',
    inviteUser: false
  });

  function closeEmployeeModal(): void {
    onClose();
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      levelID: '',
      inviteUser: false
    });
  }

  async function inviteUser(): Promise<void> {
    if (formData.firstName.length < 2 || formData.lastName.length < 2 || !formData.email) {
      toast.warning('Please fill all fields');
      return;
    }

    const res: any = await dispatch(createOrganizationUser({ organizationSlug, data: formData }));
    if (res.error) {
      return;
    }

    if (formData.inviteUser) {
      dispatch(inviteUserToOrganization({ organizationSlug, email: formData.email }));
    }

    dispatch(fetchOrganizationUsers({ organizationSlug }));
    closeEmployeeModal();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={closeEmployeeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                    Create new organization member
                  </Dialog.Title>
                  <div className="flex flex-col mt-4 gap-6">
                    <LabeledInput
                      label="First Name"
                      inputType="text"
                      placeholder="Jhon"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          firstName: e.target.value
                        });
                      }}
                    />
                    <LabeledInput
                      label="Last Name"
                      inputType="text"
                      placeholder="Smith"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          lastName: e.target.value
                        });
                      }}
                    />

                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900">
                        Select Level
                      </label>
                      <div className="mt-2">
                        <LevelsCombobox
                          onChange={(newLevel) => {
                            setFormData({
                              ...formData,
                              levelID: newLevel.id
                            });
                          }}
                        />
                      </div>
                    </div>

                    <LabeledInput
                      label="Email"
                      inputType="email"
                      placeholder="you@example.com"
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          email: e.target.value
                        });
                      }}
                    />

                    <div className="flex items-start justify-between">
                      <div className="flex items-center">
                        <input
                          id="inviteUser"
                          name="inviteUser"
                          type="checkbox"
                          checked={formData.inviteUser}
                          onChange={() => {
                            setFormData({
                              ...formData,
                              inviteUser: !formData.inviteUser
                            });
                          }}
                          className="size-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                          htmlFor="inviteUser"
                          className="ml-3 block text-sm leading-6 text-gray-900">
                          Send email invite to join the organization
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-5 sm:mt-8 sm:flex-row-reverse gap-3">
                  <Button
                    onClick={() => {
                      inviteUser();
                    }}>
                    Create user
                  </Button>
                  <Button
                    ref={cancelButtonRef}
                    variant={'outline'}
                    onClick={() => closeEmployeeModal()}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddEmployeeModal;
