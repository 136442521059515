import SkillLevel, { SkillLevelData } from '../library/skill/SkillLevel.model';
import User, { UserData } from '../user/User.model';

export interface LevelData {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  departmentID: string;
  /** @type {Generics.UUID} */
  developmentPathID: string;
  label: string;
  seniority: number;
  name: string;
  description?: string;
  salaryMin?: number;
  salaryMax?: number;
  salaryCurrency?: string;
  skills: SkillLevelData[];
  assignedUsers: UserData[];
}

class Level implements LevelData {
  id: string;
  departmentID: string;
  developmentPathID: string;
  label: string;
  seniority: number;
  name: string;
  description?: string;
  salaryMin?: number;
  salaryMax?: number;
  salaryCurrency?: string;
  skills: SkillLevelData[];
  assignedUsers: UserData[];

  constructor(data: LevelData) {
    this.id = data.id;
    this.departmentID = data.departmentID;
    this.developmentPathID = data.developmentPathID;
    this.label = data.label;
    this.seniority = data.seniority;
    this.name = data.name;
    this.description = data.description;
    this.salaryMin = data.salaryMin;
    this.salaryMax = data.salaryMax;
    this.salaryCurrency = data.salaryCurrency;
    this.skills = data.skills;
    this.assignedUsers = data.assignedUsers;
  }

  static buildLevelDataFromResponse(response: any): LevelData {
    const skills: SkillLevelData[] = (response.skills ?? []).map((skill: any) => {
      return SkillLevel.buildSkillLevelDataFromResponse(skill);
    });

    const assignedUsers: UserData[] = (response.assigned_users ?? []).map((user: any) => {
      return User.buildUserDataFromResponse(user);
    });

    return {
      id: response.id,
      departmentID: response.department_id,
      developmentPathID: response.development_path_id,
      label: response.label,
      seniority: response.seniority,
      name: response.name,
      description: response.description,
      salaryMin: response.salary_min,
      salaryMax: response.salary_max,
      salaryCurrency: response.salary_currency,
      skills,
      assignedUsers
    };
  }
}

export default Level;
