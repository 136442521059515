import { PerimssionRole } from '../../common/constants';
import User, { UserData } from '../user/User.model';
import EmployeeDepartment, { EmployeeDepartmentData } from './EmployeeDepartment.model';

export interface EmployeeData {
  /** @type {Generics.UUID} */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string;
  status: 'not_invited' | 'invitation_sent' | 'invitation_accepted';
  permissionRole: PerimssionRole;

  manager: UserData | null;
  department?: EmployeeDepartmentData;
}

class Employee implements EmployeeData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string;
  status: 'not_invited' | 'invitation_sent' | 'invitation_accepted';
  permissionRole: PerimssionRole;

  manager: UserData | null;
  department?: EmployeeDepartmentData;

  constructor(data: EmployeeData) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.picture = data.picture;
    this.status = data.status;
    this.permissionRole = data.permissionRole;
    this.manager = data.manager;
    this.department = data.department;
  }

  static buildEmployeeDataFromResponse(response: any): EmployeeData {
    let department;
    if (response.department) {
      department = EmployeeDepartment.buildEmployeeDepartmentDataFromResponse(response.department);
    }

    return {
      id: response.id,
      firstName: response.first_name,
      lastName: response.last_name,
      email: response.email,
      picture: response.picture,
      status: response.status,
      permissionRole: response.permissions.role,
      manager: response.manager ? User.buildUserDataFromResponse(response.manager) : null,
      department
    };
  }

  static toUserData(employee: EmployeeData): UserData {
    return {
      id: employee.id,
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      picture: employee.picture,
      isInternal: false,
      permissionRole: employee.permissionRole
    };
  }
}

export default Employee;
