import React, { useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import _ from 'lodash';

interface CheckboxInputItemProps {
  checked: boolean;
  checkable?: boolean;
  onCheck: (checked: boolean) => void;

  value: string;
  placeholder?: string;
  autoFocus?: boolean;
  changeable?: boolean;
  onChange: (value: string) => void;
}

const CheckboxInputItem = ({
  checked,
  checkable = true,
  onCheck,
  value,
  placeholder,
  autoFocus,
  changeable = true,
  onChange
}: CheckboxInputItemProps): JSX.Element => {
  const updateValue = useMemo(() => {
    return _.debounce((value: string) => {
      onChange(value);
    }, 500);
  }, []);

  return (
    <div className="flex-1 flex flex-row gap-3">
      <input
        type="checkbox"
        className="size-4 rounded mt-[3px] border-gray-300 text-indigo-600 focus:ring-indigo-600"
        checked={checked}
        disabled={!checkable}
        onChange={(e) => onCheck(e.target.checked)}
      />
      <TextareaAutosize
        className={`w-full p-0 m-0 text-md border-t-0 border-l-0 border-r-0 border-b-0 resize-none overflow-hidden focus:border-b-2 focus:border-spacing-4 focus:ring-0 focus:outline-none ${
          checked ? 'line-through text-gray-500 decoration-gray-500' : 'text-gray-900'
        }`}
        autoFocus={autoFocus}
        defaultValue={value}
        placeholder={placeholder}
        disabled={!changeable}
        onChange={(e) => updateValue(e.target.value)}
      />
    </div>
  );
};

export default CheckboxInputItem;
