import React from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';

import styles from '../styles';
import { useAppSelector } from '../core/store';
import Breadcrumbs from './Breadcrumbs';
import UserSettingsMenu from './user/UserSettingsMenu';

type ProfileHeaderNavigationProps = {
  activateSidebar?: boolean;
  sidebarOnClick?: () => void;
};

const ProfileHeaderNavigation = ({
  activateSidebar = false,
  sidebarOnClick = () => {}
}: ProfileHeaderNavigationProps): JSX.Element => {
  const breadcrumbsPages = useAppSelector((state) => state.breadcrumbs.pages);

  function showSidebar(show: boolean): string {
    return show ? '' : 'hidden';
  }

  return (
    <div className="sticky z-10 top-0 flex h-16 shrink-0 items-center border border-gray-200 bg-gray-50 px-4 shadow sm:px-6 lg:px-6 rounded-b-lg">
      <div className={`${showSidebar(activateSidebar)} flex flex-row gap-x-4`}>
        <button
          type="button"
          onClick={() => sidebarOnClick()}
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="size-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />
      </div>

      <div
        className={`${showSidebar(
          !activateSidebar
        )} flex flex-col h-16 shrink-0 justify-center mr-4 sm:mr-32`}>
        <Link to="/home">
          <h1 className={`${styles.fontFuturaPT} text-[28px] mb-1`}>Sprout</h1>
        </Link>
      </div>

      <div className="flex-none flex flex-col justify-center ml-2">
        <Breadcrumbs pages={breadcrumbsPages} />
      </div>

      <div className="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          {/* Separator */}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

          {/* Profile dropdown */}
          <UserSettingsMenu />
        </div>
      </div>
    </div>
  );
};

export default ProfileHeaderNavigation;
