import React, { Fragment, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { HttpStatusCode } from 'axios';
import _ from 'lodash';

import { useAppDispatch } from '../../hooks';
import { ProgressionLevelSkillData } from '../../core/progression/ProgressionLevelSkill.model';
import { UserData } from '../../core/user/User.model';
import VerticalLine from '../VerticalLine';
import GrowAreaUpdatesSection from '../grow-area/GrowAreaUpdatesSection';
import GrowAreaActionsSection from '../grow-area/GrowAreaActionsSection';
import WysiwygEditor from '../wysiwyg/WysiwygEditor';
import { useAppSelector } from '../../core/store';
import {
  createGrowArea,
  fetchProgressionSkillGrowArea,
  updateGrowArea
} from '../../core/grow-area/growAreasSlice';
import { ApiError } from '../../api';
import AppLoader from '../AppLoader';
import { classNames } from '../../common/utils';

type SkillGrowAreasSlideoverProps = {
  progressionUser: UserData;
  skillLevel: ProgressionLevelSkillData;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const SkillGrowAreasSlideover = ({
  progressionUser,
  skillLevel,
  open,
  setOpen
}: SkillGrowAreasSlideoverProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const organizationSlug = params.organizationSlug ?? '';

  const authUser = useAppSelector((state) => state.user);
  const isGrowAreaPending = useAppSelector((state) => state.growAreas.isGrowAreaPending);
  const selectedGrowArea = useAppSelector((state) => state.growAreas.selectedGrowArea);

  useEffect(() => {
    if (!open) {
      return;
    }

    dispatch(
      fetchProgressionSkillGrowArea({
        organizationSlug,
        progressionUserID: progressionUser.id,
        skillLevelID: skillLevel.skillLevelID
      })
    ).then((res: any) => {
      // If grow area does not exist, create it
      if (res.error && (res.payload as ApiError)?.statusCode === HttpStatusCode.NotFound) {
        dispatch(
          createGrowArea({
            organizationSlug,
            skillLevelID: skillLevel.skillLevelID,
            createdForID: progressionUser.id,
            name: `${skillLevel.skillName} - Grow Area`
          })
        );
      }
    });
  }, [open]);

  const updateGrowAreaDescriptionDebounced = useMemo(() => {
    return _.debounce((content: string) => {
      dispatch(
        updateGrowArea({
          organizationSlug,
          growAreaID: selectedGrowArea?.id ?? '',
          description: content
        })
      );
    }, 500);
  }, [selectedGrowArea?.id]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
        }}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex w-full md:w-2/3 lg:w-1/2 pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-full">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex flex-col gap-1 p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          <div className="flex flex-row gap-2">
                            {skillLevel.skillName}
                            <VerticalLine />
                            <span className="flex-initial flex items-center justify-center text-base font-medium text-indigo-700 bg-transparent">
                              L{skillLevel.seniority}
                            </span>
                            - Grow Area
                          </div>
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="size-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <p className="text-sm text-gray-500 italic">
                        Define and share clear, actionable steps for skill progress, linked to
                        current opportunities and personalized goals.
                      </p>
                    </div>

                    {isGrowAreaPending ? (
                      <div className="flex items-center justify-center h-96">
                        <AppLoader />
                      </div>
                    ) : (
                      <div className="flex-1 px-6 mb-4">
                        <div className="flex-1 flex flex-col gap-6 h-full">
                          <div className="flex flex-col gap-2">
                            <p className="font-medium">Description</p>
                            <WysiwygEditor
                              initialContent={selectedGrowArea?.description ?? ''}
                              placeholder={
                                'Describe the skill growth area linked to current opportunities and personalized goals'
                              }
                              stringHandler="html"
                              className={classNames(
                                authUser.id === selectedGrowArea?.createdFor?.manager?.id
                                  ? 'focus:ring-1 focus:ring-gray-400'
                                  : 'bg-gray-50',
                                'block w-full min-h-16 rounded-md border-none p-2 text-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none'
                              )}
                              editable={authUser.id === selectedGrowArea?.createdFor?.manager?.id}
                              onChange={(content) => {
                                updateGrowAreaDescriptionDebounced(content);
                              }}
                            />
                          </div>

                          <GrowAreaActionsSection
                            organizationSlug={organizationSlug}
                            createdForUser={progressionUser}
                          />

                          <div className="flex-1">
                            <GrowAreaUpdatesSection
                              organizationSlug={organizationSlug}
                              createdForUser={progressionUser}
                              skillLevel={skillLevel}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SkillGrowAreasSlideover;
