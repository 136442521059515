import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { classNames } from '../../common/utils';
import { MeetingData } from '../../core/one-on-one/Meeting.model';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import moment from 'moment-timezone';
import HorizontalLine from '../HorizontalLine';
import { meetingDateFormat } from '../../common/timezone';

interface MeetingsListboxProps {
  meetings: MeetingData[];
  defaultMeetingID?: string;
  onChange?: (meeting: MeetingData) => void;
}

const MeetingsListbox = ({
  meetings,
  defaultMeetingID,
  onChange = () => {}
}: MeetingsListboxProps): JSX.Element => {
  const [selected, setSelected] = useState(
    meetings.find((meeting) => meeting.id === defaultMeetingID)
  );

  useEffect(() => {
    if (defaultMeetingID) {
      setSelected(meetings.find((meeting) => meeting.id === defaultMeetingID));
    }
  }, [defaultMeetingID, meetings]);

  const timeNow = moment().local();
  const upcomingMeetings = meetings.filter((m) => moment(m.scheduledTime).local().isAfter(timeNow));
  const pastMeetings = meetings.filter((m) => moment(m.scheduledTime).local().isBefore(timeNow));

  return (
    <Listbox
      defaultValue={selected}
      onChange={(selected) => {
        setSelected(selected);
        onChange(selected);
      }}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-600 hover:text-gray-900 hover:bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm leading-6">
              <CalendarDaysIcon className="size-5" aria-hidden="true" />
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="size-6" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-64 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <p
                  className={`${
                    !upcomingMeetings.length ? 'hidden' : undefined
                  } px-3 py-1 text-gray-900 font-semibold`}>
                  Upcoming
                </p>
                {upcomingMeetings.map((meeting) => (
                  <Listbox.Option
                    key={meeting.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={meeting}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate'
                          )}>
                          {moment(meeting.scheduledTime).local().format(meetingDateFormat)}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}

                <div
                  className={
                    !upcomingMeetings.length || !pastMeetings.length ? 'hidden' : undefined
                  }>
                  <HorizontalLine />
                </div>

                <p
                  className={`${
                    !pastMeetings.length ? 'hidden' : undefined
                  } px-3 py-1 text-gray-900 font-semibold`}>
                  Past
                </p>
                {pastMeetings.map((meeting) => (
                  <Listbox.Option
                    key={meeting.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={meeting}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate'
                          )}>
                          {moment(meeting.scheduledTime).local().format(meetingDateFormat)}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}>
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default MeetingsListbox;
