import React from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

import Menu from './Menu';

interface EllipsisMenuItemProps {
  name: string;
  onClick: () => void;
  color?: string;
}

interface EllipsisMenuProps {
  direction?: 'left' | 'right';
  items: EllipsisMenuItemProps[];
}

const EllipsisMenu = ({ direction = 'left', items }: EllipsisMenuProps): JSX.Element => {
  return (
    <Menu
      buttonName={<EllipsisHorizontalIcon className="size-5" aria-hidden="true" />}
      direction={direction}
      items={items}
    />
  );
};

export default EllipsisMenu;
