import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from './Button';

type DisableButtonCb = (disable: boolean) => void;

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  description: string | JSX.Element;
  variant?: 'default' | 'destructive';
  onClose: () => void;
  onConfirm: (disableButton: DisableButtonCb) => void;
};

const ConfirmationDialog = ({
  open,
  title,
  description,
  variant = 'default',
  onClose,
  onConfirm
}: ConfirmationDialogProps): JSX.Element => {
  const cancelButtonRef = useRef(null);

  const [isContinueBtnDisabled, setIsContinueBtnDisabled] = React.useState(false);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          initialFocus={cancelButtonRef}
          onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-600">{description}</p>
                  </div>

                  <div className="flex flex-row justify-end gap-3 mt-4">
                    <Button
                      ref={cancelButtonRef}
                      variant={'outline'}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                      }}>
                      Cancel
                    </Button>
                    <Button
                      disabled={isContinueBtnDisabled}
                      variant={variant}
                      onClick={(e) => {
                        e.stopPropagation();
                        onConfirm(setIsContinueBtnDisabled);
                      }}>
                      Continue
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ConfirmationDialog;
