import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../../api';
import { getCustomUserError } from '../../../api/error';
import { SkillData } from './Skill.model';

type SkillsState = {
  skills: SkillData[];
  areSkillsFetched: boolean;
  areSkillsPending: boolean;
  isSkillPending: boolean;
  activeTab: 'organization-skills' | 'skill-library';
  error: api.ApiError;
};

const initialState: SkillsState = {
  skills: [],
  areSkillsFetched: false,
  areSkillsPending: true,
  isSkillPending: true,
  activeTab: 'organization-skills',
  error: null
};

const fetchLibrarySkills = createAsyncThunk(
  'librarySkills/getLibrarySkills',
  async (args: api.GetLibrarySkillsArgs, { rejectWithValue }) => {
    try {
      return await api.getLibrarySkills(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchLibrarySkill = createAsyncThunk(
  'librarySkills/getLibrarySkill',
  async (args: api.GetLibrarySkillArgs, { rejectWithValue }) => {
    try {
      return await api.getLibrarySkill(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createLibrarySkill = createAsyncThunk(
  'librarySkills/createLibrarySkill',
  async (args: api.CreateLibrarySkillArgs, { rejectWithValue }) => {
    try {
      return await api.createLibrarySkill(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateLibrarySkill = createAsyncThunk(
  'librarySkills/updateLibrarySkill',
  async (args: api.UpdateLibrarySkillArgs, { rejectWithValue }) => {
    try {
      return await api.updateLibrarySkill(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteLibrarySkill = createAsyncThunk(
  'librarySkills/deleteLibrarySkill',
  async (args: api.GetLibrarySkillArgs, { rejectWithValue }) => {
    try {
      return await api.deleteLibrarySkill(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const duplicateLibrarySkill = createAsyncThunk(
  'librarySkills/duplicateLibrarySkill',
  async (args: api.GetLibrarySkillArgs, { rejectWithValue }) => {
    try {
      return await api.duplicateLibrarySkill(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createLibrarySkillLevel = createAsyncThunk(
  'librarySkills/createLibrarySkillLevel',
  async (args: api.CreateLibrarySkillLevelArgs, { rejectWithValue }) => {
    try {
      return await api.createLibrarySkillLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateLibrarySkillLevel = createAsyncThunk(
  'librarySkills/updateLibrarySkillLevel',
  async (args: api.UpdateLibrarySkillLevelArgs, { rejectWithValue }) => {
    try {
      return await api.updateLibrarySkillLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteLibrarySkillLevel = createAsyncThunk(
  'librarySkills/deleteLibrarySkillLevel',
  async (args: api.DeleteLibrarySkillLevelArgs, { rejectWithValue }) => {
    try {
      return await api.deleteLibrarySkillLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const librarySkillSelector = (
  state: SkillsState,
  skillID: string | undefined
): SkillData | undefined => {
  return state.skills.find((skill) => skill.id === skillID);
};

const skillsSlice = createSlice({
  name: 'librarySkills',
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Fetch library skills
    builder.addCase(fetchLibrarySkills.pending, (state) => {
      state.areSkillsPending = true;
      state.error = null;
    });
    builder.addCase(fetchLibrarySkills.rejected, (state, action) => {
      state.areSkillsPending = false;
      state.error = action.payload as api.ApiError;

      toast.error(getCustomUserError(state.error, 'Failed to fetch library skills'));
    });
    builder.addCase(fetchLibrarySkills.fulfilled, (state, action) => {
      state.skills = action.payload.map((skill) => {
        return { ...skill, isFullyLoaded: false };
      });
      state.areSkillsPending = false;
      state.areSkillsFetched = true;
      state.error = null;
    });

    // Fetch library skill
    builder.addCase(fetchLibrarySkill.pending, (state) => {
      state.isSkillPending = true;
      state.error = null;
    });
    builder.addCase(fetchLibrarySkill.rejected, (state, action) => {
      state.isSkillPending = false;
      state.error = action.payload as api.ApiError;

      toast.error(getCustomUserError(state.error, 'Failed to fetch library skill'));
    });
    builder.addCase(fetchLibrarySkill.fulfilled, (state, action) => {
      let isUpdated = false;
      for (let i = 0; i < state.skills.length; i++) {
        if (state.skills[i].id === action.payload.id) {
          state.skills[i] = { ...action.payload, isFullyLoaded: true };
          isUpdated = true;
          break;
        }
      }
      if (!isUpdated) {
        state.skills.push({ ...action.payload, isFullyLoaded: true });
      }

      state.isSkillPending = false;
      state.error = null;
    });

    // Create library skill
    builder.addCase(createLibrarySkill.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to create library skill'));
    });
    builder.addCase(createLibrarySkill.fulfilled, (state, action) => {
      state.skills.unshift({ ...action.payload, isFullyLoaded: false });
    });

    // Update library skill
    builder.addCase(updateLibrarySkill.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to update library skill'));
    });
    builder.addCase(updateLibrarySkill.fulfilled, (state, action) => {
      state.skills = state.skills.map((skill) => {
        if (skill.id === action.payload.id) {
          return { ...action.payload, isFullyLoaded: true };
        }
        return skill;
      });
      toast.success('Skill updated successfully!');
    });

    // Delete library skill
    builder.addCase(deleteLibrarySkill.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to delete library skill'));
    });
    builder.addCase(deleteLibrarySkill.fulfilled, (state, action) => {
      state.skills = state.skills.filter((skill) => skill.id !== action.meta.arg.skillID);
    });

    // Duplicate library skill
    builder.addCase(duplicateLibrarySkill.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to duplicate library skill'));
    });
    builder.addCase(duplicateLibrarySkill.fulfilled, (state, action) => {
      state.skills.unshift({ ...action.payload, isFullyLoaded: true });
    });

    // Create library skill level
    builder.addCase(createLibrarySkillLevel.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to create library skill level'));
    });
    builder.addCase(createLibrarySkillLevel.fulfilled, (state, action) => {
      state.skills = state.skills.map((skill) => {
        if (skill.id === action.meta.arg.skillID) {
          return {
            ...skill,
            levels: [...skill.levels, action.payload]
          };
        }
        return skill;
      });
    });

    // Update library skill level
    builder.addCase(updateLibrarySkillLevel.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to update library skill level'));
    });
    builder.addCase(updateLibrarySkillLevel.fulfilled, (state, action) => {
      state.skills = state.skills.map((skill) => {
        if (skill.id === action.meta.arg.skillID) {
          return {
            ...skill,
            levels: skill.levels.map((level) => {
              if (level.id === action.payload.id) {
                return action.payload;
              }
              return level;
            })
          };
        }
        return skill;
      });
      toast.success('Skill level updated successfully!');
    });

    // Delete library skill level
    builder.addCase(deleteLibrarySkillLevel.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to delete library skill level'));
    });
    builder.addCase(deleteLibrarySkillLevel.fulfilled, (state, action) => {
      state.skills = state.skills.map((skill) => {
        if (skill.id === action.meta.arg.skillID) {
          return {
            ...skill,
            levels: skill.levels
              .filter((level) => level.id !== action.meta.arg.skillLevelID)
              .map((level, index) => {
                return { ...level, seniority: index + 1 };
              })
          };
        }
        return skill;
      });
    });
  }
});

export const { setActiveTab } = skillsSlice.actions;

export default skillsSlice.reducer;

export {
  fetchLibrarySkills,
  fetchLibrarySkill,
  createLibrarySkill,
  updateLibrarySkill,
  deleteLibrarySkill,
  duplicateLibrarySkill,
  createLibrarySkillLevel,
  updateLibrarySkillLevel,
  deleteLibrarySkillLevel
};

export { librarySkillSelector };
