import React, { Fragment, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';
import _ from 'lodash';

import { useAppDispatch } from '../../../hooks';
import { companySizesList } from '../../../common/constants';
import { useAppSelector } from '../../../core/store';
import {
  cloneLibraryFramework,
  createFrameworkDepartment,
  deleteFrameworkDepartment,
  deleteLibraryFramework,
  libraryFrameworkSelector,
  updateLibraryFramework
} from '../../../core/library/framework/frameworksSlice';
import PermissionProtectedAccess from '../../PermissionProtectedAccess';
import AppLoader from '../../AppLoader';
import VerticalLine from '../../VerticalLine';
import BasicListbox from '../../BasicListbox';
import HorizontalLine from '../../HorizontalLine';
import { Button } from '../../ui/Button';
import ConfirmationDialog from '../../ui/ConfirmationDialog';
import CreateNewDepartmentModal from '../../cdf/CreateNewDepartmentModal';
import { fetchDepartments } from '../../../core/cdf/departmentsSlice';
import { fetchLibrarySkills } from '../../../core/library/skill/skillsSlice';
import { Badge } from '../../ui/Badge';
import EllipsisMenu from '../../ui/EllipsisMenu';

type FrameworkLibraryOverviewProps = {
  frameworkID: string;
};

const FrameworkLibraryOverview = ({ frameworkID }: FrameworkLibraryOverviewProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const companySizeList = useRef(
    companySizesList.map((size) => ({ id: size.id, name: `${size.name} employees` }))
  );

  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);

  const isFrameworkPending = useAppSelector((state) => state.libraryFrameworks.isFrameworkPending);
  const framework = useAppSelector((state) =>
    libraryFrameworkSelector(state.libraryFrameworks, frameworkID)
  );

  const [deleteFrameworkBtnModalOpen, setDeleteFrameworkBtnModalOpen] = useState(false);
  const [useFrameworkBtnModalOpen, setUseFrameworkBtnModalOpen] = useState(false);
  const [createNewDepartmentModalOpen, setCreateNewDepartmentModalOpen] = useState(false);
  const [deleteDepartmentID, setDeleteDepartmentID] = useState('');
  const [deleteDepartmentBtnModalOpen, setDeleteDepartmentBtnModalOpen] = useState(false);

  function handleFrameworkCompanySizeChange(item: string): void {
    if (item.endsWith('+')) {
      dispatch(
        updateLibraryFramework({
          organizationSlug,
          frameworkID,
          data: {
            companySizeMin: +item.split('+')[0],
            companySizeMax: Infinity
          }
        })
      );
      return;
    }

    const [min, max] = item.split('-');
    dispatch(
      updateLibraryFramework({
        organizationSlug,
        frameworkID,
        data: {
          companySizeMin: +min,
          companySizeMax: +max
        }
      })
    );
  }

  const updateFrameworkDebouncedFn = useMemo(() => {
    return _.debounce((data: { name?: string; description?: string }) => {
      dispatch(
        updateLibraryFramework({
          organizationSlug,
          frameworkID,
          data
        })
      );
    }, 1000);
  }, [frameworkID]);

  async function onCreateDepartmentHandler(name: string): Promise<void> {
    const res: any = await dispatch(
      createFrameworkDepartment({ organizationSlug, frameworkID, name })
    );
    if (res.error) {
      return;
    }
    closeNewDepartmentModal();
  }

  async function cloneLibraryFrameworkHandler(
    disableButton: (disabled: boolean) => void
  ): Promise<void> {
    disableButton(true);

    const res: any = await dispatch(cloneLibraryFramework({ organizationSlug, frameworkID }));
    if (res.error) {
      disableButton(false);
      return;
    }

    dispatch(fetchDepartments({ organizationSlug }));
    dispatch(fetchLibrarySkills({ organizationSlug }));

    disableButton(false);
    setUseFrameworkBtnModalOpen(false);
    navigate(`/${organizationSlug}/cdf`);
  }

  function closeNewDepartmentModal(): void {
    setCreateNewDepartmentModalOpen(false);
  }

  function openNewDepartmentModal(): void {
    setCreateNewDepartmentModalOpen(true);
  }

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div className="flex-1 flex flex-col sm:mx-auto sm:w-full gap-3">
          {isFrameworkPending ? (
            <AppLoader />
          ) : !framework ? (
            <div className="text-center grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
              <h3 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                Framework not found
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                The framework you are looking for does not exist.
              </p>
            </div>
          ) : (
            <>
              <PermissionProtectedAccess
                minimalRequiredPermissionRole="editor"
                internalAccessRequired
                fallbackElement={
                  <Dialog.Title as="h3" className="font-semibold text-lg text-gray-900">
                    {framework.name}
                  </Dialog.Title>
                }>
                <input
                  type="text"
                  defaultValue={framework.name}
                  autoComplete="off"
                  className="font-semibold rounded-md text-xl h-8 text-gray-900 border-0 p-0 focus:p-1 w-[40rem]"
                  onChange={(e) => {
                    updateFrameworkDebouncedFn({ name: e.target.value });
                  }}
                />
              </PermissionProtectedAccess>

              <div className="flex flex-row gap-3">
                <p className="truncate text-sm text-gray-600 max-w-[100px] self-center">
                  @{framework.organization.name}
                </p>

                <VerticalLine />

                <PermissionProtectedAccess
                  minimalRequiredPermissionRole="editor"
                  internalAccessRequired
                  fallbackElement={
                    <p className="text-sm text-gray-600">
                      {framework.companySizeMin}-{framework.companySizeMax} employees
                    </p>
                  }>
                  <div className="flex flex-row gap-2">
                    <BasicListbox
                      items={companySizeList.current}
                      defaultItem={companySizeList.current.find(
                        (item) =>
                          item.id === `${framework.companySizeMin}-${framework.companySizeMax}`
                      )}
                      onChange={(selectedOption) => {
                        handleFrameworkCompanySizeChange(selectedOption.id);
                      }}
                    />
                  </div>
                </PermissionProtectedAccess>

                {framework.public && (
                  <PermissionProtectedAccess
                    minimalRequiredPermissionRole="editor"
                    internalAccessRequired>
                    <VerticalLine />
                    <div className="flex flex-col justify-center">
                      <Badge variant={'green'}>Published</Badge>
                    </div>
                  </PermissionProtectedAccess>
                )}
              </div>

              <div className="flex flex-row justify-start gap-3">
                <PermissionProtectedAccess
                  minimalRequiredPermissionRole="editor"
                  internalAccessRequired
                  fallbackElement={
                    <p className="text-base text-gray-600 ">{framework.description}</p>
                  }>
                  <TextareaAutosize
                    defaultValue={framework.description}
                    className="w-full text-base rounded-md text-gray-600 border-0 p-0 focus:px-0.5 max-h-[15rem]"
                    onChange={(e) => {
                      updateFrameworkDebouncedFn({ description: e.target.value });
                    }}
                  />
                </PermissionProtectedAccess>
              </div>

              <HorizontalLine />

              <div className="flex-1 max-h-full relative overflow-auto">
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5 p-1">
                  {framework.departments.map((department) => (
                    <li
                      key={department.id}
                      onClick={() => {
                        searchParams.set('departmentID', department.id);
                        setSearchParams(searchParams);
                      }}
                      className="relative overflow-hidden cursor-pointer rounded-xl border border-gray-200 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 h-[10rem] max-h-[10rem] min-h-[10rem]">
                      <div className="flex flex-row justify-center bg-gray-50 h-full">
                        <div className="flex-1 flex flex-col justify-center">
                          <p className="text-xl font-medium leading-6 text-gray-900 text-center">
                            {department.name}
                          </p>
                        </div>

                        <PermissionProtectedAccess
                          minimalRequiredPermissionRole="editor"
                          internalAccessRequired>
                          {!framework.public && (
                            <div className="absolute top-3 right-3">
                              <EllipsisMenu
                                items={[
                                  {
                                    name: 'Delete',
                                    onClick: () => {
                                      setDeleteDepartmentID(department.id);
                                      setDeleteDepartmentBtnModalOpen(true);
                                    },
                                    color: 'text-red-600'
                                  }
                                ]}
                              />
                            </div>
                          )}
                        </PermissionProtectedAccess>
                      </div>
                    </li>
                  ))}
                  <PermissionProtectedAccess
                    minimalRequiredPermissionRole="editor"
                    internalAccessRequired>
                    <li className="min-w-[12rem] h-[10rem] max-h-[10rem] min-h-[10rem] rounded-lg">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.currentTarget.blur();
                          openNewDepartmentModal();
                        }}
                        className="relative block w-full rounded-xl border-2 border-dashed border-gray-300 px-6 py-10 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <PlusIcon className="mx-auto size-12 text-gray-500" />
                        <span className="mt-2 block text-sm font-semibold text-gray-900">
                          Create new department
                        </span>
                      </button>
                    </li>
                  </PermissionProtectedAccess>
                </ul>
              </div>
            </>
          )}
        </div>

        <div className="flex-initial flex flex-col-reverse sm:flex-row justify-end gap-3 mt-8">
          {!framework?.public && (
            <PermissionProtectedAccess
              minimalRequiredPermissionRole="editor"
              internalAccessRequired>
              <Button
                variant={'destructive'}
                className="mb-4 sm:mb-0"
                onClick={() => setDeleteFrameworkBtnModalOpen(true)}>
                Delete
              </Button>
              <ConfirmationDialog
                open={deleteFrameworkBtnModalOpen}
                title={'Are you absolutely sure?'}
                description={
                  'This action cannot be undone. This will permanently delete this framework.'
                }
                variant="destructive"
                onClose={() => setDeleteFrameworkBtnModalOpen(false)}
                onConfirm={() => {
                  dispatch(deleteLibraryFramework({ organizationSlug, frameworkID }));
                  setDeleteFrameworkBtnModalOpen(false);
                  navigate('../');
                }}
              />
            </PermissionProtectedAccess>
          )}

          <div>
            <Button onClick={() => setUseFrameworkBtnModalOpen(true)}>Use this framework</Button>
            <ConfirmationDialog
              open={useFrameworkBtnModalOpen}
              title={'Are you sure?'}
              description={
                'This action cannot be undone. Proceeding will replace any existing Competencies departments with selected framework departments. Are you sure you want to continue?'
              }
              onClose={() => setUseFrameworkBtnModalOpen(false)}
              onConfirm={(disableButton) => {
                cloneLibraryFrameworkHandler(disableButton);
              }}
            />
          </div>
        </div>
      </div>
      <CreateNewDepartmentModal
        open={createNewDepartmentModalOpen}
        onCreate={(name) => {
          onCreateDepartmentHandler(name);
        }}
        onClose={closeNewDepartmentModal}
      />
      <ConfirmationDialog
        open={deleteDepartmentBtnModalOpen}
        title={'Are you absolutely sure?'}
        description={
          'This action cannot be undone. This will permanently delete the framework department, career development paths and levels inside it.'
        }
        variant="destructive"
        onClose={() => setDeleteDepartmentBtnModalOpen(false)}
        onConfirm={() => {
          dispatch(
            deleteFrameworkDepartment({
              organizationSlug,
              frameworkID,
              departmentID: deleteDepartmentID
            })
          );
          setDeleteDepartmentBtnModalOpen(false);
        }}
      />
    </>
  );
};

export default FrameworkLibraryOverview;
