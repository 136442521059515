import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FrameworkLibraryOverviewModal } from '../../components';

const FrameworkLibraryOverviewPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const frameworkID = params.frameworkID ?? '';

  const [open, setOpen] = useState(true);

  function closeModal(): void {
    setOpen(false);
    setTimeout(() => {
      navigate('../');
    }, 30);
  }

  return (
    <FrameworkLibraryOverviewModal
      open={open}
      setOpen={setOpen}
      frameworkID={frameworkID}
      onClose={closeModal}
    />
  );
};

export default FrameworkLibraryOverviewPage;
