export interface ProgressionLevelSkillData {
  /** @type {Generics.UUID} */
  skillLevelID: string;
  skillName: string;
  seniority: number;
  description?: string;
  examples?: string;
  status: string; // not_applicable, needs_improvement, as_expected, above_expectation
  isStarred: boolean;
  isNew: boolean;
}

class ProgressionLevelSkill implements ProgressionLevelSkillData {
  skillLevelID: string;
  skillName: string;
  seniority: number;
  description?: string;
  examples?: string;
  status: string; // not_applicable, needs_improvement, as_expected, above_expectation
  isStarred: boolean;
  isNew: boolean;

  constructor(data: ProgressionLevelSkillData) {
    this.skillLevelID = data.skillLevelID;
    this.skillName = data.skillName;
    this.seniority = data.seniority;
    this.description = data.description;
    this.examples = data.examples;
    this.status = data.status;
    this.isStarred = data.isStarred;
    this.isNew = data.isNew;
  }

  static buildProgressionLevelSkillDataFromResponse(response: any): ProgressionLevelSkillData {
    return {
      skillLevelID: response.skill_level_id,
      skillName: response.skill_name,
      seniority: response.seniority,
      description: response.description,
      examples: response.examples,
      status: response.status,
      isStarred: response.is_starred,
      isNew: false
    };
  }
}

export default ProgressionLevelSkill;
