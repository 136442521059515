import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { DEFAULT_FREE_PLAN_USER_QUOTA, OrganizationData } from './Organization.model';

type OrganizationState = {
  organizations: OrganizationData[];
  selectedOrganization: OrganizationData;

  areOrganizationsPending: boolean;
  isOrganizationPending: boolean;

  error: api.ApiError;
};

const initialState: OrganizationState = {
  organizations: [],
  selectedOrganization: {
    id: '',
    owner: {
      id: '',
      firstName: '',
      lastName: '',
      email: ''
    },
    slug: '',
    name: '',
    plan: 'free_plan',
    isPlanActive: true,
    userQuota: DEFAULT_FREE_PLAN_USER_QUOTA
  },

  areOrganizationsPending: true,
  isOrganizationPending: true,

  error: null
};

const fetchOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async (_, { rejectWithValue }) => {
    try {
      return await api.getOrganizations();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type FetchOrganizationBySlugArgs = {
  organizationSlug: string;
};

const fetchOrganizationBySlug = createAsyncThunk(
  'organization/getOrganizationBySlug',
  async ({ organizationSlug }: FetchOrganizationBySlugArgs, { rejectWithValue }) => {
    try {
      return await api.getOrganizationBySlug({ organizationSlug });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type CreateOrganizationArgs = {
  organizationSlug: string;
  organizationName: string;
};

const createOrganization = createAsyncThunk(
  'organization/createOrganization',
  async ({ organizationSlug, organizationName }: CreateOrganizationArgs, { rejectWithValue }) => {
    try {
      return await api.createOrganization({ organizationSlug, organizationName });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateOrganizationSettings = createAsyncThunk(
  'organization/updateOrganizationSettings',
  async (organization: OrganizationData, { rejectWithValue }) => {
    try {
      return await api.updateOrganization(organization);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch organizations
    builder.addCase(fetchOrganizations.pending, (state) => {
      state.areOrganizationsPending = true;
      state.error = null;
    });
    builder.addCase(fetchOrganizations.rejected, (state, action) => {
      state.areOrganizationsPending = false;
      state.error = action.payload as api.ApiError;
    });
    builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
      if (!state.selectedOrganization.id && action.payload.length > 0) {
        state.selectedOrganization = action.payload[0];
      }
      state.organizations = action.payload;
      state.areOrganizationsPending = false;
      state.error = null;
    });

    // Fetch organization by slug
    builder.addCase(fetchOrganizationBySlug.pending, (state) => {
      state.isOrganizationPending = true;
      state.error = null;
    });
    builder.addCase(fetchOrganizationBySlug.rejected, (state, action) => {
      state.isOrganizationPending = false;
      state.error = action.payload as api.ApiError;
    });
    builder.addCase(fetchOrganizationBySlug.fulfilled, (state, action) => {
      state.selectedOrganization = action.payload;
      state.isOrganizationPending = false;
      state.error = null;
    });

    // Create organization
    builder.addCase(createOrganization.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create organization'));
    });
    builder.addCase(createOrganization.fulfilled, (state, action) => {
      state.selectedOrganization = action.payload;
      state.organizations.push(action.payload);
    });

    // Update organization
    builder.addCase(updateOrganizationSettings.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update organization settings'));
    });
    builder.addCase(updateOrganizationSettings.fulfilled, (state, action) => {
      state.selectedOrganization.owner = action.payload.owner;
      state.selectedOrganization.slug = action.payload.slug;
      state.selectedOrganization.name = action.payload.name;
      state.selectedOrganization.logo = action.payload.logo;

      for (const organization of state.organizations) {
        if (organization.id === action.payload.id) {
          organization.owner = action.payload.owner;
          organization.slug = action.payload.slug;
          organization.name = action.payload.name;
          organization.logo = action.payload.logo;
          break;
        }
      }
    });
  }
});

export default organizationSlice.reducer;

export {
  fetchOrganizations,
  fetchOrganizationBySlug,
  createOrganization,
  updateOrganizationSettings
};
