import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../../lib/utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-full px-1.5 py-0.5 text-xs font-medium max-h-5',
  {
    variants: {
      variant: {
        gray: 'bg-gray-100 text-gray-600',
        red: 'bg-red-100 text-red-700',
        yellow: 'bg-yellow-100 text-yellow-800',
        green: 'bg-green-100 text-green-700',
        blue: 'bg-blue-100 text-blue-700',
        indigo: 'bg-indigo-100 text-indigo-700',
        purple: 'bg-purple-100 text-purple-700',
        pink: 'bg-pink-100 text-pink-700'
      }
    },
    defaultVariants: {
      variant: 'indigo'
    }
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps): JSX.Element {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
