import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { UserData } from './User.model';

type UserState = UserData;

const initialState: UserState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  isInternal: false,
  permissionRole: 'member'
};

const fetchUser = createAsyncThunk('user/getUser', async (_, { rejectWithValue }) => {
  try {
    return await api.getUser();
  } catch (err) {
    return rejectWithValue(err);
  }
});

const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (userInfo: UserData, { rejectWithValue }) => {
    try {
      return await api.updateUserProfile(userInfo);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const changePassword = createAsyncThunk(
  'user/changePassword',
  async (args: api.ChangePasswordArgs, { rejectWithValue }) => {
    try {
      return await api.changePassword(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const getOrganizationUserPermissionRole = createAsyncThunk(
  'user/getOrganizationUserPermissionRole',
  async (args: api.GetOrganizationBySlugArgs, { rejectWithValue }) => {
    try {
      return await api.getOrganizationUserPermissionRole(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch user
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.picture = action.payload.picture;
      state.isInternal = action.payload.isInternal;
    });

    // Update user profile
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update user profile'));
    });

    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    });

    // Get user organization permission role
    builder.addCase(getOrganizationUserPermissionRole.fulfilled, (state, action) => {
      state.permissionRole = action.payload;
    });

    builder.addCase(changePassword.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to change password'));
    });
  }
});

export default userSlice.reducer;

export {
  // User
  fetchUser,
  updateUserProfile,
  changePassword,
  getOrganizationUserPermissionRole
};
