import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import slugify from 'slugify';

import { useAppDispatch } from '../../hooks';
import { fetchOrganizations, createOrganization } from '../../core/organization/organizationSlice';
import {
  Badge,
  BasicListbox,
  Button,
  ErrorAlert,
  FrameworkLibraryOverviewModal,
  ProfileHeaderNavigation
} from '../../components';
import { HttpStatusCode } from '../../api';
import { companySizesList } from '../../common/constants';
import { fetchLibraryFrameworks } from '../../core/library/framework/frameworksSlice';
import { FrameworkData } from '../../core/library/framework/Framework.model';
import { useAppSelector } from '../../core/store';

type FormDataType = {
  organizationName: string;
  organizationSlug: string;
};

const OnboardingPage = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const companySizeList = useRef(
    companySizesList.map((size) => ({ id: size.id, name: `${size.name} employees` }))
  );

  const libraryFrameworks = useAppSelector((state) => state.libraryFrameworks.frameworks);

  const [errorMessage, setErrorMessage] = useState('');
  const [onboardingFailed, setOnboardingFailed] = useState(false);
  const [formData, setFormData] = useState<FormDataType>({
    organizationName: '',
    organizationSlug: ''
  });
  const [companySize, setCompanySize] = useState<string>();
  const [openRecommendedFrameworkModal, setOpenRecommendedFrameworkModal] = useState(false);

  const handleFormUpdate = (field: string, value: any): void => {
    setFormData((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = async (): Promise<void> => {
    setOnboardingFailed(false);
    setErrorMessage('');

    if (!companySize) {
      setOnboardingFailed(true);
      setErrorMessage('Company size is required');
      return;
    }

    const response: any = await dispatch(createOrganization({ ...formData }));
    if (response.error) {
      setOnboardingFailed(true);

      if (response.payload?.statusCode === HttpStatusCode.CONFLICT) {
        setErrorMessage('Organization slug already exists');
        return;
      }
      setErrorMessage(response.payload?.message ?? 'Organization creation failed');
      return;
    }

    await dispatch(fetchOrganizations());
    const libFrameworksRes: any = await dispatch(
      fetchLibraryFrameworks({ organizationSlug: response.payload?.slug })
    );

    const _libraryFrameworks = libFrameworksRes.payload as FrameworkData[];

    const recommendedFramework = _libraryFrameworks.find(
      (framework) => `${framework.companySizeMin}-${framework.companySizeMax}` === companySize
    );
    if (!recommendedFramework) {
      navigate('/home');
      return;
    }

    setSearchParams({ step: '2' });
  };

  if (searchParams.get('step') === '2') {
    return (
      <>
        <div className="flex min-h-full flex-1 flex-col">
          <ProfileHeaderNavigation />

          <div className="flex flex-1 flex-col py-12 sm:px-6 lg:px-8 bg-gray-50">
            <div className="sm:mx-auto sm:w-full sm:max-w-[36rem]">
              <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Select Your Ideal Competency Framework
              </h2>
            </div>

            <div className="flex-1 mt-6 sm:mx-auto sm:w-full sm:max-w-[45rem]">
              <div className="flex flex-col gap-4 bg-white p-6 shadow sm:rounded-lg">
                <p className="text-gray-600 text-sm font-medium italic">
                  {`Kickstart your journey with a competency framework tailored to your organization's size, or
                  customize your own to fit your unique needs.`}
                </p>

                <div className="flex flex-col sm:flex-row justify-between gap-6 mt-3">
                  {/* Recommended Competency Framework Option */}
                  <button
                    type="button"
                    onClick={() => {
                      setOpenRecommendedFrameworkModal(true);
                    }}
                    className="flex-initial w-[20rem]">
                    <div className="flex flex-col gap-3 justify-between px-5 pt-3 pb-5 cursor-pointer rounded-xl border border-gray-200 shadow-sm hover:bg-indigo-50/50 hover:border-indigo-500">
                      <div className="flex flex-row justify-center">
                        <Badge className="text-sm p-3" variant="indigo">
                          Recommended
                        </Badge>
                      </div>

                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row justify-center">
                          <p className="text-gray-900 font-medium max-w-60 text-center">
                            Optimized Competency Framework for Your Company Size
                          </p>
                        </div>
                        <div className="flex flex-row justify-center">
                          <p className="text-sm text-gray-600 italic">
                            Click the card to sneak a peek at the Competency Framework
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>

                  {/* Custom Competency Framework Option */}
                  <button
                    type="button"
                    onClick={() => {
                      navigate('/home');
                    }}
                    className="flex-initial w-[20rem] rounded-xl border-2 border-dashed border-gray-300 px-6 py-5 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <p className="mt-2 block text-gray-900 font-medium">
                      Create Your Own Competencies
                    </p>
                    <p className="mt-2 text-sm text-gray-900 font-medium">
                      Customize a framework that fits your needs
                    </p>
                  </button>
                </div>

                <p className="text-gray-600 text-sm italic text-right mt-2">
                  No stress! Pick whatever feels right now; you can always switch or customize your
                  competencies later.
                </p>
              </div>
            </div>
          </div>
        </div>

        <FrameworkLibraryOverviewModal
          open={openRecommendedFrameworkModal}
          setOpen={setOpenRecommendedFrameworkModal}
          frameworkID={
            libraryFrameworks.find(
              (framework) =>
                `${framework.companySizeMin}-${framework.companySizeMax}` === companySize
            )?.id ?? ''
          }
        />
      </>
    );
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col">
        <ProfileHeaderNavigation />

        <div className="flex flex-1 flex-col py-12 sm:px-6 lg:px-8 bg-gray-50">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Enter your organization information
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div
              className={`bg-white px-6 ${
                onboardingFailed ? 'pb-12 pt-3' : 'py-12'
              } shadow sm:rounded-lg sm:px-12`}>
              {onboardingFailed && (
                <div className="my-4">
                  <ErrorAlert>{errorMessage}</ErrorAlert>
                </div>
              )}

              <form
                className="space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <div>
                  <label
                    htmlFor="organizationName"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Organization name
                  </label>
                  <div className="mt-2">
                    <input
                      id="organizationName"
                      name="organizationName"
                      type="text"
                      autoComplete="off"
                      value={formData.organizationName}
                      onChange={(e) => {
                        handleFormUpdate('organizationName', e.target.value);
                        handleFormUpdate(
                          'organizationSlug',
                          slugify(e.target.value, { lower: true })
                        );
                      }}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="organizationSlug"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Organization slug
                  </label>
                  <div className="mt-2">
                    <input
                      id="organizationSlug"
                      name="organizationSlug"
                      type="text"
                      autoComplete="off"
                      value={formData.organizationSlug}
                      onChange={(e) => handleFormUpdate('organizationSlug', e.target.value)}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm bg-neutral-100"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="companySize"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Company Size
                  </label>
                  <div className="mt-2">
                    <BasicListbox
                      items={companySizeList.current}
                      onChange={(selectedOption) => {
                        setCompanySize(selectedOption.id);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingPage;
