import React, { useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';

import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import HorizontalLine from '../HorizontalLine';
import CheckboxInputItem from '../one-on-one/CheckboxInputItem';
import { UserData } from '../../core/user/User.model';
import {
  createGrowAreaAction,
  deleteGrowAreaAction,
  updateGrowAreaAction
} from '../../core/grow-area/growAreasSlice';

type GrowAreaActionsSectionProps = {
  organizationSlug: string;
  createdForUser: UserData;
};

const GrowAreaActionsSection = ({
  organizationSlug,
  createdForUser
}: GrowAreaActionsSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const selectedGrowArea = useAppSelector((state) => state.growAreas.selectedGrowArea);

  const [autoFocusItem, setAutoFocusItem] = useState(-1);

  const growAreaActions = selectedGrowArea?.actions ?? [];
  const isAuthUserManager = authUser.id === selectedGrowArea?.createdFor?.manager?.id;

  const updateGrowAreaActionDebounced = useMemo(() => {
    return _.debounce((growAreaActionID: string, content: string) => {
      dispatch(
        updateGrowAreaAction({
          organizationSlug,
          growAreaID: selectedGrowArea?.id ?? '',
          growAreaActionID,
          description: content
        })
      );
    }, 500);
  }, [selectedGrowArea?.id]);

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-1">
        <p className="font-medium mb-1">Grow area actions</p>
        <HorizontalLine />
      </div>

      {!isAuthUserManager && !growAreaActions.length && (
        <p className="text-md text-gray-400 italic"> No grow area actions yet</p>
      )}

      {growAreaActions.map((growAreaAction, index) => (
        <div key={growAreaAction.id} className="flex flex-row justify-between gap-6 items-start">
          <CheckboxInputItem
            checked={growAreaAction.completed}
            checkable={isAuthUserManager}
            onCheck={(checked) => {
              dispatch(
                updateGrowAreaAction({
                  organizationSlug,
                  growAreaID: selectedGrowArea?.id ?? '',
                  growAreaActionID: growAreaAction.id,
                  completed: checked
                })
              );
            }}
            value={growAreaAction.description}
            placeholder="Write grow area..."
            autoFocus={autoFocusItem === index}
            changeable={isAuthUserManager}
            onChange={(value) => {
              updateGrowAreaActionDebounced(growAreaAction.id, value);
            }}
          />

          <div className={`${isAuthUserManager ? 'flex' : 'hidden'} flex-row gap-2 items-center`}>
            <button
              className="rounded-md items-center p-0.5 text-red-500 hover:text-red-600 hover:bg-gray-50"
              onClick={() => {
                dispatch(
                  deleteGrowAreaAction({
                    organizationSlug,
                    growAreaID: selectedGrowArea?.id ?? '',
                    growAreaActionID: growAreaAction.id
                  })
                );
              }}>
              <XMarkIcon className="size-5" />
            </button>
          </div>
        </div>
      ))}

      {/* Add talking point button */}
      <div className={`${isAuthUserManager ? 'flex' : 'hidden'} flex-row gap-2 items-center`}>
        <button
          className="flex flex-row gap-2 items-center pr-1.5 text-md text-gray-500 hover:text-gray-900 whitespace-nowrap"
          onClick={() => {
            setAutoFocusItem(growAreaActions.length ?? 0);
            dispatch(
              createGrowAreaAction({
                organizationSlug,
                growAreaID: selectedGrowArea?.id ?? '',
                description: ''
              })
            );
          }}>
          <PlusIcon className="size-5" />
          Add grow area action
        </button>
      </div>
    </div>
  );
};

export default GrowAreaActionsSection;
