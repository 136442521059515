import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

import { classNames } from '../../../common/utils';
import { SkillLevelData } from '../../../core/library/skill/SkillLevel.model';
import WysiwygEditor from '../../wysiwyg/WysiwygEditor';
import { Button } from '../../ui/Button';

export type SkillLevelOnChangeFn = (key: 'description' | 'examples', value: string) => void;

type SkillLevelDescriptionProps = {
  skillLevelData: SkillLevelData;
  editable?: boolean;
  expandable?: boolean;
  onChange?: SkillLevelOnChangeFn;
};

const SkillLevelDescription = ({
  skillLevelData,
  editable = false,
  expandable = false,
  onChange = () => {}
}: SkillLevelDescriptionProps): JSX.Element => {
  const [isExpended, setIsExpended] = useState(false);

  return (
    <div className="flex flex-col items-start w-full">
      <div
        className={`flex flex-col gap-2 w-full ${
          expandable && !isExpended ? 'max-h-12 overflow-hidden' : ''
        }`}>
        <WysiwygEditor
          initialContent={skillLevelData.description}
          placeholder={editable ? 'Add Level Definition...' : ''}
          stringHandler="html"
          editable={editable}
          className={classNames(editable ? 'focus:p-1 focus:border' : '', 'flex-1 text-base')}
          onChange={(msg) => {
            onChange('description', msg);
          }}
        />
        <Disclosure
          as="div"
          className={!editable && !skillLevelData.examples ? 'hidden' : 'flex-1'}>
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between text-left font-semibold text-sm text-indigo-600 hover:text-indigo-500">
                  <div className="flex-1 flex flex-row justify-between w-full">
                    <div className="flex-1 flex flex-col justify-center">
                      <span className="text-sm">{open ? `Hide examples` : `View examples`}</span>
                    </div>
                    <span className="flex h-7 items-center">
                      {open ? (
                        <MinusSmallIcon className="size-5" aria-hidden="true" />
                      ) : (
                        <PlusSmallIcon className="size-5" aria-hidden="true" />
                      )}
                    </span>
                  </div>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <WysiwygEditor
                  initialContent={skillLevelData.examples}
                  placeholder={editable ? 'Add examples...' : ''}
                  stringHandler="html"
                  editable={editable}
                  className={classNames(
                    editable ? 'focus:p-1 focus:border' : '',
                    'flex-1 text-base'
                  )}
                  onChange={(msg) => {
                    onChange('examples', msg);
                  }}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className={expandable ? 'block' : 'hidden'}>
        <Button
          variant="link"
          size="sm"
          className="px-0"
          onClick={() => setIsExpended(!isExpended)}>
          {isExpended ? 'Show less' : 'Show more'}
        </Button>
      </div>
    </div>
  );
};

export default SkillLevelDescription;
