import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';

import { SkillData } from '../../../core/library/skill/Skill.model';
import { useAppSelector } from '../../../core/store';
import { createLibrarySkill } from '../../../core/library/skill/skillsSlice';
import { useAppDispatch } from '../../../hooks';
import HorizontalLine from '../../HorizontalLine';
import HorizontalLineWithButton from '../../HorizontalLineWithButton';
import SkillLevelDescription, { SkillLevelOnChangeFn } from './SkillLevelDescription';
import { Button } from '../../ui/Button';

interface CreateNewSkillModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateNewSkillModal = ({ open, onClose }: CreateNewSkillModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);

  const [formData, setFormData] = useState<SkillData>({
    id: '',
    createdBy: '',
    organization: { name: '' },
    name: '',
    description: '',
    levels: [
      {
        id: uuidv4(),
        seniority: 1,
        description: '',
        examples: ''
      }
    ],
    private: true,
    isFullyLoaded: false
  });

  function onSkillLevelDataChange(index: number): SkillLevelOnChangeFn {
    return (key: 'description' | 'examples', value: string) => {
      const newLevels = [...formData.levels];
      newLevels[index][key] = value;
      newLevels[index].seniority = index + 1;
      setFormData({ ...formData, levels: newLevels });
    };
  }

  async function handleCreate(): Promise<void> {
    const res: any = await dispatch(createLibrarySkill({ organizationSlug, skillData: formData }));
    if (res.error) {
      return;
    }

    resetFormData();
    onClose();
  }

  function resetFormData(): void {
    setFormData({
      id: '',
      createdBy: '',
      organization: { name: '' },
      name: '',
      description: '',
      levels: [
        {
          id: uuidv4(),
          seniority: 1,
          description: '',
          examples: ''
        }
      ],
      private: true,
      isFullyLoaded: false
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[52]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          onClose();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all max-h-full sm:max-h-[50rem] min-w-full md:min-w-[50rem] sm:max-w-lg sm:my-8 sm:p-6">
                <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-[50rem]">
                  <div className="flex flex-row justify-between">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Skill Name"
                      value={formData.name}
                      className="flex-1 mr-5 font-semibold rounded-md text-lg h-8 text-gray-900 border-0 p-0 focus:p-1"
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value });
                      }}
                    />

                    <div className="flex flex-row gap-3">
                      <Button
                        ref={cancelButtonRef}
                        variant={'outline'}
                        onClick={() => {
                          resetFormData();
                          onClose();
                        }}>
                        Close
                      </Button>
                      <Button
                        onClick={() => {
                          handleCreate();
                        }}>
                        Create
                      </Button>
                    </div>
                  </div>

                  <TextareaAutosize
                    value={formData.description}
                    placeholder="Add Description..."
                    className="text-base rounded-md text-gray-600 my-5 border-0 p-0 focus:px-0.5"
                    minRows={2}
                    onChange={(e) => {
                      setFormData({ ...formData, description: e.target.value });
                    }}
                  />
                  <div className="mb-4">
                    <HorizontalLine />
                  </div>

                  <ul role="list" className="flex flex-col flex-nowrap gap-4">
                    {formData.levels.map((level, index) => (
                      <li
                        key={level.id}
                        className="flex flex-col gap-2 p-3 rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                        <div className="flex flex-row justify-between">
                          <h2 className="font-semibold text-lg text-gray-900">
                            Level {level.seniority}
                          </h2>
                          <Button
                            variant={'link'}
                            className="text-red-500"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                levels: formData.levels
                                  .filter((_level) => _level.id !== level.id)
                                  .map((_level, index) => {
                                    _level.seniority = index + 1;
                                    return _level;
                                  })
                              });
                            }}>
                            Remove
                          </Button>
                        </div>
                        <SkillLevelDescription
                          skillLevelData={level}
                          editable={true}
                          onChange={onSkillLevelDataChange(index)}
                        />
                      </li>
                    ))}
                  </ul>
                  <div className="mt-5">
                    <HorizontalLineWithButton
                      buttonText="Add new Level Definition"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          levels: [
                            ...formData.levels,
                            {
                              id: uuidv4(),
                              seniority: formData.levels.length + 1,
                              description: '',
                              examples: ''
                            }
                          ]
                        });
                      }}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNewSkillModal;
