import React, { Fragment, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox, Transition } from '@headlessui/react';

import { currencyAcronyms } from '../common/constants';
import { classNames } from '../common/utils';

type CurrencyAcronymsComboboxProps = {
  defaultValue?: string;
  onChange?: (currencyAcronym: string) => void;
};

const CurrencyAcronymsCombobox = ({
  defaultValue,
  onChange = () => {}
}: CurrencyAcronymsComboboxProps): JSX.Element => {
  const [query, setQuery] = useState('');
  const [currencyAcronym, setCurrencyAcronym] = useState(defaultValue ?? 'USD');

  const filteredCurrencyAcronyms =
    query === ''
      ? currencyAcronyms
      : currencyAcronyms.filter((currencyAcronym) => {
          return currencyAcronym.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      defaultValue={currencyAcronym}
      onChange={(newValue) => {
        setCurrencyAcronym(newValue);
        onChange(newValue);
      }}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Currency
      </Combobox.Label>
      <div className="relative mt-2">
        <div>
          <Combobox.Input
            className="w-full sm:w-28 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(currencyAcronym: string) => currencyAcronym}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}>
          <Combobox.Options className="absolute z-10 mt-1 w-full sm:w-28 max-h-32 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredCurrencyAcronyms.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found
              </div>
            ) : (
              filteredCurrencyAcronyms.map((filteredCurrencyAcronym) => (
                <Combobox.Option
                  key={filteredCurrencyAcronym}
                  value={filteredCurrencyAcronym}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }>
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>
                        {filteredCurrencyAcronym}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}>
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default CurrencyAcronymsCombobox;
