import Level, { LevelData } from './Level.model';

export interface DevelopmentPathData {
  /** @type {Generics.UUID} */
  id: string;
  name: string;
  levels: LevelData[];
}

class DevelopmentPath implements DevelopmentPathData {
  id: string;
  name: string;
  levels: LevelData[];

  constructor(data: DevelopmentPathData) {
    this.id = data.id;
    this.name = data.name;
    this.levels = data.levels;
  }

  static buildDevelopmentPathDataFromResponse(response: any): DevelopmentPathData {
    const levels: LevelData[] = (response.levels ?? []).map((level: any) => {
      return Level.buildLevelDataFromResponse(level);
    });

    return {
      id: response.id,
      name: response.name,
      levels
    };
  }
}

export default DevelopmentPath;
