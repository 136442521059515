import React, { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';

import { useAppDispatch, useAppSelector } from '../../core/store';
import { userActions } from '../../core/actions';
import { classNames } from '../../common/utils';
import {
  SettingsItem,
  SettingsItemWithEditButton,
  ProfileHeaderNavigation,
  Button,
  ChangePasswordModal
} from '../../components';
import { UserData } from '../../core/user/User.model';

const secondaryNavigation = [{ name: 'Profile', icon: UserCircleIcon, current: true }];

const UserProfilePage = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  async function updateUserProfile(data: UserData): Promise<void> {
    await dispatch(userActions.updateUserProfile(data));
  }

  return (
    <>
      <ProfileHeaderNavigation />

      <div className="mx-auto max-w-7xl lg:flex lg:flex-col lg:gap-x-16 lg:px-8">
        <div className="lg:flex lg:flex-row">
          <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-4">
            <nav className="flex-none px-4 sm:px-6 lg:px-0">
              <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                {secondaryNavigation.map((item) => (
                  <li key={item.name}>
                    <a
                      className={classNames(
                        item.current
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                      )}>
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-indigo-600'
                            : 'text-gray-400 group-hover:text-indigo-600',
                          'size-6 shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </aside>

          <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-4">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <SettingsItemWithEditButton
                    labelName="First Name"
                    value={user.firstName}
                    onClick={(newValue) => {
                      updateUserProfile({
                        ...user,
                        firstName: newValue
                      });
                    }}
                  />
                  <SettingsItemWithEditButton
                    labelName="Last Name"
                    value={user.lastName}
                    onClick={(newValue) => {
                      updateUserProfile({
                        ...user,
                        lastName: newValue
                      });
                    }}
                  />
                  <SettingsItem labelName="Email" value={user.email} />

                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 self-center">
                      Password
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <p className="text-gray-900 text-xs self-center">●●●●●●●●●●●●●</p>
                      <Button
                        variant={'link'}
                        onClick={(e) => {
                          setChangePasswordModalOpen(true);
                        }}>
                        Change
                      </Button>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </main>
        </div>
      </div>

      <ChangePasswordModal
        open={changePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
      />
    </>
  );
};

export default UserProfilePage;
