import React, { useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { departmentSelector } from '../../core/cdf/departmentsSlice';
import { Page, setBreadcrumbsPages } from '../../core/breadcrumb/breadcrumbsSlice';
import { DepartmentData } from '../../core/cdf/Department.model';
import { LevelData } from '../../core/level/Level.model';

type BreadcrumbsRouterProps = {
  pages: Page[];
  element: JSX.Element;
};

const BreadcrumbsRouter = ({ pages, element }: BreadcrumbsRouterProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const department = useAppSelector((state) =>
    departmentSelector(state.departments, params.departmentID)
  );
  const level = useAppSelector((state) => state.level.level);

  useEffect(() => {
    const filledPages = fillBreadcrumbsPages(pages, department, level, params);
    dispatch(setBreadcrumbsPages(filledPages));
  }, [pages, department, level]);

  return <>{element}</>;
};

export function fillBreadcrumbsPages(
  pages: Page[],
  department: DepartmentData | undefined,
  level: LevelData | null,
  params: Readonly<Params<string>>
): Page[] {
  pages = pages.map((page) => {
    let updatedName = page.name;
    if (page.name.startsWith(':')) {
      switch (page.name) {
        case ':departmentID':
          updatedName = department?.name ?? ':departmentID';
          break;
        case ':levelID':
          updatedName = level?.label ?? ':levelID';
          break;
        default:
          updatedName = params[page.name.slice(1)] ?? page.name;
          break;
      }
    }

    const updatedHref = page.href
      .split('/')
      .map((part) => {
        if (part.startsWith(':')) {
          return params[part.slice(1)];
        }
        return part;
      })
      .join('/');

    return {
      name: updatedName,
      href: updatedHref,
      current: updatedHref === window.location.pathname
    };
  });

  return pages;
}

export default BreadcrumbsRouter;
