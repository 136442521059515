import React, { Fragment, useEffect, useState } from 'react';
import { Outlet, NavLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import {
  Cog6ToothIcon,
  BeakerIcon,
  XMarkIcon,
  PuzzlePieceIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
  CubeTransparentIcon,
  BuildingOffice2Icon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { TbTargetArrow } from 'react-icons/tb';

import styles from '../styles';
import { checkPermissionAccess, classNames } from '../common/utils';
import {
  ProfileHeaderNavigation,
  NotFoundComponent,
  PermissionProtectedAccess,
  VerticalLine,
  HorizontalLine,
  Avatar,
  Button,
  ScreenErrorComponent,
  CreateNewOneOnOneModal,
  UserSettingsMenu
} from '../components';
import OneOnOne, { OneOnOneData } from '../core/one-on-one/OneOnOne.model';
import { useAppDispatch, useAppSelector } from '../core/store';
import { fetchOrganizationBySlug } from '../core/organization/organizationSlice';
import { InternalServerErrorPage } from './public';
import { HttpStatusCode } from '../api';
import { getOrganizationUserPermissionRole } from '../core/user/userSlice';
import { fetchOneOnOnes } from '../core/one-on-one/oneOnOneSlice';
import { fetchMyTeamMembers } from '../core/team/teamsSlice';

const library = [
  { name: 'Skills', href: 'library/skills', icon: PuzzlePieceIcon },
  { name: 'Frameworks', href: 'library/frameworks', icon: CubeTransparentIcon }
];

type NavigationItemProps = {
  to: string;
  icon: any;
  name: string;
  onClick?: () => void;
};

const NavigationItem = ({
  to,
  icon: _icon,
  name,
  onClick = () => {}
}: NavigationItemProps): JSX.Element => {
  return (
    <li>
      <NavLink to={to} onClick={onClick}>
        {({ isActive }) => (
          <div
            className={classNames(
              isActive
                ? 'bg-gray-100 text-indigo-600'
                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
            )}>
            <_icon
              className={classNames(
                isActive ? 'text-indigo-600' : 'text-gray-500 group-hover:text-indigo-600',
                'size-6 shrink-0'
              )}
              aria-hidden="true"
            />
            {name}
          </div>
        )}
      </NavLink>
    </li>
  );
};

type OneOnOnesNavigationProps = {
  onClick?: () => void;
};

const OneOnOnesNavigation = ({ onClick }: OneOnOnesNavigationProps): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const authUser = useAppSelector((state) => state.user);
  const oneOnOnes = useAppSelector((state) => state.oneOnOnes.oneOnOnes);

  const [openCreateNewOneOnOneModal, setOpenCreateNewOneOnOneModal] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  return (
    <>
      <li>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="w-full">
                <div
                  className={classNames(
                    pathname.includes('/one-on-one')
                      ? 'text-indigo-600 bg-gray-100'
                      : 'text-gray-700',
                    `group flex flex-row w-full gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:text-indigo-600 hover:bg-gray-100`
                  )}>
                  <ChatBubbleLeftRightIcon
                    className={classNames(
                      pathname.includes('/one-on-one')
                        ? 'text-indigo-600'
                        : 'text-gray-500 group-hover:text-indigo-600',
                      'size-6 shrink-0'
                    )}
                    aria-hidden="true"
                  />
                  <span>1-on-1s</span>

                  <div className="flex-1 flex flex-row justify-end">
                    <ChevronDownIcon
                      className={classNames(
                        pathname.includes('/one-on-one')
                          ? 'text-indigo-600'
                          : 'text-gray-500 group-hover:text-indigo-600',
                        open ? 'rotate-180 transform' : '',
                        'size-6'
                      )}
                    />
                  </div>
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="flex flex-row ml-4">
                <VerticalLine />

                <div className="flex-1 max-h-64 overflow-y-auto">
                  {oneOnOnes.map((oneOnOne) => {
                    const nonSelfUser = new OneOnOne(oneOnOne).getNonSelfUser(authUser.id);

                    return (
                      <NavLink
                        key={oneOnOne.id}
                        to={`members/${nonSelfUser?.id}/one-on-one`}
                        onClick={onClick}>
                        {({ isActive }) => (
                          <div
                            className={classNames(
                              isActive
                                ? 'text-black font-medium'
                                : 'text-gray-800 font-normal hover:text-black hover:font-medium',
                              'flex gap-x-3 rounded-md py-2 pr-2'
                            )}>
                            <div className="flex-initial flex flex-col justify-center w-2 -mr-2">
                              <HorizontalLine />
                            </div>
                            <span className="flex items-center">
                              <Avatar user={nonSelfUser} size={5} />
                              <span className="ml-2 block truncate text-sm">
                                {nonSelfUser
                                  ? `${nonSelfUser.firstName}'s profile`
                                  : 'Deleted user'}
                              </span>
                            </span>
                          </div>
                        )}
                      </NavLink>
                    );
                  })}

                  <div className="flex flex-row">
                    <div className="flex-initial flex flex-col justify-center w-2 -mr-2">
                      <HorizontalLine />
                    </div>
                    <Button
                      size={'sm'}
                      variant={'link'}
                      className="text-sm"
                      onClick={() => setOpenCreateNewOneOnOneModal(true)}>
                      New 1-on-1
                    </Button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </li>

      <CreateNewOneOnOneModal
        open={openCreateNewOneOnOneModal}
        onClose={() => setOpenCreateNewOneOnOneModal(false)}
        afterSuccessfullCreate={(oneOnOne) => {
          const nonSelfUser = new OneOnOne(oneOnOne).getNonSelfUser(authUser.id);
          navigate(`/${organizationSlug}/members/${nonSelfUser?.id}/one-on-one`);
        }}
      />
    </>
  );
};

type MyTeamNavigationProps = {
  onClick?: () => void;
};

const MyTeamNavigation = ({ onClick }: MyTeamNavigationProps): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();

  const authUser = useAppSelector((state) => state.user);
  const oneOnOnes = useAppSelector((state) => state.oneOnOnes.oneOnOnes);
  const myTeamMembers = useAppSelector((state) => state.teams.myTeamMembers);

  const [openCreateNewOneOnOneModal, setOpenCreateNewOneOnOneModal] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  const nonTeamOneOnOnes: OneOnOneData[] = [];
  for (const oneOnOne of oneOnOnes) {
    const nonSelfUser = new OneOnOne(oneOnOne).getNonSelfUser(authUser.id);
    if (myTeamMembers.some((member) => member.id === nonSelfUser?.id)) {
      continue;
    }

    nonTeamOneOnOnes.push(oneOnOne);
  }

  if (myTeamMembers.length === 0) {
    return <OneOnOnesNavigation onClick={onClick} />;
  }

  return (
    <>
      <li className="flex flex-col gap-1 w-full bg-gray-200/50 rounded-md p-2">
        <div className="flex flex-row gap-x-3 w-full text-sm leading-6 font-semibold text-gray-700">
          <UserGroupIcon className="size-6 text-gray-500 shrink-0" aria-hidden="true" />
          <span>My team</span>
        </div>

        <div className="flex flex-col gap-1 max-h-48 overflow-y-auto overflow-x-hidden">
          <div className="flex flex-row ml-2 pr-2 pb-1">
            <VerticalLine />

            <div className="flex flex-col gap-3 my-1">
              {myTeamMembers.map((member) => {
                return (
                  <NavLink key={member.id} to={`members/${member.id}`} onClick={onClick}>
                    {({ isActive }) => (
                      <div
                        className={classNames(
                          isActive
                            ? 'text-black font-medium'
                            : 'text-gray-800 font-normal hover:text-black hover:font-medium',
                          'flex gap-x-3 rounded-md'
                        )}>
                        <div className="flex-initial flex flex-col justify-center w-2 -mr-2">
                          <HorizontalLine />
                        </div>
                        <span className="flex items-center">
                          <Avatar user={member} size={5} />
                          <span className="ml-2 block text-sm whitespace-nowrap truncate max-w-32">
                            {`${member.firstName}'s profile`}
                          </span>
                        </span>
                      </div>
                    )}
                  </NavLink>
                );
              })}
            </div>
          </div>

          {nonTeamOneOnOnes.length > 0 && (
            <>
              <div className="w-full px-1">
                <HorizontalLine />
              </div>

              <div className="flex-1 flex flex-col gap-3 my-1 ml-1">
                {nonTeamOneOnOnes.map((oneOnOne) => {
                  const nonSelfUser = new OneOnOne(oneOnOne).getNonSelfUser(authUser.id);

                  return (
                    <NavLink
                      key={oneOnOne.id}
                      to={`members/${nonSelfUser?.id}/one-on-one`}
                      onClick={onClick}>
                      {({ isActive }) => (
                        <div
                          className={classNames(
                            isActive
                              ? 'text-black font-medium'
                              : 'text-gray-800 font-normal hover:text-black hover:font-medium',
                            'flex gap-x-3 rounded-md'
                          )}>
                          <span className="flex items-center">
                            <Avatar user={nonSelfUser} size={5} />
                            <span className="ml-2 block truncate text-sm">
                              {nonSelfUser ? `${nonSelfUser.firstName}'s profile` : 'Deleted user'}
                            </span>
                          </span>
                        </div>
                      )}
                    </NavLink>
                  );
                })}
              </div>
            </>
          )}

          <div className="flex flex-row gap-1.5 items-center ml-1">
            <Button
              size={'sm'}
              variant={'link'}
              className="text-sm p-0 h-5"
              onClick={() => setOpenCreateNewOneOnOneModal(true)}>
              New 1-on-1
            </Button>
          </div>
        </div>
      </li>

      <CreateNewOneOnOneModal
        open={openCreateNewOneOnOneModal}
        onClose={() => setOpenCreateNewOneOnOneModal(false)}
        afterSuccessfullCreate={(oneOnOne) => {
          const nonSelfUser = new OneOnOne(oneOnOne).getNonSelfUser(authUser.id);
          navigate(`/${organizationSlug}/members/${nonSelfUser?.id}/one-on-one`);
        }}
      />
    </>
  );
};

const SideNavbarPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { organizationSlug } = useParams();

  const authUser = useAppSelector((state) => state.user);
  const isOrganizationPending = useAppSelector((state) => state.organization.isOrganizationPending);
  const organizationError = useAppSelector((state) => state.organization.error);
  const organization = useAppSelector((state) => state.organization.selectedOrganization);

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  useEffect(() => {
    if (organizationSlug) {
      dispatch(fetchOrganizationBySlug({ organizationSlug }));
      dispatch(getOrganizationUserPermissionRole({ organizationSlug }));
      dispatch(fetchMyTeamMembers({ organizationSlug }));
      dispatch(fetchOneOnOnes({ organizationSlug }));
    }
  }, [organizationSlug]);

  function showOrganizationSettings(): boolean {
    if (authUser.id === organization.owner.id) {
      return true;
    }
    if (checkPermissionAccess(authUser.permissionRole, 'editor')) {
      return true;
    }
    return false;
  }

  if (isOrganizationPending) {
    return <></>;
  }

  if (organizationError) {
    if (organizationError.statusCode === HttpStatusCode.NOT_FOUND) {
      return (
        <NotFoundComponent
          title="Organization not found"
          description="Organization not found. Please try again."
        />
      );
    }
    if (organizationError.statusCode === HttpStatusCode.FORBIDDEN) {
      return (
        <ScreenErrorComponent
          statusCode={HttpStatusCode.FORBIDDEN}
          title="Cannot access organization"
          description="You do not have access to this organization. Please check your email and use the link provided to access the organization."
        />
      );
    }
    return <InternalServerErrorPage />;
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => {
                          setSidebarOpen(false);
                        }}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="size-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-4 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <h1 className={`${styles.fontFuturaPT} text-[28px]`}>Sprout</h1>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            <PermissionProtectedAccess
                              minimalRequiredPermissionRole="manager"
                              fallbackElement={
                                <OneOnOnesNavigation
                                  onClick={() => {
                                    setSidebarOpen(false);
                                  }}
                                />
                              }>
                              <MyTeamNavigation
                                onClick={() => {
                                  setSidebarOpen(false);
                                }}
                              />
                            </PermissionProtectedAccess>

                            <NavigationItem
                              to={`members/${authUser.id}/grow`}
                              icon={RocketLaunchIcon}
                              name="Grow"
                              onClick={() => {
                                setSidebarOpen(false);
                              }}
                            />

                            <NavigationItem
                              to="goals"
                              icon={TbTargetArrow}
                              name="Goals"
                              onClick={() => {
                                setSidebarOpen(false);
                              }}
                            />

                            <NavigationItem
                              to="competencies"
                              icon={BeakerIcon}
                              name="Competencies"
                              onClick={() => {
                                setSidebarOpen(false);
                              }}
                            />

                            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                              <li className="flex flex-col">
                                <p className="text-sm font-semibold leading-6 text-gray-400">
                                  Library
                                </p>

                                <div className="flex-initial flex flex-row ml-1">
                                  <VerticalLine />
                                  <ul role="list" className="mt-2 space-y-1">
                                    {library.map((item) => (
                                      <li key={item.name}>
                                        <NavLink
                                          to={item.href}
                                          onClick={() => {
                                            setSidebarOpen(false);
                                          }}>
                                          {({ isActive }) => (
                                            <div
                                              className={classNames(
                                                isActive
                                                  ? 'bg-gray-100 text-indigo-600'
                                                  : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                              )}>
                                              <div className="flex-initial flex flex-col justify-center w-2 -mx-2">
                                                <HorizontalLine />
                                              </div>
                                              <item.icon
                                                className={classNames(
                                                  isActive
                                                    ? 'text-indigo-600'
                                                    : 'text-gray-500 group-hover:text-indigo-600',
                                                  'size-6 shrink-0'
                                                )}
                                                aria-hidden="true"
                                              />
                                              {item.name}
                                            </div>
                                          )}
                                        </NavLink>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </li>
                            </PermissionProtectedAccess>
                          </ul>
                        </li>
                        <li className="mt-auto -mx-2 space-y-1">
                          <NavLink
                            to="company"
                            onClick={() => {
                              setSidebarOpen(false);
                            }}>
                            {({ isActive }) => (
                              <div
                                className={classNames(
                                  isActive
                                    ? 'bg-gray-100 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}>
                                <BuildingOffice2Icon
                                  className={classNames(
                                    isActive
                                      ? 'text-indigo-600'
                                      : 'text-gray-500 group-hover:text-indigo-600',
                                    'size-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                Company
                              </div>
                            )}
                          </NavLink>
                          <NavLink
                            to="settings"
                            onClick={() => {
                              setSidebarOpen(false);
                            }}
                            className={showOrganizationSettings() ? undefined : 'hidden'}>
                            {({ isActive }) => (
                              <div
                                className={classNames(
                                  isActive
                                    ? 'bg-gray-100 text-indigo-600'
                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}>
                                <Cog6ToothIcon
                                  className={classNames(
                                    isActive
                                      ? 'text-indigo-600'
                                      : 'text-gray-500 group-hover:text-indigo-600',
                                    'size-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                Settings
                              </div>
                            )}
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-[9] lg:flex lg:w-[216px] lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-4 overflow-y-auto overflow-x-hidden bg-gray-50 border border-gray-200 shadow px-4 pb-4 rounded-r-lg">
            <div className="flex h-16 justify-start items-center">
              <h1 className={`${styles.fontFuturaPT} text-[28px]`}>Sprout</h1>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-3">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    <PermissionProtectedAccess
                      minimalRequiredPermissionRole="manager"
                      fallbackElement={<OneOnOnesNavigation />}>
                      <MyTeamNavigation />
                    </PermissionProtectedAccess>

                    <NavigationItem
                      to={`members/${authUser.id}/grow`}
                      icon={RocketLaunchIcon}
                      name="Grow"
                    />

                    <NavigationItem to="goals" icon={TbTargetArrow} name="Goals" />

                    <NavigationItem to="competencies" icon={BeakerIcon} name="Competencies" />

                    <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                      <li className="flex flex-col ml-2 w-full">
                        <p className="flex-none text-sm font-semibold leading-6 text-gray-500">
                          Library
                        </p>

                        <div className="flex-initial flex flex-row ml-1">
                          <VerticalLine />
                          <ul role="list" className="mt-2 space-y-1 w-full">
                            {library.map((item) => (
                              <li key={item.name}>
                                <NavLink to={item.href}>
                                  {({ isActive }) => (
                                    <div
                                      className={classNames(
                                        isActive
                                          ? 'bg-gray-100 text-indigo-600'
                                          : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                      )}>
                                      <div className="flex-initial flex flex-col justify-center w-2 -mx-2">
                                        <HorizontalLine />
                                      </div>
                                      <item.icon
                                        className={classNames(
                                          isActive
                                            ? 'text-indigo-600'
                                            : 'text-gray-500 group-hover:text-indigo-600',
                                          'size-6 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </div>
                                  )}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    </PermissionProtectedAccess>
                  </ul>
                </li>

                <li className="mt-auto -mx-2 flex flex-col gap-y-1">
                  <NavLink
                    to="company"
                    onClick={() => {
                      setSidebarOpen(false);
                    }}>
                    {({ isActive }) => (
                      <div
                        className={classNames(
                          isActive
                            ? 'bg-gray-100 text-indigo-600'
                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}>
                        <BuildingOffice2Icon
                          className={classNames(
                            isActive
                              ? 'text-indigo-600'
                              : 'text-gray-500 group-hover:text-indigo-600',
                            'size-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        Company
                      </div>
                    )}
                  </NavLink>
                  <NavLink
                    to="settings"
                    className={showOrganizationSettings() ? undefined : 'hidden'}>
                    {({ isActive }) => (
                      <div
                        className={classNames(
                          isActive
                            ? 'bg-gray-100 text-indigo-600'
                            : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}>
                        <Cog6ToothIcon
                          className={classNames(
                            isActive
                              ? 'text-indigo-600'
                              : 'text-gray-500 group-hover:text-indigo-600',
                            'size-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        Settings
                      </div>
                    )}
                  </NavLink>
                </li>

                <div className="-mx-6 mb-2">
                  <HorizontalLine />
                </div>

                <UserSettingsMenu openDirrection="top" />
              </ul>
            </nav>
          </div>
        </div>

        <div className="flex flex-col h-screen max-h-screen lg:pl-52">
          <div className="flex-none lg:hidden">
            <ProfileHeaderNavigation
              activateSidebar={true}
              sidebarOnClick={() => setSidebarOpen(true)}
            />
          </div>

          <main className="flex-1 py-2 h-full w-full">
            <div className="flex-1 flex flex-col px-4 sm:px-6 lg:px-8 h-full w-full">
              <div className="flex-1 flex">{<Outlet />}</div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SideNavbarPage;
