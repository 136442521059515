import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import Cookies from 'js-cookie';

import { useAppDispatch } from '../../hooks';
import { fetchOrganizations } from '../../core/organization/organizationSlice';
import { Button } from '../../components';
import { acceptOrganizationInvite } from '../../core/auth/authSlice';

const AcceptInvitePage = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const organizationName = searchParams.get('organization_name') ?? '';
  const token = searchParams.get('token') ?? '';

  useEffect(() => {
    if (!Cookies.get('token')) {
      navigate(`/register?${searchParams.toString()}`);
    }
  }, []);

  async function acceptInviteHandler(token: string): Promise<void> {
    try {
      await dispatch(acceptOrganizationInvite({ token }));
      await dispatch(fetchOrganizations());
    } catch (err) {
      toast.error('Failed to accept invite');
    }
    navigate('/home');
  }

  return (
    <div className="flex min-h-full w-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[800px]">
        <div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-xl font-semibold leading-6 text-gray-900">
              Join {organizationName}
            </h3>
            <div className="mt-6">
              <p className="text-base text-gray-500">
                {organizationName} uses Sprout to track employees career development and growth.
                Join the rest of your team by accepting the invitation.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center mt-5 sm:mt-6">
          <Button
            onClick={() => {
              acceptInviteHandler(token);
            }}>
            Accept Invite
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AcceptInvitePage;
