import React, { useState } from 'react';

import { classNames } from '../common/utils';
import { Button } from './ui/Button';

type SettingsItemWithEditButtonProps = {
  labelName: string;
  value: string;
  onClick?: (newValue: string) => void;
  showButton?: boolean;
};

const SettingsItemWithEditButton = ({
  labelName,
  value,
  onClick = () => {},
  showButton = true
}: SettingsItemWithEditButtonProps): JSX.Element => {
  const [updateActive, setUpdateActive] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  return (
    <div className="pt-6 sm:flex">
      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 self-center">
        {labelName}
      </dt>
      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
        <p
          className={classNames(updateActive ? 'hidden' : '', 'text-gray-900 text-sm self-center')}>
          {inputValue}
        </p>
        <input
          type="text"
          defaultValue={inputValue}
          className={classNames(
            updateActive ? '' : 'hidden',
            'border border-gray-300 text-gray-900 rounded-md max-h-[24px] text-sm self-center'
          )}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <div className={showButton ? undefined : 'hidden'}>
          {updateActive ? (
            <div className="flex flex-row">
              <Button
                variant={'link'}
                className="text-red-500 hover:decoration-red-600 pr-0"
                onClick={(e) => {
                  setUpdateActive(false);
                  setInputValue(value);
                }}>
                Cancel
              </Button>
              <Button
                variant={'link'}
                onClick={(e) => {
                  setUpdateActive(false);
                  onClick(inputValue);
                }}>
                Save
              </Button>
            </div>
          ) : (
            <Button
              variant={'link'}
              onClick={(e) => {
                setUpdateActive(true);
              }}>
              Edit
            </Button>
          )}
        </div>
      </dd>
    </div>
  );
};

export default SettingsItemWithEditButton;
