import Skill, { SkillData } from '../core/library/skill/Skill.model';
import SkillLevel, { SkillLevelData } from '../core/library/skill/SkillLevel.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetLibrarySkillsArgs = GetOrganizationArgs;

export async function getLibrarySkills({
  organizationSlug
}: GetLibrarySkillsArgs): Promise<SkillData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/library/skills`);
    const skillsData = data.skills.map((skill: any) => Skill.buildSkillDataFromResponse(skill));
    return Promise.resolve(skillsData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetLibrarySkillArgs = GetOrganizationArgs & {
  skillID: string;
};

export async function getLibrarySkill({
  organizationSlug,
  skillID
}: GetLibrarySkillArgs): Promise<SkillData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/library/skills/${skillID}`);
    return Promise.resolve(Skill.buildSkillDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateLibrarySkillArgs = GetOrganizationArgs & {
  skillData: SkillData;
};

export async function createLibrarySkill({
  organizationSlug,
  skillData
}: CreateLibrarySkillArgs): Promise<SkillData> {
  try {
    const skillLevelsReqData = skillData.levels.map((level) => ({
      seniority: level.seniority,
      description: level.description,
      examples: level.examples
    }));

    const { data } = await Api.post(`/organizations/${organizationSlug}/library/skills`, {
      name: skillData.name,
      description: skillData.description,
      levels: skillLevelsReqData
    });
    return Promise.resolve(Skill.buildSkillDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateLibrarySkillArgs = GetOrganizationArgs & {
  skillData: SkillData;
};

export async function updateLibrarySkill({
  organizationSlug,
  skillData
}: UpdateLibrarySkillArgs): Promise<SkillData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/library/skills/${skillData.id}`,
      {
        name: skillData.name,
        description: skillData.description
      }
    );
    return Promise.resolve(Skill.buildSkillDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export async function deleteLibrarySkill({
  organizationSlug,
  skillID
}: GetLibrarySkillArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/library/skills/${skillID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export async function duplicateLibrarySkill({
  organizationSlug,
  skillID
}: GetLibrarySkillArgs): Promise<SkillData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/library/skills/${skillID}/duplicate`
    );
    return Promise.resolve(Skill.buildSkillDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateLibrarySkillLevelArgs = GetLibrarySkillArgs & {
  skillLevelData: SkillLevelData;
};

export async function createLibrarySkillLevel({
  organizationSlug,
  skillID,
  skillLevelData
}: CreateLibrarySkillLevelArgs): Promise<SkillLevelData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/library/skills/${skillID}/levels`,
      {
        seniority: skillLevelData.seniority,
        description: skillLevelData.description,
        examples: skillLevelData.examples
      }
    );
    return Promise.resolve(SkillLevel.buildSkillLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateLibrarySkillLevelArgs = CreateLibrarySkillLevelArgs;

export async function updateLibrarySkillLevel({
  organizationSlug,
  skillID,
  skillLevelData
}: UpdateLibrarySkillLevelArgs): Promise<SkillLevelData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/library/skills/${skillID}/levels/${skillLevelData.id}`,
      {
        seniority: skillLevelData.seniority,
        description: skillLevelData.description,
        examples: skillLevelData.examples
      }
    );
    return Promise.resolve(SkillLevel.buildSkillLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type DeleteLibrarySkillLevelArgs = GetLibrarySkillArgs & {
  skillLevelID: string;
};

export async function deleteLibrarySkillLevel({
  organizationSlug,
  skillID,
  skillLevelID
}: DeleteLibrarySkillLevelArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/library/skills/${skillID}/levels/${skillLevelID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}
