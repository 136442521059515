import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Popover, Transition } from '@headlessui/react';

import { TeamData } from '../../core/team/Team.model';
import { DepartmentData } from '../../core/cdf/Department.model';
import { GoalDomain, GoalStatus } from '../../core/goal/Goal.model';

const statusFilters = [
  { id: GoalStatus.ON_TRACK, name: 'On track' },
  { id: GoalStatus.AT_RISK, name: 'At risk' },
  { id: GoalStatus.OFF_TRACK, name: 'Off track' },
  { id: GoalStatus.COMPLETED, name: 'Completed' },
  { id: GoalStatus.CLOSED, name: 'Closed' }
];

const domainFilters = [
  { id: 'my_goals', name: 'My goals' },
  { id: 'my_team', name: 'My team' },
  { id: GoalDomain.INDIVIDUAL, name: 'Individual' }
];

type GoalsFiltersProps = {
  teams: TeamData[];
  departments: DepartmentData[];

  selectedStatus: GoalStatus[];
  setSelectedStatus: (status: GoalStatus[]) => void;

  selectedDomain: string[];
  setSelectedDomain: (domain: string[]) => void;
};

const GoalsFilters = ({
  teams,
  departments,
  selectedStatus,
  setSelectedStatus,
  selectedDomain,
  setSelectedDomain
}: GoalsFiltersProps): JSX.Element => {
  return (
    <div className="flex flex-row gap-4 items-center">
      <p className="text-md text-gray-800">Filters:</p>
      <div className="flow-root">
        <Popover.Group className="flex flex-row items-center gap-5">
          {/* Status Filter */}
          <Popover className="relative inline-block text-left z-10">
            <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none">
              <span>Status</span>
              {selectedStatus.length !== 0 && (
                <span className="ml-1.5 rounded bg-gray-200/70 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                  {selectedStatus.length}
                </span>
              )}
              <ChevronDownIcon
                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Popover.Panel className="absolute right-0 z-10 mt-2 overflow-hidden origin-top-right rounded-md bg-white p-4 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                <form className="flex flex-col gap-3">
                  {statusFilters.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        id={`filter-status-${option.id}-checkbox`}
                        name={`filter-status-${option.id}-checkbox`}
                        type="checkbox"
                        defaultChecked={selectedStatus.includes(option.id)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedStatus([...selectedStatus, option.id]);
                            return;
                          }

                          setSelectedStatus(
                            selectedStatus.filter((status) => status !== option.id)
                          );
                        }}
                      />
                      <label
                        htmlFor={`filter-status-${option.id}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">
                        {option.name}
                      </label>
                    </div>
                  ))}
                </form>
              </Popover.Panel>
            </Transition>
          </Popover>

          {/* Domain Filter */}
          <Popover className="relative inline-block text-left z-10">
            <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none">
              <span>Domain</span>
              {selectedDomain.length !== 0 && (
                <span className="ml-1.5 rounded bg-gray-200/70 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                  {selectedDomain.length}
                </span>
              )}
              <ChevronDownIcon
                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Popover.Panel className="absolute right-0 z-10 mt-2 max-h-72 overflow-y-scroll origin-top-right rounded-md bg-white p-4 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                <form className="flex flex-col gap-3">
                  {domainFilters.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        id={`filter-domain-${option.id}-checkbox`}
                        name={`filter-domain-${option.id}-checkbox`}
                        type="checkbox"
                        checked={selectedDomain.includes(option.id)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDomain([...selectedDomain, option.id]);
                            return;
                          }

                          setSelectedDomain(
                            selectedDomain.filter((domain) => domain !== option.id)
                          );
                        }}
                      />
                      <label
                        htmlFor={`filter-domain-${option.id}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">
                        {option.name}
                      </label>
                    </div>
                  ))}

                  {/* Team domain filter */}
                  <div className="flex flex-col w-full -mb-1">
                    <p className="whitespace-nowrap pr-6 text-sm font-medium text-gray-600">Team</p>
                  </div>
                  {teams.map((team) => (
                    <div key={team.id} className="flex items-center">
                      <input
                        id={`filter-teams-${team.id}-checkbox`}
                        name={`filter-teams-${team.id}-checkbox`}
                        type="checkbox"
                        checked={selectedDomain.includes(team.id)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDomain([...selectedDomain, team.id]);
                            return;
                          }

                          setSelectedDomain(selectedDomain.filter((domain) => domain !== team.id));
                        }}
                      />
                      <label
                        htmlFor={`filter-teams-${team.id}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">
                        {team.name}
                      </label>
                    </div>
                  ))}

                  {/* Department domain filter */}
                  <div className="flex flex-col w-full -mb-1">
                    <p className="whitespace-nowrap pr-6 text-sm font-medium text-gray-600">
                      Department
                    </p>
                  </div>
                  {departments.map((department) => (
                    <div key={department.id} className="flex items-center">
                      <input
                        id={`filter-department-${department.id}-checkbox`}
                        name={`filter-department-${department.id}-checkbox`}
                        type="checkbox"
                        checked={selectedDomain.includes(department.id)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDomain([...selectedDomain, department.id]);
                            return;
                          }

                          setSelectedDomain(
                            selectedDomain.filter((domain) => domain !== department.id)
                          );
                        }}
                      />
                      <label
                        htmlFor={`filter-department-${department.id}`}
                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">
                        {department.name}
                      </label>
                    </div>
                  ))}
                </form>
              </Popover.Panel>
            </Transition>
          </Popover>
        </Popover.Group>
      </div>
    </div>
  );
};

export default GoalsFilters;
