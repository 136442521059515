import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';

import { timeAgo } from '../../common/utils';
import { useAppDispatch, useAppSelector } from '../../core/store';
import {
  AppLoader,
  Button,
  ConfirmationDialog,
  CreateNewDepartmentModal,
  EllipsisMenu,
  HorizontalLine,
  PermissionProtectedAccess
} from '../../components';
import {
  createDepartment,
  deleteDepartment,
  fetchDepartments
} from '../../core/cdf/departmentsSlice';

const CDFPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const areDepartmentsPending = useAppSelector((state) => state.departments.areDepartmentsPending);
  const areDepartmentsFetched = useAppSelector((state) => state.departments.areDepartmentsFetched);
  const departments = useAppSelector((state) => state.departments.departments);

  const [createNewDepartmentModalOpen, setCreateNewDepartmentModalOpen] = useState(false);
  const [deleteDepartmentID, setDeleteDepartmentID] = useState('');
  const [deleteDepartmentBtnModalOpen, setDeleteDepartmentBtnModalOpen] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (areDepartmentsFetched) {
      return;
    }
    dispatch(fetchDepartments({ organizationSlug }));
  }, []);

  async function onCreateDepartmentHandler(name: string): Promise<void> {
    const res: any = await dispatch(createDepartment({ organizationSlug, name }));
    if (res.error) {
      return;
    }
    closeNewDepartmentModal();
  }

  function closeNewDepartmentModal(): void {
    setCreateNewDepartmentModalOpen(false);
  }

  function openNewDepartmentModal(): void {
    setCreateNewDepartmentModalOpen(true);
  }

  if (areDepartmentsPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex flex-col justify-start h-full w-full mt-6">
        <p className="text-sm italic text-gray-600 max-w-6xl">
          {`The Competency Framework is a strategic roadmap for your organization's
          talent development. It allows you to structure your workforce by creating departments and
          defining levels within them, enabling employees to see the potential career progression
          within your company, and empowers them to find and understend their skill gaps.`}
        </p>
        <div className="my-4">
          <HorizontalLine />
        </div>

        <ul
          role="list"
          className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5 p-1">
          {departments.map((department) => (
            <li
              key={department.id}
              onClick={() => navigate(department.id)}
              className="relative flex flex-col justify-between overflow-hidden cursor-pointer rounded-xl border border-gray-200 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 h-[10rem] max-h-[10rem] min-h-[10rem]">
              <div className="flex flex-row justify-center bg-gray-50 h-full">
                <div className="flex-1 flex flex-col justify-center mt-3">
                  <p className="text-xl font-medium leading-6 text-gray-900 text-center">
                    {department.name}
                  </p>
                </div>

                <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                  <div className="absolute top-3 right-3">
                    <EllipsisMenu
                      items={[
                        {
                          name: 'Delete',
                          onClick: () => {
                            setDeleteDepartmentID(department.id);
                            setDeleteDepartmentBtnModalOpen(true);
                          },
                          color: 'text-red-600'
                        }
                      ]}
                    />
                  </div>
                </PermissionProtectedAccess>
              </div>
              <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="flex-none text-gray-500 whitespace-nowrap">Updated At:</dt>
                  <dd className="flex-initial">
                    <p className="font-medium text-gray-700 truncate">
                      {timeAgo(new Date(), new Date(department.updatedAt))}
                    </p>
                  </dd>
                </div>
              </dl>
            </li>
          ))}
          {departments.length > 0 && (
            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
              <li className="min-w-[12rem] h-[10rem] max-h-[10rem] min-h-[10rem] rounded-lg">
                <button
                  type="button"
                  onClick={openNewDepartmentModal}
                  className="relative block w-full rounded-xl border-2 border-dashed border-gray-300 px-6 py-10 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <PlusIcon className="mx-auto size-12 text-gray-500" />
                  <span className="mt-2 block text-sm font-semibold text-gray-900">
                    Create new department
                  </span>
                </button>
              </li>
            </PermissionProtectedAccess>
          )}
        </ul>

        {departments.length === 0 && (
          <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
            <div className="flex flex-col items-center justify-start h-full p-6 sm:mt-20">
              <div className="text-center space-y-4 max-w-[40rem]">
                <h3 className="text-lg font-medium italic text-gray-600">
                  Start Building Your Competency Framework 🚀
                </h3>
                <p className="text-sm italic text-gray-600">
                  {`Welcome to your Competency Framework dashboard! Here, departments are key to structuring career paths and growth opportunities. Currently, you don't have any departments set up. Let's change that! Explore our extensive library for a quick start or create a custom department from scratch to tailor it perfectly to your team's needs.`}
                </p>
                <div className="flex flex-col-reverse sm:flex-row justify-center mt-5 sm:mt-4 gap-3">
                  <Button variant={'outline'} onClick={openNewDepartmentModal}>
                    Create New Department
                  </Button>
                  <Button onClick={() => navigate('../library/frameworks')}>
                    Import Competency Framework from Library
                  </Button>
                </div>
              </div>
            </div>
          </PermissionProtectedAccess>
        )}
      </div>
      <CreateNewDepartmentModal
        open={createNewDepartmentModalOpen}
        onCreate={(name) => {
          onCreateDepartmentHandler(name);
        }}
        onClose={closeNewDepartmentModal}
      />
      <ConfirmationDialog
        open={deleteDepartmentBtnModalOpen}
        title={'Are you absolutely sure?'}
        description={
          'This action cannot be undone. This will permanently delete the department, career development paths and levels inside it.'
        }
        variant={'destructive'}
        onClose={() => setDeleteDepartmentBtnModalOpen(false)}
        onConfirm={() => {
          dispatch(deleteDepartment({ organizationSlug, departmentID: deleteDepartmentID }));
          setDeleteDepartmentBtnModalOpen(false);
        }}
      />
    </>
  );
};

export default CDFPage;
