import React, { Fragment, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';

import { useAppDispatch } from '../../hooks';
import { createGoalCheckIn, fetchGoal } from '../../core/goal/goalsSlice';
import { GoalData, GoalMeasurementType, GoalStatus } from '../../core/goal/Goal.model';
import { CheckInStatus } from '../../core/goal/CheckIn.model';
import { Button } from '../ui/Button';
import HorizontalLine from '../HorizontalLine';
import GoalStatusListbox from './GoalStatusListbox';

interface CreateCheckInModalProps {
  goal: GoalData;
  open: boolean;
  onClose: () => void;
}

const CreateCheckInModal = ({ goal, open, onClose }: CreateCheckInModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const [progressIncreaseBy, setProgressIncreaseBy] = useState(0);
  const [goalStatus, setGoalStatus] = useState<string>(goal.status);
  const [progressDescription, setProgressDescription] = useState('');

  const organizationSlug = params.organizationSlug ?? '';
  const goalID = params.goalID ?? '';

  async function createCheckinHandler(): Promise<void> {
    let newProgressIncreaseBy = progressIncreaseBy;
    if (
      goal.measurementType === GoalMeasurementType.BINARY &&
      goalStatus === GoalStatus.COMPLETED
    ) {
      newProgressIncreaseBy = 1;
    }

    const res: any = await dispatch(
      createGoalCheckIn({
        organizationSlug,
        goalID: goal.id,
        data: {
          progressIncreaseBy: newProgressIncreaseBy,
          status: goalStatus as CheckInStatus,
          progressDescription
        }
      })
    );
    if (res.error) {
      return;
    }

    dispatch(fetchGoal({ organizationSlug, goalID }));

    setProgressIncreaseBy(0);
    setProgressDescription('');
    onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="flex flex-row items-center gap-1 text-base font-semibold leading-6 text-gray-900">
                      Update Progress - Write Check-in
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    <div className="flex flex-row items-center gap-8">
                      {goal.measurementType !== GoalMeasurementType.BINARY && (
                        <div className="flex flex-col gap-1">
                          <p className="text-sm font-medium text-gray-700">Increase progress by</p>
                          <div className="flex flex-row gap-1">
                            <input
                              type="number"
                              value={progressIncreaseBy}
                              min={0}
                              className="text-gray-900 h-7 rounded-md border-gray-300 text-sm w-full max-w-36"
                              onChange={(e) => {
                                setProgressIncreaseBy(parseInt(e.target.value, 10));
                              }}
                            />
                            <p className="text-gray-700">
                              {goal.measurementType === GoalMeasurementType.CURRENCY && '$'}
                              {goal.measurementType === GoalMeasurementType.PERCENTAGE && '%'}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="flex flex-col gap-1">
                        <p className="text-sm font-medium text-gray-700">Status</p>
                        <GoalStatusListbox
                          size="md"
                          status={goalStatus}
                          onChange={(s) => setGoalStatus(s)}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <p className="text-sm font-medium text-gray-700">Progress description</p>
                      <TextareaAutosize
                        className="text-gray-900 rounded-md border-gray-300 text-sm min-h-28"
                        placeholder="Write a description of the progress made"
                        value={progressDescription}
                        onChange={(e) => setProgressDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1">
                  <Button
                    onClick={() => {
                      createCheckinHandler();
                    }}>
                    Submit
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateCheckInModal;
