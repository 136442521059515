export interface OrganizationOwnerData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string | null;
}

export interface OrganizationData {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  owner: OrganizationOwnerData;

  slug: string;
  name: string;
  logo?: string | null;

  plan: 'free_plan' | 'pro_plan' | 'enterprise' | 'internal';
  isPlanActive: boolean;
  userQuota: number;
  stripeCustomerPortalURL?: string;
}

export const DEFAULT_FREE_PLAN_USER_QUOTA = 15;

class Organization implements OrganizationData {
  id: string;
  owner: OrganizationOwnerData;

  slug: string;
  name: string;
  logo?: string | null;

  plan: 'free_plan' | 'pro_plan' | 'enterprise' | 'internal';
  isPlanActive: boolean;
  userQuota: number;
  stripeCustomerPortalURL?: string;

  constructor(data: OrganizationData) {
    this.id = data.id;
    this.owner = data.owner;

    this.slug = data.slug;
    this.name = data.name;
    this.logo = data.logo;

    this.plan = data.plan;
    this.isPlanActive = data.isPlanActive;
    this.userQuota = data.userQuota;
    this.stripeCustomerPortalURL = data.stripeCustomerPortalURL;
  }

  static buildOrganizationDataFromResponse(response: any): OrganizationData {
    return {
      id: response.id,
      owner: {
        id: response.owner.id,
        firstName: response.owner.first_name,
        lastName: response.owner.last_name,
        email: response.owner.email,
        picture: response.owner.picture
      },
      slug: response.slug,
      name: response.name,
      logo: response.logo,
      plan: response.plan ?? 'free_plan',
      isPlanActive: response.plan_active ?? true,
      userQuota: response.user_quota ?? DEFAULT_FREE_PLAN_USER_QUOTA,
      stripeCustomerPortalURL: response.stripe_customer_portal_url
    };
  }
}

export default Organization;
