import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { BsBoxArrowRight } from 'react-icons/bs';

import { classNames } from '../../common/utils';
import { logoutUser } from '../../core/auth/authSlice';
import { useAppDispatch } from '../../hooks';
import Avatar from '../ui/Avatar';
import { useAppSelector } from '../../core/store';

type UserSettingsMenuProps = {
  openDirrection?: 'top' | 'bottom';
};

const UserSettingsMenu = ({ openDirrection = 'bottom' }: UserSettingsMenuProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              open
                ? 'bg-gray-100 text-indigo-600'
                : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
              'group flex items-center gap-x-3 rounded-md p-2 -m-2 text-sm leading-6 font-semibold'
            )}>
            <span className="sr-only">Open user menu</span>
            <Avatar user={user} size={8} />

            <div className="hidden sm:flex flex-col items-start max-w-[104px]">
              <p className="text-sm font-medium leading-4 text-gray-700 max-w-[104px] truncate">
                {user.firstName}
              </p>
              <p className="text-xs font-medium leading-4 text-gray-500 max-w-[104px] truncate">
                {user.email}
              </p>
            </div>

            <div className="hidden sm:flex">
              <BsBoxArrowRight className="size-4" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              className={`absolute cursor-pointer ${
                openDirrection === 'bottom' ? '' : 'bottom-9'
              } right-0 z-10 mt-2.5 w-32 origin-bottom-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}>
              <Menu.Item>
                {({ active }: { active: boolean }) => (
                  <Link
                    to="/profile"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}>
                    Your profile
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }: { active: boolean }) => (
                  <a
                    href="/login"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    )}
                    onClick={() => {
                      dispatch(logoutUser());
                    }}>
                    Sign out
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default UserSettingsMenu;
