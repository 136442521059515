import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { ProgressionData } from './Progression.model';
import { ProgressionLevelData } from './ProgressionLevel.model';
import { findAndSetNewSkills, sortSkillsArray } from '../../common/utils';

type ProgressionState = {
  activeProgression: ProgressionData | null;
  isProgressionPending: boolean;

  selectedNextLevel: ProgressionLevelData | null;
  isNextLevelPending: boolean;

  departmentLevels: ProgressionLevelData[];
  isDepartmentLevelsPending: boolean;

  error: api.ApiError;
};

const initialState: ProgressionState = {
  activeProgression: null,
  isProgressionPending: true,

  selectedNextLevel: null,
  isNextLevelPending: false,

  departmentLevels: [],
  isDepartmentLevelsPending: true,

  error: null
};

const completeProgression = createAsyncThunk(
  'progression/completeProgression',
  async (args: api.GetProgressionArgs, { rejectWithValue }) => {
    try {
      return await api.completeProgression(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchProgression = createAsyncThunk(
  'progression/getProgression',
  async (args: api.GetProgressionArgs, { rejectWithValue }) => {
    try {
      return await api.getProgression(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type AssignProgressionNextLevelArgs = api.GetProgressionArgs & {
  nextLevelID: string;
};

const assignProgressionNextLevel = createAsyncThunk(
  'progression/assignProgressionNextLevel',
  async (args: AssignProgressionNextLevelArgs, { rejectWithValue }) => {
    try {
      return await api.updateProgression(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type AssignUserProgressionCurrentLevelArgs = api.GetProgressionArgs & {
  currentLevelID: string;
};

const assignUserProgressionCurrentLevel = createAsyncThunk(
  'progression/assignUserProgressionCurrentLevel',
  async (args: AssignUserProgressionCurrentLevelArgs, { rejectWithValue }) => {
    try {
      return await api.assignUserProgressionManagerOrLevel({
        ...args,
        levelID: args.currentLevelID
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateProgressionSkillStatus = createAsyncThunk(
  'progression/updateProgressionSkillStatus',
  async (args: api.UpdateProgressionSkillStatusArgs, { rejectWithValue }) => {
    try {
      return await api.updateProgressionSkillStatus(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchDepartmentLevels = createAsyncThunk(
  'progression/getDepartmentLevels',
  async (args: api.GetDepartmentLevelsArgs, { rejectWithValue }) => {
    try {
      return await api.getDepartmentLevels(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchProgressionLevel = createAsyncThunk(
  'progression/getProgressionLevel',
  async (args: api.GetProgressionLevelArgs, { rejectWithValue }) => {
    try {
      return await api.getProgressionLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchAllDepartmentsLevels = createAsyncThunk(
  'progression/getAllDepartmentsLevels',
  async (args: api.GetDepartmentsArgs, { rejectWithValue }) => {
    try {
      return await api.getAllDepartmentsLevels(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const progressionSlice = createSlice({
  name: 'progression',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Complete progression
    builder.addCase(completeProgression.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to complete progression'));
    });

    // Fetch progression
    builder.addCase(fetchProgression.pending, (state) => {
      state.isProgressionPending = true;
      state.activeProgression = null;
      state.selectedNextLevel = null;
      state.error = null;
    });
    builder.addCase(fetchProgression.rejected, (state, action) => {
      state.isProgressionPending = false;
      state.error = action.payload as api.ApiError;
      if (state.error?.statusCode === 404) {
        return;
      }
      toast.error(getCustomUserError(state.error, 'Failed to fetch progression'));
    });
    builder.addCase(fetchProgression.fulfilled, (state, action) => {
      state.activeProgression = action.payload;
      state.selectedNextLevel = action.payload?.nextLevel ?? null;
      if (state.selectedNextLevel?.skills && state.selectedNextLevel?.skills.length > 0) {
        state.selectedNextLevel.skills = sortSkillsArray(state.selectedNextLevel.skills);
      }
      if (
        state.selectedNextLevel?.skills &&
        state.selectedNextLevel?.skills.length > 0 &&
        state.activeProgression?.currentLevel?.skills &&
        state.activeProgression?.currentLevel?.skills.length > 0
      ) {
        state.selectedNextLevel.skills = findAndSetNewSkills(
          state.activeProgression.currentLevel.skills,
          state.selectedNextLevel.skills
        );
      }

      state.isProgressionPending = false;
      state.error = null;
    });

    // Assign progression next level
    builder.addCase(assignProgressionNextLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to assign progression next level'));
    });
    builder.addCase(assignProgressionNextLevel.fulfilled, (state, action) => {
      if (state.activeProgression) {
        state.activeProgression.nextLevel = action.payload.nextLevel;
      }
      toast.success('The default next level has been changed');
    });

    // Assign user progression current level
    builder.addCase(assignUserProgressionCurrentLevel.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to assign user to level'));
    });
    builder.addCase(assignUserProgressionCurrentLevel.fulfilled, (state, action) => {
      state.activeProgression = action.payload;
      toast.success('The current level has been changed');
    });

    // Update progression skill status
    builder.addCase(updateProgressionSkillStatus.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update progression skill status'));
    });
    builder.addCase(updateProgressionSkillStatus.fulfilled, (state, action) => {
      for (const skill of state.selectedNextLevel?.skills ?? []) {
        if (skill.skillLevelID === action.payload.skillLevelID) {
          skill.status = action.payload.status;
          skill.isStarred = action.payload.isStarred;
          break;
        }
      }
      for (const skill of state.activeProgression?.currentLevel?.skills ?? []) {
        if (skill.skillLevelID === action.payload.skillLevelID) {
          skill.status = action.payload.status;
          skill.isStarred = action.payload.isStarred;
          break;
        }
      }
      if (state.selectedNextLevel?.skills && state.selectedNextLevel?.skills.length > 0) {
        state.selectedNextLevel.skills = sortSkillsArray(state.selectedNextLevel.skills);
      }
      if (
        state.selectedNextLevel?.skills &&
        state.selectedNextLevel?.skills.length > 0 &&
        state.activeProgression?.currentLevel?.skills &&
        state.activeProgression?.currentLevel?.skills.length > 0
      ) {
        state.selectedNextLevel.skills = findAndSetNewSkills(
          state.activeProgression.currentLevel.skills,
          state.selectedNextLevel.skills
        );
      }
    });

    // Fetch department levels
    builder.addCase(fetchDepartmentLevels.pending, (state) => {
      state.isDepartmentLevelsPending = true;
    });
    builder.addCase(fetchDepartmentLevels.rejected, (state, action) => {
      state.isDepartmentLevelsPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch department levels'));
    });
    builder.addCase(fetchDepartmentLevels.fulfilled, (state, action) => {
      state.departmentLevels = action.payload.map((level) => ({
        ...level,
        skills: []
      }));
      state.isDepartmentLevelsPending = false;
      state.error = null;
    });

    // Fetch progression level
    builder.addCase(fetchProgressionLevel.pending, (state) => {
      state.isNextLevelPending = true;
    });
    builder.addCase(fetchProgressionLevel.rejected, (state, action) => {
      state.isNextLevelPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch progression next level'));
    });
    builder.addCase(fetchProgressionLevel.fulfilled, (state, action) => {
      state.selectedNextLevel = action.payload;
      if (state.selectedNextLevel?.skills && state.selectedNextLevel?.skills.length > 0) {
        state.selectedNextLevel.skills = sortSkillsArray(state.selectedNextLevel.skills);
      }
      if (
        state.selectedNextLevel?.skills &&
        state.selectedNextLevel?.skills.length > 0 &&
        state.activeProgression?.currentLevel?.skills &&
        state.activeProgression?.currentLevel?.skills.length > 0
      ) {
        state.selectedNextLevel.skills = findAndSetNewSkills(
          state.activeProgression.currentLevel.skills,
          state.selectedNextLevel.skills
        );
      }

      state.isNextLevelPending = false;
      state.error = null;
    });
  }
});

export default progressionSlice.reducer;

export {
  completeProgression,
  fetchProgression,
  assignProgressionNextLevel,
  assignUserProgressionCurrentLevel,
  updateProgressionSkillStatus,
  fetchDepartmentLevels,
  fetchProgressionLevel,
  fetchAllDepartmentsLevels
};
