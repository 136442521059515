import DevelopmentPath, { DevelopmentPathData } from './DevelopmentPath.model';

export interface DepartmentData {
  /** @type {Generics.UUID} */
  id: string;
  name: string;
  updatedAt: string;
  developmentPaths: DevelopmentPathData[];
}

class Department implements DepartmentData {
  id: string;
  name: string;
  updatedAt: string;
  developmentPaths: DevelopmentPathData[];

  constructor(data: DepartmentData) {
    this.id = data.id;
    this.name = data.name;
    this.updatedAt = data.updatedAt;
    this.developmentPaths = data.developmentPaths;
  }

  static buildDepartmentDataFromResponse(response: any): DepartmentData {
    const developmentPaths: DevelopmentPathData[] = (response.development_paths ?? []).map(
      (developmentPath: any) => {
        return DevelopmentPath.buildDevelopmentPathDataFromResponse(developmentPath);
      }
    );

    return {
      id: response.id,
      name: response.name,
      updatedAt: response.updated_at ?? new Date().toISOString(),
      developmentPaths
    };
  }
}

export default Department;
