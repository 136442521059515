export interface LevelData {
  /** @type {Generics.UUID} */
  id: string;
  label: string;
  seniority: number;
  name: string;
  numberOfSkills: number;
}

class Level implements LevelData {
  id: string;
  label: string;
  seniority: number;
  name: string;
  numberOfSkills: number;

  constructor(data: LevelData) {
    this.id = data.id;
    this.label = data.label;
    this.seniority = data.seniority;
    this.name = data.name;
    this.numberOfSkills = data.numberOfSkills;
  }

  static buildLevelDataFromResponse(response: any): LevelData {
    return {
      id: response.id,
      label: response.label,
      seniority: response.seniority,
      name: response.name,
      numberOfSkills: response.number_of_skills
    };
  }
}

export default Level;
