import React, { Fragment, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox, Transition } from '@headlessui/react';

import { LevelData } from '../core/level/Level.model';
import { classNames } from '../common/utils';
import { useAppDispatch } from '../hooks';
import { useParams } from 'react-router-dom';
import { fetchAllDepartmentsLevels } from '../core/progression/progressionSlice';

type LevelComboboxProps = {
  defaultValue?: {
    /** @type {Generics.UUID} */
    id: string;
    name: string;
  };
  placeholder?: string;
  onChange?: (item: LevelData) => void;
};

const LevelsCombobox = ({
  defaultValue,
  placeholder,
  onChange = () => {}
}: LevelComboboxProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState('');
  const [levels, setLevels] = useState<LevelData[]>([]);
  const [selectedLevel, setSelectedLevel] = useState(defaultValue);

  const organizationSlug = params.organizationSlug ?? '';

  const filteredLevels =
    query === ''
      ? levels
      : levels.filter((level) => {
          return level.name.toLowerCase().includes(query.toLowerCase());
        });

  async function fetchAllDepartmentsLevelsHandler(): Promise<void> {
    const res: any = await dispatch(fetchAllDepartmentsLevels({ organizationSlug }));
    if (res.error) {
      return;
    }
    setLevels(res.payload);
  }

  return (
    <Combobox
      as="div"
      defaultValue={selectedLevel}
      onChange={(selectedItem: LevelData) => {
        setSelectedLevel(selectedItem);
        onChange(selectedItem);
      }}>
      <div className="relative">
        <div>
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(level: LevelData) => level?.name}
            placeholder={placeholder ?? 'Select a level'}
          />
          <Combobox.Button
            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
            onClick={(e) => {
              e.stopPropagation();
              fetchAllDepartmentsLevelsHandler();
            }}>
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}>
          <Combobox.Options className="absolute z-10 mt-1 max-h-52 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredLevels.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found
              </div>
            ) : (
              filteredLevels.map((level) => (
                <Combobox.Option
                  key={level.id}
                  value={level}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }>
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>
                        {level.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}>
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default LevelsCombobox;
