import Level, { LevelData } from '../core/level/Level.model';
import SkillLevel, { SkillLevelData } from '../core/library/skill/SkillLevel.model';
import { Api } from './api';
import { GetDepartmentArgs, GetDepartmentsArgs, getDepartments } from './department';
import { GetDevelopmentPathArgs } from './developmentPath';
import { parseError } from './error';

async function getAllDepartmentsLevels(args: GetDepartmentsArgs): Promise<LevelData[]> {
  try {
    const departments = await getDepartments(args);

    const promises: any = [];
    departments.forEach((department) => {
      promises.push(getDepartmentLevels({ ...args, departmentID: department.id }));
    });

    const departmentsLevels = await Promise.all(promises);
    return Promise.resolve(departmentsLevels.flat());
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetDepartmentLevelsArgs = GetDepartmentArgs;

async function getDepartmentLevels({
  organizationSlug,
  departmentID
}: GetDepartmentLevelsArgs): Promise<LevelData[]> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels`
    );
    return Promise.resolve(
      data.levels.map((level: any) => Level.buildLevelDataFromResponse(level))
    );
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateLevelArgs = GetDevelopmentPathArgs & {
  levelData: {
    label: string;
    name: string;
    seniority: number;
  };
};

async function createLevel({
  organizationSlug,
  departmentID,
  developmentPathID,
  levelData
}: CreateLevelArgs): Promise<LevelData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels`,
      {
        development_path_id: developmentPathID,
        label: levelData.label,
        name: levelData.name,
        seniority: levelData.seniority
      }
    );
    return Promise.resolve(Level.buildLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetLevelArgs = GetDepartmentLevelsArgs & {
  levelID: string;
};

async function getLevel({
  organizationSlug,
  departmentID,
  levelID
}: GetLevelArgs): Promise<LevelData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels/${levelID}`
    );
    return Promise.resolve(Level.buildLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateLevelArgs = GetLevelArgs & {
  levelData: LevelData;
};

async function updateLevel({
  organizationSlug,
  departmentID,
  levelID,
  levelData
}: UpdateLevelArgs): Promise<LevelData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels/${levelID}`,
      {
        label: levelData.label,
        name: levelData.name,
        seniority: levelData.seniority,
        description: levelData.description,
        salary_min: levelData.salaryMin,
        salary_max: levelData.salaryMax,
        salary_currency: levelData.salaryCurrency
      }
    );
    return Promise.resolve(Level.buildLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteLevel({
  organizationSlug,
  departmentID,
  levelID
}: GetLevelArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels/${levelID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type AssignSkillToLevelArgs = GetLevelArgs & {
  skillID: string;
  skillLevelID: string;
};

async function assignSkillToLevel({
  organizationSlug,
  departmentID,
  levelID,
  skillID,
  skillLevelID
}: AssignSkillToLevelArgs): Promise<SkillLevelData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels/${levelID}/skill`,
      {
        skill_id: skillID,
        skill_level_id: skillLevelID
      }
    );
    return Promise.resolve(SkillLevel.buildSkillLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UnassignSkillFromLevelArgs = GetLevelArgs & {
  skillLevelID: string;
};

async function unassignSkillFromLevel({
  organizationSlug,
  departmentID,
  levelID,
  skillLevelID
}: UnassignSkillFromLevelArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/departments/${departmentID}/levels/${levelID}/skill/${skillLevelID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  getAllDepartmentsLevels,
  getDepartmentLevels,
  createLevel,
  getLevel,
  updateLevel,
  deleteLevel,
  assignSkillToLevel,
  unassignSkillFromLevel
};
