import React, { HTMLInputTypeAttribute } from 'react';

type LabeledInputArgs = {
  label: string;
  inputType: HTMLInputTypeAttribute;
  placeholder?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const LabeledInput = ({
  label,
  inputType,
  placeholder,
  required = false,
  onChange
}: LabeledInputArgs): JSX.Element => {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">{label}</label>
      <div className="mt-2">
        <input
          type={inputType}
          autoComplete="off"
          required={required}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default LabeledInput;
