import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { INTEGRATION_TYPES, UserIntegrationData } from './UserIntegration.model';
import { CalendarEventData } from '../calendar/CalendarEvent.model';

type UserIntegrationsState = {
  integrations: UserIntegrationData;
  calendarEvents: CalendarEventData[];
  areUserIntegrationsFetched: boolean;
  areCalendarEventsPending: boolean;
};

const initialState: UserIntegrationsState = {
  integrations: {
    googleCalendar: false
  },
  calendarEvents: [],
  areUserIntegrationsFetched: false,
  areCalendarEventsPending: true
};

const fetchUserIntegrations = createAsyncThunk(
  'user/fetchUserIntegrations',
  async (_, { rejectWithValue }) => {
    try {
      return await api.getUserIntegrations();
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const connectUserGoogleCalendar = createAsyncThunk(
  'user/connectUserGoogleCalendar',
  async (args: api.GoogleCalendarOAuthUserArgs, { rejectWithValue }) => {
    try {
      return await api.googleCalendarOAuthUser(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const disconnectUserIntegration = createAsyncThunk(
  'user/disconnectUserIntegration',
  async (args: api.GetUserIntegrationArgs, { rejectWithValue }) => {
    try {
      return await api.disconnectUserIntegration(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchUserCalendarEvents = createAsyncThunk(
  'user/fetchUserCalendarEvents',
  async (args: api.GetUserIntegrationArgs, { rejectWithValue }) => {
    try {
      return await api.getUserCalendarEvents(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const userIntegrationsSlice = createSlice({
  name: 'userIntegrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch user integrations
    builder.addCase(fetchUserIntegrations.fulfilled, (state, action) => {
      state.integrations = action.payload;
      state.areUserIntegrationsFetched = true;
    });

    // Connect User Google Calendar
    builder.addCase(connectUserGoogleCalendar.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to connect Google Calendar'));
    });

    builder.addCase(connectUserGoogleCalendar.fulfilled, (state, action) => {
      state.integrations.googleCalendar = true;
    });

    // Disconnect user integration
    builder.addCase(disconnectUserIntegration.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to disconnect integration'));
    });

    builder.addCase(disconnectUserIntegration.fulfilled, (state, action) => {
      switch (action.meta.arg.integrationID) {
        case INTEGRATION_TYPES.GOOGLE_CALENDAR:
          state.integrations.googleCalendar = false;
          state.calendarEvents = [];
          break;
        default:
          break;
      }
    });

    // Fetch user calendar events
    builder.addCase(fetchUserCalendarEvents.pending, (state) => {
      state.areCalendarEventsPending = true;
    });

    builder.addCase(fetchUserCalendarEvents.rejected, (state, action) => {
      state.areCalendarEventsPending = false;
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to fetch calendar events'));
    });

    builder.addCase(fetchUserCalendarEvents.fulfilled, (state, action) => {
      state.areCalendarEventsPending = false;
      state.calendarEvents = action.payload;
    });
  }
});

export default userIntegrationsSlice.reducer;

export {
  // User Integrations
  fetchUserIntegrations,
  connectUserGoogleCalendar,
  disconnectUserIntegration,
  fetchUserCalendarEvents
};
