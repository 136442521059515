import SkillLevel, { SkillLevelData } from './SkillLevel.model';

export interface SkillData {
  /** @type {Generics.UUID} */
  id: string;
  createdBy: string;
  organization: { name: string };
  /** @type {Generics.UUID} */
  copyOfSkillID?: string;
  name: string;
  description: string;
  private: boolean;
  levels: SkillLevelData[];

  isFullyLoaded: boolean;
}

class Skill implements SkillData {
  id: string;
  createdBy: string;
  organization: { name: string };
  copyOfSkillID?: string;
  name: string;
  description: string;
  private: boolean;
  levels: SkillLevelData[];
  isFullyLoaded: boolean;

  constructor(data: SkillData) {
    this.id = data.id;
    this.createdBy = data.createdBy;
    this.organization = data.organization;
    this.copyOfSkillID = data.copyOfSkillID;
    this.name = data.name;
    this.description = data.description;
    this.private = data.private;
    this.levels = data.levels;
    this.isFullyLoaded = data.isFullyLoaded;
  }

  static buildSkillDataFromResponse(response: any): SkillData {
    const skillLevelsData: SkillLevelData[] = response.levels.map((level: any): SkillLevelData => {
      return SkillLevel.buildSkillLevelDataFromResponse(level);
    });

    return {
      id: response.id,
      createdBy: response.created_by,
      organization: { name: response.organization?.name },
      copyOfSkillID: response.copy_of_skill_id,
      name: response.name,
      description: response.description,
      private: response.private,
      levels: skillLevelsData,
      isFullyLoaded: false
    };
  }
}

export default Skill;
