import React from 'react';

type HorizontalLineProps = {
  dashed?: boolean;
};

const HorizontalLine = ({ dashed = false }: HorizontalLineProps): JSX.Element => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className={`w-full border-t ${dashed && 'border-dashed'} border-gray-300`} />
      </div>
    </div>
  );
};

export default HorizontalLine;
