import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { TeamData } from '../../core/team/Team.model';
import { checkPermissionAccess, classNames } from '../../common/utils';
import { Button } from '../ui/Button';
import { useAppDispatch, useAppSelector } from '../../core/store';
import Avatar from '../ui/Avatar';
import VerticalLine from '../VerticalLine';
import EllipsisMenu from '../ui/EllipsisMenu';
import HorizontalLine from '../HorizontalLine';
import PermissionProtectedAccess from '../PermissionProtectedAccess';
import { deleteTeam, removeTeamMember, updateTeam } from '../../core/team/teamsSlice';
import UpdateTeamModal from './UpdateTeamModal';
import AddNewTeamMemberModal from './AddNewTeamMemberModal';

type TeamViewSectionProps = {
  team: TeamData;
};

const TeamViewSection = ({ team }: TeamViewSectionProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);

  const [updateTeamModalOpen, setUpdateTeamModalOpen] = useState(false);
  const [addTeamMemberModalOpen, setAddTeamMemberModalOpen] = useState(false);

  const organizationSlug = params.organizationSlug ?? '';

  const canEdit =
    checkPermissionAccess(authUser.permissionRole, 'editor') ||
    (authUser.id === team.manager?.id && checkPermissionAccess(authUser.permissionRole, 'manager'));

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button as="div" className="w-full">
              <div
                className={classNames(
                  open ? 'bg-gray-100 rounded-t-md' : 'rounded-md',
                  `group flex flex-row gap-2 items-center p-2 w-full hover:bg-gray-100`
                )}>
                <span className="text-md text-gray-700 font-medium">{team.name}</span>
                <span
                  className={`${
                    team.department ? undefined : 'hidden'
                  } text-xs text-gray-600 mr-1`}>
                  ( {team.department?.name} )
                </span>

                <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                  <EllipsisMenu
                    direction="right"
                    items={[
                      {
                        name: 'Edit',
                        onClick: () => setUpdateTeamModalOpen(true)
                      },
                      {
                        name: 'Delete',
                        onClick: () => {
                          dispatch(deleteTeam({ organizationSlug, teamID: team.id }));
                        },
                        color: 'text-red-600'
                      }
                    ]}
                  />
                </PermissionProtectedAccess>

                <div className="flex-1 flex flex-row justify-end">
                  <ChevronDownIcon
                    className={classNames(
                      open
                        ? 'rotate-180 transform text-indigo-600'
                        : 'text-gray-600 group-hover:text-indigo-600',
                      'size-6'
                    )}
                  />
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="flex flex-row pb-2 pr-2 pl-4 -mt-2 rounded-b-md bg-gray-100">
              <VerticalLine />
              <div className="flex-1 flex flex-col gap-3 pt-1 pb-1">
                {/* Team manager */}
                {team.manager && (
                  <div className="flex flex-row gap-1.5 items-center">
                    <div className="flex-initial flex flex-col justify-center w-2">
                      <HorizontalLine />
                    </div>

                    <Avatar user={team.manager} size={5} />
                    <span className="text-sm text-gray-800">{`${team.manager.firstName} ${team.manager.lastName}`}</span>
                    <span className="text-xs text-gray-600 mr-1">( Manager )</span>

                    <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                      <EllipsisMenu
                        direction="right"
                        items={[
                          {
                            name: 'Reassign manager',
                            onClick: () => {
                              dispatch(
                                updateTeam({ organizationSlug, teamID: team.id, managerID: 'none' })
                              );
                            }
                          }
                        ]}
                      />
                    </PermissionProtectedAccess>
                  </div>
                )}

                {/* Team members */}
                {team.members.map((member) => (
                  <div key={member.id} className="flex flex-row gap-1.5 items-center">
                    <div className="flex-initial flex flex-col justify-center w-2">
                      <HorizontalLine />
                    </div>

                    <Avatar user={member} size={5} />
                    <span className="text-sm text-gray-800">{`${member.firstName} ${member.lastName}`}</span>

                    {canEdit && (
                      <EllipsisMenu
                        direction="right"
                        items={
                          authUser.permissionRole === 'manager'
                            ? [
                                {
                                  name: 'Remove member',
                                  onClick: () => {
                                    dispatch(
                                      removeTeamMember({
                                        organizationSlug,
                                        teamID: team.id,
                                        memberID: member.id
                                      })
                                    );
                                  },
                                  color: 'text-red-600'
                                }
                              ]
                            : [
                                {
                                  name: 'Assign manager',
                                  onClick: () => {
                                    dispatch(
                                      updateTeam({
                                        organizationSlug,
                                        teamID: team.id,
                                        managerID: member.id
                                      })
                                    );
                                  }
                                },
                                {
                                  name: 'Remove member',
                                  onClick: () => {
                                    dispatch(
                                      removeTeamMember({
                                        organizationSlug,
                                        teamID: team.id,
                                        memberID: member.id
                                      })
                                    );
                                  },
                                  color: 'text-red-600'
                                }
                              ]
                        }
                      />
                    )}
                  </div>
                ))}

                {/* Add member */}
                {canEdit && (
                  <div className="flex flex-row gap-1.5 items-center">
                    <div className="flex-initial flex flex-col justify-center w-2">
                      <HorizontalLine />
                    </div>

                    <Button
                      size={'sm'}
                      variant={'link'}
                      className="text-sm p-0 h-5"
                      onClick={() => setAddTeamMemberModalOpen(true)}>
                      Add team member
                    </Button>
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* Update team modal */}
      <UpdateTeamModal
        team={team}
        open={updateTeamModalOpen}
        onClose={() => setUpdateTeamModalOpen(false)}
      />

      {/* Add new team member modal */}
      <AddNewTeamMemberModal
        team={team}
        open={addTeamMemberModalOpen}
        onClose={() => setAddTeamMemberModalOpen(false)}
      />
    </>
  );
};

export default TeamViewSection;
