import React, { useEffect, useMemo, useState } from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';

import HorizontalLine from '../HorizontalLine';
import CheckboxInputItem from './CheckboxInputItem';
import { OneOnOneData } from '../../core/one-on-one/OneOnOne.model';
import { useAppDispatch, useAppSelector } from '../../core/store';
import {
  createActionItem,
  deleteActionItem,
  updateActionItem
} from '../../core/one-on-one/meetingSlice';
import AssignToUserMenu from '../ui/AssignToUserMenu';

type ActionItemsSectionProps = {
  organizationSlug: string;
  oneOnOne: OneOnOneData;
};

const ActionItemsSection = ({
  organizationSlug,
  oneOnOne
}: ActionItemsSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const meeting = useAppSelector((state) => state.meetings.meeting);

  const [oneOnOneParticipants, setOneOnOneParticipants] = useState([
    oneOnOne.guestUser,
    oneOnOne.hostUser
  ]);

  const [autoFocusItem, setAutoFocusItem] = useState(-1);

  useEffect(() => {
    setOneOnOneParticipants([oneOnOne.guestUser, oneOnOne.hostUser]);
  }, [oneOnOne.id]);

  const updateActionItemDebounced = useMemo(() => {
    return _.debounce((actionItemID: string, content: string) => {
      dispatch(
        updateActionItem({
          organizationSlug,
          oneOnOneID: oneOnOne.id,
          meetingID: meeting?.id ?? '',
          actionItemID,
          description: content
        })
      );
    }, 500);
  }, [meeting?.id]);

  if (!meeting) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="mb-1">
        <p className="font-medium mb-1">Action items</p>
        <HorizontalLine />
      </div>

      {meeting.actionItems.map((actionItem, index) => (
        <div key={actionItem.id} className="flex flex-row justify-between gap-6 items-start">
          <CheckboxInputItem
            checked={actionItem.completed}
            onCheck={(checked) => {
              dispatch(
                updateActionItem({
                  organizationSlug,
                  oneOnOneID: oneOnOne.id,
                  meetingID: meeting.id,
                  actionItemID: actionItem.id,
                  completed: checked
                })
              );
            }}
            value={actionItem.description}
            placeholder="Write action item..."
            autoFocus={autoFocusItem === index}
            onChange={(value) => {
              updateActionItemDebounced(actionItem.id, value);
            }}
          />

          <div className="flex flex-row items-center gap-2">
            <AssignToUserMenu
              users={oneOnOneParticipants}
              defaultUser={actionItem.assignedTo}
              onChange={(user) => {
                dispatch(
                  updateActionItem({
                    organizationSlug,
                    oneOnOneID: oneOnOne.id,
                    meetingID: meeting.id,
                    actionItemID: actionItem.id,
                    assignToUserID: user?.id ?? 'none'
                  })
                );
              }}
            />
            <button
              className="rounded-md items-center p-0.5 text-red-500 hover:text-red-600 hover:bg-gray-50"
              onClick={() => {
                dispatch(
                  deleteActionItem({
                    organizationSlug,
                    oneOnOneID: oneOnOne.id,
                    meetingID: meeting.id,
                    actionItemID: actionItem.id
                  })
                );
              }}>
              <XMarkIcon className="size-5" />
            </button>
          </div>
        </div>
      ))}

      {/* Add action item button */}
      <div className="flex flex-row gap-2 items-center">
        <button
          className="flex flex-row gap-2 items-center pr-1.5 text-gray-500 hover:text-gray-900 whitespace-nowrap text-md"
          onClick={() => {
            setAutoFocusItem(meeting.agendaItems.length);
            dispatch(
              createActionItem({
                organizationSlug,
                oneOnOneID: oneOnOne.id,
                meetingID: meeting.id ?? '',
                description: ''
              })
            );
          }}>
          <PlusIcon className="size-5" />
          <span>Add action item</span>
        </button>
      </div>
    </div>
  );
};

export default ActionItemsSection;
