import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ArrowUturnLeftIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/20/solid';
import _ from 'lodash';

import { classNames, generateLabel } from '../../../common/utils';
import { useAppDispatch, useAppSelector } from '../../../core/store';
import {
  createFrameworkDevelopmentPath,
  createFrameworkLevel,
  deleteFrameworkDevelopmentPath,
  fetchFrameworkDepartment,
  fetchLibraryFrameworks,
  frameworkDepartmentSelector,
  libraryFrameworkSelector,
  updateFrameworkDepartment,
  updateFrameworkDevelopmentPath
} from '../../../core/library/framework/frameworksSlice';
import AppLoader from '../../AppLoader';
import PermissionProtectedAccess from '../../PermissionProtectedAccess';
import AddNewDevelopmentPathModal from '../../cdf/AddNewDevelopmentPathModal';
import EditDevelopmentPathModal from '../../cdf/EditDevelopmentPathModal';
import { DevelopmentPathData } from '../../../core/cdf/DevelopmentPath.model';
import CreateNewLevelModal from '../../cdf/CreateNewLevelModal';
import HorizontalLine from '../../HorizontalLine';
import VerticalLine from '../../VerticalLine';
import { Button } from '../../ui/Button';
import ConfirmationDialog from '../../ui/ConfirmationDialog';
import FrameworkLevelOverview from './FrameworkLevelOverview';

type FrameworkDepartmentOverviewProps = {
  frameworkID: string;
  departmentID: string;
};

const FrameworkDepartmentOverview = ({
  frameworkID,
  departmentID
}: FrameworkDepartmentOverviewProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);

  const areFrameworksFetched = useAppSelector(
    (state) => state.libraryFrameworks.areFrameworksFetched
  );
  const isFrameworkDepartmentPending = useAppSelector(
    (state) => state.libraryFrameworks.isFrameworkDepartmentPending
  );
  const framework = useAppSelector((state) =>
    libraryFrameworkSelector(state.libraryFrameworks, frameworkID)
  );
  const frameworkDepartment = useAppSelector((state) =>
    frameworkDepartmentSelector(state.libraryFrameworks, frameworkID, departmentID)
  );

  const [open, setOpen] = useState(true);
  const [addNewDevelopmentPathModalOpen, setAddNewDevelopmentPathModalOpen] = useState(false);
  const [editDevelopmentPathModalOpen, setEditDevelopmentPathModalOpen] = useState(false);
  const [deleteDevelopmentPathModalOpen, setDeleteDevelopmentPathModalOpen] = useState(false);
  const [selectedDevelopmentPathData, setSelectedDevelopmentPathData] =
    useState<DevelopmentPathData>();
  const [selectedNewLevelSeniority, setSelectedNewLevelSeniority] = useState(1);
  const [createNewLevelModalOpen, setCreateNewLevelModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (!areFrameworksFetched) {
        await dispatch(fetchLibraryFrameworks({ organizationSlug }));
      }

      if (frameworkDepartment?.isFullyLoaded) {
        return;
      }
      dispatch(fetchFrameworkDepartment({ organizationSlug, frameworkID, departmentID }));
    })();
  }, [frameworkID, departmentID]);

  useEffect(() => {
    if (
      !searchParams.has('levelID') &&
      frameworkDepartment &&
      frameworkDepartment.developmentPaths.length > 0 &&
      frameworkDepartment.developmentPaths[0].levels.length > 0
    ) {
      searchParams.set('levelID', frameworkDepartment.developmentPaths[0].levels[0].id);
      setSearchParams(searchParams);
    }
  }, [frameworkDepartment]);

  const updateFrameworkDepartmentName = useMemo(() => {
    return _.debounce((name: string) => {
      dispatch(
        updateFrameworkDepartment({
          organizationSlug,
          frameworkID,
          departmentID,
          name
        })
      );
    }, 1000);
  }, [frameworkID, departmentID]);

  async function onCreateNewDevelopmentPath(name: string): Promise<boolean> {
    const res: any = await dispatch(
      createFrameworkDevelopmentPath({
        organizationSlug,
        frameworkID,
        departmentID,
        name
      })
    );
    return !res.error;
  }

  async function onUpdateDevelopmentPathName(newName: string): Promise<boolean> {
    if (!selectedDevelopmentPathData) {
      return false;
    }

    const res: any = await dispatch(
      updateFrameworkDevelopmentPath({
        organizationSlug,
        frameworkID,
        departmentID,
        developmentPathID: selectedDevelopmentPathData.id,
        name: newName
      })
    );
    return !res.error;
  }

  async function onCreateNewLevel(name: string): Promise<boolean> {
    if (!selectedDevelopmentPathData) {
      return false;
    }

    const res: any = await dispatch(
      createFrameworkLevel({
        organizationSlug,
        frameworkID,
        departmentID,
        developmentPathID: selectedDevelopmentPathData.id,
        levelData: {
          label: `${generateLabel(selectedDevelopmentPathData.name)}${selectedNewLevelSeniority}`,
          name,
          seniority: selectedNewLevelSeniority
        }
      })
    );
    if (res.error) {
      return false;
    }

    dispatch(fetchFrameworkDepartment({ organizationSlug, frameworkID, departmentID }));
    return true;
  }

  function renderSelectedLevel(): JSX.Element {
    if (searchParams.has('levelID')) {
      const levelID = searchParams.get('levelID') ?? '';
      return (
        <FrameworkLevelOverview
          frameworkID={frameworkID}
          departmentID={departmentID}
          levelID={levelID}
        />
      );
    }
    return <></>;
  }

  if (isFrameworkDepartmentPending) {
    return <AppLoader />;
  }

  if (!frameworkDepartment) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <ExclamationTriangleIcon className="size-12 text-gray-600" />
        <h3 className="text-xl font-semibold text-gray-600">Framework department not found!</h3>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-start gap-4 h-full w-full">
        <div className="flex-none flex flex-row">
          <div className="flex-none">
            <button
              type="button"
              className="rounded-md bg-white text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => {
                searchParams.delete('levelID');
                searchParams.delete('departmentID');
                setSearchParams(searchParams);
              }}>
              <span className="sr-only">Back</span>
              <ArrowUturnLeftIcon className="size-6" aria-hidden="true" />
            </button>
          </div>

          <div className="flex-1 flex flex-row justify-center mr-6">
            <h3 className="font-semibold text-xl text-gray-900">{framework?.name}</h3>
          </div>
        </div>

        <div className="flex flex-row h-full w-full">
          <Transition
            show={!open}
            as={Fragment}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100">
            <div className="flex flex-col justify-start w-8 pr-3 border-r border-gray-300 mt-1">
              <div className="flex flex-col justify-center h-7">
                <button
                  type="button"
                  className="flex-none rounded-md text-gray-600 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setOpen(true)}>
                  <span className="sr-only">Open panel</span>
                  <ChevronDoubleRightIcon className="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </Transition>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full">
            <div className="flex-none w-64 border-r border-gray-300 pr-3 mt-1">
              <div className="relative w-full h-full">
                <div className="absolute w-full h-full overflow-auto">
                  <div className="flex flex-col justify-start gap-2 pt-0.5 pl-0.5 pr-1">
                    <div className="flex flex-row justify-start gap-2">
                      <div className="flex-1">
                        <PermissionProtectedAccess
                          minimalRequiredPermissionRole="editor"
                          internalAccessRequired
                          fallbackElement={
                            <h3 className="font-semibold text-lg text-gray-900">
                              {frameworkDepartment.name}
                            </h3>
                          }>
                          <input
                            type="text"
                            defaultValue={frameworkDepartment.name}
                            autoComplete="off"
                            className="font-semibold rounded-md text-lg h-7 text-gray-900 border-0 p-0 focus:p-1"
                            onChange={(e) => {
                              updateFrameworkDepartmentName(e.target.value);
                            }}
                          />
                        </PermissionProtectedAccess>
                      </div>

                      <div className="flex-none flex flex-col justify-center">
                        <button
                          type="button"
                          className={`${
                            !open && 'hidden'
                          } rounded-md text-gray-600 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500`}
                          onClick={() => setOpen(false)}>
                          <span className="sr-only">Close panel</span>
                          <ChevronDoubleLeftIcon className="size-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <HorizontalLine />

                    <ul role="list" className="space-y-3 mt-2">
                      {frameworkDepartment.developmentPaths.map((developmentPath) => (
                        <li key={developmentPath.id} className="space-y-1">
                          <Disclosure defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="w-full">
                                  <div className="group flex flex-row justify-start w-full font-medium text-gray-900 hover:text-gray-700">
                                    <span className="flex-initial whitespace-nowrap truncate mr-2">
                                      {developmentPath.name}
                                    </span>

                                    <PermissionProtectedAccess
                                      minimalRequiredPermissionRole="editor"
                                      internalAccessRequired>
                                      <Menu as="div" className="flex-initial relative">
                                        <Menu.Button
                                          as="div"
                                          className="text-gray-400 hover:text-gray-500 mt-0.5">
                                          <EllipsisHorizontalIcon
                                            className="size-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95">
                                          <Menu.Items
                                            className={`absolute cursor-pointer ${
                                              developmentPath.name.length > 18
                                                ? 'right-0'
                                                : 'left-0'
                                            } z-10 mt-0.5 w-24 origin-top-left items-start rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedDevelopmentPathData(developmentPath);
                                                    setEditDevelopmentPathModalOpen(true);
                                                  }}
                                                  className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-gray-900 text-start'
                                                  )}>
                                                  Edit
                                                </a>
                                              )}
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedDevelopmentPathData(developmentPath);
                                                    setDeleteDevelopmentPathModalOpen(true);
                                                  }}
                                                  className={classNames(
                                                    active ? 'bg-gray-50' : '',
                                                    'block px-3 py-1 text-sm leading-6 text-red-600 text-start'
                                                  )}>
                                                  Delete
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </PermissionProtectedAccess>

                                    <div className="flex-1 flex flex-row justify-end pl-1">
                                      <ChevronDownIcon
                                        className={`${
                                          open ? 'rotate-180 transform' : ''
                                        } size-6 text-gray-600`}
                                      />
                                    </div>
                                  </div>
                                </Disclosure.Button>
                                <Disclosure.Panel className="flex flex-row ml-1">
                                  <VerticalLine />

                                  <div className="flex-1">
                                    <ul>
                                      {developmentPath.levels.map((level) => (
                                        <li
                                          key={level.id}
                                          onClick={() => {
                                            searchParams.set('levelID', level.id);
                                            setSearchParams(searchParams);
                                          }}>
                                          <div
                                            className={classNames(
                                              (searchParams.get('levelID') ?? '') === level.id
                                                ? 'text-black font-medium'
                                                : 'text-gray-800 hover:text-black hover:font-medium',
                                              'flex gap-x-3 rounded-md py-1.5 pr-2 text-sm cursor-pointer'
                                            )}>
                                            <div className="flex-initial flex flex-col justify-center w-2 -mr-2">
                                              <HorizontalLine />
                                            </div>
                                            <span className="flex items-center">{level.name}</span>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                    <PermissionProtectedAccess
                                      minimalRequiredPermissionRole="editor"
                                      internalAccessRequired>
                                      <div className="flex flex-row">
                                        <div className="flex-none flex flex-col justify-center w-2">
                                          <HorizontalLine />
                                        </div>
                                        <Button
                                          size={'sm'}
                                          variant={'link'}
                                          className="text-sm px-1 h-7"
                                          onClick={() => {
                                            setSelectedDevelopmentPathData(developmentPath);
                                            setSelectedNewLevelSeniority(
                                              developmentPath.levels.length + 1
                                            );
                                            setCreateNewLevelModalOpen(true);
                                          }}>
                                          Add new Level
                                        </Button>
                                      </div>
                                    </PermissionProtectedAccess>
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </li>
                      ))}
                    </ul>
                    <PermissionProtectedAccess
                      minimalRequiredPermissionRole="editor"
                      internalAccessRequired>
                      <div className="flex-initial text-start -ml-3">
                        <Button
                          size={'sm'}
                          variant={'link'}
                          className="text-sm pr-0"
                          onClick={() => {
                            setAddNewDevelopmentPathModalOpen(true);
                          }}>
                          Add new Career Development Path
                        </Button>
                      </div>
                    </PermissionProtectedAccess>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <div className="flex-1 flex ml-6">{renderSelectedLevel()}</div>
        </div>
      </div>
      <AddNewDevelopmentPathModal
        open={addNewDevelopmentPathModalOpen}
        onCreate={onCreateNewDevelopmentPath}
        onClose={() => setAddNewDevelopmentPathModalOpen(false)}
      />
      <EditDevelopmentPathModal
        developmentPath={selectedDevelopmentPathData}
        open={editDevelopmentPathModalOpen}
        onUpdate={onUpdateDevelopmentPathName}
        onClose={() => setEditDevelopmentPathModalOpen(false)}
      />
      <CreateNewLevelModal
        open={createNewLevelModalOpen}
        onCreate={onCreateNewLevel}
        onClose={() => setCreateNewLevelModalOpen(false)}
      />
      <ConfirmationDialog
        open={deleteDevelopmentPathModalOpen}
        title={'Are you absolutely sure?'}
        description={
          <span>
            This action cannot be undone. Confirming this action will permanently delete{' '}
            <span className="font-semibold">{selectedDevelopmentPathData?.name}</span> career
            development path.
          </span>
        }
        variant="destructive"
        onClose={() => setDeleteDevelopmentPathModalOpen(false)}
        onConfirm={() => {
          if (!selectedDevelopmentPathData) {
            return;
          }
          dispatch(
            deleteFrameworkDevelopmentPath({
              organizationSlug,
              frameworkID,
              departmentID,
              developmentPathID: selectedDevelopmentPathData.id
            })
          );
          setSelectedDevelopmentPathData(undefined);
          setDeleteDevelopmentPathModalOpen(false);
        }}
      />
    </>
  );
};

export default FrameworkDepartmentOverview;
