import React, { Fragment } from 'react';
import { Menu as HMenu, Transition } from '@headlessui/react';

import { classNames } from '../../common/utils';

interface MenuItemProps {
  name: string;
  onClick: () => void;
  color?: string;
}

interface MenuProps {
  buttonName: JSX.Element;
  direction?: 'left' | 'right';
  topOffset?: string;
  items: MenuItemProps[];
}

const Menu = ({ buttonName, topOffset, direction = 'left', items }: MenuProps): JSX.Element => {
  return (
    <HMenu as="div" className="relative flex flex-col justify-center">
      <HMenu.Button
        onClick={(e) => e.stopPropagation()}
        className="block -m-2.5 p-2.5 text-gray-600 hover:text-gray-900">
        <span className="sr-only">Open options</span>
        {buttonName}
      </HMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <HMenu.Items
          className={`absolute cursor-pointer ${topOffset ?? 'top-5'} ${
            direction === 'left' ? 'right-0' : 'left-0'
          } z-10 mt-0.5 min-w-28 w-fit origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none`}>
          {items.map((item) => (
            <HMenu.Item key={item.name}>
              {({ active, close }) => (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    item.onClick();
                    close();
                  }}
                  className={classNames(
                    active ? 'bg-gray-50' : '',
                    `block px-3 py-1 text-sm leading-6 text-left whitespace-nowrap ${
                      item.color ?? 'text-gray-900'
                    }`
                  )}>
                  {item.name}
                </a>
              )}
            </HMenu.Item>
          ))}
        </HMenu.Items>
      </Transition>
    </HMenu>
  );
};

export default Menu;
