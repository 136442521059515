import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

import { useAppDispatch } from '../../hooks';
import { fetchMeeting, fetchMeetings, updateMeeting } from '../../core/one-on-one/meetingSlice';
import { useAppSelector } from '../../core/store';
import {
  AppLoader,
  Badge,
  Button,
  EllipsisMenu,
  MeetingsListbox,
  NotFoundComponent,
  AgendaSection,
  ActionItemsSection,
  VerticalLine,
  MeetingNotesSection,
  StarredGrowthSkillsSection,
  OneOnOneSettingsModal,
  CreateNewOneOnOneModal
} from '../../components';
import { meetingDateFormat } from '../../common/timezone';
import { OneOnOneData } from '../../core/one-on-one/OneOnOne.model';
import { fetchOneOnOne, fetchOneOnOnes } from '../../core/one-on-one/oneOnOneSlice';
import { classNames } from '../../common/utils';

const OneOnOnePage = (): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const organizationSlug = params.organizationSlug ?? '';
  const memberID = params.memberID ?? '';

  const orgMember = useAppSelector((state) => state.employees.selectedEmployee);

  const oneOnOnes = useAppSelector((state) => state.oneOnOnes.oneOnOnes);
  const selectedOneOnOne = useAppSelector((state) => state.oneOnOnes.selectedOneOnOne);
  const meeting = useAppSelector((state) => state.meetings.meeting);
  const meetings = useAppSelector((state) => state.meetings.meetings);

  const areOneOnOnesFetched = useAppSelector((state) => state.oneOnOnes.areOneOnOnesFetched);
  const isSelectedOneOnOnePending = useAppSelector(
    (state) => state.oneOnOnes.isSelectedOneOnOnePending
  );
  const isMeetingPending = useAppSelector((state) => state.meetings.isMeetingPending);
  const areMeetingsPending = useAppSelector((state) => state.meetings.areMeetingsPending);

  const [oneOnOneExists, setOneOnOneExists] = useState(true);
  const [openOneOnOneSettingsModal, setOpenOneOnOneSettingsModal] = useState(false);
  const [openCreateNewOneOnOneModal, setOpenCreateNewOneOnOneModal] = useState(false);

  useEffect(() => {
    fetchOneOnOneData(false);
  }, [memberID]);

  async function fetchOneOnOneData(force: boolean): Promise<void> {
    let _oneOnOnes = oneOnOnes;
    if (!areOneOnOnesFetched || force) {
      const res: any = await dispatch(fetchOneOnOnes({ organizationSlug }));
      if (res.error) {
        return;
      }

      _oneOnOnes = res.payload as OneOnOneData[];
    }

    const oneOnOneID = _oneOnOnes.find(
      (oneOnOne) => oneOnOne.guestUser?.id === memberID || oneOnOne.hostUser?.id === memberID
    )?.id;
    if (!oneOnOneID) {
      setOneOnOneExists(false);
      return;
    }

    const res: any = await dispatch(fetchOneOnOne({ organizationSlug, oneOnOneID }));
    if (res.error) {
      return;
    }

    const oneOnOne = res.payload as OneOnOneData;
    dispatch(
      fetchMeeting({
        organizationSlug,
        oneOnOneID: oneOnOne.id,
        meetingID: oneOnOne.lastCreatedMeetingID ?? ''
      })
    );
    dispatch(fetchMeetings({ organizationSlug, oneOnOneID }));

    setOneOnOneExists(true);
  }

  if (!oneOnOneExists) {
    return (
      <>
        <div className="flex flex-col items-center h-full w-full mt-5 sm:mt-20">
          <div className="text-center space-y-3 max-w-xl">
            <h3 className="text-lg font-medium text-gray-800">
              No 1-on-1 set with {orgMember?.firstName} {orgMember?.lastName}
            </h3>
            <p className="text-md font-medium text-gray-700">
              Click the button below to schedule a 1-on-1 meeting.
            </p>
            <Button onClick={() => setOpenCreateNewOneOnOneModal(true)}>Create 1-on-1</Button>
          </div>
        </div>

        <CreateNewOneOnOneModal
          open={openCreateNewOneOnOneModal}
          onClose={() => setOpenCreateNewOneOnOneModal(false)}
          afterSuccessfullCreate={() => {
            fetchOneOnOneData(true);
          }}
        />
      </>
    );
  }

  if (isSelectedOneOnOnePending) {
    return <AppLoader />;
  }

  if (!selectedOneOnOne) {
    return (
      <NotFoundComponent
        title="One-on-one not found"
        description="The one-on-one you are looking for does not exist."
      />
    );
  }

  if (isMeetingPending || areMeetingsPending) {
    return <AppLoader />;
  }

  if (!meeting) {
    return (
      <NotFoundComponent
        title="Meeting not found"
        description="The meeting you are looking for does not exist."
      />
    );
  }

  const meetingScheduledTime = moment(meeting.scheduledTime).local();

  const isItLatestMeeting = meetings[0].id === meeting.id;
  const isItEarliestMeeting = meetings[meetings.length - 1].id === meeting.id;

  function renderMeetingBadge(): JSX.Element {
    if (!selectedOneOnOne?.active) {
      return (
        <div className="flex flex-col justify-center">
          <Badge variant="gray" className="px-2 py-3">
            Not active
          </Badge>
        </div>
      );
    }

    if (meeting?.canceled) {
      return (
        <div className="flex flex-col justify-center">
          <Badge variant="red" className="px-2 py-3">
            Canceled
          </Badge>
        </div>
      );
    }

    if (meetingScheduledTime.isAfter(moment().local())) {
      return (
        <div className="flex flex-col justify-center">
          <Badge variant="blue" className="px-2 py-3">
            Upcoming
          </Badge>
        </div>
      );
    }

    return <></>;
  }

  return (
    <>
      <div className="flex -ml-1 -mr-7 h-full">
        <div className="relative h-full w-full">
          <div className="absolute h-full w-full overflow-auto pl-1 pr-7">
            <div className="flex flex-col gap-3 h-full w-full pb-4">
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-col sm:flex-row gap-3 w-full">
                  <div className="flex flex-row justify-between">
                    <MeetingsListbox
                      meetings={meetings}
                      defaultMeetingID={meeting.id}
                      onChange={(selectedMeeting) => {
                        dispatch(
                          fetchMeeting({
                            organizationSlug,
                            oneOnOneID: selectedOneOnOne.id,
                            meetingID: selectedMeeting.id
                          })
                        );
                      }}
                    />

                    <div className="flex sm:hidden">
                      <Button
                        variant="outline"
                        size={'sm'}
                        className="font-medium"
                        onClick={() => {
                          setOpenOneOnOneSettingsModal(true);
                        }}>
                        <Cog6ToothIcon
                          className="size-5 font-medium hover:text-gray-900 mr-1"
                          aria-hidden="true"
                        />
                        Settings
                      </Button>
                    </div>
                  </div>

                  <div className="flex flex-row gap-3 w-full">
                    <div className="flex flex-row gap-2">
                      <button
                        className={classNames(
                          isItEarliestMeeting
                            ? 'text-gray-400'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'rounded-md'
                        )}
                        disabled={isItEarliestMeeting}
                        onClick={() => {
                          const prevMeetingIndex =
                            meetings.findIndex((m) => m.id === meeting.id) + 1;
                          dispatch(
                            fetchMeeting({
                              organizationSlug,
                              oneOnOneID: selectedOneOnOne.id,
                              meetingID: meetings[prevMeetingIndex].id
                            })
                          );
                        }}>
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="size-6" />
                      </button>
                      <button
                        className={classNames(
                          isItLatestMeeting
                            ? 'text-gray-400'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'rounded-md'
                        )}
                        disabled={isItLatestMeeting}
                        onClick={() => {
                          const nextMeetingIndex =
                            meetings.findIndex((m) => m.id === meeting.id) - 1;
                          dispatch(
                            fetchMeeting({
                              organizationSlug,
                              oneOnOneID: selectedOneOnOne.id,
                              meetingID: meetings[nextMeetingIndex].id
                            })
                          );
                        }}>
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="size-6" />
                      </button>
                    </div>

                    <div className="flex flex-col justify-center">
                      <p className="text-gray-900 text-sm sm:text-base whitespace-nowrap">
                        {meetingScheduledTime.format(meetingDateFormat)}
                      </p>
                    </div>

                    {renderMeetingBadge()}

                    <EllipsisMenu
                      items={[
                        {
                          name: 'Cancel',
                          onClick: () => {
                            dispatch(
                              updateMeeting({
                                organizationSlug,
                                oneOnOneID: selectedOneOnOne.id,
                                meetingID: meeting.id,
                                canceled: true
                              })
                            );
                          },
                          color: 'text-red-600'
                        }
                      ]}
                    />
                  </div>
                </div>

                <div className="hidden sm:flex">
                  <Button
                    variant="outline"
                    size={'sm'}
                    className="font-medium"
                    onClick={() => {
                      setOpenOneOnOneSettingsModal(true);
                    }}>
                    <Cog6ToothIcon
                      className="size-5 font-medium hover:text-gray-900 mr-1"
                      aria-hidden="true"
                    />
                    Settings
                  </Button>
                </div>
              </div>
              <div className="flex-1 flex flex-row gap-6 h-full w-full py-2">
                <div className="flex-1 flex flex-col gap-6 w-full">
                  {/* Agenda */}
                  <AgendaSection organizationSlug={organizationSlug} oneOnOne={selectedOneOnOne} />

                  {/* Action items */}
                  <ActionItemsSection
                    organizationSlug={organizationSlug}
                    oneOnOne={selectedOneOnOne}
                  />

                  {/* Notes */}
                  <MeetingNotesSection oneOnOne={selectedOneOnOne} />
                </div>

                {meeting.progression && (
                  <>
                    <div className="hidden sm:flex">
                      <VerticalLine />
                    </div>

                    <div className="hidden sm:flex flex-col gap-8 w-[30%]">
                      {/* Starred Grow Skills */}
                      <StarredGrowthSkillsSection meetingProgression={meeting.progression} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <OneOnOneSettingsModal
        organizationSlug={organizationSlug}
        oneOnOne={selectedOneOnOne}
        meeting={meetings[0]} // Always use the latest meeting for settings
        open={openOneOnOneSettingsModal}
        onClose={() => {
          setOpenOneOnOneSettingsModal(false);
        }}
      />
    </>
  );
};

export default OneOnOnePage;
