import React, { useState } from 'react';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import TextareaAutosize from 'react-textarea-autosize';

import { timeAgo } from '../../common/utils';
import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import { ProgressionLevelSkillData } from '../../core/progression/ProgressionLevelSkill.model';
import { UserData } from '../../core/user/User.model';
import Avatar from '../ui/Avatar';
import { Button } from '../ui/Button';
import EllipsisMenu from '../ui/EllipsisMenu';
import {
  createGrowAreaUpdate,
  deleteGrowAreaUpdate,
  updateGrowAreaUpdate
} from '../../core/grow-area/growAreasSlice';

type GrowAreasUpdatesSectionProps = {
  organizationSlug: string;
  createdForUser: UserData;
  skillLevel: ProgressionLevelSkillData;
};

const GrowAreaUpdatesSection = ({
  organizationSlug,
  createdForUser,
  skillLevel
}: GrowAreasUpdatesSectionProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const selectedGrowArea = useAppSelector((state) => state.growAreas.selectedGrowArea);

  const [newGrowAreaUpdate, setNewGrowAreaUpdate] = useState('');
  const [growAreaUpdateEditable, setGrowAreaUpdateEditable] = useState<string>();
  const [editGrowAreaUpdate, setEditGrowAreaUpdate] = useState('');

  const growAreaUpdates = selectedGrowArea?.updates ?? [];

  function renderContent(): JSX.Element {
    if (growAreaUpdates.length === 0) {
      return (
        <li className="flex-1 items-center flex justify-center">
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-100">
              <XMarkIcon className="size-6 text-gray-400" aria-hidden="true" />
            </div>
            <p className="mt-2 text-sm text-gray-500">No updates yet</p>
          </div>
        </li>
      );
    }

    return (
      <>
        {growAreaUpdates.map((growAreaUpdate) => (
          <li key={growAreaUpdate.id} className="flex">
            <div className="flex items-start mr-3 mt-2">
              <Avatar user={growAreaUpdate.author} />
            </div>

            <div className="flex-1 border rounded-lg px-4 pt-2 pb-3 leading-relaxed">
              <div className="flex flex-row justify-between w-full">
                <div className="flex-initial">
                  <span className="text-base font-semibold">
                    {growAreaUpdate.author?.firstName ?? 'Deleted User'}
                  </span>
                  {new Date(growAreaUpdate.createdAt).getTime() ===
                  new Date(growAreaUpdate.updatedAt).getTime() ? (
                    <span className="ml-2 text-xs text-gray-600">
                      {timeAgo(new Date(), new Date(growAreaUpdate.createdAt))}
                    </span>
                  ) : (
                    <span className="ml-2 text-xs text-gray-600">
                      {timeAgo(new Date(), new Date(growAreaUpdate.updatedAt))} edited
                    </span>
                  )}
                </div>

                <div className="flex-initial flex flex-row gap-3">
                  {growAreaUpdateEditable === growAreaUpdate.id && (
                    <div className="flex-initial flex flex-row gap-1 pt-1">
                      <div className="flex-initial">
                        <button
                          className="text-red-600 hover:text-red-400"
                          onClick={(e) => {
                            e.stopPropagation();

                            setEditGrowAreaUpdate('');
                            setGrowAreaUpdateEditable(undefined);
                          }}>
                          <XMarkIcon className="size-5" aria-hidden="true" />
                        </button>
                      </div>

                      <div className="flex-initial">
                        <button
                          className="text-indigo-600 hover:text-indigo-400"
                          onClick={(e) => {
                            e.stopPropagation();

                            dispatch(
                              updateGrowAreaUpdate({
                                organizationSlug,
                                growAreaID: selectedGrowArea?.id ?? '',
                                growAreaUpdateID: growAreaUpdate.id,
                                content: editGrowAreaUpdate
                              })
                            ).finally(() => {
                              setEditGrowAreaUpdate('');
                            });

                            setGrowAreaUpdateEditable(undefined);
                          }}>
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  )}

                  {growAreaUpdate.author?.id === authUser.id && (
                    <EllipsisMenu
                      items={[
                        {
                          name: 'Edit',
                          onClick: () => {
                            setGrowAreaUpdateEditable(growAreaUpdate.id);
                            setEditGrowAreaUpdate(growAreaUpdate.content);
                          }
                        },
                        {
                          name: 'Delete',
                          onClick: () => {
                            dispatch(
                              deleteGrowAreaUpdate({
                                organizationSlug,
                                growAreaID: selectedGrowArea?.id ?? '',
                                growAreaUpdateID: growAreaUpdate.id
                              })
                            );
                          },
                          color: 'text-red-600'
                        }
                      ]}
                    />
                  )}
                </div>
              </div>

              {growAreaUpdateEditable === growAreaUpdate.id ? (
                <TextareaAutosize
                  autoFocus
                  className="text-md text-gray-900 w-full max-w-full min-h-[2rem] max-h-[15rem] border-0 focus:ring-0 focus:outline-none -mx-3 -my-2"
                  value={editGrowAreaUpdate}
                  onChange={(e) => {
                    e.stopPropagation();
                    setEditGrowAreaUpdate(e.target.value);
                  }}
                />
              ) : (
                <div className="text-md text-gray-900 w-full max-w-full whitespace-pre-wrap">
                  {growAreaUpdate.content}
                </div>
              )}
            </div>
          </li>
        ))}
        {/* Do not delete this empty div */}
        <div></div>
      </>
    );
  }

  return (
    <div className="flex flex-col gap-3 mb-3 h-full">
      <p className="font-medium">Write a progress update you want to share</p>

      <div className="flex flex-row gap-3">
        <form
          className="flex-1"
          onSubmit={(e) => {
            e.preventDefault();

            dispatch(
              createGrowAreaUpdate({
                organizationSlug,
                growAreaID: selectedGrowArea?.id ?? '',
                content: newGrowAreaUpdate
              })
            ).then(() => {
              setNewGrowAreaUpdate('');
            });
          }}>
          <div className="p-2 bg-white rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <label htmlFor="update-text" className="sr-only">
              Your update
            </label>
            <textarea
              id="update-text"
              rows={2}
              className="p-0 w-full text-md text-gray-900 border-0 focus:ring-0 focus:outline-none min-h-[3rem] max-h-[15rem] placeholder:text-gray-400 placeholder:italic"
              value={newGrowAreaUpdate}
              placeholder={`Describe ${
                createdForUser.id === authUser.id ? 'your' : createdForUser.firstName + "'s"
              } progress in skill development here. Share specific milestones, learning experiences, and any challenges.`}
              onChange={(e) => setNewGrowAreaUpdate(e.target.value)}
              required
            />
          </div>
          <div className="flex justify-end mt-2">
            <Button size={'sm'} type="submit">
              Post Update
            </Button>
          </div>
        </form>
      </div>

      <ul role="list" className="flex-1 flex flex-col gap-3 overflow-y-auto mt-2 h-full">
        {renderContent()}
      </ul>
    </div>
  );
};

export default GrowAreaUpdatesSection;
