import User, { UserData } from '../user/User.model';

export type OneOnOneData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  organizationID: string;

  hostUser: UserData | null;
  guestUser: UserData | null;

  active: boolean;
  lastCreatedMeetingID?: string;

  meetingFrequency?: number;

  calendarType?: string;
};

class OneOnOne implements OneOnOneData {
  id: string;
  organizationID: string;
  hostUser: UserData | null;
  guestUser: UserData | null;
  active: boolean;
  lastCreatedMeetingID?: string;
  meetingFrequency?: number;
  calendarType?: string;

  constructor(data: OneOnOneData) {
    this.id = data.id;
    this.organizationID = data.organizationID;
    this.hostUser = data.hostUser;
    this.guestUser = data.guestUser;
    this.active = data.active;
    this.lastCreatedMeetingID = data.lastCreatedMeetingID;
    this.meetingFrequency = data.meetingFrequency;
    this.calendarType = data.calendarType;
  }

  getNonSelfUser(authUserID: string): UserData | null {
    if (this.hostUser?.id !== authUserID) {
      return this.hostUser;
    }
    return this.guestUser;
  }

  static buildOneOnOneDataFromResponse(response: any): OneOnOneData {
    return {
      id: response.id,
      organizationID: response.organization_id,
      hostUser: response.host_user ? User.buildUserDataFromResponse(response.host_user) : null,
      guestUser: response.guest_user ? User.buildUserDataFromResponse(response.guest_user) : null,
      active: response.active,
      lastCreatedMeetingID: response.last_created_meeting_id ?? undefined,
      meetingFrequency: response.meeting_frequency ?? undefined,
      calendarType: response.calendar_type ?? undefined
    };
  }
}

export default OneOnOne;
