import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  UsersIcon,
  UserGroupIcon,
  LockClosedIcon,
  XMarkIcon,
  UserIcon,
  BuildingOffice2Icon,
  HomeModernIcon
} from '@heroicons/react/24/outline';
import { GiArcheryTarget } from 'react-icons/gi';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import TextareaAutosize from 'react-textarea-autosize';
import moment from 'moment-timezone';
import { toast } from 'sonner';

import { useAppDispatch } from '../../hooks';
import { Button } from '../ui/Button';
import HorizontalLine from '../HorizontalLine';
import UsersCombobox from '../UsersCombobox';
import { useAppSelector } from '../../core/store';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';
import BasicListbox from '../BasicListbox';
import { createGoal } from '../../core/goal/goalsSlice';
import { GoalData, GoalDomain, GoalType } from '../../core/goal/Goal.model';
import { fetchTeams } from '../../core/team/teamsSlice';
import { fetchDepartments } from '../../core/cdf/departmentsSlice';

export const privacyTypes = [
  {
    id: 'public',
    name: (
      <div className="flex items-center gap-1 mr-2">
        <UsersIcon className="size-4" />
        Public
      </div>
    )
  },
  {
    id: 'private',
    name: (
      <div className="flex items-center gap-1 mr-2">
        <LockClosedIcon className="size-4" />
        Private
      </div>
    )
  }
];

export const domainTypes = [
  {
    id: GoalDomain.ORGANIZATION,
    name: (
      <div className="flex items-center gap-1 mr-2">
        <BuildingOffice2Icon className="size-4" />
        Organization
      </div>
    )
  },
  {
    id: GoalDomain.DEPARTMENT,
    name: (
      <div className="flex items-center gap-1 mr-2">
        <HomeModernIcon className="size-4" />
        Department
      </div>
    )
  },
  {
    id: GoalDomain.TEAM,
    name: (
      <div className="flex items-center gap-1 mr-2">
        <UserGroupIcon className="size-4" />
        Team
      </div>
    )
  },
  {
    id: GoalDomain.INDIVIDUAL,
    name: (
      <div className="flex items-center gap-1 mr-2">
        <UserIcon className="size-4" />
        Individual
      </div>
    )
  }
];

interface CreateNewObjectiveModalProps {
  parent?: GoalData;
  open: boolean;
  onClose: () => void;
}

const CreateNewObjectiveModal = ({
  parent,
  open,
  onClose: _onClose
}: CreateNewObjectiveModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const areEmployeesFetched = useAppSelector((state) => state.employees.areEmployeesFetched);
  const employees = useAppSelector((state) => state.employees.employees);
  const areTeamsFetched = useAppSelector((state) => state.teams.areTeamsFetched);
  const teams = useAppSelector((state) => state.teams.teams);
  const areDepartmentsFetched = useAppSelector((state) => state.departments.areDepartmentsFetched);
  const departments = useAppSelector((state) => state.departments.departments);

  const [title, setTitle] = useState('');
  const [selectedDomain, setSelectedDomain] = useState<GoalDomain>();
  const [selectedTeamID, setSelectedTeamID] = useState<string>();
  const [selectedDepartmentID, setSelectedDepartmentID] = useState<string>();
  const [ownerID, setOwnerID] = useState<string>();
  const [isPrivate, setIsPrivate] = useState(false);
  const [startDate, setStartDate] = useState<DateValueType>({
    startDate: new Date(),
    endDate: new Date()
  });
  const [dueDate, setDueDate] = useState<DateValueType>({ startDate: null, endDate: null });

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (open && !areEmployeesFetched) {
      dispatch(fetchOrganizationUsers({ organizationSlug }));
    }
  }, [open]);

  useEffect(() => {
    if (selectedDomain === GoalDomain.TEAM && !areTeamsFetched) {
      dispatch(fetchTeams({ organizationSlug }));
    }
    if (selectedDomain === GoalDomain.DEPARTMENT && !areDepartmentsFetched) {
      dispatch(fetchDepartments({ organizationSlug }));
    }
  }, [selectedDomain]);

  async function createObjectiveHandler(): Promise<void> {
    if (!title) {
      toast.warning('Please enter a title');
      return;
    }
    if (!selectedDomain) {
      toast.warning('Please select a domain');
      return;
    }
    if (selectedDomain === GoalDomain.TEAM && !selectedTeamID) {
      toast.warning('Please select a team');
      return;
    }
    if (selectedDomain === GoalDomain.DEPARTMENT && !selectedDepartmentID) {
      toast.warning('Please select a department');
      return;
    }
    if (!ownerID) {
      toast.warning('Please select an owner');
      return;
    }
    if (!startDate?.startDate) {
      toast.warning('Please select a start date');
      return;
    }
    if (!dueDate?.startDate) {
      toast.warning('Please select a due date');
      return;
    }

    const res: any = await dispatch(
      createGoal({
        organizationSlug,
        data: {
          type: GoalType.OBJECTIVE,
          title,
          description: '',
          ownerID,
          domain: selectedDomain,
          private: isPrivate,
          startDate: moment(startDate.startDate).toDate(),
          dueDate: moment(dueDate.startDate).toDate(),
          departmentID: selectedDepartmentID,
          teamID: selectedTeamID,
          parentID: parent?.id
        }
      })
    );
    if (res.error) {
      return;
    }

    onClose();
  }

  function onClose(): void {
    // Clear all the fields
    setTitle('');
    setSelectedDomain(undefined);
    setSelectedTeamID(undefined);
    setSelectedDepartmentID(undefined);
    setOwnerID(undefined);
    setIsPrivate(false);
    setStartDate({ startDate: new Date(), endDate: new Date() });
    setDueDate({ startDate: null, endDate: null });

    _onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="flex flex-row items-center gap-1 text-base font-semibold leading-6 text-gray-900">
                      <GiArcheryTarget className="size-6 shrink-0" aria-hidden="true" />
                      Create Objective
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    <div className="flex flex-col gap-1">
                      <p className="block text-sm font-medium text-gray-700">Goal title</p>
                      <TextareaAutosize
                        className="text-gray-900 h-9 rounded-md border-gray-300 text-sm resize-none"
                        placeholder="What is the goal or objective you want accomplish?"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Goal domain</p>
                        <BasicListbox
                          items={domainTypes}
                          placeholder="Select goal domain"
                          onChange={(domainType) => setSelectedDomain(domainType.id as GoalDomain)}
                        />
                      </div>

                      <div
                        className={`${
                          selectedDomain === GoalDomain.TEAM ? 'flex-1 w-full' : 'hidden'
                        }`}>
                        <p className="block text-sm font-medium text-gray-700 mb-1">
                          Accountable team
                        </p>
                        <BasicListbox
                          items={teams}
                          placeholder="Select team"
                          onChange={(team) => setSelectedTeamID(team.id)}
                        />
                      </div>

                      <div
                        className={`${
                          selectedDomain === GoalDomain.DEPARTMENT ? 'flex-1 w-full' : 'hidden'
                        }`}>
                        <p className="block text-sm font-medium text-gray-700 mb-1">
                          Accountable department
                        </p>
                        <BasicListbox
                          items={departments}
                          placeholder="Select department"
                          onChange={(team) => {
                            setSelectedDepartmentID(team.id);
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Goal owner</p>
                        <UsersCombobox
                          removeAuthUser={false}
                          users={employees}
                          placeholder="Select goal owner"
                          onChange={(user) => setOwnerID(user.id)}
                        />
                      </div>

                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Privacy</p>
                        <BasicListbox
                          items={privacyTypes}
                          defaultItem={privacyTypes[0]}
                          onChange={(privacyType) => setIsPrivate(privacyType.id === 'private')}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col sm:flex-row sm:justify-between gap-6">
                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Start date</p>
                        <Datepicker
                          primaryColor={'indigo'}
                          inputClassName={
                            'w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm'
                          }
                          placeholder="yyyy-mm-dd"
                          useRange={false}
                          asSingle={true}
                          value={startDate}
                          onChange={(newDate) => setStartDate(newDate)}
                        />
                      </div>

                      <div className="flex-1 w-full">
                        <p className="block text-sm font-medium text-gray-700 mb-1">Due date</p>
                        <Datepicker
                          primaryColor={'indigo'}
                          inputClassName={
                            'w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm'
                          }
                          placeholder="yyyy-mm-dd"
                          useRange={false}
                          asSingle={true}
                          value={dueDate}
                          onChange={(newDate) => setDueDate(newDate)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1">
                  <Button
                    onClick={() => {
                      createObjectiveHandler();
                    }}>
                    Create
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNewObjectiveModal;
