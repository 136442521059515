import Department, { DepartmentData } from '../cdf/Department.model';
import User, { UserData } from '../user/User.model';

export interface TeamData {
  /** @type {Generics.UUID} */
  id: string;

  name: string;

  department?: DepartmentData;
  manager: UserData | null;
  members: UserData[];
}

class Team implements TeamData {
  id: string;
  name: string;
  department?: DepartmentData;
  manager: UserData | null;
  members: UserData[];

  constructor(data: TeamData) {
    this.id = data.id;
    this.name = data.name;
    this.department = data.department;
    this.manager = data.manager;
    this.members = data.members;
  }

  static buildTeamDataFromResponse(response: any): TeamData {
    return {
      id: response.id,
      name: response.name,
      department: response.department
        ? Department.buildDepartmentDataFromResponse(response.department)
        : undefined,
      manager: response.manager ? User.buildUserDataFromResponse(response.manager) : null,
      members: (response.members ?? []).map((member: any) => User.buildUserDataFromResponse(member))
    };
  }
}

export default Team;
