import User, { UserData } from '../user/User.model';
import ProgressionLevel, { ProgressionLevelData } from './ProgressionLevel.model';

export interface ProgressionData {
  /** @type {Generics.UUID} */
  id: string;
  user: UserData;
  manager: UserData | null;
  teamMembers: UserData[];
  currentLevel: ProgressionLevelData | null;
  nextLevel: ProgressionLevelData | null;
  completed: boolean;
}

class Progression implements ProgressionData {
  id: string;
  user: UserData;
  manager: UserData | null;
  teamMembers: UserData[];
  currentLevel: ProgressionLevelData | null;
  nextLevel: ProgressionLevelData | null;
  completed: boolean;

  constructor(data: ProgressionData) {
    this.id = data.id;
    this.user = data.user;
    this.manager = data.manager;
    this.teamMembers = data.teamMembers;
    this.currentLevel = data.currentLevel;
    this.nextLevel = data.nextLevel;
    this.completed = data.completed;
  }

  static buildProgressionDataFromResponse(response: any): ProgressionData {
    return {
      id: response.id,
      user: User.buildUserDataFromResponse(response.user),
      manager: response.manager ? User.buildUserDataFromResponse(response.manager) : null,
      teamMembers: (response.team_members ?? []).map((teamMember: any) =>
        User.buildUserDataFromResponse(teamMember)
      ),
      currentLevel: response.current_level
        ? ProgressionLevel.buildProgressionLevelDataFromResponse(response.current_level)
        : null,
      nextLevel: response.next_level
        ? ProgressionLevel.buildProgressionLevelDataFromResponse(response.next_level)
        : null,
      completed: response.completed
    };
  }
}

export default Progression;
