import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { MeetingData } from './Meeting.model';

type MeetingState = {
  meetings: MeetingData[];
  meeting: MeetingData | null;

  areMeetingsPending: boolean;
  isMeetingPending: boolean;

  error: api.ApiError;
};

const initialState: MeetingState = {
  meetings: [],
  meeting: null,
  areMeetingsPending: true,
  isMeetingPending: true,
  error: null
};

const fetchMeetings = createAsyncThunk(
  'meeting/getMeetings',
  async (args: api.GetMeetingsArgs, { rejectWithValue }) => {
    try {
      return await api.getMeetings(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchMeeting = createAsyncThunk(
  'meeting/getMeeting',
  async (args: api.GetMeetingArgs, { rejectWithValue }) => {
    try {
      return await api.getMeeting(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createMeeting = createAsyncThunk(
  'meeting/createMeeting',
  async (args: api.CreateMeetingArgs, { rejectWithValue }) => {
    try {
      return await api.createMeeting(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateMeeting = createAsyncThunk(
  'meeting/updateMeeting',
  async (args: api.UpdateMeetingArgs, { rejectWithValue }) => {
    try {
      return await api.updateMeeting(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createActionItem = createAsyncThunk(
  'meeting/createActionItem',
  async (args: api.CreateActionItemArgs, { rejectWithValue }) => {
    try {
      return await api.createActionItem(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateActionItem = createAsyncThunk(
  'meeting/updateActionItem',
  async (args: api.UpdateActionItemArgs, { rejectWithValue }) => {
    try {
      return await api.updateActionItem(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteActionItem = createAsyncThunk(
  'meeting/deleteActionItem',
  async (args: api.GetActionItemsArgs, { rejectWithValue }) => {
    try {
      return await api.deleteActionItem(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const createAgendaItem = createAsyncThunk(
  'meeting/createAgendaItem',
  async (args: api.CreateAgendaItemArgs, { rejectWithValue }) => {
    try {
      return await api.createAgendaItem(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateAgendaItem = createAsyncThunk(
  'meeting/updateAgendaItem',
  async (args: api.UpdateAgendaItemArgs, { rejectWithValue }) => {
    try {
      return await api.updateAgendaItem(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteAgendaItem = createAsyncThunk(
  'meeting/deleteAgendaItem',
  async (args: api.GetAgendaItemsArgs, { rejectWithValue }) => {
    try {
      return await api.deleteAgendaItem(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const saveMeetingNote = createAsyncThunk(
  'meeting/saveMeetingNote',
  async (args: api.SaveMeetingNoteArgs, { rejectWithValue }) => {
    try {
      return await api.saveMeetingNote(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch meetings
    builder.addCase(fetchMeetings.pending, (state) => {
      state.areMeetingsPending = true;
    });
    builder.addCase(fetchMeetings.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.areMeetingsPending = false;
      state.meetings = [];
      toast.error(getCustomUserError(state.error, 'Failed to fetch 1-on-1 meetings'));
    });
    builder.addCase(fetchMeetings.fulfilled, (state, action) => {
      state.meetings = action.payload;
      state.areMeetingsPending = false;
      state.error = null;
    });

    // Fetch meeting
    builder.addCase(fetchMeeting.pending, (state) => {
      state.isMeetingPending = true;
    });
    builder.addCase(fetchMeeting.rejected, (state, action) => {
      state.error = action.payload as api.ApiError;
      state.isMeetingPending = false;
      if (state.meeting?.id !== action.meta.arg.meetingID) {
        state.meeting = null;
      }
      toast.error(getCustomUserError(state.error, 'Failed to fetch 1-on-1 meeting'));
    });
    builder.addCase(fetchMeeting.fulfilled, (state, action) => {
      state.meeting = action.payload;
      state.isMeetingPending = false;
      state.error = null;
    });

    // Create meeting
    builder.addCase(createMeeting.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create 1-on-1 meeting'));
    });
    builder.addCase(createMeeting.fulfilled, (state, action) => {
      state.meeting = action.payload;
      toast.success('1-on-1 meeting created');
    });

    // Update meeting
    builder.addCase(updateMeeting.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update 1-on-1 meeting'));
    });
    builder.addCase(updateMeeting.fulfilled, (state, action) => {
      state.meeting = action.payload;
      toast.success('1-on-1 meeting updated');
    });

    // Create action item
    builder.addCase(createActionItem.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create action item'));
    });
    builder.addCase(createActionItem.fulfilled, (state, action) => {
      if (!state.meeting) return;
      state.meeting.actionItems.push(action.payload);
    });

    // Update action item
    builder.addCase(updateActionItem.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update action item'));
    });
    builder.addCase(updateActionItem.fulfilled, (state, action) => {
      if (!state.meeting) return;

      for (let i = 0; i < state.meeting.actionItems.length; i++) {
        if (state.meeting.actionItems[i].id === action.payload.id) {
          state.meeting.actionItems[i] = action.payload;
          break;
        }
      }
    });

    // Delete action item
    builder.addCase(deleteActionItem.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete action item'));
    });
    builder.addCase(deleteActionItem.fulfilled, (state, action) => {
      if (!state.meeting) return;
      state.meeting.actionItems = state.meeting.actionItems.filter(
        (ai) => ai.id !== action.meta.arg.actionItemID
      );
    });

    // Create agenda item
    builder.addCase(createAgendaItem.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to create agenda item'));
    });
    builder.addCase(createAgendaItem.fulfilled, (state, action) => {
      if (!state.meeting) return;
      state.meeting.agendaItems.push(action.payload);
    });

    // Update agenda item
    builder.addCase(updateAgendaItem.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to update agenda item'));
    });
    builder.addCase(updateAgendaItem.fulfilled, (state, action) => {
      if (!state.meeting) return;

      for (let i = 0; i < state.meeting.agendaItems.length; i++) {
        if (state.meeting.agendaItems[i].id === action.payload.id) {
          state.meeting.agendaItems[i] = action.payload;
          break;
        }
      }
    });

    // Delete agenda item
    builder.addCase(deleteAgendaItem.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to delete agenda item'));
    });
    builder.addCase(deleteAgendaItem.fulfilled, (state, action) => {
      if (!state.meeting) return;
      state.meeting.agendaItems = state.meeting.agendaItems.filter(
        (ai) => ai.id !== action.meta.arg.agendaItemID
      );
    });

    // Save meeting notes
    builder.addCase(saveMeetingNote.rejected, (state, action) => {
      const err = action.payload as api.ApiError;
      toast.error(getCustomUserError(err, 'Failed to save meeting note'));
    });
    builder.addCase(saveMeetingNote.fulfilled, (state, action) => {
      if (!state.meeting) return;

      let isNew = true;
      for (let i = 0; i < state.meeting.notes.length; i++) {
        if (state.meeting.notes[i].id === action.payload.id) {
          state.meeting.notes[i] = action.payload;
          isNew = false;
          break;
        }
      }

      if (isNew) {
        state.meeting.notes.push(action.payload);
      }
    });
  }
});

export default meetingsSlice.reducer;

export {
  // Meetings
  fetchMeetings,
  fetchMeeting,
  createMeeting,
  updateMeeting,

  // Action Items
  createActionItem,
  updateActionItem,
  deleteActionItem,

  // Agenda Items
  createAgendaItem,
  updateAgendaItem,
  deleteAgendaItem,

  // Meeting Notes
  saveMeetingNote
};
