import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { toast } from 'sonner';

import { useAppDispatch, useAppSelector } from '../../core/store';
import { Button } from '../ui/Button';
import HorizontalLine from '../HorizontalLine';
import UsersCombobox from '../UsersCombobox';
import BasicListbox from '../BasicListbox';
import { createTeam } from '../../core/team/teamsSlice';
import { fetchDepartments } from '../../core/cdf/departmentsSlice';
import { fetchOrganizationUsers } from '../../core/employee/employeesSlice';

interface CreateTeamModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateTeamModal = ({ open, onClose: _onClose }: CreateTeamModalProps): JSX.Element => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const departments = useAppSelector((state) => state.departments.departments);
  const employees = useAppSelector((state) => state.employees.employees);

  const [teamName, setTeamName] = useState('');
  const [teamDepartmentID, setTeamDepartmentID] = useState<string>();
  const [teamManagerID, setTeamManagerID] = useState<string>();

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    if (open) {
      dispatch(fetchDepartments({ organizationSlug }));
      dispatch(fetchOrganizationUsers({ organizationSlug }));
    }
  }, [open]);

  async function createTeamHandler(): Promise<void> {
    if (!teamName) {
      toast.warning('Please fill a team name');
      return;
    }

    const res: any = await dispatch(
      createTeam({
        organizationSlug,
        name: teamName,
        departmentID: teamDepartmentID,
        managerID: teamManagerID
      })
    );
    if (res.error) {
      return;
    }

    onClose();
  }

  function onClose(): void {
    // Clear all the fields
    setTeamName('');
    setTeamDepartmentID(undefined);
    setTeamManagerID(undefined);

    _onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-3 text-center sm:items-center sm:p-0 mt-14 sm:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-[480px] sm:pb-6">
                <div className="sm:mx-auto sm:w-full">
                  <div className="flex flex-row justify-between p-4 sm:px-6">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Create new team
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="size-5" aria-hidden="true" />
                    </button>
                  </div>

                  <HorizontalLine />

                  <div className="flex flex-col gap-5 py-6 px-4 sm:px-6">
                    <div className="flex flex-col gap-1">
                      <p className="block text-sm font-medium text-gray-700">Team name *</p>
                      <input
                        type="text"
                        autoComplete="off"
                        placeholder="Enter team name"
                        required
                        className="text-gray-900 h-9 rounded-md border-gray-300 text-sm"
                        onChange={(e) => {
                          setTeamName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="block text-sm font-medium text-gray-700">Team department</p>
                      <BasicListbox
                        items={departments}
                        placeholder="Select department"
                        onChange={(department) => {
                          setTeamDepartmentID(department.id);
                        }}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="block text-sm font-medium text-gray-700">Team manager</p>
                      <UsersCombobox
                        users={employees}
                        placeholder="Select team manager"
                        onChange={(manager) => {
                          setTeamManagerID(manager.id);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex sm:flex-row-reverse gap-3 px-4 sm:px-6 mt-1">
                  <Button
                    onClick={() => {
                      createTeamHandler();
                    }}>
                    Create
                  </Button>
                  <Button ref={cancelButtonRef} variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateTeamModal;
