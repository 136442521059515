import DevelopmentPath, { DevelopmentPathData } from '../core/cdf/DevelopmentPath.model';
import Level, { LevelData } from '../core/level/Level.model';
import Framework, { FrameworkData } from '../core/library/framework/Framework.model';
import FrameworkDepartment, {
  FrameworkDepartmentData
} from '../core/library/framework/FrameworkDepartment.model';
import SkillLevel, { SkillLevelData } from '../core/library/skill/SkillLevel.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetLibraryFrameworksArgs = GetOrganizationArgs;

async function getLibraryFrameworks({
  organizationSlug
}: GetLibraryFrameworksArgs): Promise<FrameworkData[]> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/library/frameworks`);

    const frameworksData = data.frameworks.map((framework: any) =>
      Framework.buildFrameworkDataFromResponse(framework)
    );
    return Promise.resolve(frameworksData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateLibraryFrameworkArgs = GetLibraryFrameworksArgs & {
  data: {
    name: string;
    description?: string;
    companySizeMin: number;
    companySizeMax: number;
  };
};

async function createLibraryFramework({
  organizationSlug,
  data: reqData
}: CreateLibraryFrameworkArgs): Promise<FrameworkData> {
  try {
    const { data } = await Api.post(`/organizations/${organizationSlug}/library/frameworks`, {
      name: reqData.name,
      description: reqData.description ?? null,
      company_size_min: reqData.companySizeMin,
      company_size_max: reqData.companySizeMax === Infinity ? null : reqData.companySizeMax
    });
    return Promise.resolve(Framework.buildFrameworkDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetLibraryFrameworkArgs = GetLibraryFrameworksArgs & {
  frameworkID: string;
};

async function getLibraryFramework({
  organizationSlug,
  frameworkID
}: GetLibraryFrameworkArgs): Promise<FrameworkData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}`
    );
    return Promise.resolve(Framework.buildFrameworkDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function cloneLibraryFramework({
  organizationSlug,
  frameworkID
}: GetLibraryFrameworkArgs): Promise<void> {
  try {
    await Api.post(`/organizations/${organizationSlug}/library/frameworks/${frameworkID}/clone`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateLibraryFrameworkArgs = GetLibraryFrameworkArgs & {
  data: {
    name?: string;
    description?: string;
    companySizeMin?: number;
    companySizeMax?: number;
  };
};

async function updateLibraryFramework({
  organizationSlug,
  frameworkID,
  data: reqData
}: UpdateLibraryFrameworkArgs): Promise<FrameworkData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}`,
      {
        name: reqData.name ?? null,
        description: reqData.description ?? null,
        company_size_min: reqData.companySizeMin ?? null,
        company_size_max: reqData.companySizeMax === Infinity ? null : reqData.companySizeMax
      }
    );
    return Promise.resolve(Framework.buildFrameworkDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteLibraryFramework({
  organizationSlug,
  frameworkID
}: GetLibraryFrameworkArgs): Promise<void> {
  try {
    await Api.delete(`/organizations/${organizationSlug}/library/frameworks/${frameworkID}`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateFrameworkDepartmentArgs = GetLibraryFrameworkArgs & {
  name: string;
};

async function createFrameworkDepartment({
  organizationSlug,
  frameworkID,
  name
}: CreateFrameworkDepartmentArgs): Promise<FrameworkDepartmentData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments`,
      {
        name
      }
    );
    return Promise.resolve(FrameworkDepartment.buildFrameworkDepartmentDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetFrameworkDepartmentArgs = GetLibraryFrameworkArgs & {
  departmentID: string;
};

async function getFrameworkDepartment({
  organizationSlug,
  frameworkID,
  departmentID
}: GetFrameworkDepartmentArgs): Promise<FrameworkDepartmentData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}`
    );
    return Promise.resolve(FrameworkDepartment.buildFrameworkDepartmentDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateFrameworkDepartmentArgs = GetFrameworkDepartmentArgs & {
  name: string;
};

async function updateFrameworkDepartment({
  organizationSlug,
  frameworkID,
  departmentID,
  name
}: UpdateFrameworkDepartmentArgs): Promise<FrameworkDepartmentData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}`,
      {
        name
      }
    );
    return Promise.resolve(FrameworkDepartment.buildFrameworkDepartmentDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteFrameworkDepartment({
  organizationSlug,
  frameworkID,
  departmentID
}: GetFrameworkDepartmentArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateFrameworkDevelopmentPathArgs = GetFrameworkDepartmentArgs & {
  name: string;
};

async function createFrameworkDevelopmentPath({
  organizationSlug,
  frameworkID,
  departmentID,
  name
}: CreateFrameworkDevelopmentPathArgs): Promise<DevelopmentPathData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/paths`,
      { name }
    );
    return Promise.resolve(DevelopmentPath.buildDevelopmentPathDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetFrameworkDevelopmentPathArgs = GetFrameworkDepartmentArgs & {
  developmentPathID: string;
};

export type UpdateFrameworkDevelopmentPathArgs = GetFrameworkDevelopmentPathArgs & {
  name: string;
};

async function updateFrameworkDevelopmentPath({
  organizationSlug,
  frameworkID,
  departmentID,
  developmentPathID,
  name
}: UpdateFrameworkDevelopmentPathArgs): Promise<DevelopmentPathData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/paths/${developmentPathID}`,
      { name }
    );
    const developmentPathData = DevelopmentPath.buildDevelopmentPathDataFromResponse(data);
    return Promise.resolve(developmentPathData);
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteFrameworkDevelopmentPath({
  organizationSlug,
  frameworkID,
  departmentID,
  developmentPathID
}: GetFrameworkDevelopmentPathArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/paths/${developmentPathID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type CreateFrameworkLevelArgs = GetFrameworkDevelopmentPathArgs & {
  levelData: {
    label: string;
    name: string;
    seniority: number;
  };
};

async function createFrameworkLevel({
  organizationSlug,
  frameworkID,
  departmentID,
  developmentPathID,
  levelData
}: CreateFrameworkLevelArgs): Promise<LevelData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/levels`,
      {
        development_path_id: developmentPathID,
        label: levelData.label,
        name: levelData.name,
        seniority: levelData.seniority
      }
    );
    return Promise.resolve(Level.buildLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetFrameworkLevelArgs = GetFrameworkDepartmentArgs & {
  levelID: string;
};

async function getFrameworkLevel({
  organizationSlug,
  frameworkID,
  departmentID,
  levelID
}: GetFrameworkLevelArgs): Promise<LevelData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/levels/${levelID}`
    );
    return Promise.resolve(Level.buildLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateFrameworkLevelArgs = GetFrameworkLevelArgs & {
  levelData: LevelData;
};

async function updateFrameworkLevel({
  organizationSlug,
  frameworkID,
  departmentID,
  levelID,
  levelData
}: UpdateFrameworkLevelArgs): Promise<LevelData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/levels/${levelID}`,
      {
        label: levelData.label,
        name: levelData.name,
        seniority: levelData.seniority,
        description: levelData.description
      }
    );
    return Promise.resolve(Level.buildLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function deleteFrameworkLevel({
  organizationSlug,
  frameworkID,
  departmentID,
  levelID
}: GetFrameworkLevelArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/levels/${levelID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type AssignSkillToFrameworkLevelArgs = GetFrameworkLevelArgs & {
  skillID: string;
  skillLevelID: string;
};

async function assignSkillToFrameworkLevel({
  organizationSlug,
  frameworkID,
  departmentID,
  levelID,
  skillID,
  skillLevelID
}: AssignSkillToFrameworkLevelArgs): Promise<SkillLevelData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/levels/${levelID}/skill`,
      {
        skill_id: skillID,
        skill_level_id: skillLevelID
      }
    );
    return Promise.resolve(SkillLevel.buildSkillLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UnassignSkillFromFrameworkLevelArgs = GetFrameworkLevelArgs & {
  skillLevelID: string;
};

async function unassignSkillFromFrameworkLevel({
  organizationSlug,
  frameworkID,
  departmentID,
  levelID,
  skillLevelID
}: UnassignSkillFromFrameworkLevelArgs): Promise<void> {
  try {
    await Api.delete(
      `/organizations/${organizationSlug}/library/frameworks/${frameworkID}/departments/${departmentID}/levels/${levelID}/skill/${skillLevelID}`
    );
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  // Framework
  getLibraryFrameworks,
  createLibraryFramework,
  getLibraryFramework,
  cloneLibraryFramework,
  updateLibraryFramework,
  deleteLibraryFramework,

  // Framework department
  createFrameworkDepartment,
  getFrameworkDepartment,
  updateFrameworkDepartment,
  deleteFrameworkDepartment,

  // Framework development path
  createFrameworkDevelopmentPath,
  updateFrameworkDevelopmentPath,
  deleteFrameworkDevelopmentPath,

  // Framework level
  createFrameworkLevel,
  getFrameworkLevel,
  updateFrameworkLevel,
  deleteFrameworkLevel,
  assignSkillToFrameworkLevel,
  unassignSkillFromFrameworkLevel
};
