export const INTEGRATION_TYPES = {
  GOOGLE_CALENDAR: 'google_calendar'
};

export interface UserIntegrationData {
  googleCalendar: boolean;
}

class UserIntegration implements UserIntegrationData {
  googleCalendar: boolean;

  constructor(data: UserIntegrationData) {
    this.googleCalendar = data.googleCalendar;
  }

  static buildUserIntegrationDataFromResponse(response: any): UserIntegrationData {
    return {
      googleCalendar: response.google_calendar
    };
  }
}

export default UserIntegration;
