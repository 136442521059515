import GrowArea, { GrowAreaData } from '../core/grow-area/GrowArea.model';
import Progression, { ProgressionData } from '../core/progression/Progression.model';
import ProgressionLevel, { ProgressionLevelData } from '../core/progression/ProgressionLevel.model';
import ProgressionLevelSkill, {
  ProgressionLevelSkillData
} from '../core/progression/ProgressionLevelSkill.model';
import { Api } from './api';
import { parseError } from './error';
import { GetOrganizationArgs } from './organization';

export type GetProgressionsArgs = GetOrganizationArgs;

async function getActiveProgression({
  organizationSlug
}: GetProgressionsArgs): Promise<ProgressionData> {
  try {
    const { data } = await Api.get(`/organizations/${organizationSlug}/progressions`);
    return Promise.resolve(Progression.buildProgressionDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetProgressionArgs = GetProgressionsArgs & {
  progressionUserID: string;
};

export type AssignUserProgressionLevelArgs = GetProgressionArgs & {
  levelID: string;
};

async function assignUserProgressionManagerOrLevel({
  organizationSlug,
  progressionUserID,
  levelID
}: AssignUserProgressionLevelArgs): Promise<ProgressionData> {
  try {
    const { data } = await Api.post(
      `/organizations/${organizationSlug}/progressions/${progressionUserID}/assign`,
      {
        level_id: levelID
      }
    );
    return Promise.resolve(Progression.buildProgressionDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function completeProgression({
  organizationSlug,
  progressionUserID
}: GetProgressionArgs): Promise<void> {
  try {
    await Api.post(`/organizations/${organizationSlug}/progressions/${progressionUserID}/promote`);
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function getProgression({
  organizationSlug,
  progressionUserID
}: GetProgressionArgs): Promise<ProgressionData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/progressions/${progressionUserID}`
    );
    return Promise.resolve(Progression.buildProgressionDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type UpdateProgressionArgs = GetProgressionArgs & {
  managerID?: string;
  nextLevelID?: string;
};

async function updateProgression({
  organizationSlug,
  progressionUserID,
  managerID,
  nextLevelID
}: UpdateProgressionArgs): Promise<ProgressionData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/progressions/${progressionUserID}`,
      {
        manager_id: managerID ?? null,
        next_level_id: nextLevelID ?? null
      }
    );
    return Promise.resolve(Progression.buildProgressionDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetProgressionSkillArgs = GetProgressionArgs & {
  skillLevelID: string;
};

export type UpdateProgressionSkillStatusArgs = GetProgressionSkillArgs & {
  status?: string;
  isStarred?: boolean;
};

async function updateProgressionSkillStatus({
  organizationSlug,
  progressionUserID,
  skillLevelID,
  status,
  isStarred
}: UpdateProgressionSkillStatusArgs): Promise<ProgressionLevelSkillData> {
  try {
    const { data } = await Api.patch(
      `/organizations/${organizationSlug}/progressions/${progressionUserID}/skills/${skillLevelID}`,
      {
        status: status ?? null,
        is_starred: isStarred ?? null
      }
    );
    return Promise.resolve(ProgressionLevelSkill.buildProgressionLevelSkillDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

async function getProgressionSkillGrowArea({
  organizationSlug,
  progressionUserID,
  skillLevelID
}: GetProgressionSkillArgs): Promise<GrowAreaData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/progressions/${progressionUserID}/skills/${skillLevelID}/grow-area`
    );
    return Promise.resolve(GrowArea.buildGrowAreaDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export type GetProgressionLevelArgs = GetProgressionArgs & {
  levelID: string;
};

async function getProgressionLevel({
  organizationSlug,
  progressionUserID,
  levelID
}: GetProgressionLevelArgs): Promise<ProgressionLevelData> {
  try {
    const { data } = await Api.get(
      `/organizations/${organizationSlug}/progressions/${progressionUserID}/levels/${levelID}`
    );
    return Promise.resolve(ProgressionLevel.buildProgressionLevelDataFromResponse(data));
  } catch (err) {
    return Promise.reject(parseError(err));
  }
}

export {
  getActiveProgression,
  assignUserProgressionManagerOrLevel,
  completeProgression,
  getProgression,
  updateProgression,
  updateProgressionSkillStatus,
  getProgressionSkillGrowArea,
  getProgressionLevel
};
