import React, { useEffect, useState } from 'react';
import { Outlet, useParams, NavLink, useNavigate } from 'react-router-dom';

import { AppLoader, Avatar, BasicListbox } from '../../components';
import { useAppDispatch } from '../../hooks';
import { useAppSelector } from '../../core/store';
import { fetchOrganizationUser } from '../../core/employee/employeesSlice';
import { classNames } from '../../common/utils';

const UserTopNavigationPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);

  const isOrgMemberPending = useAppSelector((state) => state.employees.isEmployeePending);
  const orgMember = useAppSelector((state) => state.employees.selectedEmployee);

  const organizationSlug = params.organizationSlug ?? '';

  const [tabs, setTabs] = useState([{ id: '', name: '' }]);

  const showOneOnOneTab = authUser.id !== orgMember?.id;
  const showGrowTab = authUser.id === orgMember?.manager?.id || authUser.id === orgMember?.id;

  useEffect(() => {
    dispatch(fetchOrganizationUser({ organizationSlug, userID: params.memberID ?? '' }));
  }, [params.memberID]);

  useEffect(() => {
    const showTabs = [];
    if (showOneOnOneTab) {
      showTabs.push({
        id: `/${organizationSlug}/members/${orgMember?.id}/one-on-one`,
        name: '1-on-1'
      });
    }
    if (showGrowTab) {
      showTabs.push({
        id: `/${organizationSlug}/members/${orgMember?.id}/grow`,
        name: 'Grow'
      });
    }

    setTabs(showTabs);
  }, [orgMember?.id]);

  if (isOrgMemberPending) {
    return <AppLoader />;
  }

  return (
    <>
      <div className="flex flex-col gap-3 justify-start h-full w-full">
        <div className="relative flex flex-row justify-between gap-3 items-center py-2 px-3 bg-gray-50 border border-gray-200 shadow rounded-lg">
          <div className="flex flex-row gap-3 items-center">
            <Avatar size={8} user={orgMember} />
            <div className="flex flex-col">
              <p className="text-gray-900 font-medium">
                {orgMember?.firstName
                  ? `${orgMember.firstName} ${orgMember?.lastName ?? ''}`
                  : 'Deleted User'}
              </p>
              <p className="text-gray-700 text-sm">
                {orgMember?.department?.level ? (
                  `${orgMember.department.level.name}, ${orgMember.department.name}`
                ) : (
                  <span className="italic">{'no position specified'}</span>
                )}
              </p>
            </div>
          </div>

          {/* Mobile view */}
          <div className="sm:hidden">
            <BasicListbox
              items={tabs}
              defaultItem={tabs[0]}
              placeholder="Select page"
              onChange={(page) => {
                navigate(page.id);
              }}
            />
          </div>

          {/* Desktop view */}
          <div className="absolute w-full pr-6">
            <div className="hidden sm:flex flex-row justify-center gap-3 items-center">
              {showOneOnOneTab && (
                <NavLink to={`/${organizationSlug}/members/${orgMember?.id}/one-on-one`}>
                  {({ isActive }) => (
                    <div
                      className={classNames(
                        isActive
                          ? 'text-indigo-600 border-b-2 border-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:border-b-2 hover:border-indigo-600',
                        'group flex gap-x-3 py-1 px-3 text-sm font-semibold'
                      )}>
                      1-on-1
                    </div>
                  )}
                </NavLink>
              )}

              {showGrowTab && (
                <NavLink to={`/${organizationSlug}/members/${orgMember?.id}/grow`}>
                  {({ isActive }) => (
                    <div
                      className={classNames(
                        isActive
                          ? 'text-indigo-600 border-b-2 border-indigo-600'
                          : 'text-gray-700 hover:text-indigo-600 hover:border-b-2 hover:border-indigo-600',
                        'group flex gap-x-3 py-1 px-3 text-sm font-semibold'
                      )}>
                      Grow
                    </div>
                  )}
                </NavLink>
              )}
            </div>
          </div>
        </div>

        <div className="flex-1">{<Outlet />}</div>
      </div>
    </>
  );
};

export default UserTopNavigationPage;
