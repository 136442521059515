import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'sonner';

import * as api from '../../api';
import { getCustomUserError } from '../../api/error';
import { LevelData } from './Level.model';

type LevelState = {
  level: LevelData | null;
  isLevelPending: boolean;
  error: api.ApiError;
};

const initialState: LevelState = {
  level: null,
  isLevelPending: true,
  error: null
};

const createLevel = createAsyncThunk(
  'level/createLevel',
  async (args: api.CreateLevelArgs, { rejectWithValue }) => {
    try {
      return await api.createLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const fetchLevel = createAsyncThunk(
  'level/getLevel',
  async (args: api.GetLevelArgs, { rejectWithValue }) => {
    try {
      return await api.getLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateLevel = createAsyncThunk(
  'level/updateLevel',
  async (args: api.UpdateLevelArgs, { rejectWithValue }) => {
    try {
      return await api.updateLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const deleteLevel = createAsyncThunk(
  'level/deleteLevel',
  async (args: api.GetLevelArgs, { rejectWithValue }) => {
    try {
      return await api.deleteLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const assignUserToLevel = createAsyncThunk(
  'level/assignUserToLevel',
  async (args: api.AssignUserProgressionLevelArgs, { rejectWithValue }) => {
    try {
      return await api.assignUserProgressionManagerOrLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const assignSkillToLevel = createAsyncThunk(
  'level/assignSkillToLevel',
  async (args: api.AssignSkillToLevelArgs, { rejectWithValue }) => {
    try {
      return await api.assignSkillToLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const unassignSkillFromLevel = createAsyncThunk(
  'level/unassignSkillFromLevel',
  async (args: api.UnassignSkillFromLevelArgs, { rejectWithValue }) => {
    try {
      return await api.unassignSkillFromLevel(args);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type UpdateLevelSkillCardArgs = {
  skillLevelID: string;
  skillName: string;
};

const levelSlice = createSlice({
  name: 'level',
  initialState,
  reducers: {
    updateLevelSkillCard(state, action: PayloadAction<UpdateLevelSkillCardArgs>) {
      if (!state.level) {
        return;
      }
      state.level.skills = state.level.skills.map((skillLevel) => {
        if (skillLevel.id === action.payload.skillLevelID) {
          return {
            ...skillLevel,
            skillName: action.payload.skillName
          };
        }
        return skillLevel;
      });
    }
  },
  extraReducers: (builder) => {
    // Create level
    builder.addCase(createLevel.rejected, (state, action) => {
      toast.error(getCustomUserError(action.payload as api.ApiError, 'Failed to create level'));
    });
    builder.addCase(createLevel.fulfilled, (state, action) => {
      state.level = action.payload;
    });

    // Fetch level
    builder.addCase(fetchLevel.pending, (state) => {
      state.isLevelPending = true;
      state.error = null;
    });
    builder.addCase(fetchLevel.rejected, (state, action) => {
      state.isLevelPending = false;
      state.error = action.payload as api.ApiError;
      toast.error(getCustomUserError(state.error, 'Failed to fetch level'));
    });
    builder.addCase(fetchLevel.fulfilled, (state, action) => {
      state.error = null;
      state.isLevelPending = false;
      state.level = action.payload;
    });

    // Update level
    builder.addCase(updateLevel.rejected, (state, action) => {
      toast.error(getCustomUserError(action.payload as api.ApiError, 'Failed to update level'));
    });
    builder.addCase(updateLevel.fulfilled, (state, action) => {
      state.level = action.payload;
    });

    // Delete level
    builder.addCase(deleteLevel.rejected, (state, action) => {
      toast.error(getCustomUserError(action.payload as api.ApiError, 'Failed to delete level'));
    });
    builder.addCase(deleteLevel.fulfilled, (state, action) => {
      state.level = null;
    });

    // Assign user to level
    builder.addCase(assignUserToLevel.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to assign user to level')
      );
    });
    builder.addCase(assignUserToLevel.fulfilled, (state, action) => {
      if (!state.level) {
        return;
      }
      state.level.assignedUsers.push(action.payload.user);
    });

    // Assign skill to level
    builder.addCase(assignSkillToLevel.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to assign skill to level')
      );
    });
    builder.addCase(assignSkillToLevel.fulfilled, (state, action) => {
      if (!state.level) {
        return;
      }
      state.level.skills.push(action.payload);
      state.level.skills.sort((a, b) => (a.skillName ?? '').localeCompare(b.skillName ?? ''));
    });

    // Unassign skill from level
    builder.addCase(unassignSkillFromLevel.rejected, (state, action) => {
      toast.error(
        getCustomUserError(action.payload as api.ApiError, 'Failed to unassign skill from level')
      );
    });
    builder.addCase(unassignSkillFromLevel.fulfilled, (state, action) => {
      if (!state.level) {
        return;
      }
      state.level.skills = state.level.skills.filter(
        (skillLevel) => skillLevel.id !== action.meta.arg.skillLevelID
      );
    });
  }
});

export const { updateLevelSkillCard } = levelSlice.actions;

export default levelSlice.reducer;

export {
  createLevel,
  fetchLevel,
  updateLevel,
  deleteLevel,
  assignUserToLevel,
  assignSkillToLevel,
  unassignSkillFromLevel
};
