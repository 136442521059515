export type GrowAreaActionData = {
  /** @type {Generics.UUID} */
  id: string;
  /** @type {Generics.UUID} */
  growAreaID: string;
  /** @type {Generics.UUID} */
  createdByID?: string;

  description: string;
  completed: boolean;
};

class GrowAreaAction implements GrowAreaActionData {
  id: string;
  growAreaID: string;
  createdByID?: string;
  description: string;
  completed: boolean;

  constructor(data: GrowAreaActionData) {
    this.id = data.id;
    this.growAreaID = data.growAreaID;
    this.createdByID = data.createdByID;
    this.description = data.description;
    this.completed = data.completed;
  }

  static buildGrowAreaActionDataFromResponse(response: any): GrowAreaActionData {
    return {
      id: response.id,
      growAreaID: response.grow_area_id,
      createdByID: response.created_by_id,
      description: response.description,
      completed: response.completed
    };
  }
}

export default GrowAreaAction;
