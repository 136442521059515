import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';

import { useAppDispatch } from '../../../hooks';
import { useAppSelector } from '../../../core/store';
import { LevelData } from '../../../core/level/Level.model';
import { checkPermissionAccess } from '../../../common/utils';
import {
  deleteFrameworkLevel,
  fetchFrameworkDepartment,
  fetchFrameworkLevel,
  updateFrameworkDepartmentLevelName,
  updateFrameworkLevel
} from '../../../core/library/framework/frameworksSlice';
import { Button } from '../../ui/Button';
import AppLoader from '../../AppLoader';
import SkillCard from '../../cdf/SkillCard';
import HorizontalLine from '../../HorizontalLine';
import WysiwygEditor from '../../wysiwyg/WysiwygEditor';
import PermissionProtectedAccess from '../../PermissionProtectedAccess';
import SkillLibrarySlideover from '../skill/SkillLibrarySlideover';
import ConfirmationDialog from '../../ui/ConfirmationDialog';

type FrameworkLevelOverviewProps = {
  frameworkID: string;
  departmentID: string;
  levelID: string;
};

const FrameworkLevelOverview = ({
  frameworkID,
  departmentID,
  levelID
}: FrameworkLevelOverviewProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const isFrameworkLevelPending = useAppSelector(
    (state) => state.libraryFrameworks.isFrameworkLevelPending
  );
  const frameworkLevel = useAppSelector((state) => state.libraryFrameworks.level);
  const organizationSlug = useAppSelector((state) => state.organization.selectedOrganization.slug);

  const [openSlideover, setOpenSlideover] = useState(false);
  const [deleteLevelBtnModalOpen, setDeleteLevelBtnModalOpen] = useState(false);

  const haveEditorAccess =
    !!authUser.isInternal && checkPermissionAccess(authUser.permissionRole, 'editor');

  useEffect(() => {
    dispatch(fetchFrameworkLevel({ organizationSlug, frameworkID, departmentID, levelID }));
  }, [frameworkID, departmentID, levelID]);

  const updateLevelDebouncedFn = useMemo(() => {
    return _.debounce((_levelID: string, data: LevelData) => {
      dispatch(
        updateFrameworkLevel({
          organizationSlug,
          frameworkID,
          departmentID,
          levelID: _levelID,
          levelData: data
        })
      );
    }, 500);
  }, [levelID]);

  async function deleteDevelopmentPathLevel(levelID: string): Promise<void> {
    const res: any = await dispatch(
      deleteFrameworkLevel({
        organizationSlug,
        frameworkID,
        departmentID,
        levelID
      })
    );
    if (res.error) {
      return;
    }

    dispatch(
      fetchFrameworkDepartment({
        organizationSlug,
        frameworkID,
        departmentID
      })
    );
  }

  if (isFrameworkLevelPending) {
    return <AppLoader />;
  }

  if (!frameworkLevel) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <ExclamationTriangleIcon className="size-12 text-gray-600" />
        <h3 className="text-xl font-semibold text-gray-600">Framework level not found!</h3>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-start gap-2 h-full w-full sm:overflow-hidden">
        <div className="flex-none flex flex-row justify-between mt-1">
          <PermissionProtectedAccess
            minimalRequiredPermissionRole="editor"
            internalAccessRequired
            fallbackElement={
              <Dialog.Title as="h3" className="font-semibold text-lg text-gray-900">
                {frameworkLevel.name}
              </Dialog.Title>
            }>
            <input
              type="text"
              defaultValue={frameworkLevel.name}
              autoComplete="off"
              className="font-semibold rounded-md text-lg ml-2 h-8 text-gray-900 border-0 p-0 focus:p-1 w-[25rem]"
              onChange={(e) => {
                dispatch(
                  updateFrameworkDepartmentLevelName({
                    frameworkID,
                    departmentID,
                    developmentPathID: frameworkLevel.developmentPathID,
                    levelID,
                    name: e.target.value
                  })
                );
                updateLevelDebouncedFn(levelID, { ...frameworkLevel, name: e.target.value });
              }}
            />
          </PermissionProtectedAccess>

          <PermissionProtectedAccess minimalRequiredPermissionRole="editor" internalAccessRequired>
            <>
              <Button
                variant={'destructive'}
                className="h-8"
                onClick={() => {
                  setDeleteLevelBtnModalOpen(true);
                }}>
                Delete
              </Button>
              <ConfirmationDialog
                open={deleteLevelBtnModalOpen}
                title={'Are you absolutely sure?'}
                description={
                  <span>
                    This action cannot be undone. Confirming this action will permanently delete{' '}
                    <span className="font-semibold">{frameworkLevel.name}</span> definition.
                  </span>
                }
                variant="destructive"
                onClose={() => setDeleteLevelBtnModalOpen(false)}
                onConfirm={() => {
                  deleteDevelopmentPathLevel(frameworkLevel.id);
                  setDeleteLevelBtnModalOpen(false);
                  navigate(-1);
                }}
              />
            </>
          </PermissionProtectedAccess>
        </div>

        <div className="flex-initial">
          <WysiwygEditor
            placeholder={
              haveEditorAccess
                ? `Add your ${frameworkLevel.name} description here...`
                : 'Level description not available'
            }
            initialContent={frameworkLevel.description}
            stringHandler="html"
            className={`${haveEditorAccess && 'p-2'} text-base text-gray-700`}
            editable={haveEditorAccess}
            onChange={(html) => {
              updateLevelDebouncedFn(levelID, { ...frameworkLevel, description: html });
            }}
          />
        </div>

        <div className="flex-none">
          <HorizontalLine />
        </div>

        <div className={`flex-1 ${haveEditorAccess && 'pl-2'}`}>
          <div className="sm:relative w-full h-full">
            <div className="sm:absolute sm:max-h-full flex flex-col gap-4 w-full">
              <div className="flex-1 flex flex-row">
                <div className="flex-none mr-4 font-semibold text-base text-gray-700">
                  Required Skills
                </div>
                <div className="flex-1 flex flex-row justify-between">
                  <div className="flex-initial flex items-center justify-center w-6 rounded-md text-base text-gray-800 bg-gray-100">
                    {frameworkLevel.skills.length}
                  </div>
                  <PermissionProtectedAccess
                    minimalRequiredPermissionRole="editor"
                    internalAccessRequired>
                    <Button
                      size={'sm'}
                      variant={'link'}
                      className="h-6 font-semibold text-base"
                      onClick={() => setOpenSlideover(true)}>
                      Add Skill
                    </Button>
                  </PermissionProtectedAccess>
                </div>
              </div>
              <div className="flex-1 flex flex-col gap-3 sm:overflow-auto sm:pr-1">
                {frameworkLevel.skills.map((skill) => (
                  <div key={skill.id} className="flex-1">
                    <SkillCard
                      usedFor="frameworkLibrary"
                      skillLevel={skill}
                      editable={haveEditorAccess}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SkillLibrarySlideover
        usedFor="frameworkLibrary"
        open={openSlideover}
        setOpen={setOpenSlideover}
      />
    </>
  );
};

export default FrameworkLevelOverview;
