import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

import { classNames } from '../../common/utils';

const tabs = [
  { name: 'General', to: 'general' },
  { name: 'Billing', to: 'billing' }
];

const SettingsPage = (): JSX.Element => {
  return (
    <>
      <div className="flex flex-col justify-start h-full w-full">
        <div className="block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <NavLink key={tab.name} to={tab.to}>
                  {({ isActive }) => (
                    <p
                      className={classNames(
                        isActive
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                      )}>
                      {tab.name}
                    </p>
                  )}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex-1">{<Outlet />}</div>
      </div>
    </>
  );
};

export default SettingsPage;
