import Department, { DepartmentData } from '../cdf/Department.model';
import ProgressionLevelSkill, { ProgressionLevelSkillData } from './ProgressionLevelSkill.model';

export interface ProgressionLevelData {
  /** @type {Generics.UUID} */
  id: string;

  /** @type {Generics.UUID} */
  departmentID: string;
  department?: DepartmentData;

  /** @type {Generics.UUID} */
  developmentPathID: string;

  label: string;
  seniority: number;
  name: string;
  skills: ProgressionLevelSkillData[];
}

class ProgressionLevel implements ProgressionLevelData {
  id: string;

  departmentID: string;
  department?: DepartmentData;

  developmentPathID: string;

  label: string;
  seniority: number;
  name: string;
  skills: ProgressionLevelSkillData[];

  constructor(data: ProgressionLevelData) {
    this.id = data.id;
    this.departmentID = data.departmentID;
    this.department = data.department;
    this.developmentPathID = data.developmentPathID;
    this.label = data.label;
    this.seniority = data.seniority;
    this.name = data.name;
    this.skills = data.skills;
  }

  static buildProgressionLevelDataFromResponse(response: any): ProgressionLevelData {
    const skills: ProgressionLevelSkillData[] = (response.skills ?? []).map((skill: any) =>
      ProgressionLevelSkill.buildProgressionLevelSkillDataFromResponse(skill)
    );

    const sortedSkills = skills.sort((a, b) =>
      (a.skillName ?? '').localeCompare(b.skillName ?? '')
    );

    return {
      id: response.id,
      departmentID: response.department_id,
      department: response.department
        ? Department.buildDepartmentDataFromResponse(response.department)
        : undefined,
      developmentPathID: response.development_path_id,
      label: response.label,
      seniority: response.seniority,
      name: response.name,
      skills: sortedSkills
    };
  }
}

export default ProgressionLevel;
