import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { checkIfSkillAlreadyImported, checkPermissionAccess, classNames } from '../../common/utils';
import { useAppDispatch, useAppSelector } from '../../core/store';
import { SkillData } from '../../core/library/skill/Skill.model';
import { SkillLevelData } from '../../core/library/skill/SkillLevel.model';
import {
  librarySkillSelector,
  fetchLibrarySkill,
  duplicateLibrarySkill,
  deleteLibrarySkill,
  deleteLibrarySkillLevel,
  createLibrarySkillLevel,
  updateLibrarySkillLevel,
  updateLibrarySkill,
  fetchLibrarySkills
} from '../../core/library/skill/skillsSlice';
import {
  HorizontalLineWithButton,
  AppLoader,
  HorizontalLine,
  PermissionProtectedAccess,
  SkillLevelDescription,
  SkillLevelOnChangeFn,
  Button,
  ConfirmationDialog
} from '../../components';

const SkillLibraryOverviewPage = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authUser = useAppSelector((state) => state.user);
  const areSkillsFetched = useAppSelector((state) => state.librarySkills.areSkillsFetched);
  const librarySkills = useAppSelector((state) => state.librarySkills.skills);
  const isSkillPending = useAppSelector((state) => state.librarySkills.isSkillPending);
  const librarySkill = useAppSelector((state) =>
    librarySkillSelector(state.librarySkills, params.skillID)
  );

  const [deleteSkillBtnModalOpen, setDeleteSkillBtnModalOpen] = useState(false);

  const orgSkills = librarySkills.filter((skill) => skill.private);

  const organizationSlug = params.organizationSlug ?? '';

  useEffect(() => {
    (async () => {
      if (!areSkillsFetched) {
        await dispatch(fetchLibrarySkills({ organizationSlug }));
      }
      if (librarySkill?.isFullyLoaded) {
        return;
      }
      dispatch(fetchLibrarySkill({ organizationSlug, skillID: params.skillID ?? '' }));
    })();
  }, []);

  async function duplicateLibrarySkillHandler(): Promise<void> {
    await dispatch(duplicateLibrarySkill({ organizationSlug, skillID: params.skillID ?? '' }));
    if (!librarySkill?.private) {
      return;
    }

    setTimeout(() => {
      navigate('../');
    }, 30);
  }

  async function deleteLibrarySkillHandler(): Promise<void> {
    await dispatch(deleteLibrarySkill({ organizationSlug, skillID: params.skillID ?? '' }));
    setTimeout(() => {
      navigate('../');
    }, 30);
  }

  const updateSkillData = useMemo(() => {
    return _.debounce((newSkillData: SkillData) => {
      dispatch(updateLibrarySkill({ organizationSlug, skillData: newSkillData }));
    }, 1000);
  }, []);

  const updateSkillLevelData = useMemo(() => {
    return _.debounce(
      (
        skillID: string,
        skillLevel: SkillLevelData,
        key: 'description' | 'examples',
        value: string
      ) => {
        const newSkillLevel = { ...skillLevel };
        newSkillLevel[key] = value;
        dispatch(
          updateLibrarySkillLevel({ organizationSlug, skillID, skillLevelData: newSkillLevel })
        );
      },
      1000
    );
  }, []);

  function onSkillLevelDataChange(
    skillID: string,
    skillLevel: SkillLevelData
  ): SkillLevelOnChangeFn {
    return (key: 'description' | 'examples', value: string) => {
      updateSkillLevelData(skillID, skillLevel, key, value);
    };
  }

  function renderSkillTitle(name: string): JSX.Element {
    return (
      <Dialog.Title as="h3" className="flex-1 mr-5 font-semibold text-lg text-gray-900">
        {name}
      </Dialog.Title>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2 justify-start h-full w-full">
        <Transition.Root show={true} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => {
              setTimeout(() => {
                navigate('../');
              }, 30);
            }}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <Dialog.Panel className="relative transform overflow-hidden overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all p-4 sm:p-6 w-full h-full max-w-[90vw] lg:max-w-[70vw] max-h-[80vh]">
                    <div className="flex flex-col h-full">
                      {isSkillPending || !librarySkill ? (
                        <AppLoader />
                      ) : (
                        <>
                          <div className="flex flex-row justify-between h-full">
                            {!librarySkill.private ? (
                              renderSkillTitle(librarySkill.name)
                            ) : (
                              <PermissionProtectedAccess
                                minimalRequiredPermissionRole="editor"
                                fallbackElement={renderSkillTitle(librarySkill.name)}>
                                <input
                                  type="text"
                                  defaultValue={librarySkill.name}
                                  className="flex-1 mr-5 font-semibold rounded-md text-xl h-8 text-gray-900 border-0 p-0 focus:p-1"
                                  onChange={(e) => {
                                    const newSkillData = { ...librarySkill };
                                    newSkillData.name = e.target.value;
                                    updateSkillData(newSkillData);
                                  }}
                                />
                              </PermissionProtectedAccess>
                            )}

                            <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                              <div className="flex flex-row gap-4">
                                <Button
                                  size={'sm'}
                                  className={
                                    !librarySkill.private &&
                                    checkIfSkillAlreadyImported(orgSkills, librarySkill.id)
                                      ? 'bg-indigo-500 hover:bg-indigo-500 cursor-not-allowed'
                                      : ''
                                  }
                                  onClick={() => {
                                    if (
                                      !librarySkill.private &&
                                      checkIfSkillAlreadyImported(orgSkills, librarySkill.id)
                                    ) {
                                      return;
                                    }
                                    duplicateLibrarySkillHandler();
                                  }}>
                                  {librarySkill.private
                                    ? 'Duplicate'
                                    : checkIfSkillAlreadyImported(orgSkills, librarySkill.id)
                                    ? 'Imported'
                                    : 'Import to Organization'}
                                </Button>
                                <Button
                                  size={'sm'}
                                  variant={'destructive'}
                                  className={classNames(librarySkill.private ? '' : 'hidden')}
                                  onClick={() => setDeleteSkillBtnModalOpen(true)}>
                                  Delete
                                </Button>
                                <ConfirmationDialog
                                  open={deleteSkillBtnModalOpen}
                                  title={'Are you absolutely sure?'}
                                  description={
                                    'This action cannot be undone. This will permanently delete the skill, and remove it from all level definitions.'
                                  }
                                  variant={'destructive'}
                                  onClose={() => setDeleteSkillBtnModalOpen(false)}
                                  onConfirm={() => {
                                    deleteLibrarySkillHandler();
                                    setDeleteSkillBtnModalOpen(false);
                                  }}
                                />
                              </div>
                            </PermissionProtectedAccess>
                          </div>

                          <div className="flex flex-row gap-3 mt-2">
                            <p className="truncate text-sm text-gray-600 max-w-[100px]">
                              @{librarySkill.organization.name}
                            </p>
                          </div>

                          {!librarySkill.private ? (
                            <p className="text-base text-gray-600 my-4">
                              {librarySkill.description}
                            </p>
                          ) : (
                            <PermissionProtectedAccess
                              minimalRequiredPermissionRole="editor"
                              fallbackElement={
                                <p className="text-base text-gray-600 my-4">
                                  {librarySkill.description}
                                </p>
                              }>
                              <TextareaAutosize
                                defaultValue={librarySkill.description}
                                className="w-full text-base rounded-md text-gray-600 border-0 p-0 focus:px-0.5 max-h-[15rem] my-4"
                                onChange={(e) => {
                                  const newSkillData = { ...librarySkill };
                                  newSkillData.description = e.target.value;
                                  updateSkillData(newSkillData);
                                }}
                              />
                            </PermissionProtectedAccess>
                          )}

                          <div className="mb-4">
                            <HorizontalLine />
                          </div>

                          <ul role="list" className="flex flex-col flex-nowrap gap-4">
                            {(librarySkill.levels ?? []).map((level) => (
                              <li
                                key={level.id}
                                className="flex flex-col gap-2 p-3 rounded-lg border border-gray-200 shadow-sm hover:border-gray-400">
                                <div className="flex flex-row justify-between">
                                  <h2 className="font-semibold text-lg text-gray-900">
                                    Level {level.seniority}
                                  </h2>
                                  <PermissionProtectedAccess minimalRequiredPermissionRole="editor">
                                    <Button
                                      size={'sm'}
                                      variant={'outline'}
                                      className={
                                        librarySkill.private
                                          ? 'text-red-500 hover:text-red-500'
                                          : 'hidden'
                                      }
                                      onClick={() => {
                                        dispatch(
                                          deleteLibrarySkillLevel({
                                            organizationSlug,
                                            skillID: librarySkill.id,
                                            skillLevelID: level.id
                                          })
                                        );
                                      }}>
                                      Remove
                                    </Button>
                                  </PermissionProtectedAccess>
                                </div>
                                <SkillLevelDescription
                                  skillLevelData={level}
                                  editable={
                                    librarySkill.private &&
                                    checkPermissionAccess(authUser.permissionRole, 'editor')
                                  }
                                  onChange={onSkillLevelDataChange(librarySkill.id, level)}
                                />
                              </li>
                            ))}
                          </ul>
                          <div
                            className={classNames(
                              librarySkill.private &&
                                checkPermissionAccess(authUser.permissionRole, 'editor')
                                ? ''
                                : 'hidden',
                              'mt-5'
                            )}>
                            <HorizontalLineWithButton
                              buttonText="Add new Level Definition"
                              onClick={() => {
                                dispatch(
                                  createLibrarySkillLevel({
                                    organizationSlug,
                                    skillID: librarySkill.id,
                                    skillLevelData: {
                                      id: uuidv4(),
                                      seniority: librarySkill.levels.length + 1,
                                      description: '',
                                      examples: ''
                                    }
                                  })
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default SkillLibraryOverviewPage;
