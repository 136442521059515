import React, { Fragment, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox, Transition } from '@headlessui/react';

import { classNames } from '../common/utils';
import Avatar from './ui/Avatar';
import { useAppSelector } from '../core/store';

export type ComboboxUser = {
  /** @type {Generics.UUID} */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  picture?: string | null;
};

type UsersComboboxProps = {
  users: ComboboxUser[];
  defaultValue?: ComboboxUser | null;
  placeholder?: string;
  showEmail?: boolean;
  removeAuthUser?: boolean;
  onChange?: (user: ComboboxUser) => void;
};

const UsersCombobox = ({
  users,
  defaultValue,
  placeholder = 'Select a employee',
  showEmail = false,
  removeAuthUser = true,
  onChange = () => {}
}: UsersComboboxProps): JSX.Element => {
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<ComboboxUser | undefined>(
    defaultValue ? users.find((user) => user.id === defaultValue.id) ?? defaultValue : undefined
  );

  const authUser = useAppSelector((state) => state.user);

  if (removeAuthUser && users.length > 0) {
    users = users.filter((user) => user.id !== selectedUser?.id && user.id !== authUser.id);
  }

  const filteredOrganizationUsers =
    query === ''
      ? users
      : users.filter((user) => {
          return `${user.firstName} ${user.lastName}`.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      defaultValue={selectedUser}
      onChange={(newValue) => {
        setSelectedUser(newValue);
        onChange(newValue);
      }}>
      <div className="relative">
        <div>
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-2 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(user: ComboboxUser) => {
              if (showEmail) {
                return `${user.firstName} ${user.lastName} <${user.email}>`;
              }
              return user.firstName + ' ' + user.lastName;
            }}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}>
          <Combobox.Options className="absolute z-10 mt-1 max-h-52 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredOrganizationUsers.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                Nothing found
              </div>
            ) : (
              filteredOrganizationUsers.map((user) => (
                <Combobox.Option
                  key={user.id}
                  value={user}
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }>
                  {({ active, selected }) => (
                    <>
                      <div className="flex items-center">
                        <Avatar user={user} size={6} />
                        <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                          {showEmail
                            ? `${user.firstName} ${user.lastName} <${user.email}>`
                            : `${user.firstName} ${user.lastName}`}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                          )}>
                          <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default UsersCombobox;
